import React, {useEffect, useState} from 'react';
import { CSSTransition } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import _useTranslate from '../hooks/_useTranslate';

const SmallAlert = ({ show, mode, heading, onClose, message, feathersStore}) =>{ 

  const redColored = {color: "hsl(358, 78%, 50%)", fontSize: 35, marginRight: 1}
  const greenColored = {color: "hsl(151, 100%, 28%)", fontSize: 35, marginRight: 1}

  let navigate = useNavigate(); 
  let common = _useTranslate(feathersStore.language, "common");  

  useEffect(() => {  
    let timeoutId = null; 
  if(show){
    timeoutId = setTimeout(onClose, 7000);
  } 
  if (timeoutId){
     return () => { clearTimeout(timeoutId) }
  }else{
    return () => null
  }
  
  },[show])

  const goToCart = () => {
    navigate('/cart');
  }
    
  return(
    <>
    <div className="cookie-updated-alert">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <div className="small-alert">
          <div className="alert-title-text">
            { (mode === "stock" ||  mode === "error") ? <NotificationsActiveIcon sx={redColored}/> :
              ( mode === "cart" || mode === "qrcode") ? <CheckCircleIcon sx={greenColored}/> :          
              <FavoriteBorderIcon sx={redColored}/>
            }
            {heading}
          </div> 
          <div className="alert-body-text">{message}</div>
          {mode === "cart" &&
            <div className="go-cart" onClick={goToCart} >{common.show}</div>
          } 
          <CloseIcon className="alert-close-icon"  onClick={onClose}/>
        </div>
      </CSSTransition>
    </div>
  </>
  )
}

export default inject('feathersStore')(observer(SmallAlert));