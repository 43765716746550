import React from 'react';
import { CardActionArea } from '@mui/material';


const SectionTitle = ({image, title, all, allClicked, related})=> {

	return(	
		<>	
			<div className={related ? "related-title-container" : "title-container"}>
				<span className="title-text">{title}</span>
				{related && <div className="line-100"></div>}	
				{image && 
					<div className="title-image-container">
						<img className="title-image" src={image}></img>
					</div>
				}
				{all &&			
					<div className="all-title-button" onClick={allClicked}>
					<CardActionArea className="all-title-text">{all}</CardActionArea>
					</div>
					
				}				
			</div>
			<div className="container">
				<div className="after-title"></div>
			</div>
		</>
	)
}

export default SectionTitle;