import React, {useEffect, useState} from 'react';
import CategoryPic from './categoryPic';
import Category from './category';
import { inject, observer } from "mobx-react";
import { useParams, useSearchParams } from 'react-router-dom';
import {API_URL} from "../assets/globalConstants";
import {pickLanguageWord} from '../utils/pickLanguageWord.js';
import { CircularProgress } from '@mui/material';

// Translations
import _useTranslate from '../hooks/_useTranslate';
import {commonEL} from "../locales/el/common";

const CategoryView = ({ feathersStore }) => {

  const {name} = useParams(); 
  let [searchParams, setSearchParams] = useSearchParams();
    
  let catId;
  let title;
  let titleTranslated; 
  let item;

  //Browser BackButton and Refresh usage
  useEffect(()=>{    
    let payload =  {searchText: "", title: commonEL.all, titleTranslated: pickLanguageWord(feathersStore.language, "all")};
    if ((name || searchParams) && feathersStore.categories) {    
      searchTree(feathersStore.categories, name);
     name && Object.assign(payload, {
       categoryId: catId, title: title, titleTranslated: titleTranslated, item: item});
     searchParams && Object.assign(payload, {searchText: searchParams.get('search') || ""});            
    }
    feathersStore.setSearchCategory(payload); 
 },[name, searchParams,  feathersStore.categories, feathersStore.language]);

 const searchTree = (tree, name) => {
   tree.forEach(element => {
    if(element.name?.trim() === name || feathersStore._translateCategoryName(element)?.trim() === name){    
      catId = element.id;
      title =  element.name?.trim();
      titleTranslated = feathersStore._translateCategoryName(element);
      item = element;
    }else{
      element.childrens && searchTree(element.childrens, name)
    }
   }) 
  }  


  return(
    <div className="body-100">      
      {feathersStore.searchCategory.item?.options && <CategoryPic 
        image={feathersStore.searchCategory.item?.options?.href ? `${API_URL}/images/` + feathersStore.searchCategory.item?.options?.href : require("../img/200_spinner.gif")}
        name={feathersStore._translateCategoryName(feathersStore.searchCategory.item)}/>}
      { feathersStore.searchCategory.item ? 
        (<div className="categoryView">
          <Category item = {feathersStore.searchCategory.item}/>
        </div>) 
        :
         <div className="circular">
          <CircularProgress 
          color="secondary" 
          style={{ margin: 6 }} 
          size={'4rem'}            
          />
        </div>
      }
    </div>
  )

    
}

export default inject('feathersStore')(observer(CategoryView));