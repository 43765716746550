import React from 'react';



const AttributeButtonMinus = ({onClick, green, disabled})=> {

  function changeCursor(e) {
      e.target.style.cursor = 'pointer';
    } 

  return(      
     <button 
      onClick={onClick} 
      onMouseOver={changeCursor}  
      disabled={disabled} 
      className={disabled ? "attribute-quantity-button attribute-quantity-button-disabled" : "attribute-quantity-button"}>-</button>
         
  )
}


export default AttributeButtonMinus;