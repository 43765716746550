import React, {useState, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import Header from '../header';
import RestaurantClosed from '../restaurantClosed';
import TopBar from '../topBar/TopBar';
import CategoriesBar from '../CategoriesBar';
import Loyalty from '../loyalty/Loyalty';
import QrCode from '../QrCode';
import Footer from '../footer/footer'
import RegistrationCard from '../../modals/registrationCard'
import Prompt from '../../modals/prompt'
import VerificationCode from '../../modals/verificationCode'
import { inject, observer } from "mobx-react";
import "./Home.css";
import MessagingBtn from '../../buttons/messagingBtn';
import { HIDE_QR } from "../../assets/globalConstants";

const Home = ({feathersStore})=> {
  
  useEffect(() => {   
    const cart = localStorage.getItem('fdwa_cart');
    cart && feathersStore
      .setCartLength(JSON.parse(localStorage.getItem('fdwa_cart')).length);
  }, [])

  const [visiblePrompt, setVisiblePrompt] = useState(false);
  const [visibleRegister, setRegistrationModal] = useState(false);
  const [visibleVerification, setVisibleVerification] = useState(false);
  const [payload, setPayload] = useState({});  
   
  const togglePrompt = ()=> {  
    setVisiblePrompt(oldValue => !oldValue);      
  }

  const openRegistrationModal = ()=> {
    setVisiblePrompt(false);
    setRegistrationModal(true);
  }

  const closeRegistrationModal = ()=> {
    setRegistrationModal(false);
  }

  const openVerification = pload => {
    setRegistrationModal(false);
    setVisibleVerification(true);
    setPayload(pload);
  }
    
    const closeVerification = ()=> {
      setVisibleVerification(false);    
    }   

  const clickEnter = ()=> {      
    setVisiblePrompt(false)
  }  
  
  return(
    
    <div className={"app-base" + (!feathersStore.restaurantOpen && feathersStore.showRestaurantMessage ? " app-base-restaurant-closed" : "")}>
      { Object.keys(feathersStore?.settings)?.length > 0 &&
        <>  
          <Prompt 
            visible={visiblePrompt}      
            close={togglePrompt}
            onClickEnter={clickEnter}
            onClickAway={togglePrompt}
            onClickRegister={openRegistrationModal}      
            />
          <RegistrationCard
            visible={visibleRegister} 
            close={closeRegistrationModal}
            onClickAway={closeRegistrationModal}
            onClick={openVerification}
          />
        </>
      }
          
      <VerificationCode
        visible={visibleVerification}
        onClickCloseCode={closeVerification}         
        payload={payload}
      />  
      {!feathersStore.restaurantOpen && feathersStore.showRestaurantMessage ? <RestaurantClosed/> : null}     
      <Header />   
      <TopBar onClickSignIn={()=> togglePrompt()}/>    
      <CategoriesBar/>  
      <Loyalty/>
      <Outlet/>
      {!HIDE_QR && <QrCode/>}
      <Footer/>  
      <div className="messaging-btn-container">
        <MessagingBtn/>
      </div>    
    </div>
   
  )
}

export default inject('feathersStore')(observer(Home));