import React from 'react';
//import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import CartIndicator from './cartIndicator';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import _useTranslate from '../../hooks/_useTranslate';

const CartComponent = ({cartQuantity, feathersStore})=> {

  let cart = _useTranslate(feathersStore.language, "cart");

  const setObservable = () => {
    feathersStore.setGoToAddressHelper(false);
  }

  return (      
    <Link to="/cart" style={{textDecoration: 'none'}} onClick={setObservable}>
      <div className="cart-container">      
        <AddShoppingCartIcon style={{fontSize: '35px'}}/>
        <CartIndicator cartQuantity={cartQuantity}/>
         
      </div>      
    </Link>

    );  
}

export default inject('feathersStore')(observer(CartComponent));
