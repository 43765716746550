export const cartIT = {
    cart: "Carrello",
    couponCode: "Codice promozionale",
    couponButton: "Usa codice promozionale",
    cartTotals: "Totali",
    completeOrder: "CHECKOUT",
    continueShopping: "CONTINUA GLI ACQUISTI",
    without: "Senza",
    select: "SELEZIONA",
    selectSmall: "Seleziona",
    addToCart: "Aggiungi al carrello",
    cartItems: "Articoli nel carrello",
    couponDiscount: "Sconto del coupon",
    subTotal: "Subtotale",
    couponCaption: "Hai un coupon?",
    new: "NUOVO",
    sale: "SALDI",
    soldOut: "ESAURITO",
    couverCaption: "Numero di persone",
    couverPlaceholder: "Inserisci il numero",
    couverError: "Inserisci il numero di persone e premi check"
}
