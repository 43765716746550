import React from 'react';
import { inject, observer } from "mobx-react";

const PrivacyText = ({ feathersStore })=> {
    return(
        <div className="terms-text"><p>Για την εταιρεία / κατάστημα {feathersStore.settings?.companyName} η προστασία των προσωπικών δεδομένων έχει πρωταρχική σημασία και αντιμετωπίζεται με τη μέγιστη σοβαρότητα. Ο σεβασμός προς τα προσωπικά δεδομένα που διαχειριζόμαστε και η διασφάλιση της σωστής επεξεργασίας τους είναι μία από τις προτεραιότητες της Εταιρίας.</p> 
        <p>Για το λόγο αυτό λαμβάνουμε όλα τα κατάλληλα τεχνικά και οργανωτικά μέτρα για να προστατέψουμε τα προσωπικά δεδομένα που επεξεργαζόμαστε και να διασφαλίσουμε ότι η επεξεργασία τους πραγματοποιείται πάντα σύμφωνα με τις απαιτήσεις που τίθενται από το ισχύον νομοθετικό πλαίσιο και κυρίως από το γενικό Κανονισμό Προστασίας Δεδομένων (ΕΕ) 2016/679.</p>
        <a><strong>1. Υπεύθυνος επικοινωνίας - Στοιχεία</strong></a>
        <p>Η {feathersStore.settings?.companyName} ενεργεί ως Υπεύθυνος Επεξεργασίας για όλα τα προσωπικά δεδομένα που συλλέγει, υποβάλλει σε επεξεργασία και αποθηκεύει.</p>
        {feathersStore.settings?.companyName}<br/>
        Διεύθυνση: {feathersStore.settings?.address}<br/>
        Τηλέφωνο: {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber}<br/>
        Ηλεκτρονικό Ταχυδρομείο: {feathersStore.settings?.email}<br/>
        Υπεύθυνος Επικοινωνίας:  {feathersStore.settings?.companyName}<br/><br/>
        <a><strong>2. Ποια δεδομένα συλλέγουμε και επεξεργαζόμαστε</strong></a><br/>
        <p>Για να σας εξυπηρετήσουμε και να σας προσφέρουμε τις υπηρεσίες και τα προϊόντα μας συλλέγουμε και επεξεργαζόμαστε όλα τα δεδομένα που γνωστοποιείτε στον εκπρόσωπο του τηλεφωνικού κέντρου όταν δίνετε την παραγγελία σας ή όσα δεδομένα συμπληρώνετε στα αντίστοιχα πεδία όταν ανοίγετε λογαριασμό χρήστη στην ιστοσελίδα μας και πιο συγκεκριμένα, ονοματεπώνυμο, ταχυδρομική διεύθυνση, τηλέφωνο επικοινωνίας (σταθερό ή κινητό), e-mail, φορολογικά στοιχεία (στην περίπτωση που επιθυμείτε έκδοση τιμολογίου).<br/><br/> Παρακαλούμε λάβετε υπόψη σας ότι η ιστοσελίδα της Εταιρίας μας ενδέχεται να περιέχει συνδέσμους (links) προς άλλους ιστότοπους. H Εταιρία μας δεν ευθύνεται για τις πρακτικές απορρήτου και προστασίας προσωπικών δεδομένων ή/και το περιεχόμενο αυτών των ιστοτόπων. Αν είστε ηλικίας μέχρι 15 ετών, παρακαλούμε να λάβετε την άδεια του γονέα ή κηδεμόνα σας, προτού μας κοινοποιήσετε οποιαδήποτε προσωπικά στοιχεία σας.</p>
        <a><strong>3. Σκοποί επεξεργασίας και νομικές βάσεις</strong></a><br/>
        <p>Όλα τα ανωτέρω προσωπικά δεδομένα συλλέγονται και υπόκεινται σε επεξεργασία με σκοπό καταρχήν την εκτέλεση της μεταξύ μας σύμβασης πώλησης των προϊόντων μας και πιο συγκεκριμένα για την ετοιμασία και παράδοση της παραγγελίας σας και την επικοινωνία μας μαζί σας σχετικά με αυτή. Κάποια από τα δεδομένα σας μπορεί να χρησιμοποιηθούν και για τους παρακάτω σκοπούς πάντα με τη δική σας σχετική συγκατάθεση:<br/><br/>
        - Επικοινωνία μαζί σας σε σχέση με διαγωνισμούς, κληρώσεις και άλλες εκδηλώσεις.<br/>
        - Ενημέρωση και αποστολή προσφορών και ενημερωτικού υλικού σχετικά με τα προϊόντα και τις υπηρεσίες μας.<br/>
        - Στατιστικοί σκοποί και έρευνες.<br/>
        - Προώθηση και προαγωγή των προϊόντων και υπηρεσιών μας.</p>
        <p>Νομικές βάσεις επεξεργασίας των δεδομένων μπορεί να είναι κατά περίπτωση:<br/>
        i. Η εκτέλεση και ολοκλήρωση της μεταξύ μας σύμβασης για την πώληση των προϊόντων μας σε εσάς (προετοιμασία και παράδοση της παραγγελίας σας). Χωρίς τη συλλογή και χρήση των παραπάνω πληροφορίων δεν θα είναι δυνατόν να σας εξυπηρετήσουμε.<br/>
        ii. Η συγκατάθεση που μπορεί εσείς να μας παρέχετε προκειμένου να λαμβάνετε ενημερώσεις σχετικά με προσφορές ή προωθητικό υλικό για τα προϊόντα και τις υπηρεσίες μας, τις εμπορικές δράσεις και δραστηριότητες που αναπτύσσουμε κλπ. Μπορείτε να ανακαλέσετε τη συγκατάθεσή σας όποτε εσείς το επιθυμείτε, επικοινωνώντας μαζί μας στα στοιχεία επικοινωνίας που αναφέρονται στην παράγραφο 1 ή μέσω της ιστοσελίδας μας.</p>
        <a><strong>4. Που κοινοποιούμε τα προσωπικά δεδομένα σας</strong></a><br/>
        <p>Τα προσωπικά δεδομένα σας είναι πιθανό να κοινοποιηθούν στους ακόλουθους αποδέκτες:<br/>
        - Στους διανομείς προκειμένου να ετοιμάσουν και να παραδώσουν την παραγγελία.<br/>
        - Σε εταιρία αποστολής newsletter (εφόσον έχετε δώσει τη συγκατάθεσή σας για να σας αποστέλλουμε newsletter).<br/>
        - Σε εταιρία αποστολής sms (στην περίπτωση που έχετε δώσει τη συγκατάθεσή σας για να λαμβάνετε ενημερώσεις).<br/>
        - Σε συνεργαζόμενες εταιρίες, προκειμένου να μπορέσετε να παραλάβετε το δώρο σας, εφόσον είστε νικητές σε κάποιο διαγωνισμό στον οποίο λάβατε μέρος (κάθε φορά που οργανώνουμε ένα διαγωνισμό, αναφέρουμε στους όρους του διαγωνισμού το γεγονός της κοινοποίησης των στοιχείων σας στη συνεργαζόμενη εταιρία).<br/>
        - Σε εξωτερικό συνεργάτη που μας υποστηρίζει σε θέματα λογισμικού.<br/>
        - Στο συνεργαζόμενο νομικό τμήμα της εταιρίας, σε περίπτωση δικαστικής ή εξώδικης διένεξης.<br/>
        - Σε αστυνομικές ή άλλες κρατικές αρχές εφόσον μας ζητηθεί κάτι τέτοιο επίσημα ή μετά από εισαγγελική παραγγελία.<br/></p>
        <a><strong>5. Χρονικό διάστημα αποθήκευσης δεδομένων</strong></a><br/>
        <p>Τα δεδομένα σας τηρούνται στη βάση δεδομένων της {feathersStore.settings?.companyName} για 5 χρόνια μετά την τελευταία συναλλαγή σας με την εταιρία ή για όσο χρόνο μπορεί να απαιτεί η νομοθεσία (π.χ. στην περίπτωση έκδοσης τιμολογίου, όσο χρόνο ορίζει το φορολογικό νομοθετικό πλαίσιο).<br/>
        Αν η επεξεργασία βασίζεται στη συγκατάθεσή σας, τα προσωπικά δεδομένα τηρούνται μέχρι να ανακαλέσετε τη συγκατάθεση αυτή. Διευκρινίζεται ότι η ανάκληση της συγκατάθεσης δεν θίγει τη νομιμότητα της επεξεργασίας που βασίστηκε στη συγκατάθεση πριν την ανάκλησή της.<br/>
        Σε κάθε περίπτωση η {feathersStore.settings?.companyName} εφαρμόζει όλα τα κατάλληλα τεχνικά και οργανωτικά μέτρα για να εξασφαλίζει την προστασία των προσωπικών δεδομένων που επεξεργάζεται και μεριμνά συνεχώς για την αποτροπή κάθε μη εξουσιοδοτημένης πρόσβασης στα δεδομένα αυτά.<br/><br/>
        <a><strong>6. Δικαιώματα σε σχέση με τα προσωπικά δεδομένα σας</strong></a><br/></p>
        <p>Αναφορικά με την επεξεργασία των προσωπικών δεδομένων σας μπορείτε να ασκήσετε τα παρακάτω δικαιώματα:<br/><br/>
        Δικαίωμα πρόσβασης<br/>
        Έχετε δικαίωμα να γνωρίζετε ποια δεδομένα σας τηρούμε και επεξεργαζόμαστε, για ποιο λόγο και άλλες συμπληρωματικές πληροφορίες σχετικά με αυτά, καθώς και να μας ζητήσετε αντίγραφό τους.<br/><br/>
        Δικαίωμα διόρθωσης<br/>
        Έχετε δικαίωμα να ζητήσετε τη διόρθωση, τροποποίηση και συμπλήρωση των προσωπικών δεδομένων σας.<br/><br/>
        Δικαίωμα διαγραφής («δικαίωμα στη λήθη»)<br/>
        Έχετε δικαίωμα να ζητήσετε τη διαγραφή των προσωπικών δεδομένων σας όταν αυτά τίθενται σε επεξεργασία μετά τη σχετική συγκατάθεσή σας. Στις περιπτώσεις που η επεξεργασία βασίζεται σε άλλη νομική βάση (όπως ενδεικτικά στην εκτέλεση σύμβασης, σε έννομη υποχρέωση ή στην προστασία έννομων συμφερόντων της Εταιρίας κλπ.) το δικαίωμά σας αυτό μπορεί να υπόκειται σε περιορισμούς ή να μην υφίσταται.<br/><br/>
        Δικαίωμα περιορισμού της επεξεργασίας<br/>
        Έχετε δικαίωμα να ζητήσετε τον περιορισμό της επεξεργασίας των προσωπικών δεδομένων σας<br/>
        - όταν αμφισβητείται η ακρίβειά τους και μέχρι να γίνει η σχετική επαλήθευση.<br/>
        - εναλλακτικά, αντί για τη διαγραφή τους.<br/>
        - όταν δεν μας είναι πλέον απαραίτητα για τους σκοπούς επεξεργασίας για τους οποίους τα συλλέξαμε, είναι όμως απαραίτητα για τη θεμελίωση, άσκηση ή υποστήριξη νομικών αξιώσεων από εσάς.<br/>
        - όταν έχετε αντιρρήσεις για την επεξεργασία τους και μέχρι να επαληθευτεί ότι υπάρχουν νόμιμοι λόγοι για την επεξεργασία αυτή από την Εταιρία.<br/><br/>
        Δικαίωμα εναντίωσης στην επεξεργασία.<br/>
        Έχετε δικαίωμα να εναντιωθείτε στην επεξεργασία των προσωπικών δεδομένων σας όταν αυτή γίνεται με βάση έννομο συμφέρον, καθώς και για σκοπούς απευθείας εμπορικής προώθησης και κατάρτισης προφίλ.<br/><br/>
        Δικαίωμα στη φορητότητα<br/>
        Έχετε δικαίωμα να ζητήσετε και να λάβετε τα προσωπικά δεδομένα σας σε μορφή που σας επιτρέπει την πρόσβαση σε αυτά, τη χρήση και επεξεργασία τους με τις κοινώς διαδεδομένες μεθόδους επεξεργασίας. Επιπλέον, για τα δεδομένα σας που επεξεργαζόμαστε με αυτοματοποιημένα μέσα και με βάση τη συγκατάθεσή σας ή για την εκτέλεση σύμβασης, μπορείτε να μας ζητήσετε να τα διαβιβάσουμε απευθείας σε άλλο υπεύθυνο επεξεργασίας, εφόσον αυτό είναι τεχνικά εφικτό.<br/><br/>
        Δικαίωμα ανάκλησης συγκατάθεσης<br/>
        Αν η επεξεργασία των δεδομένων σας βασίζεται στη συγκατάθεσή σας, έχετε δικαίωμα να την ανακαλέσετε οποιαδήποτε στιγμή. Η ανάκληση της συγκατάθεσής σας δεν θίγει τη νομιμότητα της επεξεργασίας που βασίστηκε στη συγκατάθεση πριν την ανάκλησή της.<br/><br/>
        Δικαίωμα διαγραφής λογαριασμού<br/>
        Έχετε τον μοναδικό και απόλυτο έλεγχο των πληροφοριών σας και μπορείτε ανά πάσα στιγμή να τις τροποποιείτε ή να τις διαγράψετε από το λογαριασμό σας χωρίς τη συγκατάθεση ή τη συναίνεση του καταστήματος.<br/>
        Το ίδιο ισχύει και για ολόκληρο τον λογαριασμό σας αφού μπαίνοντας στις ρυθμίσεις μπορείτε να προχωρήσετε με την αντίστοιχη επιλογή.<br/><br/>
        Για την άσκηση των παραπάνω δικαιωμάτων σας και για οποιαδήποτε ερώτημα, παράπονο ή άλλη ενημέρωση σχετικά με την επεξεργασία των προσωπικών δεδομένων σας μπορείτε να επικοινωνείτε μαζί μας στο email {feathersStore.settings?.email} ή στα στοιχεία επικοινωνίας που αναφέρονται στην παράγραφο 1.<br/></p>
        <a><strong>7. Δικαίωμα καταγγελίας στην αρχή προστασίας δεδομένων χαρακτήρα (ΑΠΔΠΧ)</strong></a><br/>
        <p>Έχετε δικαίωμα να υποβάλετε καταγγελία στην Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα (www.dpa.gr) σχετικά με θέματα που αφορούν την επεξεργασία των προσωπικών δεδομένων σας.<br/></p>
        <a><strong>8. Αλλαγές στην προστασία δεδομένων</strong></a><br/>
        <p>Τυχόν μελλοντικές αλλαγές στην Πολιτική Προστασίας Προσωπικών Δεδομένων της Εταιρίας μας θα αναρτηθούν στην ιστοσελίδα της {feathersStore.settings?.siteUrl} και όπου απαιτείται, θα σας κοινοποιηθούν.<br/></p>
        <a><strong>9. Πολιτική Cookies</strong></a><br/>
        <p>Επειδή το ηλεκτρονικό κατάστημα χρησιμοποεί σύγχρονες τεχνολογίες δεν χρησιμοποιούνται cookies με τα στοιχεία σας ακόμη και σε περίπτωση που έχετε πραγματοποιήσει εγγραφή στο ηλεκτρονικό κατάστημά μας.<br/> <br/> Τα μοναδικά cookies που χρησιμοποιούνται αφορούν την υπηρεσία Google Analytics, εφόσον αυτή είναι ενεργοποιημένη και σε αυτή την περίπτωση κρατούνται γενικά στατιστικά στοιχεία για τις επισκέψεις στις σελίδες του ηλεκτρονικού καταστήματος.</p>
    </div>


    )
}

export default inject('feathersStore')(observer(PrivacyText));