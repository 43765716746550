import feathersStore from "../feathersStore";
import {pickLanguageWord} from '../utils/pickLanguageWord.js';
import { DateTime } from "luxon";

const masterLanguage = feathersStore.settings.masterLanguage;

export const issueDeltio = async (subOrder, response) => {
    try{   
  
      let printer = feathersStore.settings?.printers.find(prn => prn.invoicing);
      let itemsList = "";
      const dateTime = DateTime.fromMillis(subOrder.createdAt).setLocale('el').toLocaleString(DateTime.DATETIME_FULL); 
  
      for (let item of subOrder.subOrderItems){
        let extraList = "";
        let ingredientList = "";
        let attributeList = "";
        const weightedString = item.product_weightedQuantity > 0 ? `${item.product_weightedQuantity * 1000} gr` : ""
        if(item?.features)for(let feature of item.features){extraList += feature.name + " "};
        if(item?.attributes)for(let attribute of item.attributes){attributeList += attribute.title + ": " + attribute.name + " "
          + (attribute?.quantity > 1 ? "x" + attribute.quantity + " " : "")};
        if(item?.ingredients)for(let ingredient of item.ingredients){ingredientList += ingredient.name};          
          
        if(printer.nonEpos){
          const splittedName = splitName(item.product_name);
          itemsList = itemsList + 
          '<align mode="left">' + 
          `<text-line>${item.product_quantity} X ${splittedName[0]}` +    
          `<x-position>340</x-position><text>${weightedString}</text></text-line>` +          
          (splittedName[1]?.length > 0 ? `<text-line>${splittedName[1]}</text-line>` : "") +
          (item?.product_size?.name ? `<text-line>${item?.product_size?.name}</text-line>` : "") +
            (extraList ? `<text-line>${extraList}</text-line>` : "") +
            (attributeList ? `<text-line>${attributeList}</text-line>` : "") + 
            (ingredientList ? `<text-line>${pickLanguageWord(masterLanguage, "without")}: ${ingredientList}</text-line>` : "") +
          '</align>';
        }else{   
          itemsList = itemsList +  '<text align = "left" />' + 
            `<text em="true">${item.product_quantity} X ${item.product_name} ${item?.product_size?.name || ""} ${weightedString}&#10;</text>` +                   
            '<text align = "left" />' +
              (extraList ? `<text lang="en" em="false" smooth="true">${extraList}&#10;</text>` : "") +
              (attributeList ? `<text lang="en" em="false" smooth="true">${attributeList}&#10;</text>` : "") + 
              (ingredientList ? `<text lang="en" em="false" smooth="true">${pickLanguageWord(masterLanguage, "without")}: ${ingredientList}&#10;</text>` : "") 
        }
  
      } 
  
  
      let req =   
        '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">' +
          '<s:Body>' +
            '<epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">' +
              '<text align = "center" />' +
              constructCompanyTitle() +
              '<feed unit="24"/>' + 
              `<text lang="en" em="true">ΔΕΛΤΙΟ ΠΑΡΑΓΓΕΛΙΑΣ&#10;</text>` +
              `<text em="true">${pickLanguageWord(masterLanguage, "tablePrint")}: ${subOrder.tableName}&#10;</text>` +
              '<feed unit="24"/>' + 
              '<text align = "left" />' +
              `<text em="false" smooth="true">${dateTime}&#10;</text>` +
              `<text em="false" smooth="true">ΑΡΙΘΜΟΣ ΔΕΛΤΙΟΥ: ${subOrder.numericId}&#10;</text>` +
              '<text align = "center" />' +
              `<text >------------------------------------------&#10;</text>` +          
                itemsList + 
              '<feed unit="24"/>' +         
              '<text align = "center" />' +
              `<text>------------------------------------------&#10;</text>` +
              '<text align = "left" />' +         
              '<feed unit="24"/>' +
              `<text width="1" height="1" em="false"  smooth="true">${response?.footer}&#10;</text>` +
              '<feed unit="24"/>' +
              `<symbol align = "center" type="qrcode_model_2" level="level_q">${response?.qrcode}</symbol>` +        
              '<feed unit="24"/>' +
              `<text align = "center">------------------------------------------&#10;</text>` +
              `<text>${feathersStore.user.username}&#10;</text>` +           
              '<feed unit="24"/>' +            
              '<cut/>' +
            '</epos-print>' +
          '</s:Body>' +
        '</s:Envelope>';
      
      let payload = {ip: printer.ip, devid: printer.devId, req};
  
      if(printer.nonEpos){
         
        req =  
          '<?xml version="1.0" encoding="UTF-8"?>' +
          '<document>' +
            '<set-cp/>' +
            '<align mode="center">' +
              constructCompanyTitleNonEpos() +             
            '<line-feed />' +
            '<bold>' +             
              `<text-line>ΔΕΛΤΙΟ ΠΑΡΑΓΓΕΛΙΑΣ</text-line>`  +                        
              `<text-line>${pickLanguageWord(masterLanguage, "tablePrint")}: ${subOrder.tableName}</text-line>` +
            '</bold>' +
            '</align>' +
            '<line-feed />' +
            '<align mode="left">' +
            `<text-line>${dateTime}</text-line>` +
            `<text-line>ΑΡΙΘΜΟΣ ΔΕΛΤΙΟΥ: ${subOrder.numericId}</text-line>` +
            '</align>' +
            '<align mode="center">' +
            `<text-line>------------------------------------------</text-line>` +          
                itemsList + 
            '<line-feed />' +         
            '<align mode="center">' +
            `<text-line>------------------------------------------</text-line>` +
            '</align>' +
            '<align mode="left">' +          
            '<line-feed/>' +         
            '<line-feed/>' +    
            `<text-line>${response?.footer}</text-line>` +
            '<line-feed/>' +
            '</align>' +     
            '<align mode="center">' +   
              `<qrcode ecl="M" size="4">${response?.qrcode}</qrcode>` +        
            '<line-feed />' +  
            `<text-line>Πάροχος myData: app.simplycloud.gr</text-line>` +
            '</align>' +     
            '<align mode="center">' +       
            `<text-line>------------------------------------------</text-line>` +
            `<text-line>${feathersStore.user.username}</text-line>` +
            `<text-line></text-line>` +
            `<text-line>BRINGFOOD</text-line>` +
            '</align>' +
            '<line-feed />' +                     
            '<paper-cut />' +
          '</document>' ;
         
        Object.assign(payload, {nonEpos: true, req})
        
      }      
      await feathersStore.createLocalPrint(payload);   
      return payload;
    }catch(err){
  //    this.sendingOrder = false;
      console.log('Could not create printJob!', err);
    }; 
  
  } 
  
  export const constructMyDataForDeltio = async (subOrder, file, line, numericId) => {
  
    const date = new Date();
    const day = date.getDate().toString().padStart(2, "0"); 
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const isoDate = year + "-" + month + "-" + day;       
    const isoTime = hours + ":" + minutes + ":" + seconds;
  
    let details = [];
    let vatAnalysis = [];
  
    subOrder.subOrderItems.forEach((item, index) => {     
  
      let detailObject =
      {
        "LineNumber": +index + 1,
        "Barcode": "",
        "Name": `${item.product_name} ${item?.product_size?.name || ""}`.substring(0, 30), 
        "Quantity": +item.product_quantity,
        "Notes": [       
          ...item?.features.map(f => `${f.name} `)  || "" , 
          ...item?.attributes.map(a => `${a.title}: ${a.name} `) || "",
          ...item?.ingredients.map(i => `XΩΡΙΣ: ${i.name} `) || ""      
        ].toString().substring(0, 70),
        "AdditionalNotes": "", 
        "Total": item?.product_free ? 0 : parse_fix(item.product_netPrice * item.product_quantity),       
        "VATAmount": item?.product_free ? 0 : parse_fix(item.product_totalPrice - (item.product_netPrice * item.product_quantity)),
        "VATPerc": feathersStore.settings.vats.find(v => +v.id === +item.product_vat)?.label,
        "TotalWithVAT": item?.product_free ? 0 : +parse_fix(item.product_totalPrice),
        "UnitPrice": item?.product_free ? 0 : +parse_fix(item.product_netPrice),
        "UnitDesc": 0,
        "IncomeCode": "category1_2",
        "EInvoiceIncomeClassTypeCode": "E3_561_003",
        "ExpenseCode": "",
        "EInvoiceExpenseClassTypeCode": null,
        "ReasonForTaxExp": "15",
        "VatCategory": +item.product_vat,
        "MeasurementUnit": "1",
        "withheldAmount": 0.0,
        "withheldPercentCategory": null,
        "stampDutyAmount": 0.00,
        "stampDutyPercentCategory": null,
        "feesAmount": 0.0,
        "feesPercentCategory": null,
        "otherTaxesAmount": 0.0,
        "otherTaxesPercentCategory": null,     
      }      
  
      details.push(detailObject);     
  
    })
  
  
    for(let vat of feathersStore.settings.vats){
      const label = feathersStore.settings.vats.find(v => +v.id === +vat.id)?.label
      if(subOrder[`vat${vat.id}`] != 0){
        vatAnalysis.push({
          "Name": "VAT" + label + ".00%",
          "Percentage": +label,
          "VatAmount": parse_fix(subOrder[`vat${vat.id}`].vatAmount),
          "UnderlyingValue": parse_fix(subOrder[`vat${vat.id}`].underlyingValue)
        })
      }      
    } 
  
    const totalNetAmount = calculateTotalNetAmount(subOrder);
  
    const body = 
      {
        "ActionTypeId": 1,
        "InvoiceDate": isoDate,
        "InvoiceTypeCode": "8.6",
        "InvoiceTypeName": "ΔΕΛΤΙΟ ΠΑΡΑΓΓΕΛΙΑΣ",
        "InvoiceTypeSeries": `${numericId}`,
        "InvoiceTypeSeriesName": "Custom",
        "InvoiceTypeNumber": "ΑΛΠ",
        "InvoiceTypeNumberName": "ΑΛΠ-Α",
        "Currency": "EURO",
        "CurrencyCode": "EUR",
        "IsRetailInvoice": true,
        "IsDelayedInvoice": false,
        "transmissionFailure": "",
        "DelayedDocumentID": "",
        "tableAA": `${subOrder.tableName}`,
        "selfPricing": false,
        "SubmissionToMyDATA": true,
        "vatPaymentSuspension": false,
        "CorrelatedInvoices": "",
        "distributionInfo": null,
        "dispatchDate": isoDate,
        "dispatchTime": isoTime,
        "vehicleNumber": null,
        "WebOrderNumber": null,
        "StoreComments": "",
        "CustomerComments": "",
        "movePurpose": 1,
        "ShippingMethod": "COURIER",
        "Issuer": {
          "CompanyName": `${feathersStore.settings.companyName}`,
          "CompanyLegalName": `${feathersStore.settings?.companyLegalName || feathersStore.settings.companyName}`,
          "CompanyActivity":  `${feathersStore.settings.occupation}`,
          "CompanyAddress": {
            "Country": "GR",
            "CountryCode": "GR",
       //     "Region":  `${this.settings.taxAddress?.region}`,
            "City":  `${feathersStore.settings.taxAddress?.city}`,
            "Municipality":  `${feathersStore.settings.taxAddress?.municipality}`,
            "Street":  `${feathersStore.settings.taxAddress?.street}`,
            "Number":  `${feathersStore.settings.taxAddress?.number}`,
            "Postal":  `${feathersStore.settings.taxAddress?.postal}`
          },
          "CompanyPhones": [
            `${feathersStore.settings.phoneNumbers[0]?.phoneNumber}`
          ],
          "CompanyEmails": [
            `${feathersStore.settings.email}`
          ],
          "CompanyCurrency": "€",
          "CompanyVatNumber": `${feathersStore.settings.afm}`,       
          "CompanyTaxAuthority": `ΔΟΥ ${feathersStore.settings.doy}`,
          "CompanyBranch": "0",
          "CompanyBranchCode": "",
          "CompanyPosId": ""         
        },       
        "Payment": 
      //    "TotalPieces": persistedReceipt.totalPieces,
          [
            {
              "PaymentMethodType": "ΜΕΤΡΗΤΟΙΣ",
              "PaymentMethodTypeCode": 3,
              "PaymentAmount": parseFloat(subOrder.subOrderTotal).toFixed(2),
              "PaymentNotes": "ΜΕΤΡΗΤΟΙΣ"
            }
          ]
        ,    
        "lstLineItem": details,    
        "Summary": {
          "totalNetValue": totalNetAmount.toFixed(2),
          "totalWithheldAmount": 0.00,
          "totalFeesAmount": 0.00,
          "totalStampDutyAmount": 0.00,  
          "TotalVATAmount": (subOrder.subOrderTotal - totalNetAmount).toFixed(2),
          "totalOtherTaxesAmount": 0.00,
          "totalDeductionsAmount": 0.00,
          "totalGrossValue": parseFloat(subOrder.subOrderTotal).toFixed(2),
          "totalAllowances": 0.00,
          "totalAllowancesWithoutLines": 0.00,
          "totalCharges": 0.00,
          "totalSpecialCharges": 0.00      
     //     "TotalPayableAmount": persistedReceipt.receiptTotal
        },    
        "VatAnalysis": vatAnalysis,
        "AllowancesCharges": [
          {
            "Type": "",
            "Description": "",
            "Code": "",
            "Amount": 0.00,
            "VatPercentage": 0.00,
            "VatAmount": 0.00
          }
        ],       
        "SendMethod": "0",
        "Email": [
            "support@simplypos.com"
        ]
      }    
          
      try{
        const response = await feathersStore.postToMyData(body); 
        if(response?.data){
          console.log(response.data)
          return {       
            qrcode:  `${response.data.invoiceURL}`,
            footer: `${response.data.invoiceMark} ${response.data.invoiceUid} ${response.data.authenticationCode}`,
            mark:  `${response.data.invoiceMark}`
          } 
        }else{
          console.log(response);
          await logError(response, subOrder, file, line);
          return;
        }
      }catch(error){
        console.log(error);
        await logError(error, subOrder, file, line);
        return;
      }   
  }
  
  const constructCompanyTitle = () => {
    return(
      `<text dw="true" dh="true" />`+
      `<text lang="en" em="true">${feathersStore.settings.companyName}&#10;</text>` +
      '<feed unit="24"/>' + 
      `<text dw="false" dh="false" />`+
      `<text lang="en" em="true">${feathersStore.settings?.companyLegalName || feathersStore.settings.companyName}&#10;</text>` +
      '<feed unit="24"/>' + 
      `<text lang="en" em="true">${feathersStore.settings.address}&#10;</text>` + 
      `<text lang="en" em="true">${pickLanguageWord(masterLanguage, "afm")}: ${feathersStore.settings.afm} ${pickLanguageWord(masterLanguage, "doy")} ${feathersStore.settings.doy}&#10;</text>` +
      `<text lang="en" em="true">${feathersStore.settings.occupation}&#10;</text>` 
    )
  }
  
  const constructCompanyTitleNonEpos = () => {
    const phonesArray = feathersStore.settings?.phoneNumbers?.filter(number => number?.numberEnabled);
    return(
      `<bold><text-line size="1:1">${feathersStore.settings.companyName}</text-line></bold>` +
      '<line-feed />' +
      `<bold><text-line>${feathersStore.settings?.companyLegalName || feathersStore.settings.companyName}</text-line></bold>` +
      '<line-feed />' +
      '<bold>' +
        `<text-line>${feathersStore.settings.address}</text-line>` + 
        `<text-line>${pickLanguageWord(masterLanguage, "afm")}: ${feathersStore.settings.afm} ${pickLanguageWord(masterLanguage, "doy")} ${feathersStore.settings.doy}</text-line>` +
        `<text-line>${pickLanguageWord(masterLanguage, 'phone')}: ${phonesArray?.length > 0 ? phonesArray[0]?.phoneNumber : ""}</text-line>` +
        `<text-line>${feathersStore.settings.occupation}</text-line>` +
      '</bold>'
    )
  }  
  
  const calculateTotalNetAmount = persistedReceipt => {
    let returnVal = 0;
    for(let vat of feathersStore.settings.vats){
      returnVal += +persistedReceipt[`vat${vat.id}`].underlyingValue
    }
    return returnVal;
  }
  
  export const logError = async(error, persistedReceipt, file, line) => {
    const payload = {
      errorKind: "my_data",
      origin: "Web app",
      file: file,
      line: line,
      error,
      persistedReceipt
    }
    await feathersStore.createLogEntry(payload)
  }
  
  export const splitName = (name) => {
    let returnArray = [];
    returnArray[0] = name.substring(0, 24);
    returnArray[1] = name.substring(24);
    return returnArray;
  }
  
  export const parse_fix = price => {
    return price ? parseFloat(price).toFixed(2) : 0;
  }  
  
  export const calculateVats = items => {
    const givenVats = feathersStore.settings?.vats;   
    let vatsObj = {}
    for(let vat of givenVats){
      let vatAmount = items.filter(item => +item.product_vat === +vat.id)
        .map(i => i?.product_free ? 0 : (i.product_totalPrice - (i.product_netPrice * i.product_quantity)))        
        .reduce((a,b) => (+a + +b), 0).toFixed(2);
      let underlyingValue = items.filter(item => +item.product_vat === +vat.id)
        .map(i => i?.product_free ? 0 : (i.product_quantity * i.product_netPrice))        
        .reduce((a,b) => (+a + +b), 0).toFixed(2);
    Object.assign(vatsObj, {[`vat${vat.id}`]: {vatAmount, underlyingValue}})
    }  
    return vatsObj;
  }