import React from 'react';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../hooks/_useTranslate';

const RegisteredEmptyRating = ({ feathersStore })=> {

    let common = _useTranslate(feathersStore.language, "common"); 

    return(
        <div>{common.beTheFirstReview}</div>
    )
}

export default inject('feathersStore')(observer(RegisteredEmptyRating));