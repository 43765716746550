export const accountIT = {
    myAccount: "Il mio account",
    profile: "PROFILO",
    addresses: "INDIRIZZI",
    history: "CRONOLOGIA",
    comments: "COMMETI",
    settings: "IMPOSTAZIONI",
    psw: "Cambia password",
    updateAlert: "I tuoi dati sono stati aggiornati con successo",
    updateError: "Errore durante l'aggiornamento",
    addAddress: "Aggiungi un indirizzo",
    addNewAddress: "Aggiungi un nuovo indirizzo",
    myLocation: "La mia posizione",
    modalHeaderUpdate: "Cambia indirizzo",
    otherLocation: "Altra posizione",
    home: "Casa",
    work: "Lavoro",
    helperText: "Inserisci i dettagli del tuo indirizzo",
    addressComments: "Commenti aggiuntivi sull'indirizzo",
    historyPlaceholder: "Non hai ancora effettuato acquisti",
    commentPlaceholder: "Non hai ancora effettuato commenti",
    emptyHistory: "Non hai ancora effettuato ordini.",
    uploadAvatar: "Fai clic sull'immagine per scegliere un'altra immagine.",
    submit: "INVIA",
    uploadSuccess: "Caricamento file riuscito",
    uploadError: "Errore durante il caricamento del file",
    bigFile: "L'immagine è troppo grande. Deve essere fino a 1 MB. Seleziona un'immagine più piccola",
    favorites: "I miei preferiti",
    hello: "Ciao",
    profileHeader: "Profilo",
    settingsSmall: "Impostazioni"
}
