export const footerAL = {
    contactHours : "Orët e Kontaktit",
    week : "E hënë – E premte",
    contactUs : "Na kontaktoni",
    address : "Rr. Mesogeion 131, Marousi 15126",
    findUs : "Na gjeni në hartë",
    contact: "Na kontaktoni",
    privacyPolicy: "Politika e Privatësisë",
    termsOfService: "Kushtet e Shërbimit",
    stayConnected: "Qëndroni të lidhur",
    cookiesTitle: "Përdorimi i Cookies",
    aboutUs : "Rreth nesh",
    cookiesText: "Ne përdorim vetëm cookies e nevojshme minimale. Të dhënat tuaja personale nuk ruhen në asnjërën prej tyre.",
    cookiesAccept: "Prano",  
    accessibilityTitle:  "Aksesueshmëria",
    fontIncrease: "Rrit madhësinë e shkronjave",
    fontDecrease: "Ul madhësinë e shkronjave",
    greyscale: "Shkallë e hirtë",
    highContrast: "Kontrast i lartë",
    negativeContrast: "Kontrast negativ",
    lightBackground: "Sfonda e lehtë",
    linksUnderline: "Nënvizimi i lidhjeve",
    readableFont: "Shkronjë e lexueshme",
    reset: "Rivendos",
    paymentOptions: "Opsionet e pagesës",
    deliveryOptions: "Opsionet e dorëzimit",
    openingTime: "Ora e Hapjes",    
    loginRegister: "Hyr/Regjistrohu",
    messagingTitle: "Mbështetje",
    messagingSubTitle: "Dërgoni një mesazh me pyetjet tuaja!",
    message: "Mesazh",
    installBringFood: "Fillo me BrigFood!",
    usefull: "E dobishme",
    company: "Kompania",
    faq: "Pyetje të Shpeshta",
    chatPlaceholder: "Shkruani tekst...",
    restaurantClosed: "Ambjentet janë të mbyllura në këtë moment dhe nuk mund të përgatisim porosinë tuaj",
    holidayText: "Ambjentet do të jenë të mbyllura sot",
    allDay: "Gjithë ditën",
    to: "Deri"
}
