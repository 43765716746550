import React from 'react';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";

const FaqText = ({ feathersStore })=> {
  return(
    <div className="terms-text">
      <h2>Ποιούς τρόπους πληρωμής δέχεστε;</h2>
      <p>
        Μπορείτε να δείτε τους τρόπους πληρωμής <Link className="privacy-text" to="/paymentoptions">εδώ</Link>.
      </p>    
      <h2>
        Με ποιούς τρόπους μπορώ να παραλάβω την παραγγελία μου;
      </h2>
      <p>
        Μπορείτε να δείτε τους τρόπους αποστολής <Link className="privacy-text" to="/deliveryoptions">εδώ</Link>.
      </p>
      <h2>
        Μπορώ να κάνω κάποια αλλαγή στην παραγγελία μου;
      </h2>
      <p>
      Μπορείτε να κάνετε οποιαδήποτε αλλαγή πριν την αποστολή της παραγγελίας σας. Τηλεφωνήστε μας στο {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber} ή επικοινωνήστε μαζί μας μέσω της φόρμας επικοινωνίας <Link className="privacy-text" to="/contact">εδώ</Link> ή στο email {feathersStore.settings?.email} αναφέροντας τα στοιχεία σας και τις αλλαγές που θέλετε να κάνετε.
      </p>
      <h2>
        Υπάρχει κάποιο κατάστημα που μπορώ να επισκεφθώ;
      </h2>
      <p>
        Μπορείτε να μας επισκεφθείτε στο χώρο μας στη διεύθυνση {feathersStore.settings?.address}.
      </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(FaqText));