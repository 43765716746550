import React from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PaymentOptionsText from './paymentOptionsText';
import PaymentOptionsTextEnglish from './paymentOptionsTextEnglish';
import PaymentOptionsTextFrench from './paymentOptionsTextFrench';
import PaymentOptionsTextGerman from './paymentOptionsTextGerman';
import PaymentOptionsTextItalian from './paymentOptionsTextItalian';
import PaymentOptionsTextSpanish from './paymentOptionsTextSpanish';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const PaymentOptions = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");

  const pickText = () => {
    switch (feathersStore.language){
        case "el" : {
            return <PaymentOptionsText/>;
        }
        case "en" : {
            return <PaymentOptionsTextEnglish/>;
        }
        case "de" : {
            return <PaymentOptionsTextGerman/>;
        }
        case "es" : {
            return <PaymentOptionsTextSpanish/>;
        }
        case "fr" : {
            return <PaymentOptionsTextFrench/>;
        }
        case "it" : {
            return <PaymentOptionsTextItalian/>;
        }
        case "ar" : {
            return <PaymentOptionsTextEnglish/>;
        }
      }
    }
    
    return(
        <div className="settings-sub-container">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
            </div>
            <p className="terms-title">{`-${footer.paymentOptions}-`}</p>
            <div className="terms-container">
                {pickText()}            
            </div> 
        </div>
    )
}

export default inject('feathersStore')(observer(PaymentOptions));