export const footerEL = {
    contactHours : "Ωράριο Επικοινωνίας",
    week : "Δευτέρα – Παρασκευή",
    contactUs : "Επικοινωνία",
    address : "Οδός Μεσογείων 131, 15126 Μαρούσι",
    findUs : "Βρείτε μας στον χάρτη",   
    contact: "Στείλτε μας μήνυμα",
    privacyPolicy: "Δήλωση απορρήτου",
    termsOfService: "Όροι και προϋποθέσεις",
    stayConnected: "Βρείτε μας",
    cookiesTitle: "Χρήση cookies",
    aboutUs : "Εταιρία",
    cookiesText: "Χρησιμοποιούμε μόνο τα απολύτως απαραίτητα cookies. Σε κανένα από αυτά δεν αποθηκεύονται τα προσωπικά σας δεδομένα.",
    cookiesAccept: "Αποδοχή",  
    accessibilityTitle:  "Προσβασιμότητα",
    fontIncrease: "Αύξηση γραμμάτων",
    fontDecrease: "Μείωση γραμμάτων",
    greyscale: "Γκρι αποχρώσεις",
    highContrast: "Υψηλή αντίθεση",
    negativeContrast: "Αρνητική αντίθεση",
    lightBackground: "Φωτεινό φόντο",
    linksUnderline: "Υπογράμμιση συνδέσμων",
    readableFont: "Ευανάγνωστη γραμματοσειρά",
    reset: "Επαναφορά",
    paymentOptions: "Τρόποι Πληρωμής",
    deliveryOptions: "Τρόποι Αποστολής",
    openingTime: "Ώρες λειτουργίας" ,
    loginRegister: "Είσοδος/Εγγραφή",
    messagingTitle: "Υποστήριξη",
    messagingSubTitle: "Στείλτε μας μήνυμα για οποιαδήποτε απορία σας!",
    message: "Μήνυμα",
    installBringFood: "Βάλε και εσύ BringFood!",
    usefull: "Χρήσιμα",
    company: "Εταιρεία",
    faq: "Συχνές Ερωτήσεις",
    chatPlaceholder: "Πληκτρολογείστε εδώ...",
    restaurantClosed: "Το κατάστημα είναι κλειστό και δεν εξυπηρετεί παραγγελίες αυτή τη στιγμή",
    holidayText: "Σήμερα το κατάστημα θα παραμείνει κλειστό",
    allDay: "24ωρο",
    to: "Έως"
}