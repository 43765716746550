import React from 'react';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AppColors from '../../assets/colors';
import { inject, observer } from "mobx-react";
// Translations
import _useTranslate from '../../hooks/_useTranslate';

const LoginComponent = ({onClick, feathersStore})=> {

  let footer = _useTranslate(feathersStore.language, "footer");

  function changeCursor(e) {
      e.target.style.cursor = 'pointer';
    }

  return (
    <div 
      className="genContainer"  
      onClick={onClick} 
      onMouseOver={changeCursor}
    >
      <PersonOutlineIcon style={{fontSize: '35px'}}/>
        <div          
          className="login-link"      
        >{footer.loginRegister}</div>
    </div>
    );
  
}

export default inject('feathersStore')(observer(LoginComponent));
