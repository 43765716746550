import React from 'react';
import { inject, observer } from "mobx-react";


const DeliveryOptionsTextEnglish = ({feathersStore})=> {
  return(
    <div className="terms-text">
       <h2>Delivery & shipping</h2>
      <p>
        In our store we make every effort to serve our customers. You can order remotely either by phone or through our website. Below are the two most frequent ways of sending and receiving our products.
      </p>    
      <h2>
        Pickup from our store
      </h2>
      <p>
        You can collect your purchases from our store on the street {feathersStore.settings?.address}.
      </p>
      <h2>
        Delivery to your location
      </h2>
      <p>
        We undertake to bring your order to the place you have indicated.
      </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(DeliveryOptionsTextEnglish));