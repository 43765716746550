import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Button from '@mui/material/Button';
import SettingsIcon from '@mui/icons-material/Settings';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link } from 'react-router-dom';
import AppColors from '../../assets/colors';
import { inject, observer } from "mobx-react";
// Translations
import _useTranslate from '../../hooks/_useTranslate';

const SettingsMenu = ({feathersStore}) => {

  let account = _useTranslate(feathersStore.language, "account");

  const styles ={
    root: {
      background: '#fff',
      alignItems: 'center',
      justifyContent: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      color: 'black'   
    }
  };
 
  const [menuOpen, setMenuOpen] = useState(false);
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const toggle = () => setMenuOpen(!menuOpen);

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  return (
    <>
      {menuOpen && 
      <div className="settingsContainer">
        <Link to="account" style={{textDecoration: 'none'}} onClick={toggle} className="genContainer">       
          <SettingsIcon/>
          <span className="login-link">{account.settingsSmall}</span>         
        </Link>
        <Link to="favorites" style={{textDecoration: 'none'}} onClick={toggle} className="genContainer">
          <FavoriteBorderIcon/>
          <span className="login-link">{account.favorites}</span>
        </Link>
      </div>}
      <div className="genContainer" onClick={toggle} onMouseOver={changeCursor}>
        {menuOpen? <MenuOpenIcon style={{fontSize: '35px'}}/>  : <MenuIcon style={{fontSize: '35px'}}/>}
      </div>   
      <span className="login-link">{account.hello} {feathersStore.user?.firstname}!</span>
    </>
  );
}
 

export default inject('feathersStore')(observer(SettingsMenu));
