import React,{ useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';

const Hamburger = ({feathersStore, showResponsiveMenu})=> { 

  return(
    <button aria-labelledby='Sign in' type="button" className="nav-button-SignIn undecorated-link" onClick={showResponsiveMenu}>
      <ManageSearchOutlinedIcon className="calendar-icon" style={{ fontSize: 42 }}/>       
    </button>
  )

}

export default inject('feathersStore')(observer(Hamburger));