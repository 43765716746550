export const cartEN = {
    cart : "Cart",
    couponCode : "Coupon Code",
    couponButton : "Use Coupon",
    cartTotals : "Totals",
    completeOrder : "CHECKOUT",    
    continueShopping : "CONTINUE ORDERING",   
    without: "Without",
    select: "SELECT",
    selectSmall: "Select",
    addToCart: "Added to cart",
    cartItems: "Cart items",
    couponDiscount: "Coupon discount",
    subTotal: "Sub-Total",
    couponCaption: "Do you have a coupon?",
    new: "NEW",
    sale: "SALE",
    soldOut: "SOLDOUT",
    couverCaption: "Number of Persons",
    couverPlaceholder: "Enter Number",
    couverError: "Please enter number of people and press check"
}
