import React from 'react';
import { inject, observer } from "mobx-react";

const PrivacyTextGerman = ({ feathersStore })=> {
    return (
        <div className="terms-text">
        <p>Für das Unternehmen / Geschäft {feathersStore.settings?.companyName} hat der Schutz personenbezogener Daten höchste Priorität und wird mit größter Ernsthaftigkeit behandelt. Die Achtung der von uns verwalteten personenbezogenen Daten und die Gewährleistung ihrer ordnungsgemäßen Verarbeitung haben für das Unternehmen oberste Priorität.</p>
        <p>Aus diesem Grund treffen wir alle geeigneten technischen und organisatorischen Maßnahmen, um die von uns verarbeiteten personenbezogenen Daten zu schützen und sicherzustellen, dass ihre Verarbeitung immer gemäß den Anforderungen des geltenden rechtlichen Rahmens und insbesondere der Datenschutz-Grundverordnung (DSGVO) (EU) 2016/679 erfolgt.</p>
        <a><strong>1. Ansprechpartner - Kontaktdaten</strong></a>
        <p>{feathersStore.settings?.companyName} fungiert als Verantwortlicher für alle personenbezogenen Daten, die es erhebt, verarbeitet und speichert.</p>
        {feathersStore.settings?.companyName}<br/>
        Adresse: {feathersStore.settings?.address}<br/>
        Telefon: {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber}<br/>
        E-Mail: {feathersStore.settings?.email}<br/>
        Kontakt: {feathersStore.settings?.companyName}<br/><br/>
        <a><strong>2. Welche Daten wir erheben und verarbeiten</strong></a><br/>
        <p>Um Ihnen unsere Dienstleistungen und Produkte anzubieten, erfassen und verarbeiten wir alle Daten, die Sie dem Callcenter-Mitarbeiter bei Ihrer Bestellung oder in den entsprechenden Feldern beim Erstellen eines Benutzerkontos auf unserer Website bereitstellen, wie Namen, Postanschrift, Kontaktnummer (Festnetz oder Mobil), E-Mail, Steuerinformationen (falls Sie eine Rechnung ausstellen möchten).<br/><br/> Beachten Sie, dass die Website unseres Unternehmens Links zu anderen Websites enthalten kann. Unser Unternehmen ist nicht für die Datenschutzpraktiken und/oder den Inhalt dieser Websites verantwortlich. Wenn Sie jünger als 15 Jahre sind, holen Sie bitte die Erlaubnis Ihrer Eltern oder Erziehungsberechtigten ein, bevor Sie persönliche Informationen mit uns teilen.</p>
        <a><strong>3. Verarbeitungszwecke und Rechtsgrundlagen</strong></a><br/>
        <p>All die oben genannten personenbezogenen Daten werden grundsätzlich zum Zweck der Vertragserfüllung zwischen uns für den Verkauf unserer Produkte erfasst und verarbeitet, insbesondere für die Vorbereitung und Lieferung Ihrer Bestellung und unsere Kommunikation mit Ihnen in Bezug darauf. Einige Ihrer Daten können auch für die folgenden Zwecke verwendet werden, immer mit Ihrer entsprechenden Zustimmung:<br/><br/>
        - Kommunikation mit Ihnen in Bezug auf Wettbewerbe, Gewinnspiele und andere Veranstaltungen.<br/>
        - Aktualisierung und Versand von Angeboten und Informationsmaterial über unsere Produkte und Dienstleistungen.<br/>
        - Statistische Zwecke und Forschung.<br/>
        - Förderung und Bewerbung unserer Produkte und Dienstleistungen.</p>
        <p>Rechtsgrundlagen für die Datenverarbeitung können im Einzelfall sein:<br/>
        i. Die Ausführung und Erfüllung des Vertrags zwischen uns für den Verkauf unserer Produkte an Sie (Vorbereitung und Lieferung Ihrer Bestellung). Ohne die Erhebung und Verwendung der oben genannten Informationen ist es uns nicht möglich, Sie zu bedienen.<br/>
        ii. Die von Ihnen gegebene Einwilligung, um Updates in Bezug auf Angebote oder Werbematerial für unsere Produkte und Dienstleistungen, Handelsaktionen und Aktivitäten, die wir entwickeln, usw. zu erhalten. Sie können Ihre Einwilligung jederzeit widerrufen, indem Sie uns über die in Absatz 1 genannten Kontaktdaten oder über unsere Website kontaktieren.</p>
        <a><strong>4. Wo wir Ihre personenbezogenen Daten teilen</strong></a><br/>
        <p>Ihre personenbezogenen Daten können mit den folgenden Empfängern geteilt werden:<br/>
        - An die Vertriebsmitarbeiter, um die Bestellung vorzubereiten und auszuliefern.<br/>
        - An ein Newsletter-Versandunternehmen (sofern Sie uns Ihre Zustimmung zur Zusendung eines Newsletters gegeben haben).<br/>
        - An ein SMS-Versandunternehmen (wenn Sie Ihre Zustimmung zur Erhalt von Updates erteilt haben).<br/>
        - An Partnerunternehmen, um Ihr Geschenk zu erhalten, wenn Sie ein Gewinner in einem Wettbewerb sind, an dem Sie teilgenommen haben (jedes Mal, wenn wir einen Wettbewerb veranstalten, erwähnen wir in den Wettbewerbsbedingungen die Tatsache, dass Ihre Daten mit dem Partnerunternehmen geteilt werden).<br/>
        - An einen externen Partner, der uns in Softwarefragen unterstützt.<br/>
        - An die kooperierende Rechtsabteilung des Unternehmens im Falle eines gerichtlichen oder außergerichtlichen Streits.<br/>
        - An die Polizei oder andere Regierungsbehörden, wenn wir offiziell dazu aufgefordert werden oder aufgrund einer Anordnung der Staatsanwaltschaft.<br/></p>
        <a><strong>5. Zeitspanne der Datenspeicherung</strong></a><br/>
        <p>Ihre Daten werden in der Datenbank von {feathersStore.settings?.companyName} für 5 Jahre nach Ihrer letzten Transaktion mit dem Unternehmen oder solange es das Gesetz erfordert (z.B. im Fall der Rechnungsausstellung, so lange wie vom Steuergesetzgeber festgelegt) aufbewahrt.<br/><br/>
        Wenn die Verarbeitung auf Ihrer Einwilligung beruht, werden die personenbezogenen Daten so lange aufbewahrt, bis Sie diese Einwilligung widerrufen. Es sei klargestellt, dass der Widerruf der Einwilligung die Rechtmäßigkeit der Verarbeitung, die auf der Einwilligung vor ihrem Widerruf beruhte, nicht beeinträchtigt.<br/>
        In jedem Fall ergreift {feathersStore.settings?.companyName} alle geeigneten technischen und organisatorischen Maßnahmen zum Schutz der personenbezogenen Daten, die es verarbeitet, und kümmert sich ständig darum, den unbefugten Zugriff auf diese Daten zu verhindern.<br/><br/></p>
        <a><strong>6. Rechte in Bezug auf Ihre personenbezogenen Daten</strong></a><br/>
        <p>In Bezug auf die Verarbeitung Ihrer personenbezogenen Daten können Sie die folgenden Rechte ausüben:<br/><br/>
        Recht auf Zugang<br/>
        Sie haben das Recht zu wissen, welche Daten wir über Sie haben, warum und weitere zusätzliche Informationen darüber, sowie uns um eine Kopie davon zu bitten.<br/><br/>
        Recht auf Berichtigung<br/>
        Sie haben das Recht, die Berichtigung, Änderung und Vervollständigung Ihrer personenbezogenen Daten anzufordern.<br/><br/>
        Recht auf Löschung ("Recht auf Vergessenwerden")<br/>
        Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu beantragen, wenn sie nach Ihrer Einwilligung verarbeitet werden. In Fällen, in denen die Verarbeitung auf einer anderen Rechtsgrundlage beruht (wie zum Beispiel der Erfüllung eines Vertrags, einer gesetzlichen Verpflichtung oder dem Schutz der rechtlichen Interessen des Unternehmens usw.), kann Ihr Recht Einschränkungen unterliegen oder möglicherweise nicht bestehen.<br/><br/>
        Recht auf Einschränkung der Verarbeitung<br/>
        Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu beantragen<br/>
        - wenn deren Richtigkeit bestritten wird und bis die entsprechende Überprüfung erfolgt.<br/>
        - alternativ, anstelle der Löschung.<br/>
        - wenn sie für uns nicht mehr für die Verarbeitungszwecke erforderlich sind, für die wir sie erhoben haben, aber für die Begründung, Ausübung oder Verteidigung rechtlicher Ansprüche von Ihnen erforderlich sind.<br/>
        - wenn Sie gegen ihre Verarbeitung Einspruch erheben und bis überprüft wird, ob berechtigte Gründe für diese Verarbeitung durch das Unternehmen vorliegen.<br/><br/>
        Recht auf Widerspruch gegen die Verarbeitung.<br/>
        Sie haben das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen, wenn sie auf einem berechtigten Interesse beruht, sowie zu Zwecken des Direktmarketings und des Profilings.<br/><br/>
        Recht auf Datenübertragbarkeit<br/>
        Sie haben das Recht, Ihre personenbezogenen Daten in einer Form anzufordern und zu erhalten, die es Ihnen ermöglicht, sie mit den üblichen Verarbeitungsmethoden abzurufen, zu verwenden und zu verarbeiten. Darüber hinaus können Sie für Ihre Daten, die wir aufgrund Ihrer Einwilligung oder zur Erfüllung eines Vertrags automatisiert verarbeiten, verlangen, dass sie, wenn dies technisch möglich ist, direkt an einen anderen Verantwortlichen übertragen werden.<br/><br/>
        Recht auf Widerruf der Einwilligung<br/>
        Wenn die Verarbeitung Ihrer Daten auf Ihrer Einwilligung beruht, haben Sie das Recht, diese jederzeit zu widerrufen. Der Widerruf Ihrer Einwilligung beeinträchtigt nicht die Rechtmäßigkeit der Verarbeitung, die auf der Einwilligung vor ihrem Widerruf beruhte.<br/><br/>
        Recht zum Löschen des Kontos<br/>
        Sie haben die alleinige und uneingeschränkte Kontrolle über Ihre Daten und können diese jederzeit ändern oder aus Ihrem Konto löschen ohne Zustimmung oder Zustimmung des Ladens.<br/>
        Das Gleiche gilt für Ihr gesamtes Konto, da Sie durch Eingabe der Einstellungen mit der entsprechenden Option fortfahren können.<br/><br/>
        Um Ihre oben genannten Rechte auszuüben und für Fragen, Beschwerden oder weitere Informationen zur Verarbeitung Ihrer personenbezogenen Daten können Sie uns unter der E-Mail {feathersStore.settings?.email} oder den in Absatz 1 genannten Kontaktdaten kontaktieren.<br/></p>
        <a><strong>7. Beschwerderecht bei der Datenschutzbehörde (DSB)</strong></a><br/>
        <p>Sie haben das Recht, bei der Datenschutzbehörde (www.dpa.gr) Beschwerde über Angelegenheiten im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten einzureichen.<br/></p>
        <a><strong>8. Änderungen im Datenschutz</strong></a><br/>
        <p>Alle zukünftigen Änderungen in unserer Datenschutzrichtlinie werden auf der Website {feathersStore.settings?.siteUrl} veröffentlicht und, falls erforderlich, an Sie kommuniziert.<br/></p>
        <a><strong>9. Cookie-Richtlinie</strong></a><br/>
        <p>Da der Online-Shop moderne Technologien verwendet, werden keine Cookies mit Ihren Daten verwendet, auch wenn Sie sich in unserem Online-Shop registriert haben.<br/><br/> Die einzigen verwendeten Cookies betreffen den Google Analytics-Dienst, solange dieser aktiviert ist. In diesem Fall werden allgemeine Statistiken über die Besuche auf den Seiten des Online-Shops gespeichert.</p>
      </div>
    )
}
export default inject('feathersStore')(observer(PrivacyTextGerman));