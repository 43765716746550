import React, {useState} from 'react';
import Prompt from '../../modals/prompt';
import RegistrationCard from '../../modals/registrationCard'
import VerificationCode from '../../modals/verificationCode'
import "./Registration.css"


const Registration = ({title})=> { 

  const [visiblePrompt, setVisiblePrompt] = useState(false);
  const [visibleRegister, setRegistrationModal] = useState(false);
  const [visibleVerification, setVisibleVerification] = useState(false);

 
  const openPrompt = ()=> {
    setVisiblePrompt(true); 
  }
 
  const closePrompt = ()=> {
    setVisiblePrompt(false); 
  }

  const openRegistrationModal = ()=> {
    setVisiblePrompt(false);
    setRegistrationModal(true);
  }

  const closeRegistrationModal = ()=> {
    setRegistrationModal(false);
  }

  const openVerification = ()=> {
    setRegistrationModal(false);
    setVisibleVerification(true);
  }

  const closeVerification = ()=> {
    setVisibleVerification(false);     
  } 
   
  return(
    <div >
      <Prompt 
        visible={visiblePrompt}
        close={closePrompt}       
        onClickRegister={openRegistrationModal}       
        />     
      <RegistrationCard
        visible={visibleRegister} 
        close={closeRegistrationModal}
        onClickAway={closeRegistrationModal}
        onClick={openVerification}
      />       
      <VerificationCode
        visible={visibleVerification}
        onClickCloseCode={closeVerification}
        onClickAway={closeVerification}
      />     
      <div onClick={openPrompt} className="here-word">{title}</div>             
    </div>
  )
}

export default (Registration);