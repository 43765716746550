import React from 'react';
import Button from '@mui/material/Button';

const RoundedButton = (props)=> {

function changeCursor(e) {
e.target.style.cursor = 'pointer';
}

  return (
    <Button 
      className="addressAddButton"
      style={{backgroundColor: `${props.color}`}}
      onMouseOver={changeCursor}
      onClick={props.onClick}>
          {props.icon}
    </Button> 
  );
}

export default RoundedButton;