export const accountAL = {
    myAccount : "llogariaIme",
    profile : "PROFILI",
    addresses : "ADRESAT",
    history : "HISTORIKU",
    comments : "RISHIKIMET",
    settings : "CILËSIMET",
    psw : "Ndrysho Fjalëkalimin",
    updateAlert : "Të dhënat tuaja u përditësuan me sukses",
    updateError : "Gabim gjatë përditësimit",
    addAddress : "Shto një adresë",
    addNewAddress : "Shto një adresë të re",
    myLocation : "Vendndodhja ime",    
    modalHeaderUpdate : "Ndrysho Adresën",
    otherLocation : "Vendndodhje Tjetër",
    home : "Shtëpi",
    work : "Punë",
    helperText : "Ju lutemi shkruani detajet e adresës tuaj",
    addressComments : "Komente shtesë për adresën",
    historyPlaceholder : "Nuk keni bërë asnjë blerje ende",
    commentPlaceholder : "Nuk keni bërë asnjë koment ende",
    emptyHistory: "Nuk keni vendosur asnjë porosi ende.",
    uploadAvatar: "Klikoni mbi imazhin për të zgjedhur një imazh tjetër.",
    submit: "DËRGO",
    uploadSuccess: "Ngarkimi i skedarit u krye me sukses",    
    uploadError: "Gabim gjatë ngarkimit të skedarit",
    bigFile: "Imazhi është shumë i madh. Duhet të jetë deri në 1MB. Ju lutemi zgjidhni një imazh më të vogël",
    favorites: "të preferuarat e mia",
    hello: "Përshëndetje",
    profileHeader: "Profili",
    settingsSmall: "Cilësimet"
}
