import React, {useState, useEffect} from 'react';
import Camera from './camera';
import Colors from '../../assets/colors'
import LockOutlined from '@mui/icons-material/LockOutlined'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import 'react-slidedown/lib/slidedown.css'
import ChangePassword from './changePassword';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import GreenSubmitButton from '../../buttons/greenSubmitButton';
import { 
    FormControl,
    FormHelperText,
    InputAdornment,
    Input,
    Divider
   } from '@mui/material';
import { inject, observer } from "mobx-react";
import Utils from '../../Utils';
import { CSSTransition } from 'react-transition-group';

// Translations
import _useTranslate from '../../hooks/_useTranslate';

let firstNameElement;
let lastNameElement;
let phoneElement;

const inputControl = {
  fontSize: 20,
}
const inputIcon = {
  fontSize: 35
}

const ProfileSettings = ({feathersStore})=> {

  let account = _useTranslate(feathersStore.language, "account");
  let common = _useTranslate(feathersStore.language, "common"); 
  
  const [email, setEmail] = useState(feathersStore.user?.email  || ""); 
  const [firstName, setFirstName] = useState(feathersStore.user?.firstname || ""); 
  const [lastName, setLastName] = useState(feathersStore.user?.lastname || ""); 
  const [phone, setPhone] = useState(feathersStore.user?.phone || ""); 
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [loading, setLoading] = useState(false);  
  const [ activeDropdown, setActiveDropdown] = useState(false);

  useEffect(() => {
    setEmail(feathersStore.user?.email || "");
    setFirstName(feathersStore.user?.firstname || "");
    setLastName(feathersStore.user?.lastname || "");
    setPhone(feathersStore.user?.phone || "")
  }, [feathersStore.user])
    
  const firstNameChange = event => {    
    setFirstName(event.target.value);
    firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    phoneValidation(event.target.value);   
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  } 

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
      phoneElement.focus();
    }
  }  

  const enterPhonePressed = (ev) => {   
    if (ev.key === 'Enter') {
      firstNameElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      updateUser();
    }
  }

  const updateUser = () => {
    setLoading(true);
    feathersStore.patchUser(feathersStore.user._id, firstName, lastName, phone)
    .then(user => {  
      feathersStore.setUser(user);
       setLoading(false);   
      alert(account.updateAlert);
    })
    .catch(error => {
      setLoading(false);   
      alert(`${account.updateError}:  ${error.message}`)
    });    
  };

  return(
    <div className="column-flex-start-wrap width-100">
      <div className="upper-section">
        <div className="camera-container">
          <Camera />
        </div>
        <div className="profile-data-form">
          <FormControl fullWidth={true} > 
            <Input
              sx={inputControl}
              value={firstName}
              error={firstNameError}
              inputRef={r => {
                firstNameElement = r;
              }}
              aria-label='firstname'
              onChange={firstNameChange}
              onKeyPress={enterFirstNamePressed}
              placeholder={common.firstName + "*"}
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={inputIcon}/>
                </InputAdornment>
              }
            />
            {firstNameError && <FormHelperText id="component-error-text" >{common.firstNameError}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={true}>
            <Input
              sx={inputControl}
              value={lastName}
              error={lastNameError}
              inputRef={r => {
                lastNameElement = r;
              }}
              aria-label='lastname'
              onChange={lastNameChange}
              onKeyPress={enterLastNamePressed}
              placeholder={common.lastName + "*"}
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlineIcon sx={inputIcon}/>
                </InputAdornment>
              }
            />
            {lastNameError && <FormHelperText id="component-error-text" >{common.lastNameError}</FormHelperText>}
          </FormControl>
          <FormControl fullWidth={true} disabled={true}> 
            <Input
              sx={inputControl}
              value={email} 
              placeholder={common.email + "*"}
              aria-label='email'
              startAdornment={
                <InputAdornment position="start">
                  <MailOutlineIcon sx={inputIcon}/>
                </InputAdornment>
              }
            />
          </FormControl>
          <FormControl fullWidth={true}>    
            <Input
              sx={inputControl}
              value={phone}     
              error={phoneError}
              inputRef={r => {
                phoneElement = r;
              }}
              aria-label='phone'
              onChange={phoneChange}              
              onKeyPress={enterPhonePressed}   
              placeholder={common.phone + "*"}
              startAdornment={
                <InputAdornment position="start">
                  <CallIcon sx={inputIcon}/>
                </InputAdornment>
              }
            />
            {phoneError && <FormHelperText id="component-error-text" >{common.phoneError}</FormHelperText>}
          </FormControl>
        </div>
      </div>
      <div className="profile-save-area">
        <GreenSubmitButton 
          title={common.save}
          disabled = {!firstName || !lastName || !phone|| 
            firstNameError || lastNameError || phoneError}
          loading={loading}
          onClick={updateUser} 
        /> 
      </div>  
      <div className="change-password-container">
        <div className="changePassTitle">
          <LockOutlined sx={inputIcon}/>
          <span>{account.psw}</span>
        </div>
        {activeDropdown ? 
        <div className="column-flex-center" onClick={() => setActiveDropdown(false)} aria-label='Cancel icon'>
          <ExpandLess sx={inputIcon}/>
        </div>
        :
        <div className="column-flex-center" onClick={() => setActiveDropdown(true)} aria-label='Expand more'>
          <ExpandMore sx={inputIcon}/>
        </div>   
        }
      </div>
        <CSSTransition
          in={activeDropdown}
          timeout={300}
          classNames="my-alert"
          unmountOnExit
        >
        <ChangePassword />
      </CSSTransition>
    </div>
  )
}

export default inject('feathersStore')(observer(ProfileSettings));