import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import { useNavigate } from 'react-router-dom';
import CategoryProductCard from '../cards/categoryProductCard';
import SubCategoryComponent from './subCategoryComponent';
import {API_URL, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";
import { inject, observer } from "mobx-react";
import * as cloneDeep from 'lodash/cloneDeep';
import { CircularProgress } from '@mui/material';
import _useTranslate from '../hooks/_useTranslate';

const Category = ({ item, feathersStore }) => {

  let navigate = useNavigate(); 
  let common = _useTranslate(feathersStore.language, "common");

  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productsMapS, setProductsMapS] = useState(null);

  const productsMap = useRef(new Map());
    
  useEffect(() => {
    productsMap.current = new Map();
    setProducts([]); 
    setSubCategories([]);
    setProductsMapS(cloneDeep(productsMap.current));
    if(item?.childrens?.length > 0){
      setSubCategories([...item.childrens.filter(s => s.options?.eshop)]);      
    }else feathersStore.isAuthenticated  && fetchCategoryProducts();    
  }, [item, feathersStore.searchCategory, feathersStore.isAuthenticated, productsMap,
    feathersStore.productUpdatedEvent, feathersStore.productCreatedEvent])

  const routeChange =  product =>  {    
    navigate(`/product/${feathersStore._translateName(product)}`,{
      state: {id: `${product._id}`,  payload: product}     
    });
  }
   
  const renderSubCategoryItem = ( item, index ) => (
    <div key={index} className="inSubCategory">
      <SubCategoryComponent         
        onClick={() => fetchSubCategoryProducts(item, index)} 
        image={item.options?.href ? `${API_URL}/images/` + item.options?.href : require("../img/200_spinner.gif")} 
        name={feathersStore._translateCategoryName(item)}>
      </SubCategoryComponent>
      <div>
        { productsMapS && 
          <div className="categoryView">
          { 
            productsMapS && productsMapS.get(index) && 
              <>
                {productsMapS.get(index)?.length > 0 ?
                [...productsMapS.get(index)].map(renderProductCard) :
                <div className='column-flex-center-100 no-category-items'>
                  {common.noCategoryItemsYet}
                </div>
                } 
              </> 
          } 
          </div>
        }
      </div>
    </div>    
  );

  const renderProductCard = ( item, index ) => (
    <CategoryProductCard
      key={index}
      image={item?.images[0] ? (`${API_URL}/images/` + item?.images[0]) : require("../img/200_spinner.gif")}
      title={feathersStore._translateName(item)}      
      description={feathersStore._translateProductShortDescription(item)}
      price={item[packagePricing() ? 'packagePrice' : 'retailPrice']}
      oldPrice={item[packagePricing() ? 'packageOldPrice' : 'oldPrice']}
      onClick={() => routeChange(item)}
      badges={item.badges}
      soldOut={item.soldOut}
    />         
  );

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }

  const fetchSubCategoryProducts = async (item, index) => {
    const feathersProducts = 
    await feathersStore.productsPerCategory2(+item.id); 
    productsMap.current.has(index) ? productsMap.current.delete(index) 
      : productsMap.current.set(index, feathersProducts) ;    
    setProductsMapS(cloneDeep(productsMap.current));   
  }

  const fetchCategoryProducts = async() => {
    const feathersProducts = 
    await feathersStore.productsPerCategory1(+item.id);
    setProducts([...feathersProducts]); 
  }
    
  return(
    <div className="subCategory">
      {subCategories ? 
          subCategories.length > 0 ? subCategories.map(renderSubCategoryItem) : null
         :
         <div className="circular">
          <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}            
          />
        </div>
      }     
      {products ?       
          products.length > 0  ? 
            <div className="categoryView"> 
            {  products.map(renderProductCard) } 
            </div> 
            :
            <> 
              {subCategories.length < 1 &&  
                <div className='column-flex-center-100 no-category-items'>
                  {common.noCategoryItemsYet}
                </div>
              }
            </>
        :
        <div className="circular">
          <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}            
          />
        </div>
      }
    </div>
  )
               
}
 
export default inject('feathersStore')(observer(Category));