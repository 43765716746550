import React, { useState, useEffect } from 'react';
import { CarouselData } from './CarouselData'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './carousel.css';
import { inject, observer } from "mobx-react";
import {API_URL} from "../../assets/globalConstants";
import GreenSubmitButton from "../../buttons/greenSubmitButton";
import Colors from '../../assets/colors';
import _useTranslate from '../../hooks/_useTranslate';

const Carousel = ({feathersStore}) => {
  
  const [current, setCurrent] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState([]);
  const length = CarouselData.length; 

  let common = _useTranslate(feathersStore.language, "common");


  useEffect(() => {
    //preloading images to avoid flickering
    let preloadedArray = [];
    if(feathersStore.settings?.webImages){
      CarouselData.forEach((s, index) => {
        const img = new Image();     
        img.src = `${API_URL}/images/` + feathersStore.settings?.webImages[index];
        img.text1 = translate1(s);
        img.text2 = translate2(s);
        img.carouselLink = s?.carouselLink ? feathersStore.settings[s.carouselLink] : "";
        preloadedArray.push(img)
      });
      setPreloadedImages(preloadedArray);
    }
  }, [feathersStore.settings?.webImages, feathersStore?.language]);
  
  useEffect(() => {   
    let slideShow = setTimeout(
      () => nextSlide(),
      7000); 
      return () => clearTimeout(slideShow); //needed because it stops abruptly when navigating to another page
  }, [current])

  if(!Array.isArray(CarouselData) || CarouselData.length <= 0) {
    return null;
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const translate1 = (s) => {
    if(feathersStore.language === feathersStore.settings.masterLanguage)return feathersStore.settings[s.text1];
    else return feathersStore.settings[s.text1Trans]?.find(tr => tr?.code === feathersStore.language)?.text || feathersStore.settings[s.text1EN] || feathersStore.settings[s.text1];
  }

  const translate2 = (s) => {
    if(feathersStore.language === feathersStore.settings.masterLanguage)return feathersStore.settings[s.text2];
    else return feathersStore.settings[s.text2Trans]?.find(tr => tr?.code === feathersStore.language)?.text || feathersStore.settings[s.text2EN] || feathersStore.settings[s.text2];
  }

  const goToLink = (carouselLink) => () => {
    window.open(`${carouselLink}`, "_blank");
  }

  return (
    <div className='carousel-container'>   
      <div className='arrows-container'>
        <div className='arrow'><ArrowBackIcon sx={{fontSize: 25}} onClick={prevSlide} /></div>
        <div className='arrow'><ArrowForwardIcon sx={{fontSize: 25}} onClick={nextSlide} /></div>
      </div>   
      
      {preloadedImages?.map((slide, index) => {
        return (
          <div className={index === current ? 'slide active' : 'slide'} key={index}>
            {index === current && (
              <>         
                <img src={slide.src} alt="slider" className="image-carousel" />
                <div className='color-overlay-carousel color-overlay'></div>            
               
                <div className="carousel-text-wrapper">
                  <div  className="carousel-text-1">{slide?.text1}</div>
                    { slide?.text1 && 
                      <div className="carousel-text-separator">
                        <div className="carousel-text-separator-element"></div>
                      </div>
                    }
                  <div className="carousel-text-2-wrapper">
                    <div  className="carousel-text-2">{slide?.text2}</div>
                    {slide?.carouselLink &&
                      <GreenSubmitButton
                        onClick={goToLink(slide?.carouselLink)}
                        width="calc(50px + 14vw)"
                        height="calc(8px + 2vw)"
                        title={common.more}
                        color={Colors.secondary}
                        marginBottom={0}
                        marginLeft={0}
                        marginTop={"calc(8px + 2vw)"}
                        fontSize={"calc(9px + 0.7vw)"}
                        textTransform={"none"}
                      ></GreenSubmitButton>
                    }
                  </div>                
                </div>
              </>
            )}
          </div>
        )
      })}
    </div>
  );
}
 
export default inject('feathersStore')(observer(Carousel));
