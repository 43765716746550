export const commonEN = {
    firstName : "First Name",
    lastName : "Last Name",
    street : "Street",
    streetNumber : "Number",
    city : "City",
    origin : "Region",
    postcode : "ZIP",
    phone : "Phone number",
    apartment : "Apartment",
    email : "E-mail",
    emailMessage : "We'll never share your email with anyone else",
    checkout: "Checkout",
    streetAndNumber : "Street & Number",
    privacyStatement : "Privacy Statement",
    termsOfService : "Terms of service",
    termsOfServiceNoun : "Terms of service",
    aboutUs : "About us",
    product : "Product",
    products : "Products",
    subTotal : "Sub-total",
    transportation : "Shipping cost",
    total : "Total",
    price : "Price",
    quantity : "Quantity",
    items: "Items",
    firstNameError : "Please Enter a first name",
    lastNameError : "Please Enter a last name",
    phoneError : "Please Enter a valid phone number",
    emailError : "Please Enter a valid Email",
    save : "SAVE",
    updateSuccess : "The update was successful",
    updateError : "Error while updating",
    updated : "Updated!",
    cartUpdated : "You updated succesfully your shopping cart!",
    wishlistUpdated : "You updated succesfully your wishlist!",
    streetError : "Please Enter a street",
    streetNumberError : "Please Enter a street number",
    cityError : "Please Enter a city",
    order : "Order",
    logout : "Logout",
    warning : "WARNING",
    logoutWarning : "Are you sure you want to logout?",
    yes : "YES",
    no : "NO",
    loginRegistration : "Login/Register",
    login : "LOGIN",
    passwordHelperText : "at least 8 chars including numbers, uppercase and lowercase",
    forgotPassword : "Forgot password?",
    or : "or",
    facebookLogin : "LOGIN WITH FACEBOOK",    
    googleLogin : "LOGIN WITH GOOGLE",
    register : "Registration",
    registerButton : "NEW CUSTOMER",
    exit : "EXIT",
    resetPassword : "Reset Password",
    resetPasswordInfoA : "Please enter your e-mail",
    resetPasswordInfoB : "and you will get a new password.",
    registerA : "REGISTER",
    registerInfoA : "With my registration, I accept the",
    registerInfoB : "general terms of service",
    registerInfoC : "the",
    registerInfoD : "Privacy Statement",
    registerInfoE : "and the",
    registerInfoF : "terms of service of the offers",
    verificationCode : "Confirm Code",
    verificationInfo : "Please enter the 4 digit code that was sent to :",
    enterCode : "Enter Code",
    errorCode : "Wrong Verification Code, Please try again",
    repeat : "RESEND",
    youSee : "You see",
    from : "from",
    stockWarning: "Unfortunately the product you have chosen is out of stock!",
    leaveAComment: "Leave a comment...",
    saveReview: "Save the Review",
    reviews: "Reviews",
    qrCodeBuy: "Buy fast and easy with one click from your mobile!",
    qrCodeScanAndroid: "Scan to download our app for Android",
    qrCodeDownload: "Download our app in Android",
    qrCodeBuyMobile: "For both Android and iOS, download our restaurant app and order anytime from your mobile!",    
    qrCodeScanIos: "Scan to download our app for IOs",    
    qrCodeForAndroid: "For Android",
    qrCodeForIos: "For iOS",    
    photoUpload: "Photo upload",    
    edit: "EDIT",
    delete: "DELETE",
    addCart: "Add to cart",
    noEvaluation: "No evaluation yet.",
    onlyLoggedInCustomers: "Only logged in customers who have bought the product can write a review.",
    beTheFirstReview: "Be the first who writes a review for this product.",
    seeMoreProducts: "See more products",
    favorite: "FAVORITES",
    favorites: "Favorites",
    noFavoritesYet: "You don't have favorite products yet",
    deleteProduct: "Are you sure you want to remove this product?",
    deleteProduct1: "Are you sure you want to remove this product",
    fromCart: "from the cart?",
    deleteAddress: "Are you sure you want to delete the address?",
    danger: "WARNING",
    connectionIssue: "Caution! Sending the order was not successful. Please check your connection.",
    sendOrder: "Send Order",
    pleaseWait: "Please wait...",
    noMoreProductsInStock: "You can not add in your cart more products than the stock",
    productAddedCart: "Product added to your cart!",
    home: "Home",
    myAccount: "myAccount",
    ckeckout: "CHECKOUT",
    all:"All",
    signIn:"SignIn",
    search: "Search",
    productSKU: "SKU",
    productSKUFull: "Product SKU:",
    language: "Language",
    size: "SIZE",
    extra: "Extra",
    removeIngredients: "Remove ingredients",    
    status: "Status",
    searchResults: "Search Results",
    noResults: "NO RESULTS",
    dailyProducts: "Daily Products",
    offersText: "Daily offers",
    popularText: "Most Popular",
    productComments: "Enter a comment or a special need (Optional)",
    weighted: "Weighted Product",
    kgr: "Price per Kgr",
    without: "WITHOUT",
    noSuchUser: "No user with this email exists!",
    reset: "Reset.",
    lostPassword: "Forgot your password? ",
    loginOr: "or login with",
    here: "here",
    orRegister: "Not having an account? Register ",
    stockWarningHeader: "Stock warning",
    duplicateEmail: "This email is being used by another account. Please try to login instead.",
    cartUpdatedAlert: "Cart updated",
    wishlistUpdatedAlert: "Wishlist updated",
    watchThis: "Check these out!",
    pickSize: "Pick a size",
    addExtra: "Add extra ingredients",
    logoutAccount: "Logout",
    deleteAccount: "Delete account",
    deleteAccountMessage: "Do you really want to delete your account?",
    yesSmall: "Yes",
    noSmall: "No",
    show: "Show Cart",
    removeItem: "Remove Item",
    youSure: "Are you sure?",
    cancel: "Cancel",
    scannerError: "Error while scanning. Please try again.",
    scannerSuccess: "Welcome",    
    scannerMessage: "Continue as a guest in your table",
    companyOrigin: "Main Region",
    addNewAddress : "Add a new address",
    holidayText: "The premises will remain closed today",
    minimumOrderHeader: "Minimum Order",
    minimumOrder: "The minimum order is ",
    appleId: "This email has been used for login with Apple",
    facebookId: "This email has been used for Facebook login",
    googleId: "This email has been used for Google login",
    emailPassword: "This email has been used for login with email and password",
    loginError: "Login Error: ",
    discount: "Discount",
    to: "Open till",
    openingTime: "Opening Time",    
    more: "More",
    lastAddress: "Cannot delete the last address",
    updateFailure: "Failed to update",
    succesDeleteAddress: "The address was successfully deleted.",
    noCategoryItemsYet: "There are no active products in this category.",
    reserveTable: "Reserve a table",
    reserveTableUC: "Reserve a table",
    yourReservation: "Your reservation",
    chooseHour: "Choose hour",
    bookingComment: "Add special request (optional)",
    party: "Party",
    pickDate: "Pick Date",
    partyOf: "Party of ",
    date: "Date",
    hour: "Time",
    customerDiscount: "Customer discount",
    couponDiscount: "Discount coupon",
    voucherTitle: "You have won a coupon of value ",
    applyVoucher: "Apply this coupon to your shopping cart.",
    copy: "Copy",
    minimumVoucherOrder: "Minimum Order",
    minimumVoucherCaption: "The minimum order required to apply your discount is",
    loyaltyTitle: "Loyalty rewards",
    loyaltyCaption: "You have won loyalty rewards of total value",
    loyaltySubCaption: "They will be discounted automatically during your checkout.",
    loyaltyMoto:  "Keep ordering to win discounts",
    endsIn: "Ends in",
    days: "days",
    table: "TABLE",
    comments: "COMMENTS",
    dateCut: "Date",
    newOrder: "NEW ORDER",
    num: "S/N",
    extras: "EXTRAS",
    tableOrder : "TABLE",
    phoneDelivery : "PHONE DELIVERY",
    phoneTakeAway : "PHONE TAKE-AWAY",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR TABLE",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR TABLE",
}