import React from 'react';
import CartTable from './cartTable';
import { inject, observer } from "mobx-react";
import "./Cart.css";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Cart = ({feathersStore})=> {
  
  let cart = _useTranslate(feathersStore.language, "cart"); 

  return(
    
      <div className="body-container">
       <div className="grey-header">{cart.cart}</div>
        {feathersStore.cartLength !== 0 ? <CartTable/> : <div className="empty-cart"/>}
      </div>
   
  )
}

export default inject('feathersStore')(observer(Cart));