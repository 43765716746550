import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import ProductCard from '../cards/productCard';
import SmallAlert from '../modals/smallAlert';
import {API_URL, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";
import { inject, observer } from "mobx-react";
import { CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams  } from 'react-router-dom';
import SectionTitle from "../cards/sectionTitle"
import searchImage from '../img/search.png';
import bestSellerImage from '../img/best-seller.png';
import servingImage from '../img/serving-dish.png';
import * as flatten from 'lodash/flatten';
import useWindowDimensions from '../hooks/useWindowDimensions';

// Translations
import _useTranslate from '../hooks/_useTranslate';

const ListProducts = ({ feathersStore })=> {

  let cartFromStorage = [];
  let common = _useTranslate(feathersStore.language, "common");
  let navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();

  const { height, width } = useWindowDimensions();  

  const[offers, setOffers] = useState([]);
  const[discountedProducts, setDiscountedProducts] = useState([]);
  const[popularProducts, setPopularProducts] = useState([]);
  const[searchProducts, setSearchProducts] = useState([]);
  const [cartIncreased, setCartIncreased] = useState(false);
  const [warning, setWarning] = useState(false);
  const [allOffers, setAllOffers] = useState(false);
  const [allDiscounted, setAllDiscounted] = useState(false);
  const [showQuantity, setShowQuantity] = useState(5);

  
  useEffect(() => {    
    feathersStore?.isAuthenticated && getFeathers();        
  }, [feathersStore?.isAuthenticated, searchParams
    ,feathersStore.productCreatedEvent, feathersStore.productUpdatedEvent
  ]);

  useEffect(() => {
    if(width < 650)setShowQuantity(4);
    else if(width < 992)setShowQuantity(3);
    else if(width < 1250)setShowQuantity(4);
    else setShowQuantity(5);
  }, [width])

  const getFeathers = async() => {
    cartFromStorage = JSON.parse(localStorage.getItem('fdwa_cart')) || [];
    let ids = cartFromStorage.map(item=> item.product_id);
    let feathersOffers = await feathersStore.offers();
    feathersOffers.forEach( prod =>{
      if(ids.includes(prod._id)) prod.added = true;
    }) 
    setOffers([...feathersOffers]); 
    await loadPopularProducts();
    if(searchParams.has('search'))loadSearchProducts();
    else setSearchProducts([]);
  }

  const loadPopularProducts = async() => {
    let ids = cartFromStorage.map(item=> item.product_id)
    let feathersPopularProducts = await feathersStore.popularProducts();    
    feathersPopularProducts.forEach( prod =>{
      if(ids.includes(prod._id)) prod.added = true;
    })     
    setPopularProducts([...feathersPopularProducts]); 
    let feathersDiscounted =  await feathersStore._products();
    const disc = feathersDiscounted
      .filter(d => d?.enabledForDelivery)
      .filter(p => (packagePricing()  ? p?.packageOldPrice || 0 : p?.oldPrice || 0) > 0)
    disc.forEach( prod =>{
      if(ids.includes(prod._id)) prod.added = true;
    })     
    setDiscountedProducts([...disc]);
  }

  const loadSearchProducts = async() => {
    let ids = cartFromStorage.map(item=> item.product_id)
    const searchText = searchParams.get('search') || "";
    let feathersSearchProducts = await feathersStore.products(searchText); 
    feathersSearchProducts.forEach( prod =>{
      if(ids.includes(prod._id)) prod.added = true;
    })     
    setSearchProducts([...feathersSearchProducts]);  
  }

  const toggleAllOffersButton = () => {   
    if((width < 650) && !allOffers)window.scrollBy({
      top: 400,
      behavior: "smooth",
    }) 
    setAllOffers(!allOffers);
  }

  const toggleAllDiscountedButton = () => {
    setAllDiscounted(!allDiscounted);
  }
  

   const addToCart = (item) => {    
    let cart = [];
    let cartBiggestId = 0;
    if(localStorage.getItem('fdwa_cart')){
      cart = JSON.parse(localStorage.getItem('fdwa_cart'));    
      if(cart.length > 0)
        cartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    } 
    enhanceProduct(item);
    const pickedSize = item.sizes.filter(size => size.default)[0];  
    pickedSize && Object.assign(pickedSize, {nameTranslated: feathersStore._translateNameAttr(pickedSize)}); 
    const cartItem = {
      id: cartBiggestId,
      product_id: item._id,
      product_name: item.name,
      product_nameTranslated: feathersStore._translateName(item),
      product_size: pickedSize,
      product_image: `${API_URL}/images/${item?.images[0]}`,
      product_price: item[packagePricing() ? 'packagePrice' : 'retailPrice'],
      product_totalPrice: +item.product_totalPrice,
      product_netPrice: calculateNetPrice(item),
      product_quantity: 1,
      product_extraSum: item.extraSum,
      features: [],
      ingredients: [],
      attributes: item.attributeItems,  
      product_printers: item.printers,
      product_kdsSections: item.kdsSections,
      product_vat: item[packagePricing() && item?.packageVat ? 'packageVat' : 'vat'],
      product_stock: item.stock,
      product_handleStock: item.handleStock,
      product_comments: item.comments,
      nameTranslations: item?.nameTranslations,
      shortDescriptionTranslations: item?.shortDescriptionTranslations
    }
    if (feathersStore.checkOutOfStock(item._id, item.handleStock, item.stock, cartItem.product_quantity, cart)){
      setWarning(true); return;
    } 
    cart.push(cartItem);
    feathersStore.setCartLength(feathersStore.cartLength + 1); 
    localStorage.setItem("fdwa_cart", JSON.stringify(cart));
    setCartIncreased(true); 
    let clonedPopularProducts = [...popularProducts];
    let clonedOffers = [...offers];
    clonedPopularProducts.forEach(prod => {
      if(prod._id === item._id)prod.added = true;
    })     
    clonedPopularProducts.filter(prod => prod.added) && setPopularProducts([...clonedPopularProducts]);
    clonedOffers.forEach(prod => {
      if(prod._id === item._id)prod.added = true;
    })     
    clonedOffers.filter(prod => prod.added) && setOffers([...clonedOffers]);
  }

  const enhanceProduct = product => {
    product.attributeItems = [];
    product.extraSum = 0;
    product.product_totalPrice = +product[packagePricing() ? 'packagePrice' : 'retailPrice'];   
    product?.savedAttributesTables && product.savedAttributesTables.forEach( table => {
      if(table?.mandatory && table.eshop){
        Object.assign(table.savedAttributesArray[0], {
          picked: true, 
          title: table.title, 
          titleTranslated: feathersStore._translateTitle(table),
          nameTranslated: feathersStore._translateNameAttr(table.savedAttributesArray[0])
        });         
        product.attributeItems.push(table.savedAttributesArray[0]);  
        product.extraSum += parseFloat(table.savedAttributesArray[0][packagePricing() ? 'packagePrice' : 'price']);  
        product.product_totalPrice += parseFloat(table.savedAttributesArray[0][packagePricing() ? 'packagePrice' : 'price']);
      }
    }); 
  }

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }

  const calculateNetPrice = item => {
    const label = feathersStore.settings.vats
      ?.find(v => +v.id === +item[packagePricing() && item?.packageVat ? 'packageVat' : 'vat'])?.label
    return +((item.product_totalPrice / item.product_quantity) / (1 + label / 100 )).toFixed(2);
  }

  const goToProduct =  product =>  {    
    navigate(`/product/${feathersStore._translateName(product)}`,{
      state: {id: `${product._id}`,  payload: product}     
    });
  }

  const onlyToProduct = item => { 
    return ((flatten(item.savedAttributesTables?.map(table => table.savedAttributesArray)).filter(attr => attr.active && attr.eshop)?.length > 0) ||
    (item.features.filter(f => f.featuresEnabled)?.length > 0) ||
    (item.sizes.filter(s => s.enabled)?.length > 1))
  }

  const renderProductItem = (item, index) => {
    enhanceProduct(item);
    return (
    <ProductCard
      key={item._id}
      image={`${API_URL}/images/` + item?.images[0]}
      title={feathersStore._translateName(item)}
      price={+item.product_totalPrice}
      oldPrice={item[packagePricing() ? 'packageOldPrice' : 'oldPrice']}
      badges={item.badges}
      soldOut={item.soldOut}
      weighted={item.weighted}
      onClick={() => addToCart(item, index)}
      added={item.added}  
      handleClick={() => goToProduct(item)} 
      attributes={item.attributeItems}
      onlyToProduct={onlyToProduct(item)}    
    />
  )}
    
  return (
    <div className="body-container">
      <div className="body-100">  
        {searchProducts.length > 0 ? 
          <>
            <SectionTitle 
              image={searchImage} 
              title= {common.searchResults} 
              />           
            <div className="offers">
              {searchProducts.length !== 0 ? searchProducts.map(renderProductItem)
          
            : 
              <div className="circular">
                <CircularProgress 
                color="secondary" 
                style={{ margin: 6 }} 
                size={'4rem'}            
                />
              </div>}
            </div> 
          </> 
          :
          searchParams.has('search') ?
          <div > 
            <SectionTitle image={searchImage} title= {common.searchResults}/>
            <h1 className="column-flex-center">{common.noResults}</h1>
          </div> 
          : 
          null
              }  
        {offers?.length > 0 ?
          <SectionTitle 
            image={servingImage} 
            title= {common.dailyProducts} 
            all={common.all}
            allClicked={toggleAllOffersButton}/>
          : null      
        } 
        <div className="offers">
          {offers ?
            offers.length > 0 ? offers.slice(0, allOffers ?  offers.length : showQuantity).map(renderProductItem)
            : null
          : 
          <div className="circular">
            <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}            
            />
          </div>}
        </div>

        {discountedProducts?.length > 0 ?
          <SectionTitle 
            image={servingImage} 
            title= {common.offersText} 
            all={common.all}
            allClicked={toggleAllDiscountedButton}/>
          : null  
        }             
        <div className="offers">
          {discountedProducts ?
            discountedProducts.length > 0 ? discountedProducts.slice(0, allDiscounted ? discountedProducts.length : showQuantity).map(renderProductItem)
            : null
          : 
          <div className="circular">
            <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}            
            />
      </div>}
        </div>
        {popularProducts?.length > 0 ?
          <SectionTitle image={bestSellerImage} title= {common.popularText}/>
        : null
        }
        <div className="offers">
      
          {popularProducts ?
            popularProducts.length > 0 ? popularProducts.slice(0, showQuantity).map(renderProductItem)
            : null
          : 
          <div className="circular">
            <CircularProgress 
            color="secondary" 
            style={{ margin: 6 }} 
            size={'4rem'}            
            />
      </div>}
        </div>
        <div className="preFooter"/>
      </div>
      <SmallAlert 
        show={cartIncreased} 
        mode="cart"
        heading={common.cartUpdatedAlert} 
        onClose={() => setCartIncreased(false)} 
        message={common.cartUpdated} 
      /> 
      <SmallAlert 
        show={warning} 
        mode="stock"
        heading={common.stockWarningHeader} 
        onClose={() => setWarning(false)}  
        message={common.stockWarning}
      />     
    </div>
    );
  
}

export default inject('feathersStore')(observer(ListProducts));
