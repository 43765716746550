import React from 'react';
import ScrollToTopBtn from "./scrollToTopButton";
import FooterUsefullMenu from './footerUsefullMenu';
import FooterInfoMenu from './footerInfoMenu';
import FooterAddressMenu from './footerAddressMenu';
//import topBarLogo from '../../img/bringfood-inline-logo.png';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";
import CookiesModal from '../../modals/cookiesModal';
import "./Footer.css";
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined'; 
import arrow_appdate from '../../img/arrow-appdate.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import googleButton from '../../img/Google-Play-Store-Button.png';
import {API_URL} from "../../assets/globalConstants";
import { HIDE_QR, HIDE_APPDATE, QR_READ_ONLY} from "../../assets/globalConstants";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Footer = ({ feathersStore })=> { 
  
  let footer = _useTranslate(feathersStore.language, "footer");

  const androidUrl = feathersStore.settings?.ANDROID_URL || "";
  const urlIos = feathersStore.settings?.IOS_URL || "";
  const imageIos = 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1625702400&h=756295c0d4e95f66799873c9eee485ea'

  return (
    <>
        <div className="footer">
          <div className="section section-50">
              <Link className="footerLogo" to="/" 
                //onClick={() => resetProducts()} 
               >
                <img 
                  className={feathersStore.settings?.useSquareWebLogo ? "logo logo-square" : "logo"}
                  aria-label='BringFood logo'             
                  alt="BringFood Logo"
                  src={ feathersStore.settings?.useSquareWebLogo ?
                    feathersStore.settings?.webLogoImageSquare ? (`${API_URL}/images/` + feathersStore.settings?.webLogoImageSquare) : require("../../img/200_spinner.gif")
                    :
                    feathersStore.settings?.webLogoImage ? (`${API_URL}/images/` + feathersStore.settings?.webLogoImage) : require("../../img/200_spinner.gif")
                  }                />  
              </Link>               
            <FooterAddressMenu/>
            <span className="footerSocial">
              {(feathersStore.settings?.facebookUrl || feathersStore.settings?.instagramUrl || feathersStore.settings?.youtubeUrl || feathersStore.settings?.twitterUrl) && <span>Follow us!</span>}
              {feathersStore.settings?.facebookUrl ? <a aria-label="fb Icon" href={feathersStore.settings?.facebookUrl} target="_blank" rel="noopener noreferrer" className="social-icon fb"><FacebookIcon sx={{ fontSize: 32 }}/></a> : null}
              {feathersStore.settings?.instagramUrl ? <a aria-label="Instagram Icon" href={feathersStore.settings?.instagramUrl} target="_blank" rel="noopener noreferrer" className="social-icon"><InstagramIcon sx={{ fontSize: 32 }} /></a> : null}
              {feathersStore.settings?.youtubeUrl ? <a aria-label="YouTube Icon" href={feathersStore.settings?.youtubeUrl} target="_blank" rel="noopener noreferrer" className="social-icon"><YouTubeIcon sx={{ fontSize: 32}}/></a> : null}
              {feathersStore.settings?.twitterUrl ? <a aria-label="Twitter Icon" href={feathersStore.settings?.twitterUrl} target="_blank" rel="noopener noreferrer" className="social-icon"><TwitterIcon sx={{ fontSize: 32 }}/></a> : null}
            </span>
            {!HIDE_QR &&
              <div className="footer-store-icons"> 
                App Download:
                <div className='footer-icon'>              
                  <a href={androidUrl}>
                    <img height="30" src={googleButton} alt="Download on the App Store" />
                  </a>                                
                </div>
                <div className='footer-icon'>
                  <a href={urlIos}>
                    <img height="30" src={imageIos} alt="Download on the App Store" />
                  </a>
                </div>
              </div> 
            }
            
          </div>
          {!QR_READ_ONLY && <FooterUsefullMenu/>}
          {!QR_READ_ONLY && <FooterInfoMenu/>}                            
        </div> 
        <div className="arrow-footer">
             <span><ScrollToTopBtn /></span>
        </div>       
        <div className="copyright copyright-bar">         
          {HIDE_APPDATE ?
          <div></div>
          :
          <>               
            <div className='copyright-bar-el-upper'>
              <span className="copyright-bar-el-first copyright-bar-el">
                Proudly Developed by              
                <a className="link-text footer-link-text" href="https://www.appdate.gr" target="_blank">
                  <span >AppDate.gr<img src={arrow_appdate} className="arrow-appdate"></img></span>
                </a>              
              </span>            
            </div>
            <div className='copyright-bar-el-upper'>
              <a className="copyright-bar-el-cent copyright-bar-el" href="https://www.bringfood.gr" target="_blank">
                <span>{footer.installBringFood}</span>               
              </a>
            </div>
            <div className='copyright-bar-el-upper'>
              <span className="copyright-bar-el-cent copyright-bar-el" style={{marginLeft: "2vw"}}>
                <CopyrightOutlinedIcon  style={{fontSize: 14}}></CopyrightOutlinedIcon>
                <span className="copyright-text-item">BringFood 2014-2023</span>
              </span>
            </div>      
          </>  
          }
        </div>
      {feathersStore.showCookies && <CookiesModal></CookiesModal> }      
    </>
    );
  
}

export default inject('feathersStore')(observer(Footer));
