import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
// Translations
import _useTranslate from '../../hooks/_useTranslate';

const FooterInfoMenu = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");  
  
return (
  <div className="section section-second section-25">
     <div className="footer-section-header">{footer.company}</div>
    <div className="line"></div>
   
    <Link to="/termsnconditions" className="footerMenuText2">{footer.termsOfService}</Link>
    <Link to="/privacypolicy"  className="footerMenuText2">{footer.privacyPolicy}</Link>    
    <Link to="/about" className="footerMenuText2">{footer.aboutUs}</Link>
    <a           
      className="footerMenuText2"
      href={`https://www.google.com/maps/search/?api=1&query=${feathersStore.settings?.latitude}%2C${feathersStore.settings?.longitude}`} 
      target="_blank" 
      rel="noopener noreferrer"
    >
    {footer.findUs}</a>  
  </div>
  );
}

export default inject('feathersStore')(observer(FooterInfoMenu));
