export const contactAL = {
    mustHaveChar: "Duhet të përmbajë dy karaktere ose më shumë",
    less255: "Duhet të jetë më e shkurtër se 255 karaktere",
    required: "E nevojshme",
    invalidEmail: "Email i pavlefshëm",
    invalidPhone: "Telefon i pavlefshëm",
    textareaMax: "Duhet të jetë më e shkurtër se 1000 karaktere",
    contactForm : "Forma e Kontaktit",
    firstName : "Emri",
    lastName : "Mbiemri",
    phone : "Telefoni",
    email : "Email",
    text : "Mesazhi",
    buttonForm : "Dërgo",
    emailSent: "Email i dërguar!",
    emailSentMessage: "E-mail juaj u dërgua me sukses!",
    emailError: "Gabim në Email!",
    emailErrorMessage: "E-mail juaj nuk u dërgua!"
}