export const checkoutDE = {
  less255: "Maximal 255 Zeichen",
  less50: "Maximal 50 Zeichen",
  less20: "Maximal 20 Zeichen",
  less10: "Maximal 10 Zeichen",
  required: "Erforderlich",
  invalidEmail: "Ungültige E-Mail",
  invalidPhone: "Ungültige Nummer",
  textareaMax: "Maximal 1000 Zeichen",
  invalidPayment: "Ungültige Zahlungsmethode",
  terms: "Sie müssen die AGB akzeptieren",
  createAccount: "Konto erstellen",
  differentDeliveryAddress: "An eine andere Adresse liefern",
  orderComments: "Bestellkommentare",
  payment: "ZAHLUNG",
  cash: "Bezahlung bei Lieferung",
  cashText: "Barzahlung bei Lieferung der Artikel",
  clickAway: "Abholung vor Ort",
  clickAwayText: "Abholung der Artikel im Geschäft",
  paypalRadio: "Kreditkarte oder PayPal",
  paypalError: "Bitte füllen Sie das Formular korrekt aus!",
  personalDataInfo: "Ihre persönlichen Daten werden für die Bedürfnisse dieser Website und zur Verwaltung Ihres Kontos verwendet",
  readThe: "Lesen Sie die",
  iHaveRead: "Ich habe die",
  ofThisSite: "dieser Website gelesen",
  yourOrder: "Ihre Bestellung",
  sendOrder: "Bestellung absenden",
  alreadyCustomer: "Ich bin bereits Kunde",
  orderCompleteTitle: "BESTELLUNG ABGESCHLOSSEN!",
  orderCompleteLine1: "Ihre Bestellung wurde erfolgreich erhalten",
  orderCompleteLine2: "Vielen Dank für Ihre Bestellung",
  isCompany: "Rechnung ausstellen",
  companyAfm: "USt-IdNr.",
  companyDoy: "Finanzamt",
  companyName: "Firmenname",
  companyProfession: "Beruf",
  bankTransfer: "Direkte Banküberweisung",
  bankTransferText: "Überweisen Sie auf eines unserer Bankkonten",
  bankLargeText: "Zahlen Sie direkt auf unser Bankkonto. Bitte geben Sie Ihre Bestellnummer bei der Banküberweisung an. Ihre Bestellung wird nach Prüfung des Geldeingangs versandt",
  bank: "Bank",
  nbgRadio: "Kreditkarte über die NBG Bank",
  without: "Ohne",
  checkoutData: "Zahlungs- / Lieferdetails",
  product: "PRODUKT",
  subTotal: "ZWISCHENSUMME",
  without: "OHNE",
  total: "GESAMT",
  doYouHave: "Bereits ein Konto? Klicken Sie",
  here: "hier",
  toConnect: "zur Anmeldung",
  legalName: "Firmenname",
  legalDescription: "Firmenart",
  postalAddress: "Hauptstraße",
  postalAddressNo: "Hausnummer",
  postalAreaDescription: "Hauptstadt",
  postalZipCode: "PLZ",
  firmActDescription: "Hauptberuf",
  companyPhone: "Telefon",
  companyEmail: "E-Mail",
  doyDescription: "Finanzamtsbezeichnung",
  returnToShop: "ZURÜCK ZUM SHOP",
  alergies: "Haben Sie eine Allergie oder Unverträglichkeit gegen Lebensmittel? Gibt es eine ernährungsphysiologische Besonderheit? Wenden Sie sich an das Geschäft, bevor Sie Ihre Bestellung aufgeben."
}