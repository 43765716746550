export const checkoutFR = {
  less255: "Maximum 255 caractères",
  less50: "Maximum 50 caractères",
  less20: "Maximum 20 caractères",
  less10: "Maximum 10 caractères",
  required: "Requis",
  invalidEmail: "E-mail invalide",
  invalidPhone: "Numéro invalide",
  textareaMax: "Maximum 1000 caractères",
  invalidPayment: "Méthode de paiement invalide",
  terms: "Vous devez accepter les termes et conditions",
  createAccount: "Créer un compte",
  differentDeliveryAddress: "Livrer à une adresse différente",
  orderComments: "Commentaires de la commande",
  payment: "PAIEMENT",
  cash: "Payer à la livraison",
  cashText: "Payer en espèces lorsque les articles sont livrés",
  clickAway: "Cliquer et Emporter",
  clickAwayText: "Récupérez les articles en magasin",
  paypalRadio: "Carte de crédit ou PayPal",
  paypalError: "Veuillez remplir le formulaire correctement !",
  personalDataInfo: "Vos données personnelles seront utilisées pour les besoins de ce site et pour la gestion de votre compte.",
  readThe: "Lisez les",
  iHaveRead: "J'ai lu et j'accepte les",
  ofThisSite: "de ce site",
  yourOrder: "Votre commande",
  sendOrder: "Envoyer la commande",
  alreadyCustomer: "Je suis déjà un client",
  orderCompleteTitle: "COMMANDE TERMINÉE !",
  orderCompleteLine1: "Votre commande a été reçue avec succès",
  orderCompleteLine2: "Merci pour votre commande",
  isCompany: "Émettre une facture",
  companyAfm: "Numéro de TVA",
  companyDoy: "Service des impôts",
  companyName: "Nom de l'entreprise",
  companyProfession: "Profession",
  bankTransfer: "Virement bancaire direct",
  bankTransferText: "Transférez vers l'un de nos comptes bancaires.",
  bankLargeText: "Effectuez votre paiement directement sur notre compte bancaire. Veuillez inclure votre numéro de commande dans votre virement bancaire. Votre commande sera expédiée après vérification de votre transfert d'argent.",
  bank: "Banque",
  nbgRadio: "Carte de crédit via la Banque NBG",
  without: "Sans",
  checkoutData: "Détails de paiement / de livraison",
  product: "PRODUIT",
  subTotal: "SOUS-TOTAL",
  without: "SANS",
  total: "TOTAL",
  doYouHave: "Déjà un compte ? Cliquez",
  here: "ici",
  toConnect: " pour vous connecter.",
  legalName: "Nom légal",
  legalDescription: "Type d'entreprise",
  postalAddress: "Rue principale",
  postalAddressNo: "Numéro",
  postalAreaDescription: "Ville principale",
  postalZipCode: "Code postal",
  firmActDescription: "Profession principale",
  companyPhone: "Téléphone",
  companyEmail: "E-mail",
  doyDescription: "Nom du service des impôts",
  returnToShop: "RETOUR À LA BOUTIQUE",
  alergies: "Avez-vous une allergie ou une intolérance à un aliment ? Une spécificité nutritionnelle ? Contactez le magasin avant de passer votre commande."
}