import React, { useEffect } from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TermsText from './termsText';
import TermsTextEnglish from './termsTextEnglish';
import TermsTextFrench from './termsTextFrench';
import TermsTextGerman from './termsTextGerman';
import TermsTextItalian from './termsTextItalian';
import TermsTextSpanish from './termsTextSpanish';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const TermsNConditions = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");   
  
  const pickText = () => {
    switch (feathersStore.language){
        case "el" : {
            return <TermsText/>;
        }
        case "en" : {
            return <TermsTextEnglish/>;
        }
        case "de" : {
            return <TermsTextGerman/>;
        }
        case "es" : {
            return <TermsTextSpanish/>;
        }
        case "fr" : {
            return <TermsTextFrench/>;
        }
        case "it" : {
            return <TermsTextItalian/>;
        }
        case "ar" : {
          return <TermsTextEnglish/>;
        }
      }
    }
    
  return(
    <div className="settings-sub-container">
   {/*   <CategoryPic image={back}/>    */}
      <div className="pageIcon">
        <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
      </div>
      <p className="terms-title">{`-${footer.termsOfService}-`}</p>     
      <div className="terms-container">
        {pickText()}
      </div> 
    </div>
  )
}

export default inject('feathersStore')(observer(TermsNConditions));