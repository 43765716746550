import React, {useEffect, useState} from 'react';
import { CSSTransition } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckIcon from '@mui/icons-material/Check';
import copy from 'copy-to-clipboard';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import _useTranslate from '../hooks/_useTranslate';

const VoucherModal = ({ show, code, type, reward, promotionText, minimumOrder, onClose, feathersStore}) =>{ 

  const greenColored = {color: "hsl(151, 100%, 28%)", fontSize: 35, marginRight: 1}

  let common = _useTranslate(feathersStore.language, "common");  

  const [copied, setCopied] = useState(false)

  useEffect(() => {  
    let timeoutId = null; 
  if(show){
    timeoutId = setTimeout(onClose, 30000);
  } 
  if (timeoutId){
     return () => { clearTimeout(timeoutId) }
  }else{
    return () => null
  }
  
  },[show])

  const copyToClipboard = () => {
    copy(code);
    setCopied(true);
  }
    
  return(
    <>
    <div className="cookie-updated-alert">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <div className="voucher-alert">
          <div className="copy-title-text">         
            <CheckCircleIcon sx={greenColored}/>          
            {`${common.voucherTitle} ${reward}${type === 'Percent' ? '%' : '€'}`}
          </div> 
          <div className="alert-body-text">{common.applyVoucher}</div>
          <div className="alert-body-text">{promotionText}</div> 
          <div className="alert-body-text">{`${common.minimumVoucherOrder}: ${minimumOrder}€`}</div> 
          <div className="alert-body-text">{`${common.productSKU}: ${code}`}</div> 
          <div className="copy-row">            
            <div className="go-cart" onClick={copyToClipboard} >{`${common.copy} `} </div>  
            <div>{copied &&<CheckIcon className="copy-check-icon" sx={greenColored}/>}</div> 
          </div>        
          <CloseIcon className="alert-close-icon"  onClick={onClose}/>
        </div>
      </CSSTransition>
    </div>
  </>
  )
}

export default inject('feathersStore')(observer(VoucherModal));