import React, {useEffect} from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import DeliveryOptionsText from './deliveryOptionsText';
import DeliveryOptionsTextEnglish from './deliveryOptionsTextEnglish';
import DeliveryOptionsTextGerman from './deliveryOptionsTextGerman';
import DeliveryOptionsTextSpanish from './deliveryOptionsTextSpanish';
import DeliveryOptionsTextFrench from './deliveryOptionsTextFrench';
import DeliveryOptionsTextItalian from './deliveryOptionsTextItalian';

import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const DeliveryOptions = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");   

  const pickText = () => {
    switch (feathersStore.language){
        case "el" : {
            return <DeliveryOptionsText/>;
        }
        case "en" : {
            return <DeliveryOptionsTextEnglish/>;
        }
        case "de" : {
            return <DeliveryOptionsTextGerman/>;
        }
        case "es" : {
            return <DeliveryOptionsTextSpanish/>;
        }
        case "fr" : {
            return <DeliveryOptionsTextFrench/>;
        }
        case "it" : {
            return <DeliveryOptionsTextItalian/>;
        }
        case "ar" : {
            return <DeliveryOptionsTextEnglish/>;
        }
      }
    }
    
    return(
        <div className="settings-sub-container">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
            </div>
            <p className="terms-title">{`-${footer.deliveryOptions}-`}</p>
            <div className="terms-container">
                {pickText()}                    
            </div> 
        </div>
    )
}

export default inject('feathersStore')(observer(DeliveryOptions));