import React from 'react';
import Colors from '../../assets/colors'
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const HistoryComponent = ({date, list, total, transportation, discounts, onClick, status, orderNumber, feathersStore})=> {
  
  let common = _useTranslate(feathersStore.language, "common");
  let cart = _useTranslate(feathersStore.language, "cart"); 
  
  const constructTitle = (item)=> {  
    return feathersStore._translate(item.product_name , item.product_nameTranslated) 
      + (item?.product_size?.name ? 
        "  " + feathersStore._translateNameAttr(item.product_size) :
        "")
      + (item?.product_weightedQuantity > 0 ? ("  " + (+item.product_weightedQuantity * 1000).toString() + " gr") : "")
      + item.features?.map(feature => "  " + feathersStore._translateNameAttr(feature))
      + item.attributes?.map(attribute => "  " + feathersStore._translateTitle(attribute) + ": " 
          + feathersStore._translateNameAttr(attribute) + " " +(attribute?.quantity > 1 ? "x" + attribute.quantity + " " : ""))
      + (item?.ingredients?.length > 0 ? ("  " + cart.without  + item.ingredients?.map(ingredient => "  " 
          + feathersStore._translateNameAttr(ingredient))) : "")
  }  
  
  const parse_fix = price => {
    return price ? parseFloat(price).toFixed(2) : 0;
  }

  const renderListItems = list.map((item, index)=> {
    return(        
      <div key={index} className="history-object-body">        
        <div aria-labelledby='Link to product'
          onClick={()=> item.enabledForDelivery  ? onClick(item.product_id) : {}} className="history-object-link">
          <span style={item.enabledForDelivery  ? null : { color : 'red' }}>
            {constructTitle(item)} x {item.product_quantity}</span></div>             
        <span style={item.enabledForDelivery  ? null : { color : 'red' }}>
          {parse_fix(item.product_totalPrice)}€</span>
      </div>
    )
  })

   

  return (
    <div className="history-object">
      <div className="history-object-topHeader">
        <span className="order-number">{common.order} {orderNumber}&nbsp;{date}</span>
        <span className="status-line">{common.status}: <span 
          className={"status-badge" +  (status === 'Pending' ? " pending" : 
            status === 'Processing' ? " processing" : 
            status === 'Completed' ? " completed" :
            status === 'Delivering' ? " delivering" : 
            status === 'Delivered' ? " delivered" : "")}
        >{status}</span></span>
      </div>      
      <div className="history-object-body-container">
        {renderListItems}
      </div>
      {transportation > 0 &&
        <div className="history-object-body">
          <div>{common.transportation}</div>
          <div>{parse_fix(transportation)}€</div>
        </div>
      }
      {discounts > 0 &&
        <div className="history-object-body">
          <div>{common.discount}</div>
          <div>{parse_fix(discounts)}€</div>
        </div>
      }
      <div className="history-object-footer">
        <span>{common.total}:</span>
        <span>{parse_fix(total)}€</span>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(HistoryComponent));