export const contactIT = {
    mustHaveChar: "Deve contenere almeno due caratteri",
    less255: "Deve essere più corto di 255 caratteri",
    required: "Obbligatorio",
    invalidEmail: "Email non valida",
    invalidPhone: "Numero di telefono non valido",
    textareaMax: "Deve essere più corto di 1000 caratteri",
    contactForm: "Modulo di contatto",
    firstName: "Nome",
    lastName: "Cognome",
    phone: "Telefono",
    email: "Email",
    text: "Messaggio",
    buttonForm: "Invia",
    emailSent: "Email inviata!",
    emailSentMessage: "Hai inviato con successo la tua email!",
    emailError: "Errore nell'invio dell'email",
    emailErrorMessage: "La tua email non è stata inviata!"
}