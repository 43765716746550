import React, { useEffect } from 'react'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import {Link} from 'react-router-dom'
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../../hooks/_useTranslate';

const AccountButton = ({ feathersStore })=> {

  let common = _useTranslate(feathersStore.language, "common");  

  return(
    <Link to="/account" className="undecorated-link">
      <span aria-labelledby='Sign In' className="nav-button-SignIn undecorated-link">
        <PermContactCalendarIcon className="calendar-icon" style={{ fontSize: 30 }}/>
        <span style={{marginLeft: "1vw"}}>{common.myAccount}</span>
      </span>
    </Link>
  )
}

export default inject('feathersStore')(observer(AccountButton));