import React from 'react';

const CartIndicator = ({cartQuantity})=> {

  return (
    <div className="cartIndicator">
      <span style={{color: '#fff', fontSize: 12}}>{cartQuantity}</span>
    </div>           
  );
}

export default CartIndicator;
