import React from 'react'
import HighlightOff from '@mui/icons-material/HighlightOff';
import AppColors from '../assets/colors'

const DeleteButton = (props)=> {

  function changeCursor(e) {
      e.target.style.cursor = 'pointer';
    }

  return(
    <button onMouseOver={changeCursor} onClick={props.onClick} className="deleteButton">
      <HighlightOff style={{color: AppColors.secondary, fontSize: 26}}/>
    </button>       
  )
}

export default DeleteButton;