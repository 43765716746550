import React,{useState, useEffect, useRef, Suspense, lazy} from 'react'
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import AddressComponent from './addressComponent';
import AddIcon from '@mui/icons-material/Add';
import AddOrEditaddressModal from './addOrEditAddressModal/AddOrEditAddressModal';
import CheckoutComponent from '../../cards/checkoutComponent'
import Registration from '../../buttons/registration/Registration'
import LoadingOrderModal from '../../modals/loadingOrderModal';
import OrderCompleteModal from '../../modals/orderCompleteModal';
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { inject, observer } from "mobx-react";
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Colors from '../../assets/colors';
import { PayPalButton } from "react-paypal-button-v2";
import GreenSubmitButton from '../../buttons/greenSubmitButton';
import RoundedButton from '../../buttons/roundedButton';
import SearchIcon from '@mui/icons-material/Search';
import "./Checkout.css";
import { PHONE_REGEX, DEFAULT_EMAIL} from "../../assets/globalConstants";
import * as cloneDeep from 'lodash/cloneDeep';
import * as uniq from 'lodash/uniq';
import * as flatten from 'lodash/flatten';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { DateTime } from "luxon";
import { OrderKind } from "../../utils/enums";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Checkout = ({ feathersStore })=> {

  let navigate = useNavigate();  
  let location = useLocation();
 
  let common = _useTranslate(feathersStore.language, "common");
  let checkout = _useTranslate(feathersStore.language, "checkout");
  
  const formRef = useRef();
  
  const [origins, setOrigins] = useState([]);
  const [companyOrigins, setCompanyOrigins] = useState([]);
  const [cartSub, setCartSub] = useState(0);  
  const [isLoading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);  
  const [discount, setDiscount] = useState(0);
  const [voucherDiscount, setVoucherDiscount] = useState({});
  const [loyaltyReward, setLoyaltyReward] = useState(0);


  const companyIdRef = useRef();
  let aadeResponseRef = useRef(null);
  const discountsArrayRef = useRef([]);

  //---------company addresses controls
  const [addresses, setAddresses] = useState([]);  
  const [visibleAddNewAddress, setAddNewAddress] = useState(false);

  const openNewAddress = () => {     
    setAddNewAddress(true); 
  };

  const closeNewAddress = () =>{
    setAddNewAddress(false); 
  }  

  const setDeliveryAddress = item => () => {    
    const index = addresses.indexOf(item);
    let activeIndex = addresses.findIndex(e => e.active === true);
    if(activeIndex < 0)activeIndex = 0;        
    let clonedAddresses = [...addresses];

    if (activeIndex !== index) {
      clonedAddresses[activeIndex].active = false;
      clonedAddresses[index].active = true;      
      updateCompany(clonedAddresses);   
    }
  };

  const updateCompany = async (newAddresses) => {
    setAddresses([...newAddresses]);   
    await saveActiveAddress(newAddresses); 
  }

  const saveActiveAddress = async (val) => {   
    await feathersStore.patchCompanyAddresses( companyIdRef.current, [...val]);   
  };

  //-----------
  
  const cartItems = JSON.parse(localStorage.getItem('fdwa_cart')); 

  useEffect(()=> { //used for refresh
    feathersStore.setTableId(getWithExpiry('tableId'))
  },[]);

  useEffect(()=> { //used for voucher general discount coming from Cart.js
    if(location?.state?.payload)setVoucherDiscount(location.state.payload[0]);
  },[location?.state?.payload]);

  useEffect(()=> { //Loyalty past rewards
      const _loyaltyReward = applyOldRewards();  
      setLoyaltyReward(_loyaltyReward)
  },[feathersStore?.user]);

  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)   
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) { 
      localStorage.removeItem(key);
      emptyCart();
      return null
    }
    return item.value
  }

  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': 1,
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fdwa_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }

  useEffect(()=> {
    setOrigins(feathersStore.settings?.origins);    
    setCompanyOrigins(feathersStore.settings?.companyOrigins); 
  },[feathersStore.isAuthenticated && feathersStore.settings]);

  useEffect(()=> {
    const _subTotal =  cartItems.map(item=> item.product_totalPrice)
      .reduce((prev, curr)=> +prev + +curr, 0).toFixed(2);
    setCartSub(_subTotal)
  },[cartItems])

  useEffect(()=> {
    
    if(feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ){

        formRef.current.setFieldValue('firstName', feathersStore.user?.firstname || "");        
        formRef.current.setFieldValue('lastName', feathersStore.user?.lastname || "");
        if(feathersStore.user?.addresses?.length > 0){  
          const addr =  feathersStore.user.addresses.find(addr => addr.active);
          const cityAllowed = feathersStore.settings.citys?.filter(c => c.enabled)
            .map(c => c.id).includes(addr.city);
         
          if (cityAllowed){           
            formRef.current.setFieldValue('street', addr?.street || "");
            formRef.current.setFieldValue('streetNumber', addr?.streetNumber || "");
            formRef.current.setFieldValue('city', addr?.city || 1);
            formRef.current.setFieldValue('origin', addr?.origin || 1);
            formRef.current.setFieldValue('postcode', addr?.postcode || "");
          }     
               
        formRef.current.setFieldValue('phone',feathersStore.user?.phone);
        formRef.current.setFieldValue('email',feathersStore.user?.email);  
           
      }else{
        formRef.current.resetForm();
        formRef.current.validateForm();
      }   
    }
  },[feathersStore.user, feathersStore.isAuthenticated && feathersStore.settings, cartItems]);

  useEffect(()=> {
    const asyncFunction = async() => {    
      let couponsActive = [];
      if(feathersStore?.user?.coupons?.length > 0){
        couponsActive = feathersStore?.user?.coupons?.filter(cpn => cpn.userActive);
      }    
      if((couponsActive?.length > 0) && (cartSub > 0))constructDiscount(couponsActive);
    }
    asyncFunction();
  },[feathersStore?.user?.coupons, cartSub]);
 
  const constructTitle = (item)=> {  
    return feathersStore._translate(item.product_name , item.product_nameTranslated) 
    + (item?.product_size?.name ? 
      "  " + feathersStore._translateNameAttr(item.product_size) :
      "");     
  }  

  const constructData = item => {
    return (item?.product_weightedQuantity > 0 ? ((+item.product_weightedQuantity * 1000).toString() + " gr ") : "")
    + item.features?.map(feature => feathersStore. _translateNameAttr(feature) + "  ")
    + item.attributes?.map(attribute => "  " + feathersStore._translateTitle(attribute) + ": " 
        + feathersStore. _translateNameAttr(attribute) + " " +(attribute?.quantity > 1 ? "x" + attribute.quantity + " " : ""))
    + (item?.ingredients?.length > 0 ? ("  " + checkout.without  + item.ingredients?.map(ingredient => "  " 
        + feathersStore. _translateNameAttr(ingredient))) : "")
  }

  const BanksIterator = () => {
    return(
      <table className="banks-container">
        <thead>
          <tr>
            <th>{checkout.bank}</th>
            <th>IBAN</th>
          </tr>       
        </thead>
        <tbody>       
          {feathersStore.settings?.bankAccounts
            ?.filter(item => item.bankEnabled)
            ?.map((bank, index) => 
              <tr key={bank.bankIBAN}>
                <td>
                  {bank.bankName}
                </td> 
                <td>
                  {bank.bankIBAN}
                </td>
              </tr> 
          )}  
        </tbody>     
      </table>
    )}
  

  const createOrder = (data, actions) => {
    let values;
    if (formRef.current) {
      values = formRef.current.values;
    }
    return actions.order
      .create({
        purchase_units: [
          {
            amount: {
              currency: "EUR",
              value: values.grandTotal,
            },
          },
        ],
        application_context: {
           shipping_preference: "NO_SHIPPING" // default is "GET_FROM_FILE"
         }
      })
      .then((orderID) => {       
        feathersStore.setOrderItem({paypalOrderID: orderID});     
        return orderID;
      });
  }

  const approved = (data, actions) =>  {
    let values;
    if (formRef.current) {
      values = formRef.current.values;
    }
    // This function captures the funds from the transaction.
    return actions.order.capture().then(function(details)  {
      // This function shows a transaction success message to your buyer.
      sendOrder(values);
      alert('Transaction completed by ' + details.payer.name.given_name);
     });
   }
  
  const onInit = (data, actions) =>  {
    let formikRef;
    if (formRef.current) {
      formikRef = formRef.current;
    }

    // Disable the buttons
    actions.disable();
    if (formikRef.isValid)  {
      actions.enable();
      } else  {
      actions.disable();
    }
  }

  const onClick = () => {
    let formikRef;
    if (formRef.current) {
      formikRef = formRef.current;
    }

    if (!formikRef.isValid) {    
      alert("Please fill all fields");
    }
  }

  //----------> LOYALTY

  const applyOldRewards = () => {//applied at the beginning of sendOrder
    let totalReward = 0;
    if(feathersStore?.user?.email !== DEFAULT_EMAIL){
      if(feathersStore.user?.loyaltyRewards?.length > 0){
        totalReward = feathersStore.user?.loyaltyRewards
          ?.filter(r => !r?.applied)//Old rewards not yet applied
          ?.map(reward => reward?.value || 0)
          ?.reduce((a, b) => +a + +b, 0);       
      }
    }
    return totalReward;
  }

  //-------------->

  const sendOrder = async values => {  

    //Loyalty  
    if(loyaltyReward > 0)discountsArrayRef.current.push({
      type : "Cash",
      label : "Loyalty",
      value : parse_fix(loyaltyReward),
      reward : parse_fix(loyaltyReward),
      applied : false,
      promotionText : "Loyalty Rewards"
    }) 

    //voucher
    if(voucherDiscount?.value > 0)discountsArrayRef.current.push(voucherDiscount) 

    const discounts = discountsArrayRef.current
      ?.filter(disc => !disc?.applied)
      ?.map(d => d.value)
      ?.reduce((a,b) => +a + +b, 0);   

    const day = new Date().getDate(); //Current Date
    const month = new Date().getMonth() + 1; //Current Month
    const year = new Date().getFullYear(); //Current Year
    const hours = new Date().getHours(); //Current Hours
    const min = new Date().getMinutes(); //Current Minutes
    const initDate = day + '/' + month + '/' + year + ' ' + hours + ':' + min;
    let finalOrderItems = [...cartItems];
    finalOrderItems.forEach(item => {
      Object.assign(item, {receipt: false});
      return item;
    })
    if(values.paymentMethod === 'PayPal'){
      finalOrderItems.forEach(item => {
        Object.assign(item, {
          'paid_quantity': item.product_quantity,
          'visa_quantity': item.product_quantity
        });
        return item;
      })           
    }
    let dataItem = { 
      'bookingDate': initDate,
      'processed': false,   
      'comments':values.comments || "",
      'firstName': values.firstName,
      'lastName': values.lastName,
      'street': values.street,
      'streetNumber': values.streetNumber,
      'city': findCity(values.city),
      'origin': values.origin, 
      'apartment' : "", 
      'postcode': values?.postcode || "",        
      'phone': values.phone,
      'email': values.email, 
      'latitude': 0,
      'longitude': 0,     
      'isCompany': values.isCompany,
      'afm': values.isCompany ? values.afm : "",
      'legalName': values.isCompany ? values.legalName : "",
      'legalDescription': values.isCompany ? values.legalDescription : "",
      'doyDescription': values.isCompany ? values.doyDescription : "",
      'firmActDescription': values.isCompany ? values.firmActDescription : "",
      'postalAddress': values.isCompany ? values.postalAddress : "",
      'postalAddressNo': values.isCompany ? values.postalAddressNo : "",
      'postalAreaDescription': values.isCompany ? values.postalAreaDescription : "",
      'postalZipCode': values.isCompany ? values.postalZipCode : "",
      'companyPhone': values.isCompany ? values.companyPhone : "",
      'companyEmail': values.isCompany ? values.companyEmail : "",
      'companyOrigin': values.isCompany ? values.companyOrigin : 1, 
      'hasOtherAddress': values.hasOtherAddress,
      'deliveryFirstName': values.hasOtherAddress ? values.deliveryFirstName : "",
      'deliveryLastName': values.hasOtherAddress ? values.deliveryLastName : "",
      'deliveryStreet': values.hasOtherAddress ? values.deliveryStreet : "",
      'deliveryStreetNumber': values.hasOtherAddress ? values.deliveryStreetNumber : "",
      'deliveryCity': values.hasOtherAddress ? findCity(values.deliveryCity) : "", 
      'deliveryOrigin': values.hasOtherAddress ? values.deliveryOrigin : 1, 
      'deliveryPostcode': values.hasOtherAddress ? values?.deliveryPostcode : "", 
      'deliveryPhone': values.hasOtherAddress ? values.deliveryPhone : "",  
    }
    if(values.isCompany){
      const companyDeliveryAddress = addresses.find(a => a.active);
      Object.assign(dataItem, {companyDeliveryAddress});
      await saveOrUpdateCompany(dataItem.companyPhone, dataItem.companyEmail, dataItem.companyOrigin);
    }
    if(feathersStore.tableId?.length > 0){
      const table = await feathersStore.getTable(feathersStore.tableId);
      let oldOrderClone = cloneDeep(table.order);
      const printersUsed = uniq(flatten(finalOrderItems.map(item => item?.product_kdsSections?.map(prn => prn.name))))
      Object.assign(dataItem, {
        '_id': table.tableName,
        'waiterId': "qrcode",
        'waiterUsername': "qrcode",
        'customerId': "anonymous", 
        "tableId": feathersStore.tableId,
        "orderKind": OrderKind.webTable
      })    
      //append order   
      if(table?.order && table.order?.items?.length > 0){
        const maxId = calculateMaxId(table.order);
        finalOrderItems.forEach((item, index) => {
          Object.assign(item, {reorder: true, id: +maxId + 1 + +index})
          return item;
        })
        Object.assign(dataItem, table.order, {
        'items':  [...oldOrderClone.items, ...finalOrderItems],
        'discounts': +discounts,
        discountsArray: discountsArrayRef.current,
        'total': +oldOrderClone.total + +values.grandTotal,
        'unpaid': +oldOrderClone.unpaid + (values.paymentMethod === 'PayPal' ? 0 : +values.grandTotal)
        })    
      }else{
          //new order  
        finalOrderItems?.forEach((item, index) => {
          Object.assign(item, {id: +index})
          return item;
        })
        Object.assign(dataItem, {
          'items': [...finalOrderItems],
          'discounts': +discounts,
          discountsArray: discountsArrayRef.current,
          'total': +values.grandTotal,
          "unpaid": (values.paymentMethod === 'PayPal' ? 0 : +values.grandTotal),        
          'status': "Pending"
        })
        printersUsed?.forEach(printer => {
          Object.assign(dataItem, {[`${printer}`]: "Pending"})
        }) 
      }   
    }else{    
      Object.assign(feathersStore.orderItem, dataItem, {
        'customerId': feathersStore?.user._id || "anonymous_mobile_customer",
        "unpaid": (values.paymentMethod === 'PayPal' ? 0 : +values.grandTotal),        
        'items': [...finalOrderItems],
        'transportation': +values.transportation,
        'transportationPaid': (+values.transportation > 0) && (values.paymentMethod === 'PayPal') ? true : false,
        'discounts': +discounts,
        discountsArray: discountsArrayRef.current,
        'total': +values.grandTotal,
        'status': 'Pending',
        'paymentMethod': values.paymentMethod,
        'orderKind': values.paymentMethod === 'ClickAway' ? OrderKind.webTakeAway : OrderKind.webDelivery
      });
    } 
    feathersStore.setOrderItem(dataItem); 
    setLoading(true);
  }

  const calculateMaxId = order => {
    if(order?.items?.length > 0){
      const arr = order.items.map(item => +item?.id || 0);    
      return arr.sort((a, b) => b - a)[0];
    }     
    else return -1;
  }

  const findCity = id => {
    const city =  feathersStore.settings.citys?.find(c => +c.id === +id )    
    if(city) return feathersStore._translate(city.name, city.nameEnglish);
    else return "";   
  } 

  const findTransportation = (id) => {
  
    const city =  feathersStore.settings.citys?.find(c => +c.id === +id );  
   
    if(feathersStore?.tableId?.length > 0)return 0;
    else return city?.transportation || 0;
  
  } 

  const saveOrUpdateCompany = async(companyPhone, companyEmail, companyOrigin) => {
    try{
      const payload = {
        afm: aadeResponseRef.current.afm.trim(),
        aadeResponse: aadeResponseRef.current,
        phone: companyPhone,
        email: companyEmail,
        origin: companyOrigin,
        addresses: addresses
      }
      if(companyIdRef.current){
        await feathersStore.updateCompany(companyIdRef.current, payload)
      }else{
        await feathersStore.createCompany(payload);
      }
    }catch(error){
      console.lof(error);
    }
  } 
  const parse_fix = price => {
    return price ? parseFloat(price).toFixed(2) : 0;
  }

  //---------------------> REWARD SYSTEM

  const constructDiscount = (coupons) => {
    let _discount = 0;
    let discountsArray = [];
    const now = DateTime.now().setZone("Europe/Athens");    
    coupons.forEach(coupon => {
      if(coupon.userCoupon?.completion === 0 && DateTime.fromISO(coupon.stop) > now && DateTime.fromISO(coupon.start) < now ){ 
        if(coupon.userCoupon.type === "Percent"){ //ad hoc discount          
          const disc = parse_fix(cartSub * coupon.userCoupon.reward / 100);
          _discount = +_discount + +disc;
          discountsArray.push({
            type: "Percent",
            label: "AdHoc",
            value: disc,
            reward: coupon.userCoupon.reward,
            applied: false,
            display:coupon.userCoupon.display,
            promotionText: coupon.userCoupon.promotionText
          })
        }else if(coupon.userCoupon.type === "Cash"){  
          const disc = coupon.userCoupon.reward;
          _discount = +_discount + +disc;
          discountsArray.push({
            type: "Cash",
            label: "AdHoc",
            value: disc,
            reward: coupon.userCoupon.reward,
            applied: false,
            display:coupon.userCoupon.display,
            promotionText: coupon.userCoupon.promotionText
          })
        }
      }else{  //voucher         
       
      }
    })   
    setDiscount(_discount);
    discountsArrayRef.current = discountsArray;
  }

  //---------------------------------->

  const validationSchema = Yup.object({ //Three ways to do the validation    
  
    firstName: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .max(255, checkout.less255)
      .required(checkout.required)
    })
    ,
    
    lastName: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .max(255, checkout.less255)
      .required(checkout.required)
      })
    ,
    street: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .max(50, checkout.less50)
      .required(checkout.required)
      })
    ,
    streetNumber: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .max(10, checkout.less10)
      .required(checkout.required)
      })
    ,   
    postcode: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .max(10, checkout.less10)
    //  .required(checkout.required)
    })
    ,  
    email: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .email(checkout.invalidEmail)
      .required(checkout.required)
      })
    , 
    phone: Yup.string()
      .when('tableId', {
      is: false,
      then:  Yup.string()
      .matches(PHONE_REGEX, checkout.invalidPhone)
      .required(checkout.required)
      })
    ,     
    transportation: Yup.number(), 
    customerDiscount: Yup.number(), 
    couponDiscount: Yup.number(),
    loyaltyDiscount: Yup.number(),
    grandTotal: Yup.number(),
    hasOtherAddress:  Yup.boolean()
      .required(checkout.required),
    isCompany:  Yup.boolean()
      .required(checkout.required),
    comments: Yup.string()
      .max(500, checkout.textareaMax),
    paymentMethod:  Yup.string() 
      .required(checkout.required)
      .oneOf(["Cash", "ClickAway", "BankTransfer", "NBG", "PayPal"], checkout.invalidPayment),
     acceptedTerms: Yup.boolean()
      .required(checkout.required)
      .oneOf([true], checkout.terms),
    
      //---- Company sub - form validation
    afm:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(20, checkout.less20)
        .required(checkout.required)
    }),
    doyDescription:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),
    legalName:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),
    legalDescription:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),
    firmActDescription:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),
    postalAddress:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),
    postalAddressNo:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(10, checkout.less10)
        .required(checkout.required)
    }),
    postalAreaDescription:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),
    postalZipCode:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .max(10, checkout.less10)
        .required(checkout.required)
    }),
    companyPhone:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .matches(PHONE_REGEX, checkout.invalidPhone) 
        .required(checkout.required)
    }),
    companyEmail:  Yup.string().when('isCompany', {
      is: true,
      then:  Yup.string()
        .email(checkout.invalidEmail)
        .required(checkout.required)
    }),
    companyOrigin: Yup.number().when('isCompany', {
      is: true,
      then:  Yup.number()
      .required(checkout.required)
    }),

      //----- Other - Address - Sub - form validation ----------
    deliveryFirstName:   Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }), 
    deliveryLastName:   Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(255, checkout.less255)
        .required(checkout.required)
    }),          
    deliveryStreet:Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(50, checkout.less50)
        .required(checkout.required)
    }),
    deliveryStreetNumber:Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(10, checkout.less10)
        .required(checkout.required)
    }),
    deliveryCity: Yup.number().when('hasOtherAddress', {
      is: true,
      then:  Yup.number()         
        .required(checkout.required)
    }),        
    deliveryOrigin: Yup.number().when('hasOtherAddress', {
      is: true,
      then:  Yup.number()         
        .required(checkout.required)
    }),
    deliveryPostcode: Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .max(10, checkout.less10)
     //   .required(checkout.required)
    }),
    deliveryPhone:Yup.string().when('hasOtherAddress', {
      is: true,
      then:  Yup.string()
        .matches(PHONE_REGEX, checkout.invalidPhone)        
        .required(checkout.required)
    })  
        
  })

  const grandTotalComponent = ({field, form}) => {
    return(
    <span>{field.value}€</span>
    )
  }

  const transportationComponent = ({field, form}) => {
    return(
    <span>{field.value}€</span>
    )
  }

  const discountComponent = ({field, form}) => {
    return(
    <span>{field.value}€</span>
    )
  }


  //----------------- Invoice methods --------------

  const search = async(afm) => { 
    let company;  
    const companyFromDb = await feathersStore.findCompany(afm);  
    if(companyFromDb?.data?.length > 0)company = companyFromDb?.data[0];
    aadeResponseRef.current = await searchAade(afm);    
    if(company?._id){   
      companyIdRef.current = company._id     
      await fillAdditionalForm(company);
    }else{      
      let data ={       
        street: aadeResponseRef.current.postal_address,        
        streetNumber: aadeResponseRef.current.postal_address_no,
        postcode: aadeResponseRef.current.postal_zip_code, 
        city: aadeResponseRef.current.postal_area_description,
        active:  true
      }       
      setAddresses([data]);      
    }
       
  }

  const searchAade = async (afm) => {
    const aadeResponse = await feathersStore.createSoap(afm);   
    await fillBasicForm(aadeResponse);
    return aadeResponse;
  }
  
  const fillAdditionalForm = async(company) => { 
    await formRef.current.setFieldValue('companyPhone', company?.phone);
    await formRef.current.setFieldValue('companyEmail', company?.email);
    await formRef.current.setFieldValue('companyOrigin', +company?.companyOrigin || 1);    
    setAddresses(company?.addresses);
  } 
  
  const fillBasicForm = async(company) => {  
    if(company){
      const desc = company.firm_act_tab?.item    
        .find((d)=> d.firm_act_kind[0] === '1')
      desc && await formRef.current.setFieldValue('firmActDescription', desc?.firm_act_descr[0]?.toString().trim());
      await formRef.current.setFieldValue('legalName', company?.onomasia?.toString().trim());     
      await formRef.current.setFieldValue('legalDescription', company?.legal_status_descr?.toString().trim());
      await formRef.current.setFieldValue('doyDescription', company?.doy_descr?.toString().trim()); 
      await formRef.current.setFieldValue('postalAddress', company?.postal_address?.toString().trim());
      await formRef.current.setFieldValue('postalAddressNo', company?.postal_address_no?.toString().trim());
      await formRef.current.setFieldValue('postalAreaDescription', company?.postal_area_description?.toString().trim());
      await formRef.current.setFieldValue('postalZipCode', company?.postal_zip_code?.toString().trim());  
      
    } 
  }

  const renderAddressItem = ( item, index ) => (
   
    <AddressComponent
      companyId={companyIdRef.current}
      setAddresses={setAddresses}
      key={index}
      onPress={setDeliveryAddress(item)}
      street={item.street}
      city={item.city}
      postcode={item?.postcode}
      streetNumber={item.streetNumber}
      active={item.active} 
      item={item}   
      itemIndex={index} 
      disableDelete={addresses?.length < 2}
    />
    );

  
  //----------------- LoadingOrderModal controls -----------------

  const whenError = () => {
    setLoading(!isLoading); 
  }

  const CompleteOrder = ()=> {
    setLoading(!isLoading);
    setComplete(!isComplete);     
  }

  const setOrder = ()=> {
    setComplete(!isComplete);      
    navigate("/") ;     
  }

  const goToTerms = () => {
    navigate("/termsnconditions") ;     
  }

  const calculateDefaultPaymentMethod = () => {
    if(!feathersStore.settings?.disableCashPayments)return 'Cash';
    else if(!feathersStore.settings?.disableClickAway)return 'ClickAway';
    else if(feathersStore.settings?.hasCardPayments)return 'PayPal';
    else return 'BankTransfer';
  }

  const renderCartItems = cartItems.map( (item, index) => (
    <CheckoutComponent
      key={index}
      sku={item.productSKU}
      title={constructTitle(item)}
      data={constructData(item)}
      quantity={item.product_quantity}
      total={item?.product_totalPrice || 0}
    />
  ))
  
  return(   
    <>    
      {formRef.current?.values && 
        <LoadingOrderModal
          visible={isLoading}
          whenSent={CompleteOrder}
          whenError={whenError}
          total={(+formRef.current.values.grandTotal)}
        />
      }
      <OrderCompleteModal
        visible={isComplete}
        onClickFinish={setOrder}        
      /> 
      <div className="header-section">      
        <div className="grey-header">{common.checkout}</div>    
      </div>
      <div className="info-wrapper">
        <div className="info-text">
          <div>
            <InfoOutlinedIcon className="info-icon"/>
          </div>
          <div>
            {checkout.alergies}
          </div>
        </div>
        <div className="info-line"></div>
      </div>
     
      { feathersStore.user?.firstname === "default" &&  !feathersStore.tableId &&
         <div className="checkout-login">
          <div>{checkout.doYouHave}&nbsp; </div>
          <Registration title = {checkout.here} />
          <div>&nbsp;{checkout.toConnect}</div>
       </div>}
      <>
      <Formik 
        innerRef={formRef}  
        initialValues={{
        
          firstName: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.firstname : "",          
          lastName: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.lastname : "",
          street: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses?.length > 0 ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.street : "",
          streetNumber: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses?.length > 0 ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.streetNumber : "",
          city: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses?.length > 0 ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.city : 1, 
          origin: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses?.length > 0 ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.origin : 1, 
          postcode: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' && feathersStore.user?.addresses?.length > 0 ? 
            feathersStore.user?.addresses.find(addr => addr.active)?.postcode : "",       
          phone: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.phone : "",
          email: feathersStore.isAuthenticated && feathersStore.user?.firstname !== 'default' ? feathersStore.user?.email : "",

          
          tableId: feathersStore.isAuthenticated && feathersStore.tableId ? true : false, //Helper for Yup validation   
          isCompany: false,
          afm: "",
          doyDescription: "",
          legalName: "",
          legalDescription: "",
          firmActDescription: "",
          postalAddress: "",
          postalAddressNo: "",
          postalAreaDescription: "",
          postalZipCode: "",
          companyPhone: "",
          companyEmail: "",
          companyOrigin: 1, 
          hasOtherAddress: false,
          deliveryFirstName: '',
          deliveryLastName: '',
          deliveryStreet: '',
          deliveryStreetNumber: '',
          deliveryCity: 1, 
          deliveryOrigin: 1, 
          deliveryPostcode: '',       
          deliveryPhone: '',
          comments:'',
          paymentMethod: calculateDefaultPaymentMethod(),
          acceptedTerms: true,
          grandTotal:0,  
          transportation: 0,
          customerDiscount: 0,
          couponDiscount: 0,
          loyaltyDiscount: 0        
        }}
      
      validationSchema= {validationSchema}
      
      onSubmit={(values, { setSubmitting }) => {  
        sendOrder(values);
        setSubmitting(false);
        ;
      }}
    >
    {formik => (
    <Form>
      <div className="column-flex-center main-container">
        <div className="row-flex-start-start-wrap form-container" >
          <div className="left-container">   
          {(!feathersStore.tableId || (feathersStore.tableId && feathersStore.user?.firstname !== 'default')) &&
              <>
            <div className="checkout-order-table-title">{checkout.checkoutData}</div> 
                   
            <div className=" row-container" >
              <div className="two-quarters-container">                
                <label htmlFor="firstName" style={{fontWeight: 'bold'}}>{common.firstName}</label><span style={{color: 'red'}}>*</span>                
                <Field name="firstName" type="text" className="form-control" />
                <ErrorMessage name="firstName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
              <div className="two-quarters-container-right">                
                <label htmlFor="lastName" style={{fontWeight: 'bold'}}>{common.lastName}</label><span style={{color: 'red'}}>*</span>                
                <Field name="lastName" type="text" className="form-control" />
                <ErrorMessage name="lastName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>                
            </div>
            <div className="row-container">
              <div className="three-quarters-container">               
                <label style={{fontWeight: 'bold'}} htmlFor="street">{common.street}</label><span style={{color: 'red'}}>*</span>               
                <Field name="street" type="text" className="form-control" />
                <ErrorMessage name="street" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
              <div className="one-quarter-container-right">                
                <label style={{fontWeight: 'bold'}} htmlFor="streetNumber">{common.streetNumber}</label><span style={{color: 'red'}}>*</span>               
                <Field name="streetNumber" type="text" className="form-control" />
                <ErrorMessage name="streetNumber" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
            </div>
            <div className="row-container" >
              <div className="two-quarters-container">              
                <label style={{fontWeight: 'bold'}} htmlFor="city">{common.city}</label><span style={{color: 'red'}}>*</span>              
                <Field name="city" as="select" className="form-control" >
                {feathersStore?.settings?.citys?.filter(c => c.enabled)?.map((i, index)=> (              
                    <option key={index} className="option-item" value={+i.id}>{feathersStore._translate(i.name , i.nameEnglish)}</option>
                  ))}             
                </Field>
                <ErrorMessage name="city" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>               
              </div>
            </div>
            <div className="row-container" >
              <div className="three-quarters-container">                
                <label htmlFor="origin" style={{fontWeight: 'bold'}}>{common.origin}</label><span style={{color: 'red'}}>*</span>                
                <Field name="origin" as="select" className="form-control">
                  {origins?.map((i, index)=> (              
                    <option key={index} className="option-item" value={+i.id}>{feathersStore.translate('en', i.label , i.labelEnglish)}</option>
                  ))}             
                </Field>
                <ErrorMessage name="origin" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
              <div className="one-quarter-container-right">                
                <label htmlFor="postcode" style={{fontWeight: 'bold'}}>{common.postcode}</label>               
                <Field name="postcode" type="text" className="form-control" />
                <ErrorMessage name="postcode" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
              </div>
            </div>
              <div className="row-container">   
                <div className="two-quarters-container">              
                  <label htmlFor="phone" style={{fontWeight: 'bold'}}>{common.phone}</label><span style={{color: 'red'}}>*</span>               
                  <Field name="phone" type="text" className="form-control" />
                  <ErrorMessage name="phone" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                </div>
              </div>
              <div className="row-container">  
                <div className="two-quarters-container">                
                  <label style={{fontWeight: 'bold'}} htmlFor="email">{common.email}</label><span style={{color: 'red'}}>*</span>              
                  <Field name="email" type="email" className="form-control" />
                  <ErrorMessage name="email" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>      
                  <small id="emailHelp" className="form-text text-muted">{common.emailMessage}</small>
                </div>
              </div>   
              </>
              }    
              {!feathersStore.tableId &&
                <div className="checkbox-container">
                  <Field type="checkbox"   name="isCompany" 
                      style={{width: 16, height: 16}}/>
                  <label className="form-check-label" htmlFor="isCompany" >
                    {checkout.isCompany}
                  </label>
                </div>
              }
              <SlideDown className='my-dropdown-slidedown'>
                {formik.values.isCompany && 
                <div style={{marginTop: 20}}>
                  <div className="row-container">
                    <div className="two-quarters-container">                      
                      <label htmlFor="afm" style={{fontWeight: 'bold'}}>{checkout.companyAfm}</label><span style={{color: 'red'}}>*</span>                      
                      <div className="row-container">
                        <div>
                          <Field type="text" className="form-control" name="afm" />
                          <ErrorMessage name="afm" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                        </div>
                        <button     
                          type="button"                     
                          className="afm-search-button"         
                          onClick={() => search(formik.values.afm, formik)}
                          >
                            <span>{<SearchIcon />}</span>
                        </button>                     
                      </div>      
                    </div>
                    <div className="two-quarters-container-right">                     
                      <label htmlFor="legalName" style={{fontWeight: 'bold'}}>{checkout.legalName}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="legalName" type="text" className="form-control" />
                      <ErrorMessage name="legalName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div>
                  <div className="row-container">
                    <div className="two-quarters-container">                      
                      <label htmlFor="doyDescription" style={{fontWeight: 'bold'}}>{checkout.doyDescription}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="doyDescription" />
                      <ErrorMessage name="doyDescription" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="two-quarters-container-right">                     
                      <label htmlFor="legalDescription" style={{fontWeight: 'bold'}}>{checkout.legalDescription}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="legalDescription" type="text" className="form-control" />
                      <ErrorMessage name="legalDescription" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div>
                  <div className="row-container">
                    <div className="two-quarters-container">
                      <label className="company-label" htmlFor="firmActDescription" style={{fontWeight: 'bold', alignSelf: 'baseline'}}>{checkout.firmActDescription}<span style={{color: 'red'}}>*</span> </label>                   
                      <Field name="firmActDescription" type="text" className="form-control" />
                      <ErrorMessage name="firmActDescription" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                    
                  </div>                  
                  <div className="row-container" >
                    <div className="two-quarters-container">
                      <label className="company-label" htmlFor="companyOrigin" style={{fontWeight: 'bold', alignSelf: 'baseline'}}>{common.companyOrigin}<span style={{color: 'red'}}>*</span></label>                   
                      <Field name="companyOrigin" as="select" className="form-control">
                        {companyOrigins?.map((i, index)=> (              
                          <option key={index} className="option-item" value={+i.id}>{feathersStore.translate('en', i.label , i.labelEnglish)}</option>
                        ))}             
                      </Field>
                      <ErrorMessage name="companyOrigin" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                  
                  </div>
                  <div className="row-container">
                    <div className="three-quarters-container">                      
                      <label htmlFor="postalAddress" style={{fontWeight: 'bold'}}>{checkout.postalAddress}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="postalAddress" />
                      <ErrorMessage name="postalAddress" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="one-quarter-container-right">                     
                      <label htmlFor="postalAddressNo" style={{fontWeight: 'bold'}}>{checkout.postalAddressNo}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="postalAddressNo" type="text" className="form-control" />
                      <ErrorMessage name="postalAddressNo" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div> 
                  <div className="row-container">
                    <div className="three-quarters-container">                      
                      <label htmlFor="postalAreaDescription" style={{fontWeight: 'bold'}}>{checkout.postalAreaDescription}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="postalAreaDescription" />
                      <ErrorMessage name="postalAreaDescription" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="one-quarter-container-right">                     
                      <label htmlFor="postalZipCode" style={{fontWeight: 'bold'}}>{checkout.postalZipCode}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="postalZipCode" type="text" className="form-control" />
                      <ErrorMessage name="postalZipCode" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>
                   </div>     
                  <div className="row-container">
                    <div className="three-quarters-container">                     
                      <label htmlFor="companyEmail" style={{fontWeight: 'bold'}}>{checkout.companyEmail}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="companyEmail" type="text" className="form-control" />
                      <ErrorMessage name="companyEmail" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div> 
                    <div className="one-quarter-container-right">                      
                      <label htmlFor="companyPhone" style={{fontWeight: 'bold'}}>{checkout.companyPhone}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="companyPhone" />
                      <ErrorMessage name="companyPhone" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>                                
                  </div>
                  <div>
                    <div className="company-addresses-container">
                      <div className="company-address-container"> 
                        <FormControl component="fieldset" style={{width: '100%'}}>
                          <FormLabel component="legend"/>
                          <RadioGroup  name="addresses"  onChange={setDeliveryAddress}>
                            {
                            addresses?.length > 0 && addresses.map(renderAddressItem)
                            }
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                      <div className="addAddressOptionsButton">
                      <div className="addAddressOptionsButton-label">{common.addNewAddress}</div>
                      <RoundedButton
                        aria-label='Open new address'
                        color={'#00b970'}
                        icon={ <AddIcon style={{color: '#fff', fontSize: 30, marginTop: 2}}/>}
                        onClick={openNewAddress}
                      />
                    </div>
                  </div>  
                  <AddOrEditaddressModal 
                    visible={visibleAddNewAddress} 
                    close={closeNewAddress} 
                    itemToEdit={{}}
                    companyId={companyIdRef.current}
                    setAddresses={setAddresses}
                  />                           
                </div>}
              </SlideDown> 
              {!feathersStore.tableId &&
              <>
              { !formik.values.isCompany &&
                <div className="checkbox-container">
                  <Field type="checkbox"   name="hasOtherAddress" 
                      style={{width: 16, height: 16}}/>
                  <label className="form-check-label" htmlFor="hasOtherAddress" >
                    {checkout.differentDeliveryAddress}
                  </label>
                </div>
              } 
              <SlideDown className='my-dropdown-slidedown'>
                {formik.values.hasOtherAddress && 
                <div style={{marginTop: 20}}>
                  <div className="row-container">
                    <div className="two-quarters-container">                      
                      <label htmlFor="deliveryFirstName" style={{fontWeight: 'bold'}}>{common.firstName}</label><span style={{color: 'red'}}>*</span>                      
                      <Field type="text" className="form-control" name="deliveryFirstName" />
                      <ErrorMessage name="deliveryFirstName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>       
                    </div>
                    <div className="two-quarters-container-right">                     
                      <label htmlFor="deliveryLastName" style={{fontWeight: 'bold'}}>{common.lastName}</label><span style={{color: 'red'}}>*</span>                    
                      <Field name="deliveryLastName" type="text" className="form-control" />
                      <ErrorMessage name="deliveryLastName" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                
                  </div>
                  <div className="row-container">                    
                    <div className="three-quarters-container">
                      <label style={{fontWeight: 'bold'}} htmlFor="deliveryStreet">{common.street}</label><span style={{color: 'red'}}>*</span>                      
                      <Field name="deliveryStreet" type="text" className="form-control" />
                      <ErrorMessage name="deliveryStreet" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>                   
                    <div className="one-quarter-container-right">                
                      <label style={{fontWeight: 'bold'}} htmlFor="deliveryStreetNumber">{common.streetNumber}</label><span style={{color: 'red'}}>*</span>               
                      <Field name="deliveryStreetNumber" type="text" className="form-control" />
                      <ErrorMessage name="deliveryStreetNumber" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>
                  </div>
                  <div>
                    <div className="row-container">
                      <label style={{fontWeight: 'bold'}} htmlFor="deliveryCity">{common.city}</label><span style={{color: 'red'}}>*</span>
                    </div>
                    <Field name="deliveryCity" as="select" className="form-control">
                      {feathersStore?.settings?.citys?.filter(c => c.enabled)?.map((i, index)=> (              
                        <option key={index} className="option-item" value={+i.id}>{feathersStore._translate(i.name , i.nameEnglish)}</option>
                      ))}             
                    </Field>
                    <ErrorMessage name="deliveryCity" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                  <div className="row-container">
                    <div className="three-quarters-container">                      
                      <label htmlFor="deliveryOrigin" style={{fontWeight: 'bold'}}>{common.origin}</label><span style={{color: 'red'}}>*</span>                      
                      <Field name="deliveryOrigin" as="select" className="form-control" >
                        {origins?.map((i, index)=> (              
                          <option key={index} className="option-item" value={+i.id}>{feathersStore.translate('en', i.label , i.labelEnglish)}</option>
                        ))}             
                      </Field>
                      <ErrorMessage name="deliveryOrigin" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>
                    <div className="one-quarter-container-right">                      
                      <label htmlFor="deliveryPostcode" style={{fontWeight: 'bold'}}>{common.postcode}</label>                  
                      <Field name="deliveryPostcode" type="text" className="form-control" />
                      <ErrorMessage name="deliveryPostcode" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                    </div>
                  </div>
                  <div>
                    <div className="row-container">
                      <label htmlFor="deliveryPhone" style={{fontWeight: 'bold'}}>{common.phone}</label><span style={{color: 'red'}}>*</span>
                    </div>
                    <Field name="deliveryPhone" type="text" className="form-control" />
                    <ErrorMessage name="deliveryPhone" >{msg => <div className="error-message">{msg}</div>}</ErrorMessage>
                  </div>
                </div>
                }
              </SlideDown>
              </>
              }
              <div style={{display: 'flex', flexFlow: 'column', alignItems: 'baseline', justifyContent: 'left', marginTop: 20, width: '100%'}}>
                <label style={{fontWeight: 'bold'}} htmlFor="comments">
                  {checkout.orderComments}
                </label>
                <Field as="textarea" className="form-control" name="comments" rows="3"/>
              </div>  
            </div>  
            <div className="right-container">
              <div className="checkout-order-table-title">{checkout.yourOrder}</div>
              <div className="checkout-order-table">
                <div className="checkout-order-table-header">
                  <span >{checkout.product}</span>
                  <span className="sub-total-color">{checkout.subTotal}</span>
                </div>              
                {renderCartItems}  
                <div className="checkout-order-table-header">
                  <div>{common.transportation}</div>
                  <Field name="transportation" 
                    type="number" 
                    value={formik.values.transportation = (((formik.values.paymentMethod === "ClickAway") || (+cartSub > +feathersStore.settings.transportation?.free)) ? 0 : +findTransportation(formik.values.hasOtherAddress ? +formik.values.deliveryCity : +formik.values.city)).toFixed(2)} 
                    component={transportationComponent}>             
                  </Field>
                </div>  
                {(voucherDiscount?.value > 0) && <div className="checkout-order-table-header">
                  <div>{common.couponDiscount} {voucherDiscount.type === "Percent" ? voucherDiscount.reward + "%" : ""} </div>
                  <Field name="couponDiscount" 
                    type="number" 
                    value={formik.values.couponDiscount = +voucherDiscount?.value} 
                    component={discountComponent}>             
                  </Field>
                </div> } 
                {(loyaltyReward > 0) && <div className="checkout-order-table-header">
                  <div>{common.loyaltyTitle} {loyaltyReward} </div>
                  <Field name="loyaltyDiscount" 
                    type="number" 
                    value={formik.values.loyaltyDiscount = +loyaltyReward} 
                    component={discountComponent}>             
                  </Field>
                </div> } 
                {(discount > 0) && <div className="checkout-order-table-header">
                  <div>{common.customerDiscount} </div>
                  <Field name="customerDiscount" 
                    type="number" 
                    value={formik.values.customerDiscount = +discount} 
                    component={discountComponent}>             
                  </Field>
                </div> }         
                <div className="checkout-order-table-header">
                  <div style={{fontWeight: 'bold'}}>{checkout.total}</div>
                  <Field name="grandTotal" 
                    type="number" 
                    value={formik.values.grandTotal = 
                      (+cartSub - discount - formik.values.couponDiscount - formik.values.loyaltyDiscount + (((formik.values.paymentMethod === "ClickAway") || (+cartSub > +feathersStore.settings.transportation?.free)) ? 0 : +findTransportation(formik.values.hasOtherAddress ? +formik.values.deliveryCity : +formik.values.city))).toFixed(2)} 
                    component={grandTotalComponent}>             
                  </Field>
                </div>  
                <div className="payment-title">{checkout.payment}</div>  
                { !feathersStore.settings?.disableCashPayments &&
                  <div className="checkout-order-table-paymentType">
                    <Field type="radio" name="paymentMethod" value="Cash" />
                    <span style={{marginLeft: 5}}>{checkout.cash}</span>                  
                  </div>
                }
                {!feathersStore.tableId && 
                <>
                {!feathersStore.settings?.disableClickAway &&
                  <div className="checkout-order-table-paymentType">
                    <Field type="radio" name="paymentMethod" value="ClickAway" />
                    <span style={{marginLeft: 5}}>{checkout.clickAway}</span>                  
                  </div>
                }
                {(feathersStore.settings?.bankAccounts?.filter(item => item.bankEnabled)?.length > 0) &&
                  <div className="checkout-order-table-paymentType">
                    <Field type="radio" name="paymentMethod" value="BankTransfer" />
                    <span style={{marginLeft: 5}}>{checkout.bankTransfer}</span>                  
                  </div>  
                }                         
                {formik.values.paymentMethod === "BankTransfer" && 
                  <div className="banks-container column-flex-center">
                    <div className="bank-table-container">
                      <BanksIterator/>
                    </div>
                    <p>
                      {checkout.bankLargeText}
                    </p>
                  </div>
                }   
                </> 
                }    
                { feathersStore.settings?.hasCardPayments &&
                  <>                       
                    <div className="checkout-order-table-paymentType">
                      <Field type="radio" name="paymentMethod"  value="PayPal"/>
                      <span style={{marginLeft: 5}}>{checkout.paypalRadio}</span>                 
                    </div>
                    <div
                      hidden={formik.values.paymentMethod !== "PayPal" || formik.isValid} 
                      className="paypal-error"
                    >
                      {checkout.paypalError}
                    </div>  
                  </>
                 } 
                <div className="checkout-order-table-acceptTerms">
                  <span>
                  <Field type="checkbox" name="acceptedTerms" />
                  <span style={{ marginLeft: 5}}>
                  {checkout.iHaveRead} <a href="#" onClick={goToTerms}>{common.termsOfService}</a> 
                    &nbsp;{checkout.ofThisSite}<span style={{color: 'red'}}>*</span></span></span>
                </div> 
              

              </div>  
              <div className="checkout-order-table-submit">            
                {formik.values.paymentMethod === 'Cash' 
                  || formik.values.paymentMethod === 'ClickAway' 
                  || formik.values.paymentMethod === 'BankTransfer' ? 
                <GreenSubmitButton 
                  type="submit"
                  aria-label='Submit' 
                  width="70%"
                  disabled={formik.isSubmitting || !formik.values.acceptedTerms || (!formik.isValid && !feathersStore.tableId) }
                  title={checkout.sendOrder}
                  color={Colors.secondary}
                ></GreenSubmitButton>
                :
                null} 
              </div>            
            </div>
          </div>
        </div>    
      
        {formik.values.paymentMethod === 'PayPal' &&
        <div className= {`checkout-paypal ${!formik.isValid && "disable-paypal-buttons"}`}>  
          <div className="checkout-paypal-buttons">                            
            <PayPalButton
              aria-labelledby='Paypal'
              onClick={onClick}                 
              onApprove={approved}
              createOrder={createOrder}
              options={{                 
                clientId: feathersStore.settings?.PRODUCTION_CLIENT_ID
              }}
            />
          </div>            
        </div>}
          
      </Form>  
      )}
      
      </Formik> 
     
      </>
    <div className="checkout-order-table-footer"/>      
  </>
  )
}

export default inject('feathersStore')(observer(Checkout));