import React from 'react';
import Main from './main';
import './App.css';
import feathersStore from './feathersStore';
import { Provider } from 'mobx-react';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'

function App() {
  return (
    <GoogleOAuthProvider clientId="597039682294-a9dbtm4j2u43o5io54kb3rnleg57vk87.apps.googleusercontent.com">
    <Provider feathersStore={feathersStore}>
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="el">
      <Main/>
     </LocalizationProvider> 
    </Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
