import React, {useState, useEffect, useRef} from 'react';
import {
    FormControl,
    FormHelperText,
    Input, 
    CircularProgress, 
    Modal,
    InputAdornment,
    IconButton 
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CallIcon from '@mui/icons-material/Call';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import { inject, observer } from "mobx-react";
import Utils from '../Utils';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { DEFAULT_EMAIL, DEFAULT_PSW} from "../assets/globalConstants";
import Box from '@mui/material/Box';
import Colors from "../assets/colors";

// Translations
import _useTranslate from '../hooks/_useTranslate';

let emailElement;
let passwordElement;
let firstNameElement;
let lastNameElement;
let phoneElement;

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 500,  
  },
  height: 750,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};
const inputControl = {
  fontSize: 20,
}
const inputIcon = {
  fontSize: 35
}

const RegistrationCard = ({visible, onClick, close, feathersStore})=> {

  let common = _useTranslate(feathersStore.language, "common");

  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState(""); 
  const [lastName, setLastName] = useState(""); 
  const [phone, setPhone] = useState("");  
  const [showPassword, setShowPassword] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false);  
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [secondTime, setSecondTime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  
  const isMountedRef = useRef(null);
    
  useEffect(() => {
    isMountedRef.current = true;    
  }, [visible]);  

  const firstNameChange = event => {    
    setFirstName(event.target.value);
    secondTime && firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    secondTime && lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    secondTime && phoneValidation(event.target.value);   
  };

  const emailChange = event => {    
    setEmail(event.target.value);
    secondTime && emailValidation(event.target.value);   
  };

  const passwordChange = event => {    
    setPassword(event.target.value);
    passwordValidation(event.target.value);   
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  }

  const emailValidation = val => {
     if (!Utils.validateEmail(val) ) {
      setEmailError(true);
    }else{
      setEmailError(false);
    }
  } 

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

  const passwordFocus = () => { 
    setSecondTime(true);   
  };

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        emailElement.focus();
    }
  }

  const enterEmailPressed = (ev) => {   
    if (ev.key === 'Enter') {
        phoneElement.focus();
    }
  }

  const enterPhonePressed = (ev) => {   
    if (ev.key === 'Enter') {
        passwordElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      createAccount();
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const createAccount = async() => {
    try{
      const userArray = await feathersStore.getUserByEmail(email);
      if(!userArray?.data?.length > 0){
        feathersStore.sendApplication( firstName, lastName, email, password, phone  )     
          .then(async(application) =>{
            await feathersStore.logout();   
            onClick({
                    applicationId: application._id,
                    email,
                    firstName,
                    lastName,
                    phone,
                    password
            }); 
        });
      }else{
        console.log("Duplicate")
        setWarning(true)
      }
    }catch(error){
      alert("Error: " + error.message)
    }    
  };

  const responseFacebook = async(response) => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.facebookAuth(response.accessToken?.toString())
      .then(async auth => {
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {
        await loginAsDefault();
        if  (error.data.code === 11000) {
          const userArray = await feathersStore.getUserByEmail(error.data.keyValue.email);
          if(!userArray?.data?.length > 0){
            let errorMessage = "";
            if(userArray[0]?.appleId?.length > 0)errorMessage = common.appleId;
            else if(userArray[0]?.googleId?.length > 0)errorMessage = common.googleId;
            else errorMessage = common.emailPassword;
            window.alert(`${common.loginError} ${errorMessage}`);
          }else{
            window.alert(`${common.loginError} ${error.message}`);
            handleExit(); 
          }              
        }else{        
          window.alert(`${common.loginError} ${error.message}`);  
          handleExit();
       } 
      });  
  }

  const responseFacebookFailed = (error) => {
    console.log(error);
  }

  const onLogoutSuccess = res => {
    console.log("Logged out from Google")
  }

  const onFailure = () => {
    console.log("Failed to logout from Google")
  }

  const responseGoogle = async(response) => {   
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.googleAuth(response.access_token?.toString())
      .then(async auth => {
        await feathersStore.setObservables();  
        googleLogout(); //--> SignOut from Google because the server handles auth and not Google with its cookies
        handleExit();               
      })
      .catch(async(error) => {
        googleLogout();
        await loginAsDefault();            
        if  (error.data.code === 11000) {
          const userArray = await feathersStore.getUserByEmail(error.data.keyValue.email);
          if(!userArray?.data?.length > 0){
            let errorMessage = "";
            if(userArray[0]?.appleId?.length > 0)errorMessage = common.appleId;
            else if(userArray[0]?.facebookId?.length > 0)errorMessage = common.facebookId;
            else errorMessage = common.emailPassword;
            window.alert(`${common.loginError} ${errorMessage}`);
          }else{
            window.alert(`${common.loginError} ${error.message}`);
            handleExit(); 
          }              
        }else{
          window.alert(`${common.loginError} ${error.message}`);
          handleExit(); 
        }                    
      }); 
  }

  const responseGoogleFailed = (response) => {
    console.log(response);
  }

  const customGoogleLogin = useGoogleLogin({
    onSuccess: responseGoogle,  
    onError: responseGoogleFailed,    
    scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile" 
   // flow: 'auth-code',
   //flow:'implicit'
  //  ux_mode: 'popup',
 //   redirect_uri: 'https://bookali.hellochat.gr/oauth/google/callback'
    //ux_mode: "redirect"
   
  })

      
  const loginAsDefault = async()=> {
    isMountedRef.current && setLoading(true);
    try{
        feathersStore?.isAuthenticated && await feathersStore.logout();      
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
           // history.push('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  }  

  const handleExit = () => {
    setSecondTime(false);
    setEmailError(false);
    setPasswordError(false);
    setEmail('');
    setPassword('');
    close();
  }  

  return (
    <section>
      <Modal 
        open={visible} 
        onClose={close}
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
      <Box sx={style}>
        <div className="modalContainer">
          <div className="modalTitleContainer">
            <div>{common.register}</div>
          </div>
          <CloseIcon className="close-icon"  onClick={handleExit}/>
          <div className="modalFormContainer">
            <FormControl fullWidth={true}> 
              <Input
                sx={inputControl}
                autoFocus
                value={firstName}     
                error={firstNameError}
                inputRef={r => {
                  firstNameElement = r;
                }}
                aria-label='firstname'
                onChange={firstNameChange}              
                onKeyPress={enterFirstNamePressed}                
                placeholder={common.firstName + "*"}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonOutlineIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {firstNameError && <FormHelperText id="component-error-text1" >{common.firstNameError}</FormHelperText>}
            </FormControl>
            <div className="mt-2"></div>
            <FormControl fullWidth={true}>
              <Input
                sx={inputControl}
                value={lastName}     
                error={lastNameError}
                inputRef={r => {
                  lastNameElement = r;
                }}
                aria-label='lastname'
                onChange={lastNameChange}              
                onKeyPress={enterLastNamePressed}  
                placeholder={common.lastName + "*"}
                startAdornment={
                  <InputAdornment position="start">
                    <PersonOutlineIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {lastNameError && <FormHelperText id="component-error-text2" >{common.lastNameError}</FormHelperText>}
            </FormControl>
            <div className="mt-2"></div>
            <FormControl fullWidth={true}> 
              <Input
                sx={inputControl}
                value={email}     
                error={emailError}
                inputRef={r => {
                   emailElement = r;
                }}
                aria-label='email'
                onChange={emailChange}              
                onKeyPress={enterEmailPressed}   
                placeholder={common.email + "*"}
                startAdornment={
                  <InputAdornment position="start">
                    <MailOutlineIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {emailError && <FormHelperText id="component-error-text3" >{common.emailError}</FormHelperText>}
            </FormControl>
            <div className="mt-2"></div>
            <FormControl fullWidth={true}>    
              <Input
                sx={inputControl}
                value={phone}     
                error={phoneError}
                inputRef={r => {
                  phoneElement = r;
                }}
                aria-label='phone'
                onChange={phoneChange}              
                onKeyPress={enterPhonePressed}   
                placeholder={common.phone + "*"}
                startAdornment={
                  <InputAdornment position="start">
                    <CallIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {phoneError && <FormHelperText id="component-error-text4" >{common.phoneError}</FormHelperText>}
            </FormControl>
            <div className="mt-2"></div>
            <FormControl fullWidth={true}>
              <Input
                sx={inputControl}
                value={password}
                error={passwordError}              
                inputRef={r => {
                  passwordElement = r;
                }}
                aria-label='password'
                type={showPassword ? 'text' : 'password'}
                onChange={passwordChange}
                onFocus={passwordFocus}          
                onKeyPress={submitEditing}              
                placeholder="Password*" 
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LockOpenIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {passwordError && 
                <FormHelperText id="component-error-text5" sx={{color: Colors.red}}>{common.passwordHelperText}</FormHelperText>}
            </FormControl>
            <div className="mt-1"></div>
            <button
              onClick={createAccount} 
              disabled = {!firstName || !email || !password || !lastName || !phone || firstNameError || lastNameError || phoneError || emailError || passwordError}         
              aria-labelledby='Register'
              className="my-facebook-button-class my-email-button-class"
            >
              {common.registerA}
              {loading && 
              <CircularProgress 
                color="common" 
                style={{ marginLeft: 6 }} 
                size={'1.5rem'}            
              />                  
            }
            </button>  
            {warning && 
              <div>
                <FormHelperText id="component-error-text6"  sx={{color: Colors.red}}>{common.duplicateEmail}</FormHelperText>
              </div>
            }         
          </div>
         
          <div className="login-or">
            <div className="line-or"></div>
            <div>{common.loginOr}</div>
            <div className="line-or"></div>
          </div>
          <div className="modalFacebookContainer">
          <FacebookLogin
              appId={feathersStore.settings?.FACEBOOK_APP_ID}              
              fields="name,email,picture"          
              onSuccess={responseFacebook}     
              onFail={responseFacebookFailed}
              style={{
                background:"transparent",
                border:"none",
                padding: "0px",
                margin: "0px"
              }}
              children={<div className="my-facebook-button-class">
                <FacebookOutlinedIcon/><span className="google-text">FACEBOOK</span>
              </div>}      
              aria-labelledby='Facebook login'
            /> 
            {feathersStore.settings?.GOOGLE_CLIENT_ID &&
              <button
                className="my-facebook-button-class my-google-button-class"
                onClick={() => customGoogleLogin()}               
                aria-labelledby='google login'             
              >
                <GoogleIcon/><span>GOOGLE</span>
              </button>  
            } 
                           
          </div>
          <div className="mt-1"></div>            
          <div className="terms-login-text">{common.registerInfoA} <Link to="/termsnconditions" onClick={handleExit}>{common.registerInfoB}</Link>, {common.registerInfoC} <Link to="/privacypolicy" onClick={handleExit}>{common.registerInfoD}</Link> {common.registerInfoE}.</div>
        </div> 
        </Box>        
      </Modal>       
    </section>
  );
}

export default inject('feathersStore')(observer(RegistrationCard));
