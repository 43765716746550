export const footerDE = {
    contactHours : "Kontaktzeiten",
    week : "Montag – Freitag",
    contactUs : "Kontaktieren Sie uns",
    address : "131 Mesogeion str. Marousi 15126",
    findUs : "Finden Sie uns auf der Karte",
    contact: "Kontaktieren Sie uns",
    privacyPolicy: "Datenschutzerklärung",
    termsOfService: "Nutzungsbedingungen",
    stayConnected: "In Verbindung bleiben",
    cookiesTitle: "Verwendung von Cookies",
    aboutUs : "Über uns",
    cookiesText: "Wir verwenden nur die minimal erforderlichen Cookies. Ihre persönlichen Daten werden in keinem von ihnen gespeichert.",
    cookiesAccept: "Akzeptieren",  
    accessibilityTitle:  "Barrierefreiheit",
    fontIncrease: "Schriftgröße erhöhen",
    fontDecrease: "Schriftgröße verringern",
    greyscale: "Graustufen",
    highContrast: "Hoher Kontrast",
    negativeContrast: "Negativer Kontrast",
    lightBackground: "Heller Hintergrund",
    linksUnderline: "Links unterstreichen",
    readableFont: "Lesbare Schriftart",
    reset: "Zurücksetzen",
    paymentOptions: "Zahlungsoptionen",
    deliveryOptions: "Lieferoptionen",
    openingTime: "Öffnungszeit",    
    loginRegister: "Anmelden/Registrieren",
    messagingTitle: "Support",
    messagingSubTitle: "Senden Sie eine Nachricht mit Ihren Fragen!",
    message: "Nachricht",
    installBringFood: "Beginnen Sie mit BrigFood!",
    usefull: "Nützlich",
    company: "Unternehmen",
    faq: "FAQ's",
    chatPlaceholder: "Text eingeben…",
    restaurantClosed: "Die Räumlichkeiten sind derzeit geschlossen und wir können Ihre Bestellung nicht vorbereiten",
    holidayText: "Das Gelände bleibt heute geschlossen",
    allDay: "Den ganzen Tag",
    to: "Bis",


}