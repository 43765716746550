import React from 'react';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";

const FaqTextItalian = ({ feathersStore })=> {
  return(
    <div className="terms-text">
    <h2>Quali metodi di pagamento accettate?</h2>
    <p>
    È possibile visualizzare i metodi di pagamento accettati <Link className="privacy-text" to="/paymentoptions">qui</Link>.
    </p>
    <h2>
    Con quali metodi di spedizione posso ricevere il mio ordine?
    </h2>
    <p>
    È possibile visualizzare i metodi di spedizione <Link className="privacy-text" to="/deliveryoptions">qui</Link>.
    </p>
    <h2>
    Posso apportare modifiche al mio ordine?
    </h2>
    <p>
    È possibile apportare modifiche prima che il vostro ordine venga spedito. Si prega di chiamare il numero {feathersStore.settings?.phonenumbers} o contattarci tramite il modulo sul sito web <Link className="privacy-text" to="/contact">qui</Link> o tramite e-mail all'indirizzo {feathersStore.settings?.email}, specificando i dettagli e le modifiche desiderate.
    </p>
    <h2>
    Esiste un negozio che posso visitare?
    </h2>
    <p>
    È possibile visitarci presso l'indirizzo {feathersStore.settings?.address}.
    </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(FaqTextItalian));