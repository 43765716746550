export const accountEN = {
    myAccount : "myAccount",
    profile : "PROFILE",
    addresses : "ADDRESSES",
    history : "HISTORY",
    comments : "REVIEWS",
    settings : "SETTINGS",
    psw : "Change Password",
    updateAlert : "Your data were successfully updated",
    updateError : "Error while updating",
    addAddress : "Add an address",
    addNewAddress : "Add a new address",
    myLocation : "My location",    
    modalHeaderUpdate : "Change Address",
    otherLocation : "Other Location",
    home : "Home",
    work : "Work",
    helperText : "Please enter your address details",
    addressComments : "Additional address comments",
    historyPlaceholder : "You haven't made any purchases yet",
    commentPlaceholder : "You haven't made any comment yet",
    emptyHistory: "You haven't placed any order yet.",
    uploadAvatar: "Click on the image to choose a different image.",
    submit: "SUBMIT",
    uploadSuccess: "File Upload success",    
    uploadError: "File Upload error",
    bigFile: "The image is too big. It should be up to 1MB. Please select a smaller image",
    favorites: "myFavorites",
    hello: "Hello",
    profileHeader: "Profile",
    settingsSmall: "Settings"
}
