import React from 'react';
import { inject, observer } from "mobx-react";

const DeliveryOptionsTextItalian = ({feathersStore})=> {
  return(
    <div className="terms-text">
       <h2>Consegna e spedizione</h2>
       <p>
        Nel nostro negozio facciamo ogni sforzo per servire i nostri clienti. È possibile ordinare a distanza, sia per telefono che tramite il nostro sito web. Di seguito sono riportati i due modi più frequenti per inviare e ricevere i nostri prodotti.
        </p>
        <h2>Ritiro presso il nostro negozio</h2>
        <p>
          È possibile ritirare i tuoi acquisti presso il nostro negozio in via {feathersStore.settings?.address}.
          </p>
          <h2>Consegna presso la tua posizione</h2>
          <p>
            Ci impegniamo a portare il tuo ordine al luogo da te indicato.
            </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(DeliveryOptionsTextItalian));