import React, {useEffect} from 'react';
import { Routes, Route } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { DEFAULT_EMAIL, DEFAULT_PSW } from "./assets/globalConstants";
import Home from './components/home/Home';
import HomeBody from './components/HomeBody';
import Settings from './components/account/settings';
import TermsNConditions from './components/terms/termsnconditions';
import About from './components/about';
import Favorites from './components/favorites';
import Contact from './components/contact/Contact';
import PrivacyPolicy from './components/terms/privacypolicy';
import DeliveryOptions from './components/terms/deliveryOptions';
import PaymentOptions from './components/terms/paymentOptions';
import Cart from './components/cart/Cart';
import Reservation from './components/reservation/Reservation';

import Product from './components/product';
import Checkout from './components/checkout/Checkout'
import Account from './components/account/Account';
import CategoryView from './components/categoryView';
import Faq from './components/terms/faq';
import ScrollToTop from './components/ScrollToTop';
import { DOCUMENT_TITLE, META_DESCRIPTION, META_ROBOTS, THEME } from "./assets/globalConstants";


const Main = ({feathersStore})=> {

  useEffect(() => {    
    if(DOCUMENT_TITLE)document.title = DOCUMENT_TITLE; 
    if(META_DESCRIPTION)setMeta("description", META_DESCRIPTION);
    if(META_ROBOTS)setMeta("robots", META_ROBOTS); 
    if(THEME){
      document.documentElement.className =  THEME;
    }else{
      document.documentElement.className =  "blueberry";
    }  
    connectToServer();
    createPaymentMethodLS();
    getLanguage();   
  }, []);

  function setMeta(metaName, val) {
    const metas = document.getElementsByTagName('meta');
  
    for (let i = 0; i < metas.length; i++) {
      if (metas[i].getAttribute('name') === metaName) {
        return metas[i].setAttribute('content', val);
      }
    }
  
    return '';
  }

  const connectToServer = async() => {
    await feathersStore.connect();    
    if(!feathersStore.isAuthenticated)feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
    .then(async(auth) => {         
          await feathersStore.setObservables();                          
    })
    .catch(error => window.alert(`Login error: ${error.message}`));   
  }

  const createPaymentMethodLS = () => {
    !localStorage.getItem('Payment_Method') &&      
      localStorage.setItem('Payment_Method',JSON.stringify({
          name: 'Μετρητά κατά την παράδοση',
          index: 0
      }))
  }

  const getLanguage = () => {
    if(!localStorage.getItem('Language')){ 
      const userLanguage = window.navigator.userLanguage || window.navigator.language;    
      console.log(userLanguage); 
      localStorage.setItem('Language', userLanguage.slice(0, 2));
    }
    feathersStore.setLanguage(localStorage.getItem('Language'))
  }

  return (
    <div className="body-container">
      <ScrollToTop>
      <Routes> 
        <Route path="/"  element={<Home/>}>  
          <Route path="cart" element={<Cart/>}/>
          <Route path="about" element={<About/>}/>         
          <Route path="product/:name" element={<Product/>}/>      
          <Route path="checkout"element={<Checkout/>}/>
          <Route path="favorites"element={<Favorites/>}/>
          <Route path="contact"element={<Contact/>}/>
          <Route path="settings" component={<Settings/>} />        
          <Route path="termsnconditions"element={<TermsNConditions/>}/>
          <Route path="privacypolicy"element={<PrivacyPolicy/>}/> 
          <Route path="paymentoptions"element={<PaymentOptions/>}/>
          <Route path="deliveryoptions"element={<DeliveryOptions/>}/> 
          <Route path="faq" element={<Faq/>}/>     
          <Route path="reservation" element={<Reservation/>}/> 
          <Route path="account"  element={<Account/>} />
          <Route path="Home/:name" element={<CategoryView/>} />
          <Route path="Home" element={<HomeBody/>} />   
          <Route index element={<HomeBody/>} />         
        </Route>
      </Routes>
      </ScrollToTop>
    </div>
    );
  
}

export default inject('feathersStore')(observer(Main));