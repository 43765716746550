import React, { useState, useEffect } from 'react';
import CartComponent from '../cart/cartComponent';
import './TopBar.css';
import LoginComponent from './loginComponent';
import SettingsMenu from './settingsMenu';
import SearchBar from "./searchBar";
import LanguageButton from '../../buttons/languageButton';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";
//import topBarLogo from '../../img/bringfood-inline-logo.png';
import SearchIcon from '@mui/icons-material/Search';
import Hamburger from './Hamburger';
import Collapse from '@mui/material/Collapse';
import PaymentIcon from '@mui/icons-material/Payment';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AccountButton from './navElements/accountButton';
import SignInButton from './navElements/signInButton';
import WishListButton from './navElements/wishListButton';
import {API_URL, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../../assets/globalConstants";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const TopBar = ({feathersStore, onClickSignIn})=> {

  let common = _useTranslate(feathersStore.language, "common");  

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showResponsiveArea, setShowResponsiveArea] = useState(false);
  const [showLoginBtn, setShowLoginBtn] = useState(true);

  useEffect(() =>{
    TABLE_QR_ORDERING_ONLY && setShowLoginBtn(!TABLE_QR_ORDERING_ONLY)
  }, [TABLE_QR_ORDERING_ONLY])

  const toggleSearchBar = () => {
    setShowSearchBar(oldVal => !oldVal);
  }

  const toggleResponsiveArea = () => {
    setShowResponsiveArea(oldValue => !oldValue);
  }

  return (
    <>
    <div className="top-bar-nav">      
      <div className="logoMenu">
        <Link to="/" 
          //onClick={() => resetProducts()} 
          className="logoView" >
          <img
            className={"logo-img" + (feathersStore.settings?.useSquareWebLogo ? " logo-img-square" : "")}
            aria-label='appdate logo'
            alt="BringFood Logo"
            src={ feathersStore.settings?.useSquareWebLogo ?
              feathersStore.settings?.webLogoImageSquare ? (`${API_URL}/images/` + feathersStore.settings?.webLogoImageSquare) : require("../../img/200_spinner.gif")
              :
              feathersStore.settings?.webLogoImage ? (`${API_URL}/images/` + feathersStore.settings?.webLogoImage) : require("../../img/200_spinner.gif")
            }
          />  
        </Link>
        <div className="logoMenuView">          
          {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" ? 
          <SettingsMenu/>
          :
          !TABLE_QR_ORDERING_ONLY  && <LoginComponent onClick={onClickSignIn}/>
          }          
          {showSearchBar ? <SearchBar/> : null}  
          <div 
            aria-label='Search'
            className="search-toggle"         
            onClick={toggleSearchBar}>
              <span><SearchIcon style={{ fontSize: 36 }}/></span>
          </div>               
          {!QR_READ_ONLY && <CartComponent cartQuantity={feathersStore.cartLength} /> }
          <div className="select-language">  
            <LanguageButton showCaption={false}/>
          </div>   
          <div className="nav-SignIn cart-container hamburger-show">
            <Hamburger aria-label='hamburger-menu' showResponsiveMenu={toggleResponsiveArea}/>
          </div>    
        </div>               
      </div>
    </div>
    {!showResponsiveArea && <div className="divider-top"></div>}
    <Collapse sx={{width: '100%'}} in={showResponsiveArea}>  
      <div id="responsiveDiv" className="responsive-area">
        <div className="responsive-element">
          <SearchBar  />
        </div>
          {feathersStore.isAuthenticated && feathersStore.user?.firstname === "default" && !TABLE_QR_ORDERING_ONLY && 
            <div className="responsive-element">
              <SignInButton 
                displayText={common.signIn}
                onClickSignIn={onClickSignIn} 
                aria-labelledby='Sign in'
              />
          </div> 
          } 
          {feathersStore.isAuthenticated && feathersStore.user?.firstname !== "default" && !QR_READ_ONLY &&
            <>
              <div className="responsive-element">
                <AccountButton aria-labelledby='Account'/>
                </div>
              <div className="responsive-element">
                <WishListButton  displayText={common.favorites} aria-labelledby='Wishlist'/>
              </div>
            </>
          } 
          {feathersStore.restaurantOpen && !QR_READ_ONLY && feathersStore?.orderItem?.total !== 0 && feathersStore.restaurantOpen &&
            <Link to="/checkout" className="language-styling responsive-element checkout-button">
              <span className="nav-button-SignIn undecorated-link">
                <PaymentIcon className="calendar-icon" style={{ fontSize: 30 }}/>
                <span style={{marginLeft: "1vw"}}> {common.checkout}</span>
              </span>         
            </Link> 
          }       
        
          <div className="responsive-element">
            <button className="swipe-button" onClick={toggleResponsiveArea}>
              <KeyboardArrowUpIcon className="calendar-icon" style={{ fontSize: 30 }}/>
            </button>          
          </div>
      </div>   
    </Collapse> 
    </>
  );
  
}

export default inject('feathersStore')(observer(TopBar));
