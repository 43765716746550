export const commonFR = {
    firstName: "Prénom",
    lastName: "Nom de famille",
    street: "Rue",
    streetNumber: "Numéro",
    city: "Ville",
    origin: "Région",
    postcode: "Code postal",
    phone: "Numéro de téléphone",
    apartment: "Appartement",
    email: "E-mail",
    emailMessage: "Nous ne partagerons jamais votre e-mail avec qui que ce soit",
    checkout: "PASSER LA COMMANDE",
    streetAndNumber: "Rue et Numéro",
    privacyStatement: "Déclaration de confidentialité",
    termsOfService: "Conditions d'utilisation",
    termsOfServiceNoun: "Conditions d'utilisation",
    aboutUs: "À propos de nous",
    product: "Produit",
    products: "Produits",
    subTotal: "Sous-total",
    transportation: "Livraison",
    total: "Total",
    price: "Prix",
    quantity: "Quantité",
    items: "Articles",
    firstNameError: "Veuillez entrer un prénom",
    lastNameError: "Veuillez entrer un nom de famille",
    phoneError: "Veuillez entrer un numéro de téléphone valide",
    emailError: "Veuillez entrer une adresse e-mail valide",
    save: "ENREGISTRER",
    updateSuccess: "La mise à jour a réussi",
    updateError: "Erreur lors de la mise à jour",
    updated: "Mis à jour !",
    cartUpdated: "Vous avez mis à jour avec succès votre panier d'achats !",
    wishlistUpdated: "Vous avez mis à jour avec succès votre liste de souhaits !",
    streetError: "Veuillez entrer une rue",
    streetNumberError: "Veuillez entrer un numéro de rue",
    cityError: "Veuillez entrer une ville",
    order: "Commande",
    logout: "Déconnexion",
    warning: "AVERTISSEMENT",
    logoutWarning: "Êtes-vous sûr de vouloir vous déconnecter ?",
    yes: "OUI",
    no: "NON",
    loginRegistration: "Connexion/Inscription",
    login: "CONNEXION",
    passwordHelperText: "au moins 8 caractères, comprenant des chiffres, des majuscules et des minuscules",
    forgotPassword: "Mot de passe oublié ?",
    or: "ou",
    facebookLogin: "CONNEXION AVEC FACEBOOK",
    googleLogin: "CONNEXION AVEC GOOGLE",
    register: "Inscription",
    registerButton: "NOUVEAU CLIENT",
    exit: "SORTIE",
    resetPassword: "Réinitialiser le mot de passe",
    resetPasswordInfoA: "Veuillez entrer votre adresse e-mail",
    resetPasswordInfoB: "et vous recevrez un nouveau mot de passe.",
    registerA: "INSCRIPTION",
    registerInfoA: "Avec mon inscription, j'accepte les",
    registerInfoB: "conditions générales d'utilisation",
    registerInfoC: "la",
    registerInfoD: "Déclaration de confidentialité",
    registerInfoE: "et les",
    registerInfoF: "conditions de service des offres",
    verificationCode: "Code de confirmation",
    verificationInfo: "Veuillez entrer le code à 4 chiffres qui a été envoyé à :",
    enterCode: "Entrer le code",
    errorCode: "Code de vérification incorrect, veuillez réessayer",
    repeat: "RENVOYER",
    youSee: "Vous voyez",
    from: "de",
    stockWarning: "Malheureusement, le produit que vous avez choisi est en rupture de stock !",
    leaveAComment: "Laissez un commentaire...",
    saveReview: "Enregistrer l'avis",
    reviews: "Avis",
    qrCodeBuy: "Achetez rapidement et facilement en un clic depuis votre mobile !",
    qrCodeScanAndroid: "Scannez pour télécharger notre application pour Android",
    qrCodeDownload: "Téléchargez notre application sur Android",
    qrCodeBuyMobile: "Pour Android et iOS, téléchargez notre application de restaurant et commandez à tout moment depuis votre mobile !",
    qrCodeScanIos: "Scannez pour télécharger notre application pour iOS",
    qrCodeForAndroid: "Pour Android",
    qrCodeForIos: "Pour iOS",
    photoUpload: "Téléchargement de la photo",
    edit: "MODIFIER",
    delete: "SUPPRIMER",
    addCart: "Ajouter au panier",
    noEvaluation: "Pas encore d'évaluation.",
    onlyLoggedInCustomers: "Seuls les clients connectés ayant acheté le produit peuvent laisser un avis.",
    beTheFirstReview: "Soyez le premier à rédiger un avis sur ce produit.",
    seeMoreProducts: "Voir plus de produits",
    favorite: "FAVORIS",
    favorites: "Favoris",
    noFavoritesYet: "Vous n'avez pas encore de produits favoris.",
    deleteProduct: "Êtes-vous sûr de vouloir supprimer ce produit ?",
    deleteProduct1: "Êtes-vous sûr de vouloir supprimer ce produit",
    fromCart: "du panier ?",
    deleteAddress: "Êtes-vous sûr de vouloir supprimer l'adresse ?",
    danger: "AVERTISSEMENT",
    connectionIssue: "Attention ! L'envoi de la commande n'a pas réussi. Veuillez vérifier votre connexion.",
    sendOrder: "Envoyer la commande",
    pleaseWait: "Veuillez patienter...",
    noMoreProductsInStock: "Vous ne pouvez pas ajouter plus de produits que le stock dans votre panier",
    productAddedCart: "Produit ajouté à votre panier !",
    home: "Accueil",
    myAccount: "MON COMPTE",
    ckeckout: "PASSER LA COMMANDE",
    all: "Tout",
    signIn: "Connexion",
    search: "Recherche",
    productSKU: "SKU",
    productSKUFull: "SKU du produit :",
    language: "Langue",
    size: "TAILLE",
    extra: "Supplément",
    removeIngredients: "Retirer les ingrédients",
    status: "Statut",
    searchResults: "Résultats de la recherche",
    noResults: "PAS DE RÉSULTATS",
    dailyProducts: "Produits du jour",
    offersText: "Offres quotidiennes",
    popularText: "Les plus populaires",
    productComments: "Saisissez un commentaire ou une demande spéciale (facultatif)",
    weighted: "Produit pondéré",
    kgr: "Prix par kg",
    without: "SANS",
    noSuchUser: "Aucun utilisateur avec cette adresse e-mail n'existe !",
    reset: "Réinitialiser",
    lostPassword: "Mot de passe oublié ?",
    loginOr: "ou connectez-vous avec",
    here: "ici",
    orRegister: "Vous n'avez pas de compte ? Inscrivez-vous",
    stockWarningHeader: "Avertissement de stock",
    cartUpdatedAlert: "Panier mis à jour",
    wishlistUpdatedAlert: "Liste de souhaits mise à jour",
    watchThis: "Jetez un œil à ceci !",
    pickSize: "Choisissez une taille",
    addExtra: "Ajouter des ingrédients supplémentaires",
    logoutAccount: "Déconnexion",
    deleteAccount: "Supprimer le compte",
    deleteAccountMessage: "Voulez-vous vraiment supprimer votre compte ?",
    yesSmall: "Oui",
    noSmall: "Non",
    show: "Afficher le panier",
    removeItem: "Retirer l'article",
    youSure: "Êtes-vous sûr ?",
    cancel: "Annuler",
    scannerError: "Erreur lors de la numérisation. Veuillez réessayer.",
    scannerSuccess: "Bienvenue",
    scannerMessage: "Continuez en tant qu'invité à votre table",
    companyOrigin: "Région principale",
    addNewAddress: "Ajouter une nouvelle adresse",
    holidayText: "Les locaux resteront fermés aujourd'hui",
    minimumOrderHeader: "Commande minimale",
    minimumOrder: "La commande minimale est de",
    duplicateEmail: "Cette adresse e-mail est utilisée par un autre compte. Veuillez plutôt essayer de vous connecter.",
    appleId: "Cette adresse e-mail a été utilisée pour se connecter avec Apple",
    googleId: "Cette adresse e-mail a été utilisée pour se connecter avec Google",
    facebookId: "Cette adresse e-mail a été utilisée pour la connexion à Facebook",
    emailPassword: "Cette adresse e-mail a été utilisée pour se connecter avec une adresse e-mail et un mot de passe",
    loginError: "Erreur de connexion: ",
    discount: "Rabais",
    to: "Ouvert à",
    openingTime: "Heures d'ouverture",
    more: "Plus",
    lastAddress: "Impossible de supprimer la dernière adresse",
    updateFailure: "Échec de mise à jour",
    succesDeleteAddress: "L'adresse a été supprimée avec succès.",
    noCategoryItemsYet: "Il n’y a aucun produit actif dans cette catégorie.",
    reserveTable: "Réserver une table",
    reserveTableUC: "Réserver une table",
    yourReservation: "Votre réservation",
    chooseHour: "Choisissez l’heure",
    bookingComment: "Ajouter une demande spéciale (facultatif)",
    party: "Personnes",
    pickDate: "Choisissez une date",
    partyOf: "Personnes ",
    date: "Fecha",
    hour: "Tiempo",
    customerDiscount: "Descuento al cliente",
    couponDiscount: "Cupón de descuento",
    voucherTitle: "Has ganado un cupón de valor ",
    applyVoucher: "Aplica este cupón en tu carrito de compras.",
    copy: "Copiar",
    minimumVoucherOrder: "Commande minimum",
    minimumVoucherCaption: "Le minimum de commande requis pour appliquer votre réduction est de",
    loyaltyTitle: "Récompenses de fidélité",
    loyaltyCaption: "Vous avez gagné des récompenses de fidélité d'une valeur totale",
    loyaltySubCaption: "Ils seront automatiquement réduits lors de votre paiement.",
    loyaltyMoto:  "Continuez à commander pour gagner des réductions",
    endsIn: "Fini dans",
    days: "jours",
    table: "TABLEAU",
    comments: "COMMENTAIRES",
    dateCut: "Date",
    newOrder: "NOUVELLE COMMANDE",
    num: "S/N",
    extras: "SUPPLÉMENTS",
    tableOrder : "TABLEAU",
    phoneDelivery : "TÉLÉPHONE DELIVERY",
    phoneTakeAway : "TÉLÉPHONE TAKE-AWAY",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR TABLEAU",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR TABLEAU",
}