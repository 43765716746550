import React, {useState, useEffect} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../hooks/_useTranslate';

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 400,  
  },
  height: 350,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 2,
};

const OrderCompleteModal = ({visible, onClickFinish, feathersStore})=> {

  let checkout = _useTranslate(feathersStore.language, "checkout");

  return (
    <section>
      <Modal 
        open={visible}        
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
        <Box sx={style}>
          <div className="body-container">            
              <h2>{checkout.orderCompleteTitle}</h2>
              <p style={{color: AppColors.secondary, marginBottom: 30}}>{checkout.orderCompleteLine1}<br/>
              {checkout.orderCompleteLine2}</p>
              <GreenSubmitButton
                title={checkout.returnToShop}
                onClick={onClickFinish}
                aria-labelledby='Back to shop'
              />            
          </div>
        </Box>
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(OrderCompleteModal));