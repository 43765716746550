import React from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
// Translations
import _useTranslate from '../../hooks/_useTranslate';

const FooterUsefullMenu = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");
  


return (
  <div className="section section-first section-25">
    <div className="footer-section-header">{footer.usefull}</div>
    <div className="line"></div>    
    <Link to="/paymentoptions" className="footerMenuText2">{footer.paymentOptions}</Link>
    <Link to="/deliveryoptions" className="footerMenuText2">{footer.deliveryOptions}</Link>
    <Link to="/faq" className="footerMenuText2">{footer.faq}</Link>
    <Link className="footerMenuText2" to="/contact">{footer.contactUs}</Link>  
  </div>
  );
}

export default inject('feathersStore')(observer(FooterUsefullMenu));
