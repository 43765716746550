import { useState, useEffect } from 'react';
import {commonEL} from "../locales/el/common";
import {commonEN} from "../locales/en/common";
import {commonDE} from "../locales/de/common";
import {commonES} from "../locales/es/common";
import {commonFR} from "../locales/fr/common";
import {commonIT} from "../locales/it/common";
import {commonAR} from "../locales/ar/common";
import {commonAL} from "../locales/sq/common";

import {cartEL} from "../locales/el/cart";
import {cartEN} from "../locales/en/cart";
import {cartDE} from "../locales/de/cart";
import {cartES} from "../locales/es/cart";
import {cartFR} from "../locales/fr/cart";
import {cartIT} from "../locales/it/cart";
import {cartAR} from "../locales/ar/cart";
import {cartAL} from "../locales/sq/cart";

import {accountEL} from "../locales/el/account";
import {accountEN} from "../locales/en/account";
import {accountDE} from "../locales/de/account";
import {accountES} from "../locales/es/account";
import {accountFR} from "../locales/fr/account";
import {accountIT} from "../locales/it/account";
import {accountAR} from "../locales/ar/account";
import {accountAL} from "../locales/sq/account";

import {checkoutEL} from "../locales/el/checkout";
import {checkoutEN} from "../locales/en/checkout";
import {checkoutDE} from "../locales/de/checkout";
import {checkoutES} from "../locales/es/checkout";
import {checkoutFR} from "../locales/fr/checkout";
import {checkoutIT} from "../locales/it/checkout";
import {checkoutAR} from "../locales/ar/checkout";
import {checkoutAL} from "../locales/sq/checkout";

import {contactEL} from "../locales/el/contact";
import {contactEN} from "../locales/en/contact";
import {contactDE} from "../locales/de/contact";
import {contactES} from "../locales/es/contact";
import {contactFR} from "../locales/fr/contact";
import {contactIT} from "../locales/it/contact";
import {contactAR} from "../locales/ar/contact";
import {contactAL} from "../locales/sq/contact";

import {footerEL} from "../locales/el/footer";
import {footerEN} from "../locales/en/footer";
import {footerDE} from "../locales/de/footer";
import {footerES} from "../locales/es/footer";
import {footerFR} from "../locales/fr/footer";
import {footerIT} from "../locales/it/footer";
import {footerAR} from "../locales/ar/footer";
import {footerAL} from "../locales/sq/footer";

import {offersEL} from "../locales/el/offers";
import {offersEN} from "../locales/en/offers";
import {offersDE} from "../locales/de/offers";
import {offersES} from "../locales/es/offers";
import {offersFR} from "../locales/fr/offers";
import {offersIT} from "../locales/it/offers";
import {offersAR} from "../locales/ar/offers";
import {offersAL} from "../locales/sq/offers";

const _useTranslate = (language, partition)=> {
   
  const [source, setSource] = useState({})

  const pickSourceEL = () => {
    switch (partition) {
      case "account" :
        return accountEL;  
      case "cart" :
        return cartEL;
      case "checkout" :
        return checkoutEL;
      case "common" :
        return commonEL;
      case "contact" :
        return contactEL;
      case "footer" :
        return footerEL;
      case "offers" :
        return offersEL;
    }
  }

  const pickSourceEN = () => {
    switch (partition) {
      case "account" :
        return accountEN;  
      case "cart" :
        return cartEN;
      case "checkout" :
        return checkoutEN;
      case "common" :
        return commonEN;
      case "contact" :
        return contactEN;
      case "footer" :
        return footerEN;
      case "offers" :
        return offersEN;
    }
  }

  const pickSourceDE = () => {
    switch (partition) {
      case "account" :
        return accountDE;  
      case "cart" :
        return cartDE;
      case "checkout" :
        return checkoutDE;
      case "common" :
        return commonDE;
      case "contact" :
        return contactDE;
      case "footer" :
        return footerDE;
      case "offers" :
        return offersDE;
    }
  }

  const pickSourceES = () => {
    switch (partition) {
      case "account" :
        return accountES;  
      case "cart" :
        return cartES;
      case "checkout" :
        return checkoutES;
      case "common" :
        return commonES;
      case "contact" :
        return contactES;
      case "footer" :
        return footerES;
      case "offers" :
        return offersES;
    }
  }

  const pickSourceFR = () => {
    switch (partition) {
      case "account" :
        return accountFR;  
      case "cart" :
        return cartFR;
      case "checkout" :
        return checkoutFR;
      case "common" :
        return commonFR;
      case "contact" :
        return contactFR;
      case "footer" :
        return footerFR;
      case "offers" :
        return offersFR;
    }
  }

  const pickSourceIT = () => {
    switch (partition) {
      case "account" :
        return accountIT;  
      case "cart" :
        return cartIT;
      case "checkout" :
        return checkoutIT;
      case "common" :
        return commonIT;
      case "contact" :
        return contactIT;
      case "footer" :
        return footerIT;
      case "offers" :
        return offersIT;
    }
  }

  const pickSourceAR = () => {
    switch (partition) {
      case "account" :
        return accountAR;  
      case "cart" :
        return cartAR;
      case "checkout" :
        return checkoutAR;
      case "common" :
        return commonAR;
      case "contact" :
        return contactAR;
      case "footer" :
        return footerAR;
      case "offers" :
        return offersAR;
    }
  }

  const pickSourceAL = () => {
    switch (partition) {
      case "account" :
        return accountAL;  
      case "cart" :
        return cartAL;
      case "checkout" :
        return checkoutAL;
      case "common" :
        return commonAL;
      case "contact" :
        return contactAL;
      case "footer" :
        return footerAL;
      case "offers" :
        return offersAL;
    }
  }

  useEffect(()=> { 
    switch (language){
      case "el" : {
        setSource(pickSourceEL());
        break;
      }
      case "en" : {
        setSource(pickSourceEN());
        break;
      }
      case "de" : {
        setSource(pickSourceDE());
        break;
      }
      case "es" : {
        setSource(pickSourceES());
        break;
      }
      case "fr" : {
        setSource(pickSourceFR());
        break;
      }
      case "it" : {
        setSource(pickSourceIT());
        break;
      }
      case "ar" : {
        setSource(pickSourceAR());
        break;
      }
      case "sq" : {
        setSource(pickSourceAL());
        break;
      }
    }
  },[language]);

  return source

}

export default _useTranslate;