export const cartAR = {
    "cart": "العربة",
    "couponCode": "رمز القسيمة",
    "couponButton": "استخدم القسيمة",
    "cartTotals": "المجموعات",
    "completeOrder": "اتمام الطلب",
    "continueShopping": "متابعة الطلب",
    "without": "بدون",
    "select": "اختر",
    "selectSmall": "اختيار",
    "addToCart": "تمت الإضافة إلى العربة",
    "cartItems": "عناصر العربة",
    "couponDiscount": "خصم القسيمة",
    "subTotal": "المجموع الفرعي",
    "couponCaption": "هل لديك قسيمة؟",
    "new": "جديد",
    "sale": "تخفيض",
    "soldOut": "نفذت الكمية",
    "couverCaption": "عدد الأشخاص",
    "couverPlaceholder": "أدخل العدد",
    "couverError": "يرجى إدخال عدد الأشخاص والضغط على التحقق"
}
