export const OrderKind = Object.freeze({
    tableOrder : "tableOrder",
    phoneDelivery : "phoneDelivery",
    phoneTakeAway : "phoneTakeAway",
    webDelivery : "webDelivery",
    webTakeAway : "webTakeAway",
    webTable : "webTable",
    appDelivery : "appDelivery",
    appTakeAway : "appTakeAway",
    appTable : "appTable",
  })