import React from 'react'
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import { withStyles } from '@mui/material/styles';
import { API_URL } from "../assets/globalConstants";
import { styled } from '@mui/material/styles';

const GoldRating = styled(Rating)(({ theme }) => ({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
}));

const RatedCard = ({avatar, username, date, stars, comment})=> {

  return(
    <div className="rated-card">
      <div className="rated-card-title">
        <Avatar src={avatar && `${API_URL}/images/${avatar}`}/>
        <p style={{marginLeft: 10, marginTop: 5}}>{username}</p>
        <p style={{marginLeft: 5, marginTop: 5, color: 'grey'}}>- {date}</p>
        <GoldRating style={{marginLeft: 20, marginTop: 5}} name="read-only" value={stars} readOnly />
      </div>
      {comment && 
        <div className="rated-card-commentText"> 
         <textarea disabled value={comment}/>      
        </div>
      }
    </div>
  )
}

export default RatedCard;