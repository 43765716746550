import React from 'react';
import { inject, observer } from "mobx-react";
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

// Translations
import _useTranslate from '../hooks/_useTranslate';

const RestaurantClosed = ({feathersStore})=> {

  let footer = _useTranslate(feathersStore.language, "footer"); 
  
  const close = () => {
   
    feathersStore.setShowRestaurantMessage(false)
  }

  return (
    <div className="restaurant-closed-body-container">
      <div className="restaurant-closed-header ">        
        <ErrorOutlineOutlinedIcon />
        <div className="restaurant-closed-text">
          {feathersStore.settings?.closedShopText ? 
            feathersStore._translateClosedShopText()
          :
          footer.restaurantClosed}</div> 
        <div  className="cancel-restaurant-message-icon" onClick={close}><CancelOutlinedIcon/></div>     
      </div>
    </div>
  );
  
}

export default inject('feathersStore')(observer(RestaurantClosed));
