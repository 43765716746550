import React from 'react';
import '../App.css';

const MenuComponent = ({ image, name })=> {

  return (
    <div className="menuComponent" >
      <div className="menu-component-image" style={{backgroundImage: `url(${image})`}}></div>
      <div className="menuComponentText">{name}</div>
    </div>
    );  
}

export default MenuComponent;