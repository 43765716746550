import React from 'react';
import { inject, observer } from "mobx-react";

const PrivacyTextSpanish = ({ feathersStore })=> {
    return(
    <div className="terms-text">
    <p>Para la empresa/tienda {feathersStore.settings?.companyName}, la protección de los datos personales es de suma importancia y se trata con la máxima seriedad. El respeto de los datos personales que manejamos y garantizar su correcto procesamiento son una de las prioridades de la Compañía.</p>
    <p>Por esta razón, tomamos todas las medidas técnicas y organizativas adecuadas para proteger los datos personales que procesamos y asegurarnos de que su procesamiento siempre se realice de acuerdo con los requisitos establecidos por el marco legal vigente y en particular por el Reglamento General de Protección de Datos (UE) 2016/679.</p>
    <a><strong>1. Persona de contacto - Detalles</strong></a>
    <p>{feathersStore.settings?.companyName} actúa como el Responsable del tratamiento de todos los datos personales que recopila, procesa y almacena.</p>
    {feathersStore.settings?.companyName}<br/>
    Dirección: {feathersStore.settings?.address}<br/>
    Teléfono: {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber}<br/>
    Correo electrónico: {feathersStore.settings?.email}<br/>
    Contacto: {feathersStore.settings?.companyName}<br/><br/>
    <a><strong>2. Qué datos recopilamos y procesamos</strong></a><br/>
    <p>Con el fin de servirle y ofrecerle nuestros servicios y productos, recopilamos y procesamos todos los datos que proporciona al representante del centro de llamadas cuando realiza un pedido o los datos que completa en los campos correspondientes al abrir una cuenta de usuario en nuestro sitio web, como nombre, dirección postal, número de teléfono de contacto (fijo o móvil), correo electrónico, información fiscal (en caso de que desee emitir una factura).<br/><br/> Tenga en cuenta que el sitio web de nuestra Compañía puede contener enlaces (links) a otros sitios web. Nuestra Compañía no es responsable de las prácticas de privacidad y protección de datos y/o el contenido de estos sitios web. Si tiene menos de 15 años, obtenga el permiso de sus padres o tutores antes de compartir cualquier información personal con nosotros.</p>
    <a><strong>3. Fines y bases legales del procesamiento</strong></a><br/>
    <p>Todos los datos personales mencionados anteriormente se recopilan y procesan principalmente con el propósito de ejecutar el contrato entre nosotros para la venta de nuestros productos y, en particular, para la preparación y entrega de su pedido y nuestra comunicación con usted al respecto. Algunos de sus datos también pueden utilizarse para los siguientes fines, siempre con su consentimiento pertinente:<br/><br/>
    - Comunicarse con usted en relación con concursos, sorteos y otros eventos.<br/>
    - Actualizar y enviar ofertas y material informativo sobre nuestros productos y servicios.<br/>
    - Fines estadísticos e investigativos.<br/>
    - Promoción y promoción de nuestros productos y servicios.</p>
    <p>Las bases legales para el procesamiento de datos pueden ser caso por caso:<br/>
    i. La ejecución y cumplimiento del contrato entre nosotros para la venta de nuestros productos a usted (preparación y entrega de su pedido). Sin la recopilación y el uso de la información anterior, no podremos atenderle.<br/>
    ii. El consentimiento que pueda proporcionarnos para recibir actualizaciones sobre ofertas o material promocional para nuestros productos y servicios, acciones comerciales y actividades que desarrollamos, etc. Puede retirar su consentimiento en cualquier momento, poniéndose en contacto con nosotros a través de los datos de contacto mencionados en el párrafo 1 o a través de nuestro sitio web.</p>
    <a><strong>4. Dónde compartimos sus datos personales</strong></a><br/>
    <p>Sus datos personales pueden compartirse con los siguientes destinatarios:<br/>
    - Con los distribuidores para preparar y entregar el pedido.<br/>
    - Con una empresa de envío de boletines (si ha dado su consentimiento para que le enviemos un boletín informativo).<br/>
    - Con una empresa de envío de SMS (si ha dado su consentimiento para recibir actualizaciones).<br/>
    - Con empresas colaboradoras, con el fin de recibir su premio si resulta ganador en un concurso en el que participó (cada vez que organizamos un concurso, mencionamos en los términos del concurso la posibilidad de compartir sus datos con la empresa colaboradora).<br/>
    - Con un socio externo que nos brinda soporte en cuestiones de software.<br/>
    - Con el departamento legal colaborador de la empresa, en caso de una disputa judicial o extrajudicial.<br/>
    - Con la policía u otras autoridades gubernamentales si se nos solicita oficialmente o después de una orden del fiscal.</p>
    <a><strong>5. Plazo de conservación de los datos</strong></a><br/>
    <p>Sus datos se conservan en la base de datos de {feathersStore.settings?.companyName} durante 5 años después de su última transacción con la empresa o durante el tiempo que la ley requiera (por ejemplo, en el caso de emitir una factura, durante el tiempo definido por el marco legal fiscal).<br/><br/>
    Si el procesamiento se basa en su consentimiento, los datos personales se conservan hasta que retire dicho consentimiento. Se aclara que la retirada del consentimiento no afecta a la legalidad del procesamiento basado en el consentimiento antes de su retirada.<br/>
    En cualquier caso, {feathersStore.settings?.companyName} implementa todas las medidas técnicas y organizativas adecuadas para garantizar la protección de los datos personales que procesa y se preocupa constantemente por prevenir el acceso no autorizado a estos datos.<br/></p>
    <a><strong>6. Derechos en relación con sus datos personales</strong></a><br/>
    <p>En relación con el procesamiento de sus datos personales, puede ejercer los siguientes derechos:<br/><br/>
    Derecho de acceso<br/>
    Tiene derecho a saber qué datos tenemos sobre usted, por qué los tenemos y otra información adicional al respecto, así como a solicitarnos una copia de ellos.<br/><br/>
    Derecho de rectificación<br/>
    Tiene derecho a solicitar la corrección, modificación y completación de sus datos personales.<br/><br/>
    Derecho de supresión ("derecho al olvido")<br/>
    Tiene derecho a solicitar la supresión de sus datos personales cuando se procesen tras su consentimiento. En los casos en que el procesamiento se base en otra base legal (como, por ejemplo, la ejecución de un contrato, una obligación legal o la protección de los intereses legales de la empresa, etc.), su derecho puede estar sujeto a limitaciones o puede no existir.<br/><br/>
    Derecho a la limitación del procesamiento<br/>
    Tiene derecho a solicitar la limitación del procesamiento de sus datos personales<br/>
    - cuando su exactitud se discute y hasta que se realice la verificación correspondiente.<br/>
    - alternativamente, en lugar de la supresión.<br/>
    - cuando ya no sean necesarios para nosotros para los fines de procesamiento para los que los recopilamos, pero sean necesarios para el establecimiento, ejercicio o defensa de reclamaciones legales por su parte.<br/>
    - cuando se oponga a su procesamiento y hasta que se verifique que existen motivos legítimos para dicho procesamiento por parte de la empresa.<br/><br/>
    Derecho a oponerse al procesamiento.<br/>
    Tiene derecho a oponerse al procesamiento de sus datos personales cuando se base en un interés legítimo, así como con fines de marketing directo y elaboración de perfiles.<br/><br/>
    Derecho a la portabilidad<br/>
    Tiene derecho a solicitar y recibir sus datos personales en un formato que le permita acceder, utilizar y procesar con métodos de procesamiento comunes. Además, en el caso de sus datos que procesamos por medios automatizados y basados en su consentimiento o para la ejecución de un contrato, puede solicitarnos que los transfiramos directamente a otro responsable del tratamiento, si esto es técnicamente posible.<br/><br/>
    Derecho a retirar el consentimiento<br/>
    Si el procesamiento de sus datos se basa en su consentimiento, tiene derecho a retirarlo en cualquier momento. La retirada de su consentimiento no afecta a la legalidad del procesamiento que se basó en el consentimiento antes de su retirada.<br/><br/>
    Derecho a eliminar cuenta<br/>
    Usted tiene control único y absoluto sobre su información y puede modificarla o eliminarla de su cuenta en cualquier momento sin el consentimiento o consentimiento de la tienda. <br/>
    Lo mismo aplica para toda tu cuenta ya que al ingresar a la configuración podrás proceder con la opción correspondiente.<br/><br/>
    Para ejercer los derechos mencionados anteriormente y para cualquier pregunta, queja u otra información relacionada con el procesamiento de sus datos personales, puede ponerse en contacto con nosotros en la dirección de correo electrónico {feathersStore.settings?.email} o en los datos de contacto mencionados en el párrafo 1.<br/></p>
    <a><strong>7. Derecho de reclamación ante la Autoridad de Protección de Datos (APD)</strong></a>
    <p>Tiene derecho a presentar una queja ante la Autoridad de Protección de Datos Personales (www.dpa.gr) en asuntos relacionados con el procesamiento de sus datos personales.<br/></p>
    <a><strong>8. Cambios en la protección de datos</strong></a>
    <p>Cualquier cambio futuro en la Política de privacidad de nuestra Compañía se publicará en el sitio web {feathersStore.settings?.siteUrl} y, si es necesario, se le comunicará.</p>
    <a><strong>9. Política de cookies</strong></a>
    <p>Debido a que la tienda en línea utiliza tecnologías modernas, no se utilizan cookies con sus datos, incluso si se ha registrado en nuestra tienda en línea. Las únicas cookies utilizadas se refieren al servicio de Google Analytics, siempre que esté activado. En este caso, se almacenan estadísticas generales sobre las visitas a las páginas de la tienda en línea.</p>
  </div>
  
    )
}

export default inject('feathersStore')(observer(PrivacyTextSpanish));