export const footerFR = {
    contactHours : "Heures de contact",
    week : "Lundi - Vendredi",
    contactUs : "Nous contacter",
    address : "131 rue Mesogeion, Marousi 15126",
    findUs : "Trouvez-nous sur la carte",
    contact: "Nous contacter",
    privacyPolicy: "Politique de confidentialité",
    termsOfService: "Conditions d'utilisation",
    stayConnected: "Restez connecté",
    cookiesTitle: "Utilisation des cookies",
    aboutUs : "À propos de nous",
    cookiesText: "Nous utilisons uniquement les cookies strictement nécessaires. Aucune de vos données personnelles n'est stockée dans ceux-ci.",
    cookiesAccept: "Accepter",
    accessibilityTitle: "Accessibilité",
    fontIncrease: "Augmenter la taille de la police",
    fontDecrease: "Diminuer la taille de la police",
    greyscale: "Échelle de gris",
    highContrast: "Contraste élevé",
    negativeContrast: "Contraste négatif",
    lightBackground: "Fond clair",
    linksUnderline: "Souligner les liens",
    readableFont: "Police lisible",
    reset: "Réinitialiser",
    paymentOptions: "Options de paiement",
    deliveryOptions: "Options de livraison",
    openingTime: "Heures d'ouverture",
    loginRegister: "Connexion/Inscription",
    messagingTitle: "Support",
    messagingSubTitle: "Envoyez un message avec vos questions !",
    message: "Message",
    installBringFood: "Commencez avec BrigFood !",
    usefull: "Utiles",
    company: "Entreprise",
    faq: "FAQ",
    chatPlaceholder: "Saisissez du texte...",
    restaurantClosed: "L'établissement est actuellement fermé et nous ne pouvons pas préparer votre commande",
    holidayText: "Les locaux resteront fermés aujourd'hui",
    allDay: "Toute la journée",
    to: "À"


}