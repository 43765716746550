import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import Email from '@mui/icons-material/MailOutline'
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';// Translations
import _useTranslate from '../../hooks/_useTranslate';

const FooterAddressMenu = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");
  
  const [settingsPhone, setSettingsPhone] = useState("");  

  useEffect(() => {
    const phoneNumber = feathersStore.settings?.phoneNumbers &&
    feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber;
    setSettingsPhone(phoneNumber); 
  },[feathersStore.settings])


return (
  <div className="column-flex-start" style={{marginTop: 10}}>
    <span className="footer-row">
      <AddIcCallIcon />
      <a href={`tel:${settingsPhone}`} className="footer-text-item" >
        <span  >{settingsPhone}</span>
      </a>        
    </span>
    <span className="footer-row">
      <Email/>
      <span className="footer-text-item">{feathersStore.settings?.email}</span>
    </span>
    <span className="footer-row">
      <LocationOnSharpIcon />                    
      <a           
        className="footer-text-item"
        href={`https://www.google.com/maps/search/?api=1&query=${feathersStore.settings?.latitude}%2C${feathersStore.settings?.longitude}`} 
        target="_blank" 
        rel="noopener noreferrer"
      >{feathersStore._translateAddress()} 
      </a>                   
    </span>  
  </div>
  );
}

export default inject('feathersStore')(observer(FooterAddressMenu));
