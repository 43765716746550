export const contactES = {
    mustHaveChar: "Debe contener al menos dos caracteres",
    less255: "Debe tener menos de 255 caracteres",
    required: "Requerido",
    invalidEmail: "Correo electrónico inválido",
    invalidPhone: "Número de teléfono inválido",
    textareaMax: "Debe tener menos de 1000 caracteres",
    contactForm: "Formulario de contacto",
    firstName: "Nombre",
    lastName: "Apellido",
    phone: "Teléfono",
    email: "Correo electrónico",
    text: "Mensaje",
    buttonForm: "Enviar",
    emailSent: "¡Correo electrónico enviado!",
    emailSentMessage: "¡Ha enviado su correo electrónico con éxito!",
    emailError: "Error de correo electrónico",
    emailErrorMessage: "No se pudo enviar su correo electrónico."
}