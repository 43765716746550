import React, {useState} from 'react';
import ProfileSettings from './profileSettings'
import Address from './address';
import History from './history';
import Comments from './comments';
import Settings from './settings';
import "./Account.css";
import { inject, observer } from "mobx-react";
import {SHOW_REVIEWS} from "../../assets/globalConstants";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Account = ({ feathersStore })=> {

  let account = _useTranslate(feathersStore.language, "account");

  const [profile, setProfile] = useState(true);
  const [visibleAddress, setVisibleAddress] = useState(false);
  const [history, setHistory] = useState(false);
  const [comments, setComments] = useState(false);
  const [settings, setSettings] = useState(false);
  
  const profileClicked = () => {
    setProfile(true)
    setVisibleAddress(false)
    setHistory(false)
    setComments(false)
    setSettings(false)
  }
  
  const addressesClicked = () => {
    setProfile(false)
    setVisibleAddress(true);
    setHistory(false)
    setComments(false)
    setSettings(false)
  }

  const historyClicked = () => {
    setProfile(false)
    setVisibleAddress(false)
    setHistory(true)
    setComments(false)
    setSettings(false)
  }

  const commentsClicked = () => {
    setProfile(false)
    setVisibleAddress(false)
    setHistory(false)
    setComments(true)
    setSettings(false)
  }

  const settingsClicked = () => {
    setProfile(false)
    setVisibleAddress(false)
    setHistory(false)
    setComments(false)
    setSettings(true)
  }


  const handleMenuSelection = () => {

    if(profile) {

      return <ProfileSettings />

    } else if(history){

      return <History/>

    } else if(visibleAddress){

      return <Address/>

    } else if(comments) {
      
      return <Comments />
 
    } else if(settings){

      return <Settings/>
    }
  }

  return(
    <>
      <div className="account-container">
        <div className="header-container">
          {account.profileHeader}
        </div>     
        <div className="account-board">
          <div className="account-board-header">           
            <div className={profile ? "account-header-button selected-button" : "account-header-button" } onClick={profileClicked} aria-label='Account'>           
             {account.profile}
            </div>          
            <div className={visibleAddress ? "account-header-button selected-button" : "account-header-button" } onClick={addressesClicked} aria-label='Address'>
              {account.addresses}
            </div>          
            <div className={history ? "account-header-button selected-button" : "account-header-button" } onClick={historyClicked} aria-label='History'>
              {account.history}
            </div>  
            {SHOW_REVIEWS && feathersStore.settings?.showReviews &&
              <div className={comments ? "account-header-button selected-button" : "account-header-button"} onClick={commentsClicked} aria-label='Comments'>
                {account.comments}
              </div> 
            }        
                  
            <div className={settings ? "account-header-button selected-button" : "account-header-button"} onClick={settingsClicked} aria-label='Settings'>
             {account.settings}
            </div>           
          </div>
          <div className="account-board-body">
            {handleMenuSelection()}
          </div>
        </div>
      </div>     
    </>
  )
}

export default inject('feathersStore')(observer(Account));