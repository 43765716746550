import React from 'react';
import ButtonMinus from '../../buttons/buttonMinus';
import ButtonPlus from '../../buttons/buttonPlus';
import AppColors from '../../assets/colors'

const CartProductComponent = ({ 
  image, 
  title,
  data, 
  price, 
  quantity,
  handleClick,
  onClickMinus, 
  onClickPlus })=> {

    
  return(
    <div className="cartProductCard">
      <div 
        className="cartProductCardPic" 
        style={{backgroundImage: `url(${image})`}}
        onClick={handleClick}
      >
      </div>
      <div className="details-container">
        <div className="cartProductCardDetails">   
          <div className="cart-product-title">{title}</div>       
          <div className="cartProductCardDetails-Data">{data}</div>
        </div>  
        <div className="cartProductCardDetails-P-Q-container">         
          <div className="cartProductCardDetails-Price">
            {`${parseFloat(price).toFixed(2)}€`}
          </div>
          <div className="cartProductCardDetails-Quantity">
            <ButtonMinus onClick={onClickMinus} green={true}/>
              <span style={{margin:"10px"}}>{quantity}</span>
            <ButtonPlus onClick={onClickPlus} green={true}/>
          </div>          
        </div>       
      </div>
    </div>
  )
}

export default CartProductComponent;