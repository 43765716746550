import React from 'react';

const CategoryPic = ({image, name})=> {
  return(
    <div className="category-pic-container">
      <div className="cat-name-section">
        {name}
      </div>
      <img className="cat-image" src={image}></img>
    </div>
  )
}

export default CategoryPic;