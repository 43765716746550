import React from 'react'

const PaymentOptionsTextEnglish = ()=> {
  return(
    <div className="terms-text">
      <h2>Order payment</h2>
      <p>
        In our company, we make every effort to provide the best possible service to our customers. Therefore we have included as many payment methods as possible.
      </p>    
      <h2>
        Credit card – Debit card
      </h2>
      <p>
        In our store we accept credit, debit or prepaid cards of any bank. In the case of payment in our physical store, the payment is made using POS. In the case of purchase from the online store - e-shop and payment by credit card, the user is transferred to the secure environment of the partner banking organization (PayPal). This website is secure and covered by an SSL certificate. The details of the credit debit or prepaid card that will be used are not disclosed to us and we do not keep them in our company,
             </p>
      <h2>
        Pickup from the store
      </h2>
      <p>
        If you choose to pick up your products from a store, you also pay the amount of your order on the spot.
      </p>
    </div>
  )
}
    
export default PaymentOptionsTextEnglish;