import React from 'react';
import ShoppingCart from '@mui/icons-material/ShoppingCart'
import AppColors from '../assets/colors'

const AddToCartButton = ({ disabled, onClick })=> {

  
  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  } 

  return (
    <button
      disabled={disabled} 
      onMouseOver={changeCursor}
      className="productAddtocartButton"
      onClick={onClick}>
      <ShoppingCart style={{color: AppColors.menuTitles}}/>
    </button>
  );  
}

export default AddToCartButton;
