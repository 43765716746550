export const accountES = {
    myAccount: "myAccount",
    profile: "PERFIL",
    addresses: "DIRECCIONES",
    history: "HISTORIAL",
    comments: "COMENTARIOS",
    settings: "AJUSTES",
    psw: "Cambiar contraseña",
    updateAlert: "Tus datos se actualizaron exitosamente",
    updateError: "Error al actualizar",
    addAddress: "Agregar una dirección",
    addNewAddress: "Agregar una nueva dirección",
    myLocation: "Mi ubicación",
    modalHeaderUpdate: "Cambiar dirección",
    otherLocation: "Otra ubicación",
    home: "Hogar",
    work: "Trabajo",
    helperText: "Por favor, ingresa los detalles de tu dirección",
    addressComments: "Comentarios adicionales de la dirección",
    historyPlaceholder: "No has realizado compras todavía",
    commentPlaceholder: "No has realizado ningún comentario todavía",
    emptyHistory: "No has realizado ningún pedido todavía",
    uploadAvatar: "Haz clic en la imagen para elegir una imagen diferente",
    submit: "ENVIAR",
    uploadSuccess: "Carga de archivo exitosa",
    uploadError: "Error al cargar el archivo",
    bigFile: "La imagen es demasiado grande. Debe tener hasta 1 MB. Por favor, selecciona una imagen más pequeña",
    favorites: "MIS FAVORITOS",
    hello: "Hola",
    profileHeader: "Perfil",
    settingsSmall: "Ajustes"
}
