import React,{useState, useEffect, useRef} from 'react'
import CartProductComponent from './cartProductComponent';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import DeleteAccount from '../../modals/deleteAccount';
import _useTranslate from '../../hooks/_useTranslate';
import GreenSubmitButton from "../../buttons/greenSubmitButton";
import Colors from '../../assets/colors';
import {API_URL, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../../assets/globalConstants";
import SmallAlert from '../../modals/smallAlert';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { DateTime } from "luxon";
import CouverModal from './couverModal';

const CartTable = ({feathersStore})=> {

  let navigate = useNavigate();

  let common = _useTranslate(feathersStore.language, "common");  
  let cart = _useTranslate(feathersStore.language, "cart");  

  const [total, setTotal] = useState(0.0);
  const [cart_products, setCart_products] = useState([]);
  const [itemToDelete, setItemToDelete] = useState({});
  const [voucherText, setVoucherText] = useState("");
  const [warning, setWarning] = useState(false);
  const [minimumOrder, setMinimumOrder] = useState(false);
  const [couver, setCouver] = useState(false);
  const [couverQuantity, setCouverQuantity] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [minimumVoucher, setMinimumVoucher] = useState(false);
  const [minimumVoucherText, setMinimumVoucherText] = useState(null);

  //Modal Controls
  const [visible, setVisible] = useState(false);
  
  const discountsArrayRef = useRef([]);

  useEffect(()=> { //used to empty cart if there are forgotten items after QR code scan
    feathersStore.setTableId(getWithExpiry('tableId'))
  },[]);

  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)   
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) { 
      localStorage.removeItem(key);
      emptyCart();
      return null
    }
    return item.value
  }

  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': 1,
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fdwa_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }

  useEffect(() => {
    const cartFromStorage = JSON.parse(localStorage.getItem('fdwa_cart'))  
    if(cartFromStorage){
    setCart_products(cartFromStorage); 
    setTotal(cartFromStorage.map(element => element.product_totalPrice)
    .reduce((t, sum) => sum + t, 0))  
    }
  }, []);

  useEffect(() => {
    const asyncFn = async() => {
    if(
      (feathersStore.tableId?.length > 0) && feathersStore.settings?.couver)
      cart_products?.length > 0 && await checkForCouver();
    }
    asyncFn();
  }, [feathersStore?.tableId, feathersStore?.settings, cart_products]);

  const updateTotalAmount = clonedCartProducts => {
    setTotal(clonedCartProducts.map(element => element.product_totalPrice)
      .reduce((t, sum) => sum + t, 0));
    feathersStore.setCartLength(clonedCartProducts.length);   
  };


  const goToProduct = async (item) => {
    const index = cart_products.indexOf(item);
    const otherData = {
      id: item.id,
      quantity: item.product_quantity,
      sizePrice: 0,
      total: item.product_totalPrice,     
      extraSum: item.product_extraSum,
      extraItems: [...item.features], //extraItems is written wrongly as features
      ingredientItems: [...item.ingredients], //the same
      attributeItems: [...item.attributes],
      fromCart: true,
      cartIndex: index,
      comments: item.product_comments,
      printers: item.product_printers,
      kdsSections: item.product_kdsSections,
      stock: item.product_stock,
      handleStock: item.product_handleStock,
      weightedPrice: item.product_weightedPrice,
      weightedQuantity: item.product_weightedQuantity,
     }

    const prod = await feathersStore.getProduct(item.product_id);    
    prod.features.forEach(feature => Object.assign(feature, {picked: false}));
    Object.assign(prod, otherData);
    if(prod?.sizes?.length > 0)
    for (var i in prod.sizes) {
      if (prod.sizes[i].default === true) {
        prod.sizes[i].default = false;
      }
      for(var k in prod.sizes){
        if(prod?.sizes[k]?.name === item.product_size?.name || feathersStore._translateNameAttr(prod.sizes[k]) === feathersStore._translateNameAttr(item.product_size)){
          prod.sizes[k].default = true;
          prod.sizePrice = prod.sizes[k][packagePricing() ? 'packagePrice' : 'price'];
        }
      } 
    };
    prod && navigate(`/product/${feathersStore._translateName(prod)}`,{
        state: {id: `${prod._id}`,  payload: prod}     
    }); 
  };  

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }
  
  const onPressToggle = (item, toggle) => () => {   
    const clonedItem = JSON.parse(JSON.stringify(item)) 
    let clonedCartProducts = [...cart_products];
    const index = cart_products.indexOf(item);    
    let quantity = +clonedItem.product_quantity;    
    const itemPrice = clonedItem.product_totalPrice / quantity;   
    if(toggle === "plus"){      
      if (feathersStore.checkOutOfStock(item.product_id, item.product_handleStock, item.product_stock, 
            1, clonedCartProducts, item.product_weightedQuantity)){
        setWarning(true); return;
      }
      quantity +=1;   
    }  
    else if(quantity > 0)quantity -=1
    if (quantity !== 0){
      clonedItem.product_quantity =  quantity;    
      clonedItem.product_totalPrice = itemPrice * quantity;
      clonedCartProducts.splice(index, 1, clonedItem); 
      setCart_products(clonedCartProducts);     
      localStorage.setItem("fdwa_cart", JSON.stringify(clonedCartProducts));     
      updateTotalAmount(clonedCartProducts);        
    }else {
      setVisible(true);
      setItemToDelete(item)
    }
  }

  const deleteItem = () => {
    const index = cart_products.indexOf(itemToDelete);
    let clonedCartProducts = [...cart_products];
    clonedCartProducts.splice(index, 1); 
    setCart_products(clonedCartProducts);     
    localStorage.setItem("fdwa_cart", JSON.stringify(clonedCartProducts));     
    updateTotalAmount(clonedCartProducts);
    setVisible(!visible);  
    if(
      (feathersStore.tableId?.length > 0) 
        && feathersStore.settings?.couver
        && (itemToDelete.product_id === feathersStore.settings?.couverId)
      ){     
        setCouver(true);
        setCouverQuantity(1);
      }  
  }

  const closeConfirm = ()=> {
    setVisible(!visible);
  }

  const calculateTotalQuantity = () => {
    return cart_products.length !== 0? 
    cart_products.map(el => el.product_quantity)
    .reduce((q, sum) => sum + q, 0) : 0
  }

  const goToCheckout = async() => {
    if ((feathersStore.tableId?.length > 0) && feathersStore.settings?.minimumOrder && (+total < +feathersStore.settings.minimumOrder)){
      setMinimumOrder(true); return;
    }   
    if(discount){
      feathersStore.setDiscountsArray(discountsArrayRef.current);
    }
    navigate('/checkout', {state: {payload: discountsArrayRef.current}});
  }

  const checkForCouver = async() => {
    const couverId = feathersStore.settings?.couverId
    const table = await feathersStore.getTable(feathersStore.tableId);
    const tableCouvers = table?.order?.items?.filter(item => item.product_id === couverId);
    const cartCouvers =  cart_products.filter(item => item.product_id === couverId);
    if(      
      (!tableCouvers || tableCouvers?.length < 1) && 
      (cartCouvers?.length < 1)){
        setCouver(true)
      }else return;
  }

  const couverQuantityChange = event => {    
    setCouverQuantity(event.target.value);
  };

  const voucherChange = event => {    
    setVoucherText(event.target.value);
  };

  const applyVoucher = async() => {
    if(voucherText?.length > 0 && discountsArrayRef.current.length === 0){
      const now = DateTime.now().setZone("Europe/Athens");
      const _vouchers = await feathersStore.coupons();
      const vouchers = _vouchers?.filter(coupon => coupon?.general)
      for(let voucher of vouchers){
        if(voucher.code === voucherText){   
          let valid = false;
          if(voucher?.endDate){
            if(DateTime.fromISO(voucher.endDate) > now)valid = true;
          }else{
            if(DateTime.fromMillis(voucher.createdAt).plus({days : voucher.duration}) > now)valid = true;
          }
          if(valid){
            if( total >= (voucher?.minimumOrder || 0)){
              if(voucher.type === "Percent"){  
                const disc = parse_fix(total * voucher.reward / 100);
                setDiscount(parseFloat(disc));
                discountsArrayRef.current = [{
                  type: "Percent",
                  label: "General",
                  value: disc,
                  reward: voucher.reward,
                  applied: false,
                  display: voucher.display,
                  promotionText: voucher.promotionText
                }]
              }else if(voucher.type === "Cash"){  
                setDiscount(parseFloat(voucher.reward));
                discountsArrayRef.current = [{
                  type: "Cash",
                  label: "General",
                  value: parseFloat(voucher.reward),
                  reward: voucher.reward,
                  applied: false,
                  display: voucher.display,
                  promotionText: voucher.promotionText  
                }]
              }
            }else{
              setMinimumVoucherText(voucher.minimumOrder)
              setMinimumVoucher(true);
            }
          }
        }
      }  
    }  
  }

  const addCouverToCart = async() => { 
    const item = await feathersStore.getProduct(feathersStore.settings?.couverId);  
    let cart = [];
    let cartBiggestId = 0;
    if(localStorage.getItem('fdwa_cart')){
      cart = JSON.parse(localStorage.getItem('fdwa_cart'));    
      if(cart.length > 0)
        cartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    } 
    const pickedSize = item.sizes.filter(size => size.default)[0]; 
    pickedSize && Object.assign(pickedSize, {nameTranslated: feathersStore._translateNameAttr(pickedSize)});  
    const cartItem = {
      id: cartBiggestId,
      product_id: item._id,
      product_name: item.name,
      product_nameTranslated: feathersStore._translateName(item),
      product_size: pickedSize,
      product_image: `${API_URL}/images/${item?.images[0]}`,
      product_price: +item['retailPrice'],
      product_totalPrice: +item.retailPrice * couverQuantity,
      product_netPrice: calculateNetPrice(item),
      product_quantity: +couverQuantity,
      product_extraSum: 0,
      features: [],
      ingredients: [],
      attributes: [],  
      product_printers: item.printers,
      product_kdsSections: item.kdsSections,
      product_vat: item.vat,
      product_stock: item.stock,
      product_handleStock: item.handleStock,
      product_comments: item.comments,
      nameTranslations: item?.nameTranslations,
      shortDescriptionTranslations: item?.shortDescriptionTranslations
    }
    if (feathersStore.checkOutOfStock(item._id, item.handleStock, item.stock, cartItem.product_quantity, cart)){
      setWarning(true); return;
    } 
    let clonedCartProducts = [...cart_products];
    clonedCartProducts.push(cartItem); 
    setCart_products(clonedCartProducts);     
    localStorage.setItem("fdwa_cart", JSON.stringify(clonedCartProducts));     
    updateTotalAmount(clonedCartProducts);
    setCouver(false);
  }

  const calculateNetPrice = item => {
    const label = feathersStore.settings.vats?.find(v => +v.id === +item.vat)?.label
    return +((item.retailPrice) / (1 + label / 100 )).toFixed(2);
  }

  const goToHome = () => {
    navigate('/');
  }

  const constructTitle = (item)=> {  
    return feathersStore._translate(item.product_name , item.product_nameTranslated) 
      + (item?.product_size?.name ? 
        "  " + feathersStore. _translateNameAttr(item.product_size) :
        "");   
  }  

  const constructData = item => {
    return (item?.product_weightedQuantity > 0 ? ((+item.product_weightedQuantity * 1000).toString() + " gr ") : "")
    + item.features?.map(feature => feathersStore._translateNameAttr(feature) + "  ")
    + item.attributes?.map(attribute => "  " + feathersStore._translateTitle(attribute) + ": " 
        + feathersStore._translateNameAttr(attribute) + " " + (attribute?.quantity > 1 ? "x" + attribute.quantity + " " : ""))
    + (item?.ingredients?.length > 0 ? ("  " + cart.without  + item.ingredients?.map(ingredient => "  " 
        + feathersStore._translateNameAttr(ingredient))) : "")
  } 

  const parse_fix = (price) => {
    if(!price)price = 0;
    return price.toFixed(2);
  } 

  const renderCartProducts = cart_products.map((item, index) => (
    <CartProductComponent
      key={index}
      image={item.product_image}
      quantity={item.product_quantity}
      title={constructTitle(item)}
      data={constructData(item)}
      price={item.product_totalPrice}
      handleClick={() => goToProduct(item)}
      onClickPlus={onPressToggle(item, "plus")}
      onClickMinus={onPressToggle(item, "minus")}
    />
  ))

  return(
  <>
    <>
      <DeleteAccount
        show={visible}
        heading={common.removeItem}
        onDelete={deleteItem}
        onClose={closeConfirm}
        message={common.youSure}
        no={common.noSmall}
        yes={common.yesSmall}
        fromCart={true}
      />       
    </>
    <div className="table-container">     
      <div className="table-body">
        <div className="section-header">{cart.cartItems}<div className="section-line"></div></div>        
        {renderCartProducts} 
        <div className="table-footer"> 
          <div className="coupon-caption">{cart.couponCaption}</div>
          <div className="coupon-input-container"> 
            <input aria-label='coupon' type="text" placeholder={cart.couponCode} className="coupon-input"
              value={voucherText}
              onChange={voucherChange}
            />
            <span className="coupon-button"  onClick={applyVoucher}>
              <ArrowForwardIcon/>
            </span>   
          </div>             
        </div>  
      </div> 
      <div className="cart-total-table">
        <div className="section-header">{cart.cartTotals}<div className="section-line"></div></div>        
        <div className="cart-total-body">
          <div className="totals-line">{common.products}: {feathersStore.cartLength}</div>
          <div className="totals-line">{common.items}: {calculateTotalQuantity()}</div>
          <div className="totals-line">{cart.subTotal}: {(+total).toFixed(2)}€</div>
          <div className="totals-line">{cart.couponDiscount}: {parse_fix(discount)}€</div>
        </div>
        <div className="totals-line">{common.total}: {(total - discount).toFixed(2)}€</div>
        <div className="cart-total-footer">        
          <GreenSubmitButton aria-labelledby='Complete order' 
            disabled={total === 0 || !feathersStore.restaurantOpen || couver}
            color={Colors.secondary}
            title={cart.completeOrder} 
            onClick={goToCheckout} 
            width="100%"
          ></GreenSubmitButton>         
          <GreenSubmitButton aria-labelledby='Complete order' 
            color={Colors.secondary}
            title={cart.continueShopping} 
            onClick={goToHome} 
            width="100%"
            variant="outlined"
          ></GreenSubmitButton>       
        </div>
      </div>   
    </div>   
    <SmallAlert 
      show={warning} 
      mode="stock"
      heading={common.stockWarningHeader} 
      onClose={() => setWarning(false)}  
      message={common.stockWarning}
    />
    <SmallAlert 
      show={minimumOrder} 
      mode="stock"
      heading={common.minimumOrderHeader} 
      onClose={() => setMinimumOrder(false)}  
      message={`${common.minimumOrder}${feathersStore?.settings.minimumOrder}€`}
    /> 
    <CouverModal 
      show={couver} 
      couverQuantity={couverQuantity}
      couverQuantityChange={couverQuantityChange}
      addCouverToCart={addCouverToCart}
    />  
    <SmallAlert 
      show={minimumVoucher} 
      mode="stock"
      heading={common.minimumVoucherOrder} 
      onClose={() => setMinimumVoucher(false)}  
      message={`${common.minimumVoucherCaption}: ${minimumVoucherText}€`}
    />       
  </>
  )
}

export default inject('feathersStore')(observer(CartTable));