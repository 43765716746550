export const contactEL = {
    mustHaveChar: "Το πεδίο πρέπει να περιέχει τουλάχιστον δύο χαρακτήρες",
    less255: "Το πεδίο πρέπει να περιέχει λιγότερους από 255 χαρακτήρες",
    required: "Το πεδίο δεν μπορεί να μείνει κενό",
    invalidEmail: "Λανθασμένο email",
    invalidPhone: "Λανθασμένος αριθμός",
    textareaMax: "Το πεδίο πρέπει να περιέχει λιγότερους από 1000 χαρακτήρες",
    contactForm : "Φόρμα Επικοινωνίας",
    firstName : "Όνομα",
    lastName : "Επίθετο",
    phone : "Τηλέφωνο",
    email : "Email",
    text : "Μήνυμα",
    buttonForm : "Αποστολή",
    emailSent: "Επιτυχής Αποστολή",
    emailSentMessage: "Το μήνυμά σας στάλθηκε επιτυχώς!",
    emailError: "Σφάλμα κατά την Αποστολή",
    emailErrorMessage: "Παρουσιάστηκε σφάλμα και το μήνυμά σας δεν απεστάλει!"
}