import React from 'react';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";

const FaqTextEnglish = ({ feathersStore })=> {
  return(
    <div className="terms-text">
      <h2>What payment methods do you accept?</h2>
      <p>
        You can see the payment methods <Link className="privacy-text" to="/paymentoptions">here</Link>.
      </p>    
      <h2>
        Με ποιούς τρόπους μπορώ να παραλάβω την παραγγελία μου;
      </h2>
      <p>
        You can see the shipping methods <Link className="privacy-text" to="/deliveryoptions">here</Link>.
      </p>
      <h2>
        Can I make any changes to my order?
      </h2>
      <p>
      You can make any changes before your order is shipped. Please make a call on {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber} or contact us via website form <Link className="privacy-text" to="/contact">here</Link> or via email on {feathersStore.settings?.email} stating your details and the changes you want to make.
      </p>
      <h2>
        Is there a store I can visit?
      </h2>
      <p>
      You can visit us at our place at the address {feathersStore.settings?.address}.
      </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(FaqTextEnglish));