import React from 'react';
import { inject, observer } from "mobx-react";

const DeliveryOptionsTextGerman = ({feathersStore})=> {
  return(
    <div className="terms-text">
       <h2>Lieferung & Versand</h2>
       <p>
        In unserem Geschäft geben wir unser Bestes, um unsere Kunden zu bedienen. Sie können fernmündlich oder über unsere Website bestellen. Hier sind die beiden häufigsten Möglichkeiten, wie wir unsere Produkte versenden und liefern.
        </p>
        <h2>Abholung in unserem Geschäft</h2>
        <p>
        Sie können Ihre Einkäufe in unserem Geschäft in der Straße {feathersStore.settings?.address} abholen.
        </p>
        <h2>Lieferung an Ihren Standort</h2>
        <p>
        Wir verpflichten uns, Ihre Bestellung an den von Ihnen angegebenen Ort zu bringen.
        </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(DeliveryOptionsTextGerman));