import React from 'react';

const NoneEvaluation = ({message}) => {

    return(
        <div>{message}</div>
    )
}

export default NoneEvaluation;

