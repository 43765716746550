import React from 'react';
import { inject, observer } from "mobx-react";

const TermsText = ({ feathersStore })=> {
    return(
    <><div className="terms-text"><p>Για την ολοκλήρωση των παραγγελιών σας θα σας ζητηθεί κάθε φορά ξεχωριστά να επιβεβαιώνετε ότι συμφωνείτε με τους κάτωθι όρους χρήσης:</p><ul>
            <li>
                <p>Τα πνευματικά δικαιώματα του Περιεχομένου (π.χ. προγράμματα, πληροφορίες, δεδομένα) και των Υπηρεσιών του διαδικτυακού τόπου - ιστοσελίδα που έχουν εισαχθεί στο Δίκτυο, προστατεύονται από τους ελληνικούς, κοινοτικούς και διεθνείς νόμους περί πνευματικής ιδιοκτησίας.</p>
            </li>
            <li>
                <p>Απαγορεύεται οποιαδήποτε αντιγραφή, διανομή, μεταφορά, μεταποίηση, μεταπώληση, δημιουργία παράγωγης εργασίας ή παραπλάνηση του κοινού σχετικά με τον πραγματικό παροχέα τους, του Περιεχομένου διαδικτυακού τόπου. Τυχόν αναπαραγωγή, επανέκδοση, φόρτωση, ανακοίνωση, διάδοση ή μετάδοση ή οποιαδήποτε άλλη χρήση του Περιεχομένου με οποιοδήποτε τρόπο ή μέσο για εμπορικούς ή άλλους σκοπούς επιτρέπεται μόνο κατόπιν προηγούμενης γραπτής άδειας του - ιστοσελίδα ή οιουδήποτε άλλου δικαιούχου των πνευματικών δικαιωμάτων.</p>
            </li>
            <li>
                <p>Το ηλεκτρονικό κατάστημα - ιστοσελίδα παρέχει το Περιεχόμενο (π.χ. πληροφορίες, ονόματα, φωτογραφίες, απεικονίσεις), τα προϊόντα και τις υπηρεσίες που διατίθενται μέσω του διαδικτυακού τόπου “όπως ακριβώς έχουν”. Σε καμία περίπτωση το ηλεκτρονικό κατάστημα - ιστοσελίδα δεν φέρει ευθύνη για τυχόν απαιτήσεις νομικής ή αστικής ή/και ποινικής φύσεως ούτε για τυχόν ζημία (θετική, ειδική ή αποθετική, η οποία ενδεικτικά και όχι περιοριστικά, διαζευκτικά ή και σωρευτικά συνίσταται σε απώλεια κερδών, δεδομένων, διαφυγόντα κέρδη, χρηματική ικανοποίηση κλπ.) από επισκέπτες του διαδικτυακού τόπου ή τρίτους από αιτία που έχει σχέση με τη λειτουργία ή μη ή και τη χρήση του διαδικτυακού τόπου ή και σε αδυναμία παροχής υπηρεσιών ή και πληροφοριών που διατίθενται από αυτόν ή και από τυχόν μη επιτρεπόμενες παρεμβάσεις τρίτων σε προϊόντα ή και υπηρεσίες ή και πληροφορίες που διατίθενται μέσω αυτού.</p>
            </li>
            <li>
                <p>Οι φωτογραφίες των προϊόντων είναι ενδεικτικές και δε φέρουμε καμία ευθύνη για τυχόν ορθογραφικά λάθη.</p>
            </li>
            <li>
                <p>Οι τιμές μπορούν να αλλάξουν χωρίς προειδοποίηση. Δέσμευση τιμής μπορεί να γίνει μόνο ηλεκτρονικής παραγγελίας και ολοκληρώνεται με την αποστολή της επιβεβαίωσής της. Το κατάστημα με την εμπορική ονομασία - ιστοσελίδα δεν υποχρεούται να παράσχει ουδεμία εγγύηση για τη διαθεσιμότητά των προϊόντων του, αλλά αναλαμβάνει να ενημερώνει άμεσα τους πελάτες του για τυχόν ελλείψεις ή καθυστερημένες αποστολές.</p>
            </li>
            <li>
                <p>Ο πελάτης από τη στιγμή που θα επεξεργαστεί η παραγγελία από το τμήμα παραγγελιών και έχει επιβεβαιώσει την παραγγελία του, εξασφαλίζει πως θα παραλάβει το προϊόν με την τιμή την οποία “αποδέχτηκε” τη συναλλαγή έστω και αν η τιμή στην πορεία μεταβληθεί. Σε περίπτωση που τα προϊόντα ή οι υπηρεσίες αναφέρονται στον δικτυακό τόπο με λάθος τιμή λόγω τυπογραφικού σφάλματος τότε οι παραγγελίες ακυρώνονται αυτόματα. Το - ιστοσελίδα δεν ευθύνεται για τυχόν ζημιές που προκύψουν στον πελάτη/χρήστη του ηλεκτρονικού καταστήματος από την εκτέλεση ή μη των παραγγελιών ή από την καθυστέρηση εκτέλεσης για οποιονδήποτε λόγο.</p>
            </li>
            <li>
                <p>Το ηλεκτρονικό κατάστημα - ιστοσελίδα διατηρεί το δικαίωμα να τροποποιεί, προσθέτει, μεταβάλλει το περιεχόμενο ή τις υπηρεσίες του διαδικτυακού τόπου, καθώς και τους όρους χρήσης, οποτεδήποτε το κρίνει αναγκαίο και χωρίς προηγούμενη προειδοποίηση, με μόνη την ανακοίνωσή τους μέσω του διαδικτυακού της τόπου.</p>
            </li>
        </ul><p>
                Ανάλογα με τον τρόπο πληρωμής που έχει επιλέξει ο πελάτης κατά την παραγγελία του, θα γίνει αντίστοιχα και η επιστροφή χρημάτων:
            </p><ul>
                <li>
                    <p>Για Πιστωτική κάρτα on-line η επιστροφή των χρημάτων θα γίνει στην κάρτα.</p>
                </li>
                <li>
                    <p>Για Αντικαταβολή ή κατάθεση σε τράπεζα θα πρέπει να μας δώσετε το IBAN ενός τραπεζικού λογαριασμού, το ονοματεπώνυμο του δικαιούχου όπως και την τράπεζα την οποία αφορά.</p>
                </li>
            </ul></div></>
    )
}

export default inject('feathersStore')(observer(TermsText));