import React from 'react'

const PaymentOptionsTextSpanish = ()=> {
  return(
    <div className="terms-text">
    <h2>Pago de la orden</h2>
    <p>
    En nuestra empresa, hacemos todo lo posible para ofrecer el mejor servicio a nuestros clientes. Por lo tanto, hemos incluido la mayor cantidad posible de métodos de pago.
    </p>
    <h2>
    Tarjeta de crédito - Tarjeta de débito
    </h2>
    <p>
    En nuestra tienda, aceptamos tarjetas de crédito, débito o prepago de cualquier banco. En el caso de pago en nuestra tienda física, el pago se realiza mediante un terminal de punto de venta (POS). En el caso de la compra en nuestra tienda en línea (e-shop) y el pago con tarjeta de crédito, el usuario es redirigido al entorno seguro de la organización bancaria asociada (PayPal). Este sitio web es seguro y está protegido por un certificado SSL. Los detalles de la tarjeta de crédito, débito o prepago que se utilizarán no se nos revelan y no los conservamos en nuestra empresa.
    </p>
    <h2>
    Recogida en tienda
    </h2>
    <p>
    Si elige recoger sus productos en una tienda, también puede pagar el importe de su pedido en el lugar.
    </p>
    </div>
  )
}
    
export default PaymentOptionsTextSpanish;