export const footerIT = {
    contactHours: "Orari di contatto",
    week: "Lunedì - Venerdì",
    contactUs: "Contattaci",
    address: "Via Mesogeion 131, Marousi 15126",
    findUs: "Trova la nostra posizione sulla mappa",
    contact: "Contattaci",
    privacyPolicy: "Informativa sulla privacy",
    termsOfService: "Termini di servizio",
    stayConnected: "Resta connesso",
    cookiesTitle: "Utilizzo dei cookie",
    aboutUs: "Chi siamo",
    cookiesText: "Utilizziamo solo i cookie strettamente necessari. I tuoi dati personali non sono memorizzati in nessuno di essi.",
    cookiesAccept: "Accetta",
    accessibilityTitle: "Accessibilità",
    fontIncrease: "Aumenta la dimensione del carattere",
    fontDecrease: "Diminuisci la dimensione del carattere",
    greyscale: "Scala di grigi",
    highContrast: "Alto contrasto",
    negativeContrast: "Contrasto negativo",
    lightBackground: "Sfondo chiaro",
    linksUnderline: "Sottolinea i link",
    readableFont: "Carattere leggibile",
    reset: "Ripristina",
    paymentOptions: "Opzioni di pagamento",
    deliveryOptions: "Opzioni di consegna",
    openingTime: "Orari di apertura",
    loginRegister: "Login/Registrazione",
    messagingTitle: "Supporto",
    messagingSubTitle: "Invia un messaggio con le tue domande!",
    message: "Messaggio",
    installBringFood: "Inizia con BrigFood!",
    useful: "Utente",
    company: "Azienda",
    faq: "Domande frequenti",
    chatPlaceholder: "Inserisci il testo...",
    restaurantClosed: "Il locale è chiuso in questo momento e non possiamo preparare il tuo ordine",
    holidayText: "Il locale rimarrà chiuso oggi",
    allDay: "Tutto il giorno",
    to: "A"
}