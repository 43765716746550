export const contactDE = {
    mustHaveChar: "Muss zwei oder mehr Zeichen enthalten",
    less255: "Muss kürzer als 255 Zeichen sein",
    required: "Erforderlich",
    invalidEmail: "Ungültige E-Mail",
    invalidPhone: "Ungültige Telefonnummer",
    textareaMax: "Muss kürzer als 1000 Zeichen sein",
    contactForm : "Kontaktformular",
    firstName : "Vorname",
    lastName : "NachnameNachname",
    phone : "Telefon",
    email : "Email",
    text : "Textnachricht",
    buttonForm : "Senden",
    emailSent: "E-Mail-gesendet!",
    emailSentMessage: "Sie haben Ihre E-Mail erfolgreich gesendet!",
    emailError: "E-Mail-Fehler!",
    emailErrorMessage: "Ihre E-Mail wurde nicht gesendet!"
}