import React from 'react';
import MenuComponent from './menuComponent';
import { inject, observer } from "mobx-react";
import { CircularProgress } from '@mui/material';
import { useNavigate } from "react-router-dom";
import {API_URL} from "../assets/globalConstants";

const CategoriesBar = ({feathersStore})=> {

  let navigate = useNavigate();

  const goToSubOrProduct = item => () => { 
    const payload =  { 
      categoryId: item.id,   
      item: item,  
      title: item.name?.trim(), 
      titleTranslated: feathersStore._translateCategoryName(item)?.trim(),
      searchText: ""
    }; 
    feathersStore.setSearchCategory(payload);      
    navigate(`/Home/${feathersStore._translate(payload.title, payload?.titleTranslated)}`);
  }
  
  const renderCategoryItem = ( item, index ) => (     
    <div 
      onClick={goToSubOrProduct(item)}
      style={{textDecoration: 'none'}} 
      key={item?.id}
    >
      {item.options?.eshop &&
        <MenuComponent 
          key={item?.id}
          name={feathersStore._translateCategoryName(item)} 
          image={item?.options?.href ? (`${API_URL}/images/` + item?.options?.href) : require("../img/200_spinner.gif")}
        />
      }
      
    </div> 
  );
 

  return (  
    feathersStore?.isAuthenticated && feathersStore?.categories ?  
    <div  className="mainMenu">
        {feathersStore.categories.map(renderCategoryItem)} 
    </div>
    :
    <CircularProgress 
      color="secondary" 
      style={{ margin: 6 }} 
      size={'4rem'}            
    />
  );
  
}

export default inject('feathersStore')(observer(CategoriesBar));
