import React from 'react';
import { inject, observer } from "mobx-react";

const DeliveryOptionsTextSpanish = ({feathersStore})=> {
  return(
    <div className="terms-text">
       <h2>Entrega y envío</h2>
       <p>
        En nuestra tienda, hacemos todo lo posible para atender a nuestros clientes. Puede realizar pedidos de forma remota, ya sea por teléfono o a través de nuestro sitio web. A continuación se presentan las dos formas más frecuentes de enviar y recibir nuestros productos.
        </p>
        <h2>Recogida en nuestra tienda</h2>
        <p>
          Puede recoger sus compras en nuestra tienda en la calle {feathersStore.settings?.address}.
          </p>
          <h2>Entrega a su ubicación</h2>
          <p>
            Nos comprometemos a llevar su pedido al lugar que ha indicado.
            </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(DeliveryOptionsTextSpanish));