import React, {useState} from 'react';
import { 
  FormControl,
  FormHelperText,
  Input, 
  Button,
  Modal
} from '@mui/material';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import Utils from '../Utils';
import Box from '@mui/material/Box';

// Translations
import _useTranslate from '../hooks/_useTranslate';

let emailElement;

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 500,  
  },
  height: 500,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'},  
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4
};

const ResetPassword = ({ visible, close, feathersStore })=> {

  let common = _useTranslate(feathersStore.language, "common"); 

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loading, setLoading] = useState(false); 
 

  const emailChange = event => {    
    setEmail(event.target.value);
    emailValidation(event.target.value);   
  };

  const emailValidation = val => {
    if (!Utils.validateEmail(val) ) {
     setEmailError(true);
   }else{
     setEmailError(false);
   }
  } 

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
    email && !emailError && await resetPassword();
    }
  }

  const resetPassword = async() => {  
    setLoading(true);   
    try{
      const userArray = await feathersStore.getUserByEmail(email);
     if(userArray?.data?.length > 0){
       const { _id, email, firstname, lastname } = userArray?.data[0];
       await feathersStore.sendEmail( _id, email, firstname, lastname );
       setLoading(false); 
       close();
     }else{
      setLoading(false);
      alert("Error: " + common.noSuchUser)
     }
   }catch(error){
    setLoading(false);
    alert("Error: " + error.message)
   }  
   
  };  

 
    
  return(
    <React.Fragment>
      <Modal 
        open={visible} 
        onClose={close}
        aria-labelledby="change-password-modal"
        aria-describedby="change-password"
      >
      <Box sx={style}>
        <div className="resetPasswordModal">
          <h3>{common.resetPassword}</h3>
          <div className="passwordPic"/>   
          <p style={{color: AppColors.secondary}}>{common.resetPasswordInfoA}<br/>{common.resetPasswordInfoB}</p>
          <FormControl style={{width: '80%'}}>
            <Input
              value={email} 
              autoFocus    
              error={emailError}
              inputRef={r => {
                emailElement = r;
              }}
              aria-label='email'
              onChange={emailChange}              
              onKeyPress={submitEditing}
              placeholder="E-mail"
            />
            {emailError && <FormHelperText id="component-error-text" >{common.emailError}</FormHelperText>}
          </FormControl>
          <GreenSubmitButton 
            aria-labelledby='reset password'
            title={common.resetPassword} 
            onClick={resetPassword}
            loading={loading}
            disabled = {!email || emailError}
          />
          <Button 
          aria-labelledby='Exit'
            style={{color: AppColors.red, fontSize: 18}} 
            onClick={close}
          >{common.exit}</Button>
        </div>
        </Box>
      </Modal>
      </React.Fragment>
  )
}

export default inject('feathersStore')(observer(ResetPassword));
