import React, { useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import CookieIcon from '@mui/icons-material/Cookie';
import { useNavigate } from 'react-router-dom';
import LanguageButton from '../buttons/languageButton'

import { inject, observer } from "mobx-react";

  
  const greekText = {
    privacyPolicy: "Διαχείριση",    
    cookiesTitle: "Διαχείριση cookies",
    cookiesText: "Χρησιμοποιούμε μόνο τα απολύτως απαραίτητα cookies. Σε κανένα από αυτά δεν αποθηκεύονται τα προσωπικά σας δεδομένα.",
    cookiesAccept: "Αποδοχή"
  }

  const englishText ={
    privacyPolicy: "Usage",    
    cookiesTitle: "Cookies usage",
    cookiesText: "We only use the minimum required cookies. Your personal data are not stored in any of them.",
    cookiesAccept: "Accept"  
  }
   
const CookiesModal = ({ navigation, feathersStore }) => {

  const redColored = {color: "hsl(358, 78%, 50%)", fontSize: 35, marginRight: 1};

  let navigate = useNavigate(); 

  useEffect(() => {
    if(feathersStore.showCookies){
      const showCookies = localStorage.getItem('show_cookies');
      showCookies === "false" && feathersStore.setShowCookies(false);      
    }    
  }, [feathersStore.showCookies])

  const okCookies = () => {
    feathersStore.setShowCookies(false)
    localStorage.setItem('show_cookies', 'false');    
  }

  const goToPrivacyPolicy = () => {
    feathersStore.setShowCookies(false);
    navigate("/privacypolicy")
  } 

  return (
    <div className="cookie-alert-container"> 
      <CSSTransition
        in={feathersStore.showCookies}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      > 
        <div className="cookie-alert">      
          <div className="alert-title-text">
            <CookieIcon sx={redColored}/>
            {feathersStore.language === "en" ? englishText.cookiesTitle : greekText.cookiesTitle}
          </div>  
          <div className="cookie-body-container">
            <div className="cookie-body-text">
              {feathersStore.language === "en" ? englishText.cookiesText : greekText.cookiesText}
            </div>    
            <div className="cookie-buttons-container">       
              <div className="cookie-buttons">             
                <button className="cookie-button" onClick={okCookies} color="secondary">
                  {feathersStore.language === "en" ? englishText.cookiesAccept : greekText.cookiesAccept}
                </button> 
                <button onClick={goToPrivacyPolicy} className="cookie-button cookie-privacy-button">              
                  {feathersStore.language === "en" ? englishText.privacyPolicy : greekText.privacyPolicy}
                </button>              
              </div>
            </div>
          </div> 
          <div className="cookie-language-button">
            <LanguageButton showCaption={false}/>
          </div>         
        </div>   
      </CSSTransition>
    </div>
  );
}

export default inject('feathersStore')(observer(CookiesModal));
