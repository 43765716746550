export const cartDE = {
    cart : "Warenkorb",
    couponCode : "Gutscheincode",
    couponButton : "Gutschein verwenden",
    cartTotals : "Gesamtsummen",
    completeOrder : "Zur Kasse gehen",    
    continueShopping : "WEITER BESTELLEN",   
    without: "Ohne",
    select: "AUSWÄHLEN",
    selectSmall: "Auswählen",
    addToCart: "Zum Warenkorb hinzugefügt",
    cartItems: "Warenkorbartikel",
    couponDiscount: "Coupon-Rabatt",
    subTotal: "Zwischensumme",
    couponCaption: "Haben Sie einen Coupon?",
    new: "NEU",
    sale: "VERKAUF",
    soldOut: "AUSVERKAUFT",
    couverCaption: "Anzahl der Personen",
    couverPlaceholder: "Nummer eingeben",
    couverError: "Bitte geben Sie die Anzahl der Personen ein und drücken Sie auf Prüfen"
}
