export const commonIT = {
    firstName: "Nome",
    lastName: "Cognome",
    street: "Via",
    streetNumber: "Numero civico",
    city: "Città",
    origin: "Regione",
    postcode: "CAP",
    phone: "Numero di telefono",
    apartment: "Appartamento",
    email: "E-mail",
    emailMessage: "Non condivideremo mai la tua email con nessun altro",
    checkout: "Concludi l'ordine",
    streetAndNumber: "Via e numero civico",
    privacyStatement: "Informativa sulla privacy",
    termsOfService: "Termini di servizio",
    termsOfServiceNoun: "Termini di servizio",
    aboutUs: "Chi siamo",
    product: "Prodotto",
    products: "Prodotti",
    subTotal: "Subtotale",
    transportation: "Trasporto",
    total: "Totale",
    price: "Prezzo",
    quantity: "Quantità",
    items: "Articoli",
    firstNameError: "Inserisci un nome valido",
    lastNameError: "Inserisci un cognome valido",
    phoneError: "Inserisci un numero di telefono valido",
    emailError: "Inserisci un indirizzo email valido",
    save: "SALVA",
    updateSuccess: "L'aggiornamento è avvenuto con successo",
    updateError: "Errore durante l'aggiornamento",
    updated: "Aggiornato!",
    cartUpdated: "Hai aggiornato con successo il tuo carrello",
    wishlistUpdated: "Hai aggiornato con successo la tua lista dei desideri",
    streetError: "Inserisci una via valida",
    streetNumberError: "Inserisci un numero civico valido",
    cityError: "Inserisci una città valida",
    order: "Ordine",
    logout: "Esci",
    warning: "ATTENZIONE",
    logoutWarning: "Sei sicuro di voler uscire?",
    yes: "SÌ",
    no: "NO",
    loginRegistration: "Accedi/Registrati",
    login: "ACCEDI",
    passwordHelperText: "almeno 8 caratteri, inclusi numeri, maiuscole e minuscole",
    forgotPassword: "Password dimenticata?",
    or: "oppure",
    facebookLogin: "ACCEDI CON FACEBOOK",
    googleLogin: "ACCEDI CON GOOGLE",
    register: "Registrazione",
    registerButton: "NUOVO CLIENTE",
    exit: "ESCI",
    resetPassword: "Reimposta password",
    resetPasswordInfoA: "Per favore inserisci la tua email",
    resetPasswordInfoB: "e riceverai una nuova password.",
    registerA: "REGISTRATI",
    registerInfoA: "Con la mia registrazione, accetto i",
    registerInfoB: "termini di servizio generali",
    registerInfoC: "l'",
    registerInfoD: "informativa sulla privacy",
    registerInfoE: "e i",
    registerInfoF: "termini di servizio delle offerte",
    verificationCode: "Codice di conferma",
    verificationInfo: "Inserisci il codice di 4 cifre inviato a:",
    enterCode: "Inserisci il codice",
    errorCode: "Codice di verifica errato, riprova",
    repeat: "RINVIA",
    youSee: "Vedi",
    from: "da",
    stockWarning: "Sfortunatamente il prodotto che hai scelto è esaurito!",
    leaveAComment: "Lascia un commento...",
    saveReview: "Salva la recensione",
    reviews: "Recensioni",
    qrCodeBuy: "Acquista rapidamente e facilmente con un clic dal tuo cellulare!",
    qrCodeScanAndroid: "Scansiona per scaricare la nostra app per Android",
    qrCodeDownload: "Scarica la nostra app su Android",
    qrCodeBuyMobile: "Sia per Android che iOS, scarica la nostra app per ristoranti e ordina in qualsiasi momento dal tuo cellulare!",
    qrCodeScanIos: "Scansiona per scaricare la nostra app per iOS",
    qrCodeForAndroid: "Per Android",
    qrCodeForIos: "Per iOS",
    photoUpload: "Carica foto",
    edit: "MODIFICA",
    delete: "ELIMINA",
    addCart: "Aggiungi al carrello",
    noEvaluation: "Nessuna recensione ancora.",
    onlyLoggedInCustomers: "Solo i clienti registrati che hanno acquistato il prodotto possono scrivere una recensione.",
    beTheFirstReview: "Sii il primo a scrivere una recensione per questo prodotto.",
    seeMoreProducts: "Vedi altri prodotti",
    favorite: "PREFERITI",
    favorites: "Preferiti",
    noFavoritesYet: "Non hai ancora prodotti preferiti",
    deleteProduct: "Sei sicuro di voler rimuovere questo prodotto?",
    deleteProduct1: "Sei sicuro di voler rimuovere questo prodotto",
    fromCart: "dal carrello?",
    deleteAddress: "Sei sicuro di voler eliminare l'indirizzo?",
    danger: "ATTENZIONE",
    connectionIssue: "Attenzione! L'invio dell'ordine non è stato completato con successo. Per favore controlla la tua connessione.",
    sendOrder: "Invia ordine",
    pleaseWait: "Attendere prego...",
    noMoreProductsInStock: "Non puoi aggiungere al carrello più prodotti di quanti ne siano disponibili in magazzino",
    productAddedCart: "Prodotto aggiunto al carrello!",
    home: "Casa",
    myAccount: "Il mio Account",
    ckeckout: "CONCLUDI L'ORDINE",
    all: "Tutto",
    signIn: "Accedi",
    search: "Cerca",
    productSKU: "SKU",
    productSKUFull: "SKU del prodotto:",
    language: "Lingua",
    size: "TAGLIA",
    extra: "Extra",
    removeIngredients: "Rimuovi ingredienti",
    status: "Stato",
    searchResults: "Risultati della ricerca",
    noResults: "NESSUN RISULTATO",
    dailyProducts: "Prodotti del giorno",
    offersText: "Offerte del giorno",
    popularText: "Les plus populaires",
    productComments: "Inserisci un commento o una richiesta speciale (Opzionale)",
    weighted: "Prodotto pesato",
    kgr: "Prezzo al chilo",
    without: "SENZA",
    noSuchUser: "Nessun utente con questa email esiste!",
    reset: "Reimposta.",
    lostPassword: "Hai dimenticato la tua password?",
    loginOr: "o accedi con",
    here: "qui",
    orRegister: "Non hai un account? Registrati",
    stockWarningHeader: "Avviso di esaurimento scorte",
    cartUpdatedAlert: "Carrello aggiornato",
    wishlistUpdatedAlert: "Lista dei desideri aggiornata",
    watchThis: "Guarda queste offerte!",
    pickSize: "Scegli una taglia",
    addExtra: "Aggiungi ingredienti extra",
    logoutAccount: "Esci dall'account",
    deleteAccount: "Elimina account",
    deleteAccountMessage: "Vuoi davvero eliminare il tuo account?",
    yesSmall: "Sì",
    noSmall: "No",
    show: "Mostra Carrello",
    removeItem: "Rimuovi Articolo",
    youSure: "Sei sicuro?",
    cancel: "Annulla",
    scannerError: "Errore durante la scansione. Per favore riprova.",
    scannerSuccess: "Benvenuto",
    scannerMessage: "Continua come ospite al tuo tavolo",
    companyOrigin: "Regione principale",
    addNewAddress: "Aggiungi un nuovo indirizzo",
    holidayText: "Oggi i locali rimarranno chiusi",
    minimumOrderHeader: "Ordine Minimo",
    minimumOrder: "L'ordine minimo è",
    duplicateEmail: "Questa email viene utilizzata da un altro account. Prova invece ad accedere.",
    appleId: "Questa email è stata utilizzata per l'accesso con Apple",
    googleId: "Questa email è stata utilizzata per l'accesso con Google",
    facebookId: "Questa e-mail è stata utilizzata per l'accesso a Facebook",
    emailPassword: "Questa e-mail è stata utilizzata per l'accesso con e-mail e password",
    loginError: "Errore di accesso: ",
    discount: "Sconto",
    to: "Aperto a",
    openingTime: "Orari di apertura",
    more: "Più",
    lastAddress: "Impossibile eliminare l'ultimo indirizzo",
    updateFailure: "Impossibile aggiornare",
    succesDeleteAddress: "L'indirizzo è stato eliminato con successo.",
    noCategoryItemsYet: "Non ci sono prodotti attivi in questa categoria.",
    reserveTable: "Prenota un tavola",
    reserveTableUC: "Prenota un tavola",
    yourReservation: "La tua prenotazione",    
    chooseHour: "Scegli l'ora",
    bookingComment: "Aggiungi richiesta speciale (facoltativo)",
    party: "Persone",
    pickDate: "Scegli una data",
    partyOf: "Persone ",
    date: "Data",
    hour: "Tempo",
    customerDiscount: "Sconto cliente",
    couponDiscount: "Buono sconto",
    voucherTitle: "Hai vinto un buono di valore ",
    applyVoucher: "Applica questo coupon al tuo carrello.",
    copy: "Copia",
    minimumVoucherOrder: "Ordine minimo",
    minimumVoucherCaption: "L'ordine minimo richiesto per applicare lo sconto è",
    loyaltyTitle: "Premi fedeltà",
    loyaltyCaption: "Hai vinto premi fedeltà per un valore totale",
    loyaltySubCaption: "Verranno scontati automaticamente durante il checkout.",
    loyaltyMoto:  "Continua a ordinare per vincere sconti",
    endsIn: "Finisce dentro",
    days: "giorni",
    table: "TAVOLO",
    comments: "COMMENTI",
    dateCut: "Data",
    newOrder: "NUOVO ORDINE",
    num: "N/S",
    extras: "EXTRA",
    tableOrder : "TAVOLO",
    phoneDelivery : "DELIVERY TELEFONICA",
    phoneTakeAway : "TAKE-AWAY TELEFONICA",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR TAVOLO",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR TAVOLO",
}   