export const CarouselData = [
  {  
    text1: "carouselHeader1",
    text1EN: "carouselHeader1English",
    text1Trans: "carouselHeader1Translations",
    text2: "carouselText1",
    text2EN: "carouselText1English",
    text2Trans: "carouselText1Translations",
    carouselLink: "carouselLink1"
  },
  {
    text1: "carouselHeader2",
    text1EN: "carouselHeader2English",
    text1Trans: "carouselHeader2Translations",
    text2: "carouselText2",
    text2EN: "carouselText2English",
    text2Trans: "carouselText2Translations",
    carouselLink: "carouselLink2"
  },
  {
    text1: "carouselHeader3",
    text1EN: "carouselHeader3English",
    text1Trans: "carouselHeader3Translations",
    text2: "carouselText3",
    text2EN: "carouselText3English",
    text2Trans: "carouselText3Translations",
    carouselLink: "carouselLink3"
  },
]