import React from 'react';

const SizeButton= ({className, onClick, title })=> {

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  return (
    <button 
      onMouseOver={changeCursor} 
      className={className}
      onClick={onClick}
    >
      {title}
    </button>
  );
  
}

export default SizeButton;
