export const commonES = {
    firstName: "Nombre",
    lastName: "Apellido",
    street: "Calle",
    streetNumber: "Número",
    city: "Ciudad",
    origin: "Región",
    postcode: "Código postal",
    phone: "Número de teléfono",
    apartment: "Apartamento",
    email: "Correo electrónico",
    emailMessage: "Nunca compartiremos su correo electrónico con nadie más",
    checkout: "Pagar",
    streetAndNumber: "Calle y número",
    privacyStatement: "Declaración de privacidad",
    termsOfService: "Términos de servicio",
    termsOfServiceNoun: "Términos de servicio",
    aboutUs: "Acerca de nosotros",
    product: "Producto",
    products: "Productos",
    subTotal: "Subtotal",
    transportation: "Transporte",
    total: "Total",
    price: "Precio",
    quantity: "Cantidad",
    items: "Artículos",
    firstNameError: "Por favor, ingrese un nombre",
    lastNameError: "Por favor, ingrese un apellido",
    phoneError: "Por favor, ingrese un número de teléfono válido",
    emailError: "Por favor, ingrese un correo electrónico válido",
    save: "GUARDAR",
    updateSuccess: "La actualización fue exitosa",
    updateError: "Error durante la actualización",
    updated: "¡Actualizado!",
    cartUpdated: "¡Actualizó con éxito su carrito de compras!",
    wishlistUpdated: "¡Actualizó con éxito su lista de deseos!",
    streetError: "Por favor, ingrese una calle",
    streetNumberError: "Por favor, ingrese un número de calle",
    cityError: "Por favor, ingrese una ciudad",
    order: "Pedido",
    logout: "Cerrar sesión",
    warning: "ADVERTENCIA",
    logoutWarning: "¿Está seguro de que desea cerrar sesión?",
    yes: "SÍ",
    no: "NO",
    loginRegistration: "Iniciar sesión/Registrarse",
    login: "INICIAR SESIÓN",
    passwordHelperText: "al menos 8 caracteres, incluyendo números, mayúsculas y minúsculas",
    forgotPassword: "¿Olvidó su contraseña?",
    or: "o",
    facebookLogin: "INICIAR SESIÓN CON FACEBOOK",
    googleLogin: "INICIAR SESIÓN CON GOOGLE",
    register: "Registro",
    registerButton: "NUEVO CLIENTE",
    exit: "SALIR",
    resetPassword: "Restablecer contraseña",
    resetPasswordInfoA: "Por favor, ingrese su correo electrónico",
    resetPasswordInfoB: "y recibirá una nueva contraseña.",
    registerA: "REGISTRO",
    registerInfoA: "Con mi registro, acepto los",
    registerInfoB: "términos generales de servicio",
    registerInfoC: "la",
    registerInfoD: "declaración de privacidad",
    registerInfoE: "y los",
    registerInfoF: "términos de servicio de las ofertas",
    verificationCode: "Código de confirmación",
    verificationInfo: "Por favor, ingrese el código de 4 dígitos que se envió a:",
    enterCode: "Ingresar código",
    errorCode: "Código de verificación incorrecto. Por favor, inténtelo de nuevo.",
    repeat: "REENVIAR",
    youSee: "Usted ve",
    from: "desde",
    stockWarning: "Desafortunadamente, el producto que ha elegido está agotado",
    leaveAComment: "Deja un comentario...",
    saveReview: "Guardar la reseña",
    reviews: "Comentarios",
    qrCodeBuy: "¡Compre rápido y fácil con un clic desde su móvil!",
    qrCodeScanAndroid: "Escanee para descargar nuestra aplicación para Android",
    qrCodeDownload: "Descargue nuestra aplicación en Android",
    qrCodeBuyMobile: "Tanto para Android como para iOS, descargue nuestra aplicación de restaurante y ordene en cualquier momento desde su móvil.",
    qrCodeScanIos: "Escanee para descargar nuestra aplicación para iOS",
    qrCodeForAndroid: "Para Android",
    qrCodeForIos: "Para iOS",
    photoUpload: "Carga de foto",
    edit: "EDITAR",
    delete: "ELIMINAR",
    addCart: "Agregar al carrito",
    noEvaluation: "Todavía no hay evaluaciones.",
    onlyLoggedInCustomers: "Solo los clientes que hayan comprado el producto pueden escribir una reseña.",
    beTheFirstReview: "Sé el primero en escribir una reseña de este producto.",
    seeMoreProducts: "Ver más productos",
    favorite: "FAVORITOS",
    favorites: "Favoritos",
    noFavoritesYet: "Todavía no tiene productos favoritos",
    deleteProduct: "¿Está seguro de que desea eliminar este producto?",
    deleteProduct1: "¿Está seguro de que desea eliminar este producto",
    fromCart: "del carrito?",
    deleteAddress: "¿Está seguro de que desea eliminar la dirección?",
    danger: "ADVERTENCIA",
    connectionIssue: "¡Cuidado! El envío del pedido no se realizó con éxito. Por favor, verifique su conexión.",
    sendOrder: "Enviar pedido",
    pleaseWait: "Espere por favor...",
    noMoreProductsInStock: "No puede agregar más productos a su carrito que los disponibles en stock",
    productAddedCart: "¡Producto añadido a su carrito!",
    home: "Inicio",
    myAccount: "MI CUENTA",
    ckeckout: "PAGAR",
    all: "TODO",
    signIn: "Iniciar sesión",
    search: "Buscar",
    productSKU: "SKU",
    productSKUFull: "SKU del producto:",
    language: "Idioma",
    size: "TAMAÑO",
    extra: "Extra",
    removeIngredients: "Quitar ingredientes",
    status: "Estado",
    searchResults: "Resultados de la búsqueda",
    noResults: "SIN RESULTADOS",
    dailyProducts: "Productos diarios",
    offersText: "Ofertas diarias",
    popularText: "Lo más popular",
    productComments: "Ingrese un comentario o una necesidad especial (Opcional)",
    weighted: "Producto ponderado",
    kgr: "Precio por kg",
    without: "SIN",
    noSuchUser: "No existe ningún usuario con este correo electrónico.",
    reset: "Restablecer.",
    lostPassword: "¿Olvidó su contraseña?",
    loginOr: "o inicie sesión con",
    here: "aquí",
    orRegister: "¿No tiene una cuenta? Regístrese",
    stockWarningHeader: "Advertencia de stock",
    cartUpdatedAlert: "Carrito actualizado",
    wishlistUpdatedAlert: "Lista de deseos actualizada",
    watchThis: "¡Mira esto!",
    pickSize: "Elige un tamaño",
    addExtra: "Añadir ingredientes adicionales",
    logoutAccount: "Cerrar sesión",
    deleteAccount: "Borrar cuenta",
    deleteAccountMessage: "¿Realmente desea borrar su cuenta?",
    yesSmall: "Sí",
    noSmall: "No",
    show: "Mostrar carrito",
    removeItem: "Eliminar artículo",
    youSure: "¿Está seguro?",
    cancel: "Cancelar",
    scannerError: "Error al escanear. Por favor, inténtelo de nuevo.",
    scannerSuccess: "Bienvenido",
    scannerMessage: "Continúe como invitado en su mesa",
    companyOrigin: "Región principal",
    addNewAddress: "Agregar una nueva dirección",
    holidayText: "Las instalaciones permanecerán cerradas hoy",
    minimumOrderHeader: "Pedido mínimo",
    minimumOrder: "El pedido mínimo es",
    duplicateEmail: "Este correo electrónico está siendo utilizado por otra cuenta. Por favor, intente iniciar sesión en su lugar.",
    appleId: "Este correo electrónico se ha utilizado para iniciar sesión con Apple",
    googleId: "Este correo electrónico se ha utilizado para iniciar sesión con Google",
    facebookId: "Este correo electrónico se ha utilizado para iniciar sesión en Facebook",
    emailPassword: "Este correo electrónico se ha utilizado para iniciar sesión con correo electrónico y contraseña",
    loginError: "Error de inicio de sesión: ",
    discount: "Descuento",
    to: "Abierto a",
    openingTime: "Horario de apertura",
    more: "Más",
    lastAddress: "No se puede eliminar la última dirección",
    updateFailure: "No se pudo actualizar",
    succesDeleteAddress: "La dirección se eliminó correctamente.",
    noCategoryItemsYet: "No hay productos activos en esta categoría.",
    reserveTable: "Reservar mesa",
    reserveTableUC: "Reservar mesa",
    yourReservation: "Su reserva",
    chooseHour: "Elegir hora",
    bookingComment: "Añadir petición especial (opcional)",
    party: "Personas",
    pickDate: "Escoge una fecha",
    partyOf: "Personas ",
    date: "Fecha",
    hour: "Tiempo",
    customerDiscount: "Descuento al cliente",
    couponDiscount: "Cupón de descuento",
    voucherTitle: "Has ganado un cupón de valor ",
    applyVoucher: "Aplica este cupón en tu carrito de compras.",
    copy: "Copiar",
    minimumVoucherOrder: "Pedido mínimo",
    minimumVoucherCaption: "El pedido mínimo requerido para aplicar su descuento es",
    loyaltyTitle: "Recompensas de fidelidad",
    loyaltyCaption: "Has ganado recompensas de fidelidad de valor total",
    loyaltySubCaption: "Se descontarán automáticamente durante su pago.",
    loyaltyMoto:  "Sigue ordenando para ganar descuentos",
    endsIn: "Termina en",
    days: "días",
    table: "MESA",
    comments: "COMENTARIOS",
    dateCut: "Fecha",
    newOrder: "NUEVO PEDIDO",
    num: "S/N",
    extras: "EXTRAS",
    tableOrder : "MESA",
    phoneDelivery : "DELIVERY TELEFÓNICA",
    phoneTakeAway : "TAKE-AWAY TELEFÓNICA",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR MESA",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR MESA",
}