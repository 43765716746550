import React from 'react'
import CancelPresentation from '@mui/icons-material/CancelPresentation';
import AppColors from '../assets/colors'

const CloseButton = (props)=> {

    function changeCursor(e) {
        e.target.style.cursor = 'pointer';
      }

    return(
        <button 
        className="closeButton"
        onMouseOver={changeCursor}
        onClick={props.onClick}>
            <CancelPresentation style={{fontSize: 40, color: AppColors.red}}/>
        </button>
    )
}

export default CloseButton;