import React,{useState, useEffect} from 'react';
import ProductDetailCard from '../cards/productDetailCard';
import SizeButton from '../buttons/sizeButton';
import AppColors from '../assets/colors';
import {API_URL, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";
import { inject, observer } from "mobx-react";
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import SmallAlert from "../modals/smallAlert";
import * as cloneDeep from 'lodash/cloneDeep';

import _useTranslate from '../hooks/_useTranslate';

let initialCartBiggestId = 0;
let cart = [];

const Product = ({ feathersStore })=> {

  let navigate = useNavigate();
  let location = useLocation();
  const {name} = useParams();
  
  let paramProduct;  
    
  const otherData = {
    quantity: 1,
    sizePrice: 0,
    total: 0,   
    extraSum: 0,
    extraItems: [],
    ingredientItems: [],
    attributeItems: [],
    comments: ""
  }  
  
  const [product, setProduct] = useState({});  
  const [sizes, setSizes] = useState([]);
  const [favorite, setFavorite] = useState(false);      
  const [features, setFeatures] = useState([]);     
  const [ingredients, setIngredients] = useState([]);
  const [attributes, setAttributes] = useState([]);
  const [comments, setComments] = useState("");  
  const [warning, setWarning] = useState(false);
  const [step, setStep] = useState({}); 
  const [reviews, setReviews] = useState([]); 
  const [averageStars, setAverageStars] = useState(null);   
      
  //Information Modal controls
  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);

  //Translation Hook  
  let common = _useTranslate(feathersStore.language, "common");
  
  useEffect(() => {
    if(localStorage.getItem('fdwa_cart')){
      cart = JSON.parse(localStorage.getItem('fdwa_cart'));    
      if(cart.length > 0)
        initialCartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }
  }, [paramProduct]);
  
  useEffect(() => {
    feathersStore.isAuthenticated && initProduct();  
  }, [ feathersStore.isAuthenticated, location?.state?.payload, 
    feathersStore?.favoritesArray, feathersStore.productUpdatedEvent, name]);
    
  const initProduct = async() => {
    if(product?._id && !product.fromCart && feathersStore.productUpdatedEvent._id === product._id){
      paramProduct = feathersStore.productUpdatedEvent
    }else if(!product?._id && location?.state?.payload) {
      paramProduct = location.state.payload;
    }else{  //USAGE: for reloads
      const products = await feathersStore.products(name);
      if(products)paramProduct = products[0];   
    }     
    otherData.total = +paramProduct[packagePricing() && paramProduct?.packagePrice ? 'packagePrice' : 'retailPrice'];   
    !paramProduct.fromCart && Object.assign(paramProduct, otherData);
    setSizes([...paramProduct.sizes]);       
    checkFavorite();  
    initFeatures();
    initIngredients(); 
    setComments(paramProduct?.comments || "");      
    paramProduct?.savedAttributesTables && initAttributes();
    paramProduct.weighted && initWeighted();
    paramProduct.fromCart && Object.assign(paramProduct, {originalQuantity: paramProduct.quantity}) //use it to check stock
    paramProduct.fromCart && (paramProduct.weightedQuantity > 0) && Object.assign(paramProduct, {originalWeightedQuantity: paramProduct.weightedQuantity}) //use it to check stock
    setProduct(paramProduct); 
    setReviews(paramProduct?.commentsList || []);
    setAverageStars(paramProduct?.starAverage);
  }

  const initFeatures = () => { 

    paramProduct.features.forEach(feature => Object.assign(feature, {picked: false}));
    const check = paramProduct.extraItems?.map(e=> e.name) || [];           
    paramProduct.features.filter(feature => check.includes(feature.name))
            .map(ff => ff.picked = true);     
    setFeatures([...paramProduct.features?.filter(f => f.featuresEnabled)]);   
     
  }

  const initIngredients = () => {

    paramProduct.ingredients.forEach(ingredient => Object.assign(ingredient, {picked: false}));
    const check = paramProduct.ingredientItems?.map(e=> e.name) || [];   
    paramProduct.ingredients.filter(ingredient => check.includes(ingredient.name))
                              .map(ff => ff.picked = true);  
    setIngredients([...paramProduct.ingredients?.filter(f => f.ingredientEnabled)]); 

  }

  const initAttributes = () => { 
    paramProduct.savedAttributesTables.forEach( table => {
      if(table?.quantityEnabled){table.quantity = 0};
      table.savedAttributesArray = table.savedAttributesArray.reduce((p,c) => (c.active && p.push(c),p),[]);
      if(table.savedAttributesArray?.length > 0){ 
        table.savedAttributesArray.forEach(attribute => Object.assign(attribute, 
          {title: table.title, picked: false}));
        const check = paramProduct.attributeItems?.filter(e=> e.title === table.title).map(a => a.name) || [];     
        table.savedAttributesArray.filter(attr => check.includes(attr.name))
        .map(ff => {
          ff.picked = true; 
          if(table?.quantityEnabled){         
            const attr = this.product.attributeItems?.find(e=> e.title === table.title);
            if(attr)table.quantity = +attr?.quantity || 0; 
          }});
        if(table?.eshop && table?.mandatory && table.savedAttributesArray.filter(attr => attr.picked)?.length < 1){
          Object.assign(table.savedAttributesArray[0], {picked: true, title: table.title}); 
          paramProduct.attributeItems.push(table.savedAttributesArray[0]);  
          paramProduct.extraSum += +table.savedAttributesArray[0][packagePricing() ? 'packagePrice' : 'price'];  
          paramProduct.total +=  +table.savedAttributesArray[0][packagePricing() ? 'packagePrice' : 'price'];        
        }
      }       
    })   
    setAttributes([...paramProduct.savedAttributesTables]);     
  }

  const initWeighted = () => {   
    setStep(feathersStore.settings.steps?.find(s => +s.id === +paramProduct.step));   
    if(!paramProduct.weightedPrice){
      const size = paramProduct.sizes.find(s => s.default);
      paramProduct.weightedPrice = size ?  size[packagePricing() ? 'packagePrice' : 'price']
        : paramProduct[packagePricing() ? 'packagePrice' : 'retailPrice']  ;
      paramProduct.weightedQuantity = 0;
      paramProduct.total = 0;
      paramProduct.retailPrice = 0;
      paramProduct.packagePrice = 0;
    }   
  }

  const register_product = ()=> {
    let cartItem;
    let pickedSize = sizes.filter(size => size.default)[0];  
    pickedSize && Object.assign(pickedSize, {nameTranslated: feathersStore._translateNameAttr(pickedSize)});
  
    if(product.fromCart == true){      
      cartItem = cart[product.cartIndex];
      cartItem.product_size = pickedSize;    
      cartItem.product_price = product[packagePricing() ? 'packagePrice' : 'retailPrice'];
      cartItem.product_totalPrice = product.total;
      cartItem.product_quantity = product.quantity;
      cartItem.product_extraSum = product.extraSum;
      cartItem.features = product.extraItems;
      cartItem.ingredients = product.ingredientItems;      
      cartItem.product_comments = comments;
      cartItem.attributes = product?.attributeItems || [];
      cartItem.product_weightedPrice = +product?.weightedPrice || 0;
      cartItem.product_weightedQuantity = +product?.weightedQuantity || 0;      
      cartItem.nameTranslations = product?.nameTranslations;
      cartItem.shortDescriptionTranslations = product?.shortDescriptionTranslations;
      cart.splice(product.cartIndex, 1, cartItem);
    }else{
      cartItem = {
        id: initialCartBiggestId,
        product_id: product._id,
        product_name: product.name,
        product_nameTranslated: feathersStore._translateName(product),
        product_size: pickedSize,
        product_image: `${API_URL}/images/${product?.images[0]}`,
        product_price: product[packagePricing() ? 'packagePrice' : 'retailPrice'],
        product_totalPrice: parseFloat(product.total),
        product_netPrice: calculateNetPrice(),
        product_quantity: product.quantity,
        product_extraSum: product.extraSum,
        features: product.extraItems,
        ingredients: product.ingredientItems,
        product_printers: product.printers,
        product_kdsSections: product.kdsSections,
        product_vat: product[packagePricing() && product?.packageVat ? 'packageVat' : 'vat'],
        product_stock: product.stock,
        product_handleStock: product.handleStock,
        product_comments: comments,
        attributes: product?.attributeItems || [],
        product_weightedPrice: product?.weightedPrice || 0,
        product_weightedQuantity: product?.weightedQuantity || 0,
        nameTranslations: product?.nameTranslations,
        shortDescriptionTranslations: product?.shortDescriptionTranslations         
      }    
      cart.push(cartItem);
      feathersStore.setCartLength(feathersStore.cartLength + 1);     
    } 
    localStorage.setItem("fdwa_cart", JSON.stringify(cart)); 
    setCartIncreased(true);  
  }

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }

  const calculateNetPrice = () => {
    const label = feathersStore.settings.vats
      ?.find(v => +v.id === +product[packagePricing() && product?.packageVat ? 'packageVat' : 'vat'])?.label
    return +((product.total / product.quantity) / (1 + label / 100 )).toFixed(2);
  }

  const addToCart = () => {
    const newStock = product.fromCart ? +product.stock + +(product.originalWeightedQuantity > 0 ? product.originalQuantity * product.originalWeightedQuantity : product.originalQuantity) : product.stock;   
    if (feathersStore.checkOutOfStock(product._id, product.handleStock, newStock,  //Do not check the cart item two times
      product.quantity, cart, product.weightedQuantity)){
        setWarning(true); return;
      } 
    register_product();

    //Reset View
    initProduct();  

    product.fromCart && navigate("/cart");
    
  };

  const checkFavorite = () => {   
    if(feathersStore.favoritesArray.includes(paramProduct._id)){    
      setFavorite(true);   
    }
  }

  const onPressAddToFavorites = async () => {    
    if(feathersStore.user._id === "5e109aaccf07d534f841e677")return;
    let id = '';
    id = product._id;
    if(feathersStore.favoritesArray.includes(id)){
      const index = feathersStore.favoritesArray.indexOf(id);
      if (index > -1) {
        feathersStore.favoritesArray.splice(index, 1);
      }
      setFavorite(false);
    }else{
      feathersStore.favoritesArray.push(id);
      setFavorite(true);
    }   
    if(feathersStore.wishlistId === ""){
        const data = await feathersStore.createWishlist();        
        data && (feathersStore.wishlistId = data._id); 
    }else await feathersStore.updateWishlist();
    setFavoritesUpdated(true);
  };

  const onPressIncreaseAmount = () => {
    const clonedProduct = cloneDeep(product);    
    let { quantity } = product;
    quantity += 1;
    clonedProduct.quantity = quantity;   
    const newStock = product.fromCart ? +product.stock + +(product.originalWeightedQuantity > 0 ? product.originalQuantity * product.originalWeightedQuantity : product.originalQuantity) : product.stock;   
    if (feathersStore.checkOutOfStock(product._id, product.handleStock, newStock, //Do not check the cart item two times,
      quantity, cart, product.weightedQuantity)){
      setWarning(true); return;
    }   
    const total = clonedProduct.sizePrice == 0 || product.weighted ? 
      clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] * quantity + clonedProduct.extraSum * quantity : 
      clonedProduct.sizePrice * quantity + clonedProduct.extraSum * quantity;    
    clonedProduct.total = +total; 
    setProduct(clonedProduct);  
  };

  const onPressDecreaseAmount = () => {
    const clonedProduct = cloneDeep(product);
    let { quantity } = product;
    quantity -= 1;
    quantity = quantity < 1 ? 1 : quantity;
    clonedProduct.quantity = quantity;
       
    const totalRetail = product[packagePricing() ? 'packagePrice' : 'retailPrice'] * quantity + product.extraSum * quantity;
    const totalSizePrice = product.sizePrice * quantity + product.extraSum * quantity;

    const total = product.sizePrice == 0 || product.weighted ? totalRetail : totalSizePrice;
    clonedProduct.total = +total;   
    setProduct(clonedProduct); 
  };

   //------> Attribute table with quantityEnabled controls

   const disabledQTableQuantityButtons = (tableIndex) => {
    return product.savedAttributesTables[tableIndex]?.savedAttributesArray?.filter(attr => attr?.picked)?.length > 0 ? false : true
  }

  const onPressIncreaseAttribute = (tableIndex) => () => {
    const clonedProduct = cloneDeep(product); 
    clonedProduct.savedAttributesTables[tableIndex].quantity += 1;
    let attribute = clonedProduct.savedAttributesTables[tableIndex]?.savedAttributesArray?.filter(attr => attr?.picked)
    clonedProduct.extraSum += +attribute[0][packagePricing() ? 'packagePrice' : 'price'];
    clonedProduct.total += +attribute[0][packagePricing() ? 'packagePrice' : 'price'];
    let i = clonedProduct.attributeItems.map(f => f.title).indexOf(clonedProduct.savedAttributesTables[tableIndex].title);    
    if(i > -1){
      clonedProduct.attributeItems[i].quantity =  +clonedProduct.savedAttributesTables[tableIndex].quantity;
    }
    setProduct(clonedProduct);    
    setAttributes([...clonedProduct.savedAttributesTables]); 
  };

  const onPressDecreaseAttribute = (tableIndex) => () => {
    const clonedProduct = cloneDeep(product);
    let { quantity } =  clonedProduct.savedAttributesTables[tableIndex];
    if(quantity > 0){
      clonedProduct.savedAttributesTables[tableIndex].quantity -= 1;
      let attribute = clonedProduct.savedAttributesTables[tableIndex]?.savedAttributesArray?.filter(attr => attr?.picked);
      clonedProduct.extraSum -= +attribute[0][packagePricing() ? 'packagePrice' : 'price'];
      clonedProduct.total -= +attribute[0][packagePricing() ? 'packagePrice' : 'price'];
      if(clonedProduct.savedAttributesTables[tableIndex].quantity === 0){clonedProduct.savedAttributesTables[tableIndex].savedAttributesArray
        .filter(attr => attr?.picked).map(a => Object.assign(a, {picked: false}));
        let i = clonedProduct.attributeItems.map(f => f.title).indexOf(clonedProduct.savedAttributesTables[tableIndex].title);    
        if(i > -1){
          clonedProduct.attributeItems.splice(i,1);
        }
      }
      let i = clonedProduct.attributeItems.map(f => f.title).indexOf(clonedProduct.savedAttributesTables[tableIndex].title);    
      if(i > -1){
        clonedProduct.attributeItems[i].quantity =  +clonedProduct.savedAttributesTables[tableIndex].quantity;
      }
      setProduct(clonedProduct);  
      setAttributes([...clonedProduct.savedAttributesTables]);
    }    
  };

  //----------------->

  const onPressIncreaseWeighted = () => {   
    const clonedProduct = cloneDeep(product);
    clonedProduct.weightedQuantity = (+clonedProduct.weightedQuantity + +step.calc).toFixed(2); 
    const newStock = product.fromCart ? +product.stock + +(product.originalWeightedQuantity > 0 ? product.originalQuantity * product.originalWeightedQuantity : product.originalQuantity) : product.stock;   
    if (feathersStore.checkOutOfStock(product._id, product.handleStock, newStock, //Do not check the cart item two times,
      product.quantity, cart, clonedProduct.weightedQuantity)){
      setWarning(true); return;
    }   
    clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] = +(clonedProduct.weightedQuantity * clonedProduct.weightedPrice).toFixed(2);    
    clonedProduct.total = +((+clonedProduct.extraSum + +clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'])*clonedProduct.quantity).toFixed(2);    
    setProduct(clonedProduct); 
     
  };

  const onPressDecreaseWeighted = () => {
    if(+product.weightedQuantity === 0)return;
    const clonedProduct = cloneDeep(product);  
    clonedProduct.weightedQuantity = (clonedProduct.weightedQuantity - step.calc).toFixed(2);  
    clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] = +(clonedProduct.weightedQuantity * clonedProduct.weightedPrice).toFixed(2);
    clonedProduct.total = +((+clonedProduct.extraSum + +clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'])*clonedProduct.quantity).toFixed(2); 
    setProduct(clonedProduct); 
  };

  const transformToKgr = (weight) => {
    const grams = weight * 1000;    
    return grams + "gr"
  }

  const setServingSize = (sizeItem, index) => {
     
    const clonedProduct = cloneDeep(product);   
    const { quantity } = clonedProduct;    
    clonedProduct.sizes.map(size => size.default = false);    
    clonedProduct.sizes[index].default = true;
    clonedProduct.sizePrice = +clonedProduct.sizes[index][packagePricing() ? 'packagePrice' : 'price'];   
    const total = clonedProduct.sizePrice * +quantity + +clonedProduct.extraSum * quantity;
    clonedProduct.total = total; 
    if(product.weighted){
      clonedProduct.weightedPrice = clonedProduct.sizePrice; 
      clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] = clonedProduct.sizePrice; 
      clonedProduct.total = clonedProduct.weightedPrice * clonedProduct.weightedQuantity * quantity + clonedProduct.extraSum * quantity;   
    }    
    setSizes([...clonedProduct.sizes]);   
    setProduct(clonedProduct);

  }; 

  const setExtra = (extra, index) => {

    const clonedProduct = cloneDeep(product);
    const { quantity } = product;
    clonedProduct.features[index].picked = !clonedProduct.features[index].picked;
    let i = clonedProduct.extraItems.map(f => f.name).indexOf(extra.name);    
    if(i > -1){ 
      extra.picked = false;
      clonedProduct.extraItems.splice(i,1);
    }else{        
      extra.picked = true;
      clonedProduct.extraItems.push(extra);
    }
      
    const cut = extra.picked? +clonedProduct.extraSum + +extra[packagePricing() ? 'packageValue' : 'value'] : +clonedProduct.extraSum - +extra[packagePricing() ? 'packageValue' : 'value'];
    clonedProduct.extraSum = parseFloat(cut.toFixed(2));
    
    const extraTotal = clonedProduct.extraSum * quantity;
    extraTotal.toFixed(2);   
    const total = clonedProduct.sizePrice == 0  || clonedProduct?.weighted ? +clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] * quantity + +extraTotal : +clonedProduct.sizePrice * quantity + +extraTotal;
    clonedProduct.total = total;   
    setFeatures([...clonedProduct.features]);
    setProduct(clonedProduct);    
  };

  const setIngredient = (ingredient, index) => { 

    const clonedProduct = cloneDeep(product);
    clonedProduct.ingredients[index].picked = !clonedProduct.ingredients[index].picked;  
    let i = clonedProduct.ingredientItems.map(f => f.name).indexOf(ingredient.name);    
    if(i > -1){ 
      ingredient.picked = false;
      clonedProduct.ingredientItems.splice(i,1);
    }else{        
      ingredient.picked = true;
      clonedProduct.ingredientItems.push(ingredient);
    }     
    setIngredients([...clonedProduct.ingredients]);
    setProduct(clonedProduct); 

  };

  const setSingleAttribute = (attribute, index, tableIndex)  => { 
     
    if(!(product.savedAttributesTables[tableIndex]?.mandatory && product.attributeItems
      .filter(a => a.title === product.savedAttributesTables[tableIndex].title).filter(attr => attr.picked)?.length < 2
    && attribute.picked)){  
      const clonedProduct = cloneDeep(product); 
      const { quantity } = product;  
      const picked = !clonedProduct.savedAttributesTables[tableIndex].savedAttributesArray[+index]?.picked;    
      clonedProduct.savedAttributesTables[tableIndex].savedAttributesArray.map(e => e.picked = false);
      clonedProduct.savedAttributesTables[tableIndex].savedAttributesArray[+index].picked = picked;    
      let i = clonedProduct.attributeItems.map(f => f.title).indexOf(clonedProduct.savedAttributesTables[tableIndex].title);    
      if(i > -1){
        const item = clonedProduct.attributeItems.splice(i,1);     
        clonedProduct.extraSum -= (item[0][packagePricing() ? 'packagePrice' : 'price']
        * ((clonedProduct.savedAttributesTables[tableIndex])?.quantityEnabled ? clonedProduct.savedAttributesTables[tableIndex]?.quantity : 1));    
      }
      if(picked){
        Object.assign(attribute, {picked: true, title: clonedProduct.savedAttributesTables[tableIndex].title, quantity: 1})
        clonedProduct.attributeItems.push(attribute);  
        clonedProduct.extraSum += +attribute[packagePricing() ? 'packagePrice' : 'price'];        
      }   
  
      const extraTotal = clonedProduct.extraSum * quantity;
      extraTotal.toFixed(2);   
      const total = clonedProduct.sizePrice == 0 || clonedProduct?.weighted ? +clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] * quantity + +extraTotal : +clonedProduct.sizePrice * quantity + +extraTotal;
      clonedProduct.total = total;   
      if(clonedProduct.savedAttributesTables[tableIndex]?.quantityEnabled){
        clonedProduct.savedAttributesTables[tableIndex].quantity = 1;     
      }
      setAttributes([...clonedProduct.savedAttributesTables]);
      if(product.weighted){     
        clonedProduct.total =  (product.total - product.extraSum + +extraTotal).toFixed(2);
      } 
      setProduct(clonedProduct);    
    }
  };

  const setMultiAttribute = (attribute, index, tableIndex) => {

    if(!(product.savedAttributesTables[tableIndex]?.mandatory && product.attributeItems
      .filter(a => a.title === product.savedAttributesTables[tableIndex].title).filter(attr => attr.picked)?.length < 2
      && attribute.picked)){
      const clonedProduct = cloneDeep(product); 
      const { quantity } = product;  
      clonedProduct.savedAttributesTables[+tableIndex].savedAttributesArray[+index].picked = !clonedProduct.savedAttributesTables[+tableIndex].savedAttributesArray[+index].picked;     
      let i = clonedProduct.attributeItems.map(f => f.title+f.name)
          .indexOf(attribute.title+attribute.name);    
      if(i > -1){

          Object.assign(attribute, {picked: false, title: clonedProduct.savedAttributesTables[tableIndex].title})
          const item = clonedProduct.attributeItems.splice(i,1); 

      }else{
        Object.assign(attribute, {picked: true, title: clonedProduct.savedAttributesTables[tableIndex].title});
        clonedProduct.attributeItems.push(attribute);         
      }   
      const cut = attribute.picked ? +clonedProduct.extraSum + +attribute[packagePricing() ? 'packagePrice' : 'price'] : +clonedProduct.extraSum - +attribute[packagePricing() ? 'packagePrice' : 'price'];
      clonedProduct.extraSum = parseFloat(cut.toFixed(2));

      const extraTotal = clonedProduct.extraSum * quantity;
      extraTotal.toFixed(2);   
      const total = clonedProduct.sizePrice == 0 || clonedProduct?.weighted? +clonedProduct[packagePricing() ? 'packagePrice' : 'retailPrice'] * quantity + +extraTotal : +clonedProduct.sizePrice * quantity + +extraTotal;
      clonedProduct.total = total;  
      if(product.weighted){     
        clonedProduct.total =  (product.total - product.extraSum + +extraTotal).toFixed(2);
      } 
      setAttributes([...clonedProduct.savedAttributesTables]);
      setProduct(clonedProduct);    

    }
  }
    
  const renderSizeItem = (item, index) => (
    item.enabled && <SizeButton 
      key={index}
      title={feathersStore._translateNameAttr(item)}
      onClick={() => setServingSize(item, index)} 
      className={sizes[index].default ? 'productDetailsSellectionButtonActive': 'productDetailsSellectionButtonInactive'} 
     />   
  )

  const renderIngredients = (item, index) => (
    item.ingredientEnabled && <SizeButton 
      key={index} 
      title={feathersStore._translateNameAttr(item)}
      onClick={() => setIngredient(item, index)} 
      className={ingredients[index].picked ? 'productDetailsSellectionButtonActive': 'productDetailsSellectionButtonInactive'} 
    />  
  )

  const renderExtraItem = (item, index) => (
    item.featuresEnabled && 
      <SizeButton
      key={index} 
      title={feathersStore._translateNameAttr(item)}
      onClick={() => setExtra(item, index)} 
      className={features[index].picked ? 'productDetailsSellectionButtonActive': 'productDetailsSellectionButtonInactive'} 
   /> 
  )

  const renderAttribute = (item, index, tableIndex) => (
    item?.active &&
    <SizeButton
      key={index} 
      title={feathersStore._translateNameAttr(item)}
      onClick={() => {attributes[+tableIndex]?.multi ? setMultiAttribute(item, index, tableIndex) : setSingleAttribute(item, index, tableIndex)}} 
      className={attributes[+tableIndex].savedAttributesArray[+index]?.picked ? 'productDetailsSellectionButtonActive': 'productDetailsSellectionButtonInactive'} 
    /> 
  )

  return (
    <div className="body-container">
      <div className="grey-header">
        {feathersStore._translateName(product)}
      </div>
      <div className="productDetailsView">
        <ProductDetailCard
          image={product.images && product?.images[0] ? `${API_URL}/images/${product.images[0]}` : require("../img/200_spinner.gif")}
          title={feathersStore._translateName(product)}
          price={parseFloat(product.total).toFixed(2)}
          oldPrice={product[packagePricing() ? 'packageOldPrice' : 'oldPrice']}
          description={feathersStore._translateProductShortDescription(product)}
          quantity={product.quantity}
          sizes={sizes}
          renderSizes={renderSizeItem}
          ingredients={ingredients}
          renderIngredients={renderIngredients}
          extras={features}
          renderExtras={renderExtraItem}
          attributes={attributes}
          renderAttribute={renderAttribute}
          pressPlus={onPressIncreaseAmount}
          pressMinus={onPressDecreaseAmount}
          cartButtonDisabled={product.quantity === 0 || (product.weighted && product?.weightedQuantity < step?.calc)}
          addToCart={addToCart}
          addToFavorite={onPressAddToFavorites}
          favoriteColor={favorite? AppColors.red : AppColors.secondary}
          soldOut={product.soldOut}
          badges={product.badges}
          comments={comments}
          setComments={setComments}
          weighted={product.weighted}
          weightedPrice={product.weightedPrice}
          weightedQuantity={transformToKgr(product.weightedQuantity)}
          onPressIncreaseWeighted={onPressIncreaseWeighted}
          onPressDecreaseWeighted={onPressDecreaseWeighted}
          reviews={reviews}
          setReviews={setReviews}
          productId={product._id}
          averageStars={averageStars}
          setAverageStars={setAverageStars}
          category1={product.category1}
          pressMinusAttribute={onPressDecreaseAttribute}
          pressPlusAttribute={onPressIncreaseAttribute}
          disabledQTableQuantityButtons={disabledQTableQuantityButtons}
        />
      </div>
      <SmallAlert 
        show={cartIncreased} 
        mode="cart"
        heading={common.cartUpdatedAlert} 
        onClose={() => setCartIncreased(false)} 
        message={common.cartUpdated} />
      <SmallAlert 
        show={favoritesUpdated} 
        mode="favorite"
        heading={common.wishlistUpdatedAlert}
        onClose={() => setFavoritesUpdated(false)} 
        message={common.wishlistUpdated} />
      <SmallAlert 
        show={warning} 
        mode="stock"
        heading={common.stockWarningHeader} 
        onClose={() => setWarning(false)}  
        message={common.stockWarning}
      />   
    </div>
  );
  
}

export default inject('feathersStore')(observer(Product));
