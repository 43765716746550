import React from 'react';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";

const FaqTextSpanish = ({ feathersStore })=> {
  return(
    <div className="terms-text">
    <h2>¿Qué métodos de pago aceptan?</h2>
    <p>
    Puede ver los métodos de pago aceptados <Link className="privacy-text" to="/paymentoptions">aquí</Link>.
    </p>
    <h2>
    ¿Con qué métodos de envío puedo recibir mi pedido?
    </h2>
    <p>
    Puede ver los métodos de envío <Link className="privacy-text" to="/deliveryoptions">aquí</Link>.
    </p>
    <h2>
    ¿Puedo realizar cambios en mi pedido?
    </h2>
    <p>
    Puede realizar cambios antes de que se envíe su pedido. Por favor, haga una llamada al {feathersStore.settings?.phonenumbers} o contáctenos a través del formulario de la página web <Link className="privacy-text" to="/contact">aquí</Link> o por correo electrónico a {feathersStore.settings?.email} indicando sus detalles y los cambios que desea realizar.
    </p>
    <h2>
    ¿Hay una tienda que pueda visitar?
    </h2>
    <p>
    Puede visitarnos en nuestra dirección {feathersStore.settings?.address}.
    </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(FaqTextSpanish));