import React from 'react';
import { inject, observer } from "mobx-react";

const TermsTextEnglish = ({ feathersStore })=> {
    return(
        <><div className="terms-text"><p>To complete your orders, you will be asked each time separately to confirm that you agree with the following terms of use:</p><ul>
            <li>
                <p>The copyright of the Content (e.g. programs, information, data) and Services of the website - website that have been introduced to the Network are protected by Greek, Community and international copyright laws.</p>
            </li>
            <li>
                <p>Any copying, distribution, transfer, modification, resale, creation of derivative work or misrepresentation is prohibited of the public about their actual provider of Website Content. Any reproduction, republishing, uploading, announcement, dissemination or transmission or any other use of the Content in any manner or medium for commercial or other purposes permitted only with the prior written permission of - website or any other copyright holder.</p>
            </li>
            <li>
                <p>The online store - website provides the Content (eg information, names, photos, illustrations), products and services available through the website "exactly as they are". In no case is the online store - website liable for any legal or civil and/or criminal claims nor for any damage (positive, special or consequential, which indicatively and not restrictively, disjunctively or cumulatively consists of loss of profits, data , lost profits, monetary satisfaction, etc.) from visitors to the website or third parties for a reason related to the operation or non-operation or the use of the website or to the inability to provide services or information available from it or from any unauthorized interventions by third parties in products or services or information available through it.</p>
            </li>
            <li>
                <p>The photos of the products are indicative and we bear no responsibility for any spelling errors.</p>
            </li>
            <li>
                <p>Prices are subject to change without notice. A price commitment can only be made for an online order and is completed with the sending of its confirmation. The store with the trade name - website is not required to provide no guarantee for the availability of its products, but undertakes to immediately inform its customers of any shortages or late shipments.</p>
            </li>
            <li>
                <p>The customer, once the order is processed by the orders department and has confirmed his order, ensures that he will receive the product at the price he "accepted" the transaction, even if the price changes along the way. In case the products or services are listed on the website with the wrong price due to a typographical error then the orders are automatically cancelled. The - website is not responsible for any damages that occur to the customer/user of the online store from the execution or non-execution of the orders or from the delay in execution for any reason.</p>
            </li>
            <li>
                <p>The online store - website reserves the right to modify, add, change the content or the services of the website, as well as the terms of use, whenever it deems it necessary and without prior warning, by only announcing them through its website.</p>
            </li>
        </ul><p>Depending on the payment method chosen by the customer during his order, the refund will be made accordingly:
            </p><ul>
                <li>
                    <p>For an online Credit Card, the refund will be made to the card.</p>
                </li>
                <li>
                    <p>For Cash on Delivery or bank deposit you will need to give us the IBAN of a bank account, the name of the beneficiary as well as the bank concerned.</p>
                </li>
            </ul></div></>
    )
}

export default inject('feathersStore')(observer(TermsTextEnglish));