export const footerES = {
    contactHours: "Horario de contacto",
week: "Lunes - Viernes",
contactUs: "Contáctanos",
address: "131 Calle Mesogeion, Marousi 15126",
findUs: "Encuéntranos en el mapa",
contact: "Contáctanos",
privacyPolicy: "Política de privacidad",
termsOfService: "Términos de servicio",
stayConnected: "Mantente conectado",
cookiesTitle: "Uso de cookies",
aboutUs: "Sobre nosotros",
cookiesText: "Solo utilizamos las cookies mínimas requeridas. No almacenamos tus datos personales en ninguna de ellas.",
cookiesAccept: "Aceptar",
accessibilityTitle: "Accesibilidad",
fontIncrease: "Aumentar tamaño de fuente",
fontDecrease: "Disminuir tamaño de fuente",
greyscale: "Escala de grises",
highContrast: "Alto contraste",
negativeContrast: "Contraste negativo",
lightBackground: "Fondo claro",
linksUnderline: "Subrayado de enlaces",
readableFont: "Fuente legible",
reset: "Restablecer",
paymentOptions: "Opciones de pago",
deliveryOptions: "Opciones de entrega",
openingTime: "Horario de apertura",
loginRegister: "Iniciar sesión/Registrarse",
messagingTitle: "Soporte",
messagingSubTitle: "¡Envía un mensaje con tus preguntas!",
message: "Mensaje",
installBringFood: "¡Comienza con BringFood!",
usefull: "Útil",
company: "Compañía",
faq: "Preguntas frecuentes",
chatPlaceholder: "Introduce el texto...",
restaurantClosed: "El establecimiento está cerrado en este momento y no podemos preparar tu pedido",
holidayText: "El establecimiento permanecerá cerrado hoy",
allDay: "Todo el día",
to: "Para"

}