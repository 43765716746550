export const checkoutIT = {
  less255: "Massimo 255 caratteri",
less50: "Massimo 50 caratteri",
less20: "Massimo 20 caratteri",
less10: "Massimo 10 caratteri",
required: "Obbligatorio",
invalidEmail: "Email non valida",
invalidPhone: "Numero non valido",
textareaMax: "Massimo 1000 caratteri",
invalidPayment: "Metodo di pagamento non valido",
terms: "Devi accettare i termini e le condizioni",
createAccount: "Crea Account",
differentDeliveryAddress: "Consegna a un indirizzo diverso",
orderComments: "Commenti dell'ordine",
payment: "PAGAMENTO",
cash: "Pagamento alla consegna",
cashText: "Paga in contanti quando ti vengono consegnati gli articoli",
clickAway: "Ritira in negozio",
clickAwayText: "Ritira gli articoli dal negozio",
paypalRadio: "Carta di credito o PayPal",
paypalError: "Compila il modulo correttamente!",
personalDataInfo: "I tuoi dati personali saranno utilizzati per le esigenze di questo sito e per la gestione del tuo account.",
readThe: "Leggi il",
iHaveRead: "Ho letto e accetto il",
ofThisSite: "di questo sito",
yourOrder: "Il tuo ordine",
sendOrder: "Invia ordine",
alreadyCustomer: "Sono già un cliente",
orderCompleteTitle: "ORDINE COMPLETATO!",
orderCompleteLine1: "Il tuo ordine è stato ricevuto con successo.",
orderCompleteLine2: "Grazie per il tuo ordine.",
isCompany: "Emetti fattura",
companyAfm: "Partita IVA",
companyDoy: "Ufficio delle imposte",
companyName: "Nome dell'azienda",
companyProfession: "Professione",
bankTransfer: "Bonifico bancario diretto",
bankTransferText: "Effettua il tuo pagamento direttamente sul nostro conto bancario. Includi il tuo ID ordine nel bonifico. La tua spedizione verrà effettuata dopo la verifica del pagamento.",
bankLargeText: "Effettua il pagamento direttamente sul nostro conto bancario. Includi il tuo ID ordine nel bonifico. La tua spedizione verrà effettuata dopo la verifica del pagamento.",
bank: "Banca",
nbgRadio: "Carta di credito tramite NBG Bank",
without: "Senza",
checkoutData: "Dettagli pagamento / consegna",
product: "PRODOTTO",
subTotal: "SUBTOTALE",
without: "SENZA",
total: "TOTALE",
doYouHave: "Hai già un account? Fai clic ",
here: "qui",
toConnect: " per connetterti.",
legalName: "Nome legale",
legalDescription: "Tipo di azienda",
postalAddress: "Via principale",
postalAddressNo: "Numero",
postalAreaDescription: "Città principale",
postalZipCode: "Codice postale",
firmActDescription: "Occupazione principale",
companyPhone: "Telefono aziendale",
companyEmail: "Email aziendale",
doyDescription: "Nome dell'ufficio delle imposte",
returnToShop: "TORNA AL NEGOZIO",
alergies: " Hai un'allergia o un'intolleranza a qualche alimento? Qualche specificità nutrizionale? Contatta il negozio prima di effettuare l'ordine."
}