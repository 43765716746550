import React from 'react'

const PaymentOptionsTextItalian = ()=> {
  return(
    <div className="terms-text">
    <h2>Pagamento dell'ordine</h2>
    <p>
    Nella nostra azienda facciamo del nostro meglio per fornire il miglior servizio possibile ai nostri clienti. Pertanto, abbiamo incluso il maggior numero possibile di metodi di pagamento.
    </p>
    <h2>
    Carta di credito - Carta di debito
    </h2>
    <p>
    Nel nostro negozio accettiamo carte di credito, carte di debito o prepagate di qualsiasi banca. In caso di pagamento nel nostro negozio fisico, il pagamento avviene tramite POS. Nel caso di acquisto nel nostro negozio online (e-shop) e pagamento con carta di credito, l'utente viene indirizzato all'ambiente sicuro dell'organizzazione bancaria partner (PayPal). Questo sito web è sicuro e protetto da un certificato SSL. I dettagli della carta di credito, della carta di debito o della carta prepagata utilizzata non ci vengono comunicati e non vengono conservati nella nostra azienda.
    </p>
    <h2>
    Ritiro presso il negozio
    </h2>
    <p>
    Se scegli di ritirare i tuoi prodotti in un negozio, puoi pagare l'importo dell'ordine sul posto.
    </p>
    </div>
  )
}
    
export default PaymentOptionsTextItalian;