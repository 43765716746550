import React, {useState} from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditIcon from '@mui/icons-material/Edit';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOrEditaddressModal from './addOrEditAddressModal/AddOrEditAddressModal';
import AddressDeleteModal from '../../modals/addressDeleteModal';

const AddressComponent = ({
  companyId,
  setAddresses,
  onPress,
  item,  
  itemIndex,
  street, 
  streetNumber, 
  postcode,
  city, 
  active,
  disableDelete
}) => {

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  const [visibleAddNewAddress, setAddNewAddress] = useState(false);
  const [visibleDeleteAddress, setDeleteAddress] = useState(false);

  const  editAddress = () => {     
    setAddNewAddress(true); 
  };

  const closeNewAddress = () =>{
    setAddNewAddress(false); 
  }

  const  openDeleteAddressModal = () => {     
    !disableDelete && setDeleteAddress(true);  
  };

  const closeDeleteAddressModal = () =>{
    setDeleteAddress(false); 
  }
  
  return (
    <>
    <div className={active ? "wishlist-object address-object-active" : "wishlist-object"}> 
      <div className="address-object-radio">      
        <FormControlLabel 
          value={active} 
          control={<Radio sx={{
            color: "grey",
            '&.Mui-checked': {
              color: "whitesmoke",
            },
          }}/>} 
          classes={active ?  { label: 'active-address-label' } : { label: 'addresss-label' }} 
          label={`${street} ${streetNumber}, ${city}, ${postcode ? ", " + postcode : ""}`}        
          checked={active}
          onChange={ onPress}/>     
      </div>    
      <div className="address-object-end">
        <div className={active ? "address-object-button button-active": "address-object-button"}  onMouseOver={changeCursor} 
          onClick={editAddress} aria-label='Change address'>
          <EditIcon style={active ? {color: 'whitesmoke'} : {color: 'grey'}}/>
        </div>
        <div className={active ? "address-object-button button-active": "address-object-button"} onMouseOver={changeCursor} 
          onClick={openDeleteAddressModal} aria-label='Delete address'>
          <CloseOutlinedIcon style={active ? {color: 'whitesmoke'} : {color: 'grey'}}/>
        </div>
      </div>     
    </div> 
    <AddOrEditaddressModal 
      visible={visibleAddNewAddress} 
      companyId={companyId} 
      setAddresses={setAddresses}
      close={closeNewAddress} 
      itemToEdit={item}
      itemIndex={itemIndex}
    />
    <AddressDeleteModal 
      visible={visibleDeleteAddress} 
      companyId={companyId} 
      setAddresses={setAddresses}
      onClickNo={closeDeleteAddressModal} 
      itemToDelete={item}
      itemIndex={itemIndex}
    />
    </>
  );
}

export default AddressComponent;
