import React, {useState, useEffect} from 'react';
import Fab from '@mui/material/Fab';
import topBarLogo from '../img/web_hi_res_512_rounded.png';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import TextsmsIcon from '@mui/icons-material/Textsms';
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../hooks/_useTranslate';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 32,     
     // marginTop: theme.spacing(1),
      width: 350,  
      height: 540,    
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
        fontSize:14
      },
    },
  }));

  const sendButton = {
    backgroundColor: "hsl(358, 78%, 50%)", 
    color: "white", 
    width: 26, 
    height: 26,
    "&:hover": {
      backgroundColor: "hsl(358, 78%, 40%)",
      color: "hsl(0, 0%, 90%)"    
    }
  }
  const fabButton = {
    backgroundColor: "hsl(358, 78%, 50%)",
    color: "white",    
    "&:hover": {
      backgroundColor: "hsl(358, 78%, 40%)",
      color: "hsl(0, 0%, 90%)"  
    }
  }

const MessagingBtn = ({feathersStore}) => {

  let footer = _useTranslate(feathersStore.language, "footer");
  let common = _useTranslate(feathersStore.language, "common");

  const [messageText, setMessageText] = useState("");
  const [messages, setMessages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    feathersStore.isAuthenticated && initList();  
  }, [ feathersStore.isAuthenticated, feathersStore.messageCreatedEvent]); 

  const initList = async() => {   
    const databaseList = await feathersStore.findMessages();
    setMessages(databaseList);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setMessageText(event.target.value);
  };

  const sendMessage = async() => {    
    let payload = {messageText};
    if(feathersStore?.tableId?.length > 0){
      const table = await feathersStore.getTable(feathersStore.tableId);
      payload =  {
        messageText: `${common.table} ${table.tableName}: ${messageText}`
      } 
    }  
    await feathersStore.createMessage(payload);
    setMessageText("");
  }

  const renderItems = messages.map((item, index) => (   
    <div className="message-line" key={index}>
      {item.adminAnswer &&
        <img 
          className="messaging-logo" 
          aria-label='appdate logo'
      //    width="60"
          alt="BringFood Logo"
          src={topBarLogo}
        />
      }      
    
      <div className={"message-card " + (item.adminAnswer ? "message-card-admin" : "message-card-customer")}>
        {item.messageText}
      </div>
    </div>   
  ))

  return (
    <>
      <Fab 
        sx={fabButton}       
        size="large"       
        id="accessibility-button"
        aria-controls="accessibility-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <TextsmsIcon sx={{fontSize: 32}} />
      </Fab>
      <StyledMenu
        id="accessibility-menu"
        MenuListProps={{
          'aria-labelledby': 'accessibility-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className="messaging-title-container">
          <div className="messaging-title">{footer.messagingTitle}</div>
          <div className="messaging-sub-title">{footer.messagingSubTitle}</div>
        </div>
        <div className="content-container">
          <div className="messages-list">
            {renderItems}
          </div>
          <div className="input-container">
            <TextField
              sx={{width: 320}}
              id="outlined-multiline-flexible"
              label={footer.message}
              placeholder={footer.chatPlaceholder}
              color="secondary"
              size="small"
              multiline
              maxRows={4}
              value={messageText}
              onChange={handleChange}
              InputProps={{
           //     ...InputProps,
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton sx={sendButton} onClick={sendMessage}
                     aria-label="send message" component="span">
                    <SendIcon sx={{fontSize: 16}} />
                  </IconButton>        
                </InputAdornment>
                )
              }}
            />                
          </div>
        </div>
      </StyledMenu>  
          
    </>
  );
}

export default inject('feathersStore')(observer(MessagingBtn))