export const cartES = {
    cart: "Carrito",
    couponCode: "Código de cupón",
    couponButton: "Usar cupón",
    cartTotals: "Totales",
    completeOrder: "PAGAR",
    continueShopping: "CONTINUAR COMPRANDO",
    without: "Sin",
    select: "SELECCIONAR",
    selectSmall: "Seleccionar",
    addToCart: "Añadido al carrito",
    cartItems: "Artículos en el carrito",
    couponDiscount: "Descuento de cupón",
    subTotal: "Subtotal",
    couponCaption: "¿Tienes un cupón?",
    new: "NUEVO",
    sale: "VENTA",
    soldOut: "AGOTADO",
    couverCaption: "Número de personas",
    couverPlaceholder: "Ingrese un número",
    couverError: "Ingrese el número de personas y presione verificar"
}
