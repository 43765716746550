import React from 'react'

const PaymentOptionsText = ()=> {
  return(
    <div className="terms-text">
      <h2>Πληρωμή παραγγελίας</h2>
      <p>
        Στην εταιρία μας κάνουμε κάθε δυνατή προσπάθεια για την καλύτερη δυνατή εξυπηρέτηση του αγοραστικού μας κοινού. Ως εκ τούτου έχουμε συμπεριλάβει όσο το δυνατόν περισσότερους τρόπους πληρωμής.
      </p>    
      <h2>
        Πιστωτική κάρτα – Χρεωστική κάρτα
      </h2>
      <p>
       Στο κατάστημά μας δεχόμαστε πιστωτικές, χρεωστικές ή prepaid κάρτες οποιασδήποτε τράπεζας. Στην περίπτωση πληρωμής στο φυσικό μας κατάστημα, η χρέωση γίνεται με την χρήση POS. Στην περίπτωση αγοράς από το ηλεκτρονικό κατάστημα - e-shop και πληρωμής με πιστωτική κάρτα, ο χρήστης μεταφέρεται στο ασφαλές περιβάλλον του συνεργαζόμενου τράπεζικού οργανισμού (PayPal). Ο ιστότοπος αυτός είναι ασφαλής και καλύπτεται από πιστοποιητικό SSL. Τα στοιχεία της πιστωτικής χρεωστικής ή prepaid κάρτας που θα χρησιμοποιηθεί δεν μας γνωστοποιούνται κι δεν τα κρατάμε στην εταιρία μας, καθώς δεν εισάγονται στην δική μας ιστοσελίδα. Διαβάστε περισσότερα για την ασφάλεια των συναλλαγών στους όρους χρήσης.
      </p>
      <h2>
        Παραλαβή από το κατάστημα
      </h2>
      <p>
        Εφόσον επιλέξετε την παραλαβή των προϊόντων σας από κάποιο κατάστημα, εξοφλείτε και το ποσό της παραγγελίας σας επιτόπου.
      </p>
    </div>
  )
}
    
export default PaymentOptionsText;