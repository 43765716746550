import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import CommentsCard from './commentsCard';

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Comments = ({ feathersStore }) => {

  let account = _useTranslate(feathersStore.language, "account");  

  let navigate = useNavigate();

  const [comments, setComments] = useState([]);

  useEffect(() => {
    feathersStore.user.username !== "default" && initComments();
  }, [feathersStore?.user]);

  const initComments = async() => {            
    setComments(feathersStore.user?.commentsList.filter(rev => !rev.blocked));
  }

 // useEffect(() => {
 //   setComments(feathersStore.user?.commentsList.filter(rev => !rev.blocked));
 // }, [feathersStore.userUpdatedEvent]);

  const goToProduct = product_id => {
    feathersStore.getProduct(product_id).then(prod => {
      prod && navigate(`/product/${(feathersStore.language !== 'el') ? feathersStore._translateName(prod) : prod.name}`,
      {
        state: {id: `${prod._id}`,  payload: prod}     
      });
    });
  }

  const renderComments = comments?.map((comment, index) => 
    <CommentsCard 
      key={comment.date}        
      redirectFunction={() => goToProduct(comment.productId)}
      username={comment.productName}
      date={comment.date}
      stars={comment.stars} 
      productId={comment.productId}
      reviewText={comment.reviewText}
      productImage={comment.productImage}
    />
  )

  return(
    <div className="comments-container" style={{overflow: 'auto', minHeight: '40vh',	maxHeight: '50vh'}}>
      {comments?.length === 0 ?
        <div className="empty-component-text">{account.commentPlaceholder}</div> 
        : 
        renderComments
      }
    </div>
  )
}

export default inject('feathersStore')(observer(Comments));