import React from 'react';
import { inject, observer } from "mobx-react";

const TermsTextGerman = ({ feathersStore })=> {
    return(<div className="terms-text">
    <p>Um Ihre Bestellungen abzuschließen, werden Sie jedes Mal separat gebeten zu bestätigen, dass Sie den folgenden Nutzungsbedingungen zustimmen:</p>
    <ul>
      <li>
        <p>Das Urheberrecht an den Inhalten (z. B. Programme, Informationen, Daten) und Diensten der Website - Website, die im Netzwerk eingeführt wurden, ist durch griechische, europäische und internationale Urheberrechtsgesetze geschützt.</p>
      </li>
      <li>
        <p>Jegliche Vervielfältigung, Verbreitung, Übertragung, Änderung, Weiterverkauf, Erstellung abgeleiteter Werke oder Fehlinformationen über den tatsächlichen Anbieter des Website-Inhalts ist untersagt. Jegliche Vervielfältigung, erneute Veröffentlichung, Hochladen, Ankündigung, Verbreitung oder Übertragung oder jede andere Verwendung des Inhalts in irgendeiner Weise oder Form zu kommerziellen oder anderen Zwecken ist nur mit vorheriger schriftlicher Genehmigung der Website oder eines anderen Urheberrechtsinhabers gestattet.</p>
      </li>
      <li>
        <p>Der Online-Shop - die Website stellt den Inhalt (z. B. Informationen, Namen, Fotos, Illustrationen), Produkte und Dienstleistungen, die über die Website verfügbar sind, "genau so, wie sie sind", zur Verfügung. In keinem Fall haftet der Online-Shop - die Website für rechtliche oder zivil- und/oder strafrechtliche Ansprüche oder für Schäden (positiver, besonderer oder Folgeschaden, der beispielsweise, aber nicht abschließend, aus Gewinnverlust, Datenverlust, entgangenem Gewinn, finanzieller Zufriedenheit usw. besteht) von Besuchern der Website oder Dritten aus einem Grund im Zusammenhang mit dem Betrieb oder Nichtbetrieb oder der Nutzung der Website oder der Unfähigkeit, Dienstleistungen oder Informationen, die von ihr verfügbar sind, bereitzustellen, oder aus unbefugten Eingriffen von Dritten in Produkte oder Dienstleistungen oder Informationen, die über sie verfügbar sind.</p>
      </li>
      <li>
        <p>Die Fotos der Produkte sind indikativ, und wir übernehmen keine Verantwortung für Schreibfehler.</p>
      </li>
      <li>
        <p>Preise können sich ohne Vorankündigung ändern. Eine Preiszusage kann nur für eine Online-Bestellung abgegeben werden und wird mit der Übermittlung ihrer Bestätigung abgeschlossen. Der Laden mit dem Handelsnamen - die Website ist nicht verpflichtet, eine Garantie für die Verfügbarkeit seiner Produkte zu geben, verpflichtet sich jedoch, seine Kunden umgehend über Engpässe oder verspätete Lieferungen zu informieren.</p>
      </li>
      <li>
        <p>Der Kunde garantiert, dass er das Produkt zum Preis, den er bei der Transaktion "akzeptiert" hat, erhält, sobald die Bestellung von der Bestellabteilung bearbeitet und bestätigt wurde, auch wenn der Preis auf dem Weg geändert wird. Bei falsch angezeigten Produkten oder Dienstleistungen auf der Website aufgrund eines Tippfehlers werden die Bestellungen automatisch storniert. Die Website ist nicht für Schäden verantwortlich, die dem Kunden/Benutzer des Online-Shops aufgrund der Ausführung oder Nichtausführung der Bestellungen oder aufgrund von Verzögerungen aus irgendeinem Grund entstehen.</p>
      </li>
      <li>
        <p>Der Online-Shop - die Website behält sich das Recht vor, den Inhalt oder die Dienste der Website sowie die Nutzungsbedingungen zu ändern, hinzuzufügen oder zu ändern, wann immer sie es für erforderlich hält, und ohne vorherige Ankündigung, indem sie sie nur auf ihrer Website bekannt gibt.</p>
      </li>
    </ul>
    <p>Abhängig von der vom Kunden während seiner Bestellung gewählten Zahlungsmethode wird die Rückerstattung wie folgt erfolgen:</p>
    <ul>
      <li>
        <p>Bei einer Online-Kreditkarte wird die Rückerstattung auf die Karte erfolgen.</p>
      </li>
      <li>
        <p>Bei Barzahlung bei Lieferung oder Banküberweisung müssen Sie uns die IBAN eines Bankkontos, den Namen des Begünstigten und die betroffene Bank mitteilen.</p>
      </li>
    </ul>
  </div>  
    )
}

export default inject('feathersStore')(observer(TermsTextGerman));