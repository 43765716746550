import React, { useEffect, useRef, useState } from 'react';
import { inject, observer } from "mobx-react";
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import "./Contact.css";
import { PHONE_REGEX } from "../../assets/globalConstants";
import SmallAlert from "../../modals/smallAlert";
import GreenSubmitButton from '../../buttons/greenSubmitButton';
import Colors from "../../assets/colors";
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Contact = ({ feathersStore })=> {

  let contact = _useTranslate(feathersStore.language, "contact");
  const formRef = useRef();

  const [showError, setShowError] = useState(false);
  const [showSuccess,setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false); 
  
  useEffect(()=> {
    formRef.current.resetForm();
    formRef.current.validateForm();
  },[feathersStore.user]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
    .min(2, contact.mustHaveChar)
    .max(255, contact.less255)
    .required(contact.required),
    lastName: Yup.string()
    .min(2, contact.mustHaveChar)
    .max(255, contact.less255)
    .required(contact.required),
    email: Yup.string()
    .email(contact.invalidEmail)
    .required(contact.required),
    phone: Yup.string()
    .matches(PHONE_REGEX, contact.invalidPhone),
    comments: Yup.string()
    .max(1000, contact.textareaMax)
    .required(contact.required)
  })

  const sendEmail = async (values) => {
    const { email, firstName, lastName, comments, phone } = values
    try {
      setLoading(true); 
      await feathersStore.sendContactEmail( email, firstName, lastName, comments, phone);
      setShowSuccess(true);
      setLoading(false);
    }catch(err){
      setLoading(false);
      console.log('Could not send email FROM sendEmail method: ', err )
      setShowError(true);
    };
  }

  return(
    <>
    <div className="contact-sub-container"> 
        <div className="pageIcon">
        <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
      </div> 
      <div>
        <p className="terms-title">{contact.contactForm}</p>
      </div> 
     
      
        <Formik
          innerRef={formRef}
          enableReinitialize
          initialValues={{
            firstName: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.firstname) || "",
            lastName: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.lastname)  || "",
            phone: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.phone) || "",
            email: (feathersStore.user?.firstname !== 'default' && feathersStore.user?.email) || "",
            comments: ""
          }}
          validationSchema={validationSchema}
          onSubmit = {(values, { setSubmitting, resetForm, validateForm }) => {
            setSubmitting(false);
            sendEmail(values);
            resetForm();
            validateForm();
          }}
        >
          {({errors, touched, isValid, isSubmitting}) => (
          <div className="contact-form-container">
            <Form>
              <div className="form">
                <div>
                  <div>
                    <label htmlFor="firstName">{contact.firstName}
                      <span>*</span>
                    </label>
                  </div>
                  <Field 
                    name="firstName"
                    type="text"
                    className="form-control fieldclass"
                  />
                  {errors.firstName && touched.firstName ? (
                  <div id="error-message">{errors.firstName}</div>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <label htmlFor="lastName">{contact.lastName}
                      <span>*</span></label>
                    </div>
                    <Field
                      name="lastName"
                      type="text"
                      className="form-control fieldclass"
                    />
                    {errors.lastName && touched.lastName ? (
                      <div id="error-message">{errors.lastName}</div>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <label htmlFor="email">{contact.email}
                        <span>*</span>
                      </label>
                    </div>
                    <Field
                      name="email"
                      type="email"
                      className="form-control fieldclass"
                    />
                    {errors.email && touched.email ? (
                      <div id="error-message">{errors.email}</div>
                    ) : null}
                  </div>
                  <div>
                    <div>
                      <label htmlFor="phone">{contact.phone}</label>
                    </div>
                    <Field
                      name="phone"
                      type="text"
                      className="form-control fieldclass"
                    />
                    {errors.phone && touched.phone ? (
                      <div id="error-message">{errors.phone}</div>
                    ) : null}
                  </div>
                  <div>
                    <label className="form-textarea" htmlFor="comments">{contact.text}
                      <span>*</span>
                    </label>
                  </div>
                  <Field
                    as="textarea"
                    className="form-control"
                    name="comments"
                    rows="4"
                  />
                  {errors.comments && touched.comments ? (
                    <div id="error-message">{errors.comments}</div>
                  ) : null}
                </div>
                <GreenSubmitButton 
                  aria-labelledby='submit form'
                  type="submit"
                  disabled = {isSubmitting || !isValid}
                  loading={loading}
                  color={Colors.amazonColor}
                  //  onClick={sendEmail} 
                  className='submit-form'
                  title={contact.buttonForm}/>
              </Form>
            </div>
            )}
          </Formik>
      </div>
      <SmallAlert
        show={showSuccess}
        mode="qrcode"
        heading={contact.emailSent}
        onClose={() => setShowSuccess(false)}
        message={contact.emailSentMessage}  />
      <SmallAlert
        show={showError}
        mode="stock"
        heading={contact.emailError}
        onClose={() => setShowError(false)}
        message={contact.emailErrorMessage} />
    </>
  )
}

export default inject('feathersStore')(observer(Contact));