export const footerEN = {
    contactHours : "Contact Hours",
    week : "Monday – Friday",
    contactUs : "Contact us",
    address : "131 Mesogeion str. Marousi 15126",
    findUs : "Find us on the map",
    contact: "Contact us",
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    stayConnected: "Stay connected",
    cookiesTitle: "Cookies Usage",
    aboutUs : "About us",
    cookiesText: "We only use the minimum required cookies. Your personal data are not stored in any of them.",
    cookiesAccept: "Accept",  
    accessibilityTitle:  "Accessibility",
    fontIncrease: "Font size increase",
    fontDecrease: "Font size decrease",
    greyscale: "Greyscale",
    highContrast: "High contrast",
    negativeContrast: "Negative contrast",
    lightBackground: "Light Background",
    linksUnderline: "Links underline",
    readableFont: "Readable font",
    reset: "Reset",
    paymentOptions: "Payment options",
    deliveryOptions: "Delivery options",
    openingTime: "Opening Time",    
    loginRegister: "Login/Register",
    messagingTitle: "Support",
    messagingSubTitle: "Send a message with your questions!",
    message: "Message",
    installBringFood: "Start with BrigFood!",
    usefull: "Useful",
    company: "Company",
    faq: "FAQ's",
    chatPlaceholder: "Enter text...",
    restaurantClosed: "The premises are closed at this moment and we cannot prepare your order",
    holidayText: "The premises will remain closed today",
    allDay: "All day",
    to: "Till"
}