export const contactEN = {
    mustHaveChar: "Must contain two characters or more",
    less255: "Must be shorter than 255 characters",
    required: "Required",
    invalidEmail: "Invalid email",
    invalidPhone: "Invalid phone",
    textareaMax: "Must be shorter than 1000 characters",
    contactForm : "Contact Form",
    firstName : "First Name",
    lastName : "Last Name",
    phone : "Phone",
    email : "Email",
    text : "Message",
    buttonForm : "Submit",
    emailSent: "Email-sent!",
    emailSentMessage: "You sent succesfully your email!",
    emailError: "Email Error!",
    emailErrorMessage: "Your E-mail was not sent!"
}