export const contactFR = {
    mustHaveChar: "Doit contenir au moins deux caractères",
    less255: "Doit être plus court que 255 caractères",
    required: "Requis",
    invalidEmail: "E-mail invalide",
    invalidPhone: "Numéro de téléphone invalide",
    textareaMax: "Doit être plus court que 1000 caractères",
    contactForm: "Formulaire de contact",
    firstName: "Prénom",
    lastName: "Nom de famille",
    phone: "Téléphone",
    email: "E-mail",
    text: "Message",
    buttonForm: "Envoyer",
    emailSent: "E-mail envoyé !",
    emailSentMessage: "Vous avez envoyé avec succès votre e-mail !",
    emailError: "Erreur d'e-mail !",
    emailErrorMessage: "Votre e-mail n'a pas été envoyé !"
}