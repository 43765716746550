export const checkoutES = {
  less255: "Máximo 255 caracteres",
less50: "Máximo 50 caracteres",
less20: "Máximo 20 caracteres",
less10: "Máximo 10 caracteres",
required: "Requerido",
invalidEmail: "Correo electrónico inválido",
invalidPhone: "Número inválido",
textareaMax: "Máximo 1000 caracteres",
invalidPayment: "Método de pago inválido",
terms: "Debe aceptar los términos y condiciones",
createAccount: "Crear cuenta",
differentDeliveryAddress: "Entregar en una dirección diferente",
orderComments: "Comentarios del pedido",
payment: "PAGO",
cash: "Pagar en la entrega",
cashText: "Pague en efectivo cuando se entreguen los artículos",
clickAway: "Recoger en tienda",
clickAwayText: "Recoge los artículos en la tienda",
paypalRadio: "Tarjeta de crédito o PayPal",
paypalError: "Por favor, complete el formulario correctamente.",
personalDataInfo: "Sus datos personales se utilizarán para las necesidades de este sitio y para la gestión de su cuenta.",
readThe: "Lea la",
iHaveRead: "He leído y acepto los",
ofThisSite: "de este sitio",
yourOrder: "Su pedido",
sendOrder: "Enviar pedido",
alreadyCustomer: "Ya soy cliente",
orderCompleteTitle: "¡PEDIDO COMPLETADO!",
orderCompleteLine1: "Su pedido ha sido recibido con éxito.",
orderCompleteLine2: "Gracias por su pedido.",
isCompany: "Emitir factura",
companyAfm: "Número de IVA",
companyDoy: "Oficina de impuestos",
companyName: "Nombre de la empresa",
companyProfession: "Profesión",
bankTransfer: "Transferencia bancaria directa",
bankTransferText: "Transfiera a una de nuestras cuentas bancarias.",
bankLargeText: "Realice su pago directamente en nuestra cuenta bancaria. Incluya su ID de pedido en la transferencia bancaria. Su pedido se enviará después de verificar su transferencia de dinero.",
bank: "Banco",
nbgRadio: "Tarjeta de crédito a través del Banco NBG",
without: "Sin",
checkoutData: "Detalles de pago / entrega",
product: "PRODUCTO",
subTotal: "SUBTOTAL",
without: "SIN",
total: "TOTAL",
doYouHave: "¿Ya tiene una cuenta? Haga clic",
here: "aquí",
toConnect: " para conectarse.",
legalName: "Nombre legal",
legalDescription: "Tipo de empresa",
postalAddress: "Calle principal",
postalAddressNo: "Número",
postalAreaDescription: "Ciudad principal",
postalZipCode: "Código postal",
firmActDescription: "Ocupación principal",
companyPhone: "Teléfono",
companyEmail: "Correo electrónico",
doyDescription: "Nombre de la oficina de impuestos",
returnToShop: "VOLVER A LA TIENDA",
alergies: " ¿Tienes alergia o intolerancia a algún alimento? ¿Alguna especificidad nutricional? Póngase en contacto con la tienda antes de realizar su pedido."
}