export const commonAL = {
    firstName : "Emri",
    lastName : "Mbiemri",
    street : "Rruga",
    streetNumber : "Numri",
    city : "Qyteti",
    origin : "Rajoni",
    postcode : "Kodi Postar",
    phone : "Numri i telefonit",
    apartment : "Apartamenti",
    email : "Email",
    emailMessage : "Ne kurrë nuk do ta ndajmë email-in tuaj me askënd tjetër",
    checkout: "Pagesë",
    streetAndNumber : "Rruga & Numri",
    privacyStatement : "Deklarata e Privatësisë",
    termsOfService : "Kushtet e Shërbimit",
    termsOfServiceNoun : "Kushtet e Shërbimit",
    aboutUs : "Rreth nesh",
    product : "Produkt",
    products : "Produkte",
    subTotal : "Nëntotali",
    transportation : "Kosto e Transportit",
    total : "Totali",
    price : "Çmimi",
    quantity : "Sasia",
    items: "Artikujt",
    firstNameError : "Ju lutem shkruani një emër",
    lastNameError : "Ju lutem shkruani një mbiemër",
    phoneError : "Ju lutem shkruani një numër telefoni të vlefshëm",
    emailError : "Ju lutem shkruani një email të vlefshëm",
    save : "RUAJ",
    updateSuccess : "Përditësimi ishte i suksesshëm",
    updateError : "Gabim gjatë përditësimit",
    updated : "Përditësuar!",
    cartUpdated : "Ju e përditësuat me sukses shportën tuaj të blerjeve!",
    wishlistUpdated : "Ju e përditësuat me sukses listën tuaj të dëshirave!",
    streetError : "Ju lutem shkruani një rrugë",
    streetNumberError : "Ju lutem shkruani një numër rruge",
    cityError : "Ju lutem shkruani një qytet",
    order : "Porosi",
    logout : "Dil",
    warning : "PARALAJMËRIM",
    logoutWarning : "A jeni i sigurt që doni të dilni?",
    yes : "PO",
    no : "JO",
    loginRegistration : "Hyr/Regjistrohu",
    login : "HYR",
    passwordHelperText : "të paktën 8 karaktere duke përfshirë numra, shkronja të mëdha dhe të vogla",
    forgotPassword : "Keni harruar fjalëkalimin?",
    or : "ose",
    facebookLogin : "HYR ME FACEBOOK",
    googleLogin : "HYR ME GOOGLE",
    register : "Regjistrimi",
    registerButton : "KLIENT I RI",
    exit : "DAL",
    resetPassword : "Rivendos Fjalëkalimin",
    resetPasswordInfoA : "Ju lutem shkruani email-in tuaj",
    resetPasswordInfoB : "dhe do të merrni një fjalëkalim të ri.",
    registerA : "REGJISTROHU",
    registerInfoA : "Me regjistrimin tim, unë pranoj",
    registerInfoB : "kushtet e përgjithshme të shërbimit",
    registerInfoC : "dhe",
    registerInfoD : "Deklaratën e Privatësisë",
    registerInfoE : "dhe",
    registerInfoF : "kushtet e shërbimit të ofertave",
    verificationCode : "Konfirmo Kodin",
    verificationInfo : "Ju lutem shkruani kodin 4-shifror që u dërgua te:",
    enterCode : "Shkruani Kodin",
    errorCode : "Kod i Gabuar i Verifikimit, Ju lutem provoni përsëri",
    repeat : "RIDERGO",
    youSee : "Ju shihni",
    from : "nga",
    stockWarning: "Fatkeqësisht produkti që keni zgjedhur është jashtë stokut!",
    leaveAComment: "Lini një koment...",
    saveReview: "Ruaj Komentet",
    reviews: "Komente",
    qrCodeBuy: "Bleni shpejt dhe lehtë me një klik nga telefoni juaj!",
    qrCodeScanAndroid: "Skanoni për të shkarkuar aplikacionin tonë për Android",
    qrCodeDownload: "Shkarkoni aplikacionin tonë në Android",
    qrCodeBuyMobile: "Për të dyja Android dhe iOS, shkarkoni aplikacionin tonë të restorantit dhe porositni në çdo kohë nga telefoni juaj!",
    qrCodeScanIos: "Skanoni për të shkarkuar aplikacionin tonë për iOS",
    qrCodeForAndroid: "Për Android",
    qrCodeForIos: "Për iOS",
    photoUpload: "Ngarko Foto",
    edit: "EDITO",
    delete: "FSHIJ",
    addCart: "Shto në shportë",
    noEvaluation: "Nuk ka ende vlerësime.",
    onlyLoggedInCustomers: "Vetëm klientët e regjistruar që kanë blerë produktin mund të shkruajnë një koment.",
    beTheFirstReview: "Bëhuni i pari që shkruan një koment për këtë produkt.",
    seeMoreProducts: "Shiko më shumë produkte",
    favorite: "TË PREFERUARAT",
    favorites: "Të preferuarat",
    noFavoritesYet: "Ju nuk keni produkte të preferuara ende",
    deleteProduct: "A jeni i sigurt që doni të hiqni këtë produkt?",
    deleteProduct1: "A jeni i sigurt që doni të hiqni këtë produkt",
    fromCart: "nga shporta?",
    deleteAddress: "A jeni i sigurt që doni të fshini adresën?",
    danger: "PARALAJMËRIM",
    connectionIssue: "Kujdes! Dërgimi i porosisë nuk ishte i suksesshëm. Ju lutem kontrolloni lidhjen tuaj.",
    sendOrder: "Dërgo Porosinë",
    pleaseWait: "Ju lutem prisni...",
    noMoreProductsInStock: "Ju nuk mund të shtoni në shportën tuaj më shumë produkte sesa stoku",
    productAddedCart: "Produkti u shtua në shportën tuaj!",
    home: "Faqja Kryesore",
    myAccount: "Llogaria ime",
    ckeckout: "PAGESA",
    all: "Të gjitha",
    signIn: "Hyr",
    search: "Kërko",
    productSKU: "SKU",
    productSKUFull: "SKU i produktit:",
    language: "Gjuha",
    size: "MADHËSIA",
    extra: "Shtesë",
    removeIngredients: "Hiq përbërësit",
    status: "Statusi",
    searchResults: "Rezultatet e Kërkimit",
    noResults: "NUK KA REZULTATE",
    dailyProducts: "Produkte Ditore",
    offersText: "Oferta Ditore",
    popularText: "Më të Popullarizuarat",
    productComments: "Shkruani një koment ose një nevojë të veçantë (Opsionale)",
    weighted: "Produkt me Peshë",
    kgr: "Çmimi për kg",
    without: "PA",
    noSuchUser: "Nuk ekziston një përdorues me këtë email!",
    reset: "Rivendos.",
    lostPassword: "Keni harruar fjalëkalimin? ",
    loginOr: "ose hyni me",
    here: "këtu",
    orRegister: "Nuk keni një llogari? Regjistrohuni ",
    stockWarningHeader: "Paralajmërim Stoku",
    duplicateEmail: "Ky email po përdoret nga një llogari tjetër. Ju lutem provoni të hyni në vend.",
    cartUpdatedAlert: "Shporta e përditësuar",
    wishlistUpdatedAlert: "Lista e dëshirave e përditësuar",
    watchThis: "Shikoni këto!",
    pickSize: "Zgjidh një madhësi",
    addExtra: "Shto përbërës shtesë",
    logoutAccount: "Dil nga llogaria",
    deleteAccount: "Fshi llogarinë",
    deleteAccountMessage: "A dëshironi me të vërtetë të fshini llogarinë tuaj?",
    yesSmall: "Po",
    noSmall: "Jo",
    show: "Shfaq Shportën",
    removeItem: "Hiq Artikullin",
    youSure: "A jeni i sigurt?",
    cancel: "Anulo",
    scannerError: "Gabim gjatë skanimit. Ju lutem provoni përsëri.",
    scannerSuccess: "Mirësevini",
    scannerMessage: "Vazhdoni si mysafir në tavolinën tuaj",
    companyOrigin: "Rajoni Kryesor",
    addNewAddress : "Shto një adresë të re",
    holidayText: "Lokali do të mbetet i mbyllur sot",
    minimumOrderHeader: "Porosi Minimale",
    minimumOrder: "Porosia minimale është ",
    appleId: "Ky email është përdorur për hyrje me Apple",
    facebookId: "Ky email është përdorur për hyrje me Facebook",
    googleId: "Ky email është përdorur për hyrje me Google",
    emailPassword: "Ky email është përdorur për hyrje me email dhe fjalëkalim",
    loginError: "Gabim në hyrje: ",
    discount: "Zbritje",
    to: "Deri",
    openingTime: "Ora e Hapjes",
    more: "Më shumë",
    lastAddress: "Nuk mund të fshihet adresa e fundit",
    updateFailure: "Përditësimi dështoi",
    succesDeleteAddress: "Adresa u fshi me sukses.",
    noCategoryItemsYet: "Nuk ka produkte aktive në këtë kategori.",
    reserveTable: "Rezervo një tavolinë",
    reserveTableUC: "Rezervo një tavolinë",
    yourReservation: "Rezervimi juaj",
    chooseHour: "Zgjidhni orën",
    bookingComment: "Shto kërkesë speciale (opsionale)",
    party: "Grup",
    pickDate: "Zgjidhni Datën",
    partyOf: "Grup prej ",
    date: "Data",
    hour: "Ora",
    customerDiscount: "Zbritje për klientët",
    couponDiscount: "Kupon zbritje",
    voucherTitle: "Ju keni fituar një kupon me vlerë ",
    applyVoucher: "Aplikoni këtë kupon në shportën tuaj të blerjeve.",
    copy: "Kopjo",
    minimumVoucherOrder: "Porosi Minimale",
    minimumVoucherCaption: "Porosia minimale e kërkuar për të aplikuar zbritjen tuaj është",
    loyaltyTitle: "Shpërblimet e Besnikërisë",
    loyaltyCaption: "Ju keni fituar shpërblime besnikërie me vlerë totale",
    loyaltySubCaption: "Ato do të zbriten automatikisht gjatë pagesës.",
    loyaltyMoto:  "Vazhdoni të porositni për të fituar zbritje",
    endsIn: "Përfundon në",
    days: "ditë",
    table: "TAVOLINA",
    comments: "KOMENTET",
    dateCut: "Data",
    newOrder: "POROSI E RE",
    num: "Nr.",
    extras: "SHTESJE",
    tableOrder : "TABELA",
    phoneDelivery : "DELIVERY TELEFONIK",
    phoneTakeAway : "TAKE-AWAY TELEFONIK",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR TABELA",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR TABELA",
}