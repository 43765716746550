import React from 'react';
import { inject, observer } from "mobx-react";

const PrivacyTextFrench = ({ feathersStore })=> {
    return(<div className="terms-text">
    <p>Pour l'entreprise/magasin {feathersStore.settings?.companyName}, la protection des données personnelles est d'une importance capitale et est traitée avec le plus grand sérieux. Le respect des données personnelles que nous gérons et la garantie de leur traitement correct sont l'une des priorités de la Société.</p>
    <p>Pour cette raison, nous prenons toutes les mesures techniques et organisationnelles appropriées pour protéger les données personnelles que nous traitons et veiller à ce que leur traitement soit toujours conforme aux exigences établies par le cadre juridique en vigueur, en particulier par le Règlement Général sur la Protection des Données (RGPD) de l'UE 2016/679.</p>
    <a><strong>1. Personne de contact - Détails</strong></a>
    <p>{feathersStore.settings?.companyName} agit en tant que responsable du traitement de l'ensemble des données personnelles qu'il recueille, traite et stocke.</p>
    {feathersStore.settings?.companyName}<br/>
    Adresse : {feathersStore.settings?.address}<br/>
    Téléphone : {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber}<br/>
    Adresse e-mail : {feathersStore.settings?.email}<br/>
    Contact : {feathersStore.settings?.companyName}<br/><br/>
    <a><strong>2. Quelles données recueillons-nous et traitons-nous ?</strong></a><br/>
    <p>Afin de vous servir et de vous offrir nos services et produits, nous recueillons et traitons toutes les données que vous fournissez au représentant du centre d'appels lorsque vous passez une commande, ou les données que vous saisissez dans les champs correspondants lorsque vous ouvrez un compte utilisateur sur notre site web, telles que votre nom, adresse postale, numéro de téléphone de contact (fixe ou mobile), adresse e-mail, informations fiscales (si vous souhaitez émettre une facture).<br/><br/> Veuillez noter que le site web de notre entreprise peut contenir des liens (liens) vers d'autres sites web. Notre entreprise n'est pas responsable des pratiques de confidentialité et de protection des données et/ou du contenu de ces sites web. Si vous avez moins de 15 ans, obtenez la permission de vos parents ou tuteurs avant de partager des informations personnelles avec nous.</p>
    <a><strong>3. Fins et bases légales du traitement</strong></a><br/>
    <p>Toutes les données personnelles susmentionnées sont principalement collectées et traitées dans le but d'exécuter le contrat entre nous pour la vente de nos produits, en particulier pour la préparation et la livraison de votre commande et notre communication avec vous à ce sujet. Certaines de vos données peuvent également être utilisées à d'autres fins, toujours avec votre consentement approprié :<br/><br/>
    - Vous contacter en relation avec des concours, des tirages au sort et d'autres événements.<br/>
    - Mettre à jour et envoyer des offres et du matériel informatif sur nos produits et services.<br/>
    - Fins statistiques et de recherche.<br/>
    - Promotion et promotion de nos produits et services.</p>
    <p>Les bases légales du traitement des données peuvent varier en fonction des cas :<br/>
    i. L'exécution et l'accomplissement du contrat entre nous pour la vente de nos produits à vous (préparation et livraison de votre commande). Sans la collecte et l'utilisation des informations susmentionnées, nous ne pourrions pas vous servir.<br/>
    ii. Le consentement que vous pouvez nous accorder pour recevoir des mises à jour sur les offres ou du matériel promotionnel pour nos produits et services, des actions commerciales et des activités que nous développons, etc. Vous pouvez retirer votre consentement à tout moment en nous contactant aux coordonnées mentionnées au paragraphe 1 ou via notre site web.</p>
    <a><strong>4. Où partageons-nous vos données personnelles ?</strong></a><br/>
    <p>Vos données personnelles peuvent être partagées avec les destinataires suivants :<br/>
    - Avec les distributeurs pour préparer et livrer la commande.<br/>
    - Avec une société d'envoi de newsletters (si vous avez donné votre consentement pour recevoir une newsletter).<br/>
    - Avec une société d'envoi de SMS (si vous avez donné votre consentement pour recevoir des mises à jour).<br/>
    - Avec des entreprises partenaires, afin de recevoir votre prix si vous êtes un gagnant dans un concours auquel vous avez participé (chaque fois que nous organisons un concours, nous mentionnons dans les termes du concours la possibilité de partager vos données avec l'entreprise partenaire).<br/>
    - Avec un partenaire externe qui nous soutient dans les questions logicielles.<br/>
    - Avec le service juridique collaborateur de la société, en cas de litige judiciaire ou extrajudiciaire.<br/>
    - Avec la police ou d'autres autorités gouvernementales si nous sommes officiellement sollicités à le faire, ou après une ordonnance du procureur.</p>
    <a><strong>5. Durée de conservation des données</strong></a><br/>
    <p>Vos données sont conservées dans la base de données de {feathersStore.settings?.companyName} pendant 5 ans après votre dernière transaction avec l'entreprise, ou aussi longtemps que la loi l'exige (par exemple, dans le cas de l'émission d'une facture, pendant la période définie par le cadre légal fiscal).<br/><br/>
    Si le traitement est basé sur votre consentement, les données personnelles sont conservées jusqu'à ce que vous retiriez ce consentement. Il est précisé que le retrait du consentement n'affecte pas la légalité du traitement qui était basé sur le consentement avant son retrait.<br/>
    En tout état de cause, {feathersStore.settings?.companyName} met en place toutes les mesures techniques et organisationnelles appropriées pour garantir la protection des données personnelles qu'elle traite et veille constamment à empêcher tout accès non autorisé à ces données.<br/></p>
    <a><strong>6. Droits liés à vos données personnelles</strong></a><br/>
    <p>En ce qui concerne le traitement de vos données personnelles, vous pouvez exercer les droits suivants :<br/><br/>
    Droit d'accès<br/>
    Vous avez le droit de savoir quelles données nous détenons et traitons à votre sujet, pourquoi nous les détenons et d'autres informations complémentaires à ce sujet, ainsi que de nous demander une copie de ces données.<br/><br/>
    Droit de rectification<br/>
    Vous avez le droit de demander la correction, la modification et le complètement de vos données personnelles.<br/><br/>
    Droit à l'effacement ("droit à l'oubli")<br/>
    Vous avez le droit de demander l'effacement de vos données personnelles lorsqu'elles sont traitées avec votre consentement. Dans les cas où le traitement est basé sur une autre base légale (comme, par exemple, l'exécution d'un contrat, une obligation légale ou la protection des intérêts légaux de l'entreprise, etc.), votre droit peut être soumis à des limitations ou peut ne pas exister.<br/><br/>
    Droit à la limitation du traitement<br/>
    Vous avez le droit de demander la limitation du traitement de vos données personnelles :<br/>
    - lorsque leur exactitude est contestée, et ce jusqu'à ce que la vérification correspondante soit effectuée.<br/>
    - alternativement, au lieu de leur suppression.<br/>
    - lorsqu'elles ne sont plus nécessaires pour nous aux fins de traitement pour lesquelles elles ont été recueillies, mais sont nécessaires pour l'établissement, l'exercice ou la défense de droits légaux de votre part.<br/>
    - lorsque vous vous opposez à leur traitement, et ce jusqu'à ce qu'il soit vérifié qu'il existe des motifs légitimes à ce traitement par l'entreprise.<br/><br/>
    Droit d'opposition au traitement<br/>
    Vous avez le droit de vous opposer au traitement de vos données personnelles lorsqu'il repose sur un intérêt légitime, ainsi que dans le cadre du marketing direct et de l'élaboration de profils.<br/><br/>
    Droit à la portabilité<br/>
    Vous avez le droit de demander et de recevoir vos données personnelles dans un format qui vous permet d'y accéder, de les utiliser et de les traiter avec des méthodes de traitement courantes. De plus, pour vos données que nous traitons par des moyens automatisés et sur la base de votre consentement ou pour l'exécution d'un contrat, vous pouvez nous demander de les transférer directement à un autre responsable du traitement, si cela est techniquement possible.<br/><br/>
    Droit de retirer son consentement<br/>
    Si le traitement de vos données repose sur votre consentement, vous avez le droit de le retirer à tout moment. Le retrait de votre consentement n'affecte pas la licéité du traitement qui reposait sur le consentement avant son retrait.<br/><br/>
    Droit de supprimer un compte<br/>
    Vous avez un contrôle unique et absolu sur vos informations et pouvez les modifier ou les supprimer de votre compte à tout moment sans le consentement ou le consentement du magasin.<br/>
    Il en va de même pour l'ensemble de votre compte puisqu'en entrant les paramètres, vous pouvez procéder à l'option correspondante.<br/><br/>
    Pour exercer les droits susmentionnés et pour toute question, réclamation ou autre information concernant le traitement de vos données personnelles, vous pouvez nous contacter à l'adresse e-mail {feathersStore.settings?.email} ou aux coordonnées mentionnées au paragraphe 1.<br/></p>
    <a><strong>7. Droit de plainte auprès de l'Autorité de protection des données (APD)</strong></a><br/>
    <p>Vous avez le droit de déposer une plainte auprès de l'Autorité de Protection des Données Personnelles (www.dpa.gr) en ce qui concerne le traitement de vos données personnelles.</p>
    <a><strong>8. Modifications de la protection des données</strong></a><br/>
    <p>Toutes les modifications futures de la Politique de Confidentialité de notre entreprise seront publiées sur le site web {feathersStore.settings?.siteUrl} et, le cas échéant, vous seront communiquées.</p>
    <a><strong>9. Politique de cookies</strong></a><br/>
    <p>Étant donné que la boutique en ligne utilise des technologies modernes, aucun cookie n'est utilisé avec vos données, même si vous vous êtes inscrit dans notre boutique en ligne. Les seuls cookies utilisés concernent le service Google Analytics, tant qu'il est activé. Dans ce cas, des statistiques générales sur les visites des pages de la boutique en ligne sont conservées.</p>
  </div>
  
    )
}

export default inject('feathersStore')(observer(PrivacyTextFrench));