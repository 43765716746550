import React from 'react';
import { inject, observer } from "mobx-react";

const TermsTextFrench = ({ feathersStore })=> {
    return(<div className="terms-text">
    <p>Pour finaliser vos commandes, il vous sera demandé à chaque fois de confirmer que vous acceptez les conditions d'utilisation suivantes :</p>
    <ul>
      <li>
        <p>Les droits d'auteur du contenu (par exemple, les programmes, les informations, les données) et les services du site Web - site Web qui ont été mis en ligne sur le réseau sont protégés par les lois sur le droit d'auteur grecques, communautaires et internationales.</p>
      </li>
      <li>
        <p>Toute copie, distribution, transmission, modification, revente, création d'œuvre dérivée ou désinformation concernant le véritable fournisseur de contenu du site Web est interdite. Toute reproduction, republication, téléchargement, annonce, diffusion ou transmission, ou toute autre utilisation du contenu de quelque manière ou moyen que ce soit à des fins commerciales ou autres n'est autorisée qu'avec la permission écrite préalable du site Web ou de tout autre détenteur des droits d'auteur.</p>
      </li>
      <li>
        <p>La boutique en ligne - site Web fournit le contenu (par exemple, des informations, des noms, des photos, des illustrations), des produits et des services disponibles via le site Web "tel qu'ils sont". En aucun cas, la boutique en ligne - site Web ne peut être tenue pour responsable de toute réclamation légale, civile et/ou pénale, ni de tout dommage (positif, spécial ou consécutif, qui consiste de manière indicative, mais non limitative, de manière disjonctive ou cumulative, à une perte de bénéfices, de données, de profits perdus, de satisfaction monétaire, etc.) provenant de visiteurs du site Web ou de tiers pour une raison liée au fonctionnement ou à la non-fonctionnement ou à l'utilisation du site Web ou à l'incapacité de fournir des services ou des informations disponibles à partir de celui-ci ou de toute intervention non autorisée par des tiers dans les produits, les services ou les informations disponibles via celui-ci.</p>
      </li>
      <li>
        <p>Les photos des produits sont indicatives et nous ne sommes pas responsables des erreurs d'orthographe.</p>
      </li>
      <li>
        <p>Les prix sont sujets à modification sans préavis. Un engagement de prix ne peut être pris que pour une commande en ligne et est confirmé lors de l'envoi de sa confirmation. La boutique portant le nom commercial - site Web n'est pas tenue de fournir de garantie quant à la disponibilité de ses produits, mais s'engage à informer immédiatement ses clients de toute rupture de stock ou de tout retard de livraison.</p>
      </li>
      <li>
        <p>Le client, une fois que la commande a été traitée par le service des commandes et qu'il a confirmé sa commande, garantit qu'il recevra le produit au prix qu'il a "accepté" lors de la transaction, même si le prix change en cours de route. En cas d'affichage de produits ou de services sur le site Web à un prix incorrect en raison d'une erreur typographique, les commandes sont automatiquement annulées. Le site Web n'est pas responsable des dommages subis par le client/utilisateur de la boutique en ligne en raison de l'exécution ou de la non-exécution des commandes ou du retard dans l'exécution pour quelque raison que ce soit.</p>
      </li>
      <li>
        <p>La boutique en ligne - site Web se réserve le droit de modifier, d'ajouter, de changer le contenu ou les services du site Web, ainsi que les conditions d'utilisation, chaque fois qu'elle le juge nécessaire et sans préavis, en les annonçant uniquement sur son site Web.</p>
      </li>
    </ul>
    <p>En fonction de la méthode de paiement choisie par le client lors de sa commande, le remboursement sera effectué comme suit :</p>
    <ul>
      <li>
        <p>Pour une carte de crédit en ligne, le remboursement sera effectué sur la carte.</p>
      </li>
      <li>
        <p>Pour le paiement en espèces à la livraison ou par virement bancaire, vous devrez nous fournir l'IBAN d'un compte bancaire, le nom du bénéficiaire ainsi que la banque concernée.</p>
      </li>
    </ul>
  </div>  
    )
}

export default inject('feathersStore')(observer(TermsTextFrench));