import React from 'react'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

const FavoriteButton = ({onClick, color})=> {

  function changeCursor(e) {
      e.target.style.cursor = 'pointer';
    }

  return(
    
    <div className="favorite-button" style={{backgroundColor: color}}>
    <FavoriteBorderIcon 
      onMouseOver={changeCursor} 
      onClick={onClick} 
      style={{fontSize: 24}}/>
    </div>
  )
}

export default FavoriteButton;