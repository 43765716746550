import React, {useState, useEffect, useRef} from 'react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import TermsNConditions from '../terms/termsnconditions';
import About from '../about';
import LogoutComponent from './logoutComponent';
import { inject, observer } from "mobx-react";
import { useNavigate } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import DeleteAccount from '../../modals/deleteAccount';
import { DEFAULT_EMAIL, DEFAULT_PSW } from "../../assets/globalConstants";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const inputIcon = {
  fontSize: 35
}

const Settings = ({ feathersStore })=> {

  let common = _useTranslate(feathersStore.language, "common"); 

  const [termsVisible, setTermsVisible] = useState(false);
  const [aboutVisible, setAboutVisible] = useState(false);
  const [logoutVisible, setLogoutVisible] = useState(false);
  const [loading, setLoading] = useState(false);  
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [deleting, setDeleting] = useState(false);


  const isMountedRef = useRef(null);

  let navigate = useNavigate();
  
  useEffect(() => {
    isMountedRef.current = true; 
   // setAddresses()   
    return () => isMountedRef.current = false;
  }, [])

  const toggleTerms = () => {
    setTermsVisible(!termsVisible);
  }

  const toggleAbout = () => {
    setAboutVisible(!aboutVisible);
  }

  const toggleLogout = () => {
    setLogoutVisible(!logoutVisible);
  }

  const logout = async()=> {
    isMountedRef.current && setLoading(true);
    try{
        await feathersStore.logout();      
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
            navigate('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch(error){
        console.log('ERROR LOGGING OUT ', error);
        isMountedRef.current && setLoading(false);
    }   
  }
  
  const removeUser = async() => {
    isMountedRef.current && setDeleting(true);
    try{
      await feathersStore.removeUser();    
      navigate('/') ;          
      isMountedRef.current && setDeleting(false);
    }catch(error){
      console.log('ERROR DELETING USER ', error);
      isMountedRef.current && setDeleting(false);
    }  
  }

  return(

    <div className="settings-container">
      <div className="change-password-container settings-margin cursor-pointer" onClick={() => toggleTerms()}>
        <div className="changePassTitle">
          <ListAltIcon sx={inputIcon}/>
          <span>{common.termsOfServiceNoun}</span>
        </div>
        {termsVisible ? 
        <div className="column-flex-center" aria-labelledby='cancel presentation'>
          <ExpandLess sx={inputIcon}/>
        </div>
        :
        <div className="column-flex-center" aria-labelledby='expand more'>
          <ExpandMore sx={inputIcon}/>
        </div>   
        }
      </div>
      <CSSTransition
        in={termsVisible}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <TermsNConditions/>
      </CSSTransition>      
      <div className="change-password-container settings-margin cursor-pointer" onClick={() => toggleAbout()}>
        <div className="changePassTitle" >
          <InfoIcon sx={inputIcon}/>
          <span>{common.aboutUs}</span>
        </div>
        {aboutVisible ? 
        <div className="column-flex-center" aria-labelledby='cancel presentation'>
          <ExpandLess sx={inputIcon}/>
        </div>
        :
        <div className="column-flex-center" aria-labelledby='expand more'>
          <ExpandMore sx={inputIcon}/>
        </div>   
        }
      </div>
      <CSSTransition
        in={aboutVisible}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <About/>
      </CSSTransition>
      <div className="change-password-container settings-margin cursor-pointer" onClick={() => toggleLogout()}>
        <div className="changePassTitle">
          <LogoutIcon sx={inputIcon}/>
          <span>{common.logoutAccount}</span>
        </div>
        {logoutVisible ? 
        <div className="column-flex-center" aria-labelledby='cancel presentation'>
          <ExpandLess sx={inputIcon}/>
        </div>
        :
        <div className="column-flex-center" >
          <ExpandMore sx={inputIcon}/>
        </div>   
        }
      </div>      
      <CSSTransition
        in={logoutVisible}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <LogoutComponent     
          heading={common.logout} 
          message={common.logoutWarning}
          no={common.noSmall}
          yes={common.yesSmall}      
          onClickNo={toggleLogout}
          deleteItem={logout}
          loading={loading}
        />
      </CSSTransition>
      <div className="line-45"></div>
      <div className="change-password-container settings-margin cursor-pointer">
        <div className="changePassTitle">
          <DeleteForeverIcon sx={inputIcon}/>
          <span className="delete-account-text" onClick={() => setDeleteAccount(true)}>{common.deleteAccount}</span>
        </div>       
      </div> 
      <DeleteAccount
        show={deleteAccount}
        loading={deleting}
        heading={common.deleteAccount}
        onDelete={removeUser}
        onClose={() => setDeleteAccount(false)}
        message={common.deleteAccountMessage}
        no={common.noSmall}
        yes={common.yesSmall}
      />
    </div>  
  )
}

export default inject('feathersStore')(observer(Settings));