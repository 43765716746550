import React from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FaqText from './faqText';
import FaqTextEnglish from './faqTextEnglish';
import FaqTextFrench from './faqTextFrench';
import FaqTextGerman from './faqTextGerman';
import FaqTextItalian from './faqTextItalian';
import FaqTextSpanish from './faqTextSpanish';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const Faq = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");

  const pickText = () => {
    switch (feathersStore.language){
        case "el" : {
            return <FaqText/>;
        }
        case "en" : {
            return <FaqTextEnglish/>;
        }
        case "de" : {
            return <FaqTextGerman/>;
        }
        case "es" : {
            return <FaqTextSpanish/>;
        }
        case "fr" : {
            return <FaqTextFrench/>;
        }
        case "it" : {
            return <FaqTextItalian/>;
        }
        case "ar" : {
            return <FaqTextEnglish/>;
        }
      }
    }
    
    return(
        <div className="settings-sub-container">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
            </div>
            <p className="terms-title">{`-${footer.faq}-`}</p>
            <div className="terms-container">
                {pickText()}            
            </div> 
        </div>
    )
}

export default inject('feathersStore')(observer(Faq));