import React from 'react';
import ButtonMinus from '../buttons/buttonMinus';
import ButtonPlus from '../buttons/buttonPlus';
import AppColors from '../assets/colors'
import DeleteButton from '../buttons/deleteButton'
import AddToCartButton from '../buttons/addToCartButton'
import '../components/cart/Cart.css';
import { inject, observer } from "mobx-react";
import {TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";


const FavoriteComponent = ({
  image,
  handleClick,
  title,
  onClickDelete,
  price,
  onClickMinus,
  onClickPlus,
  counter,
  onClickAddtoCart,
  attributes,  
  weighted,
  soldOut,
  feathersStore
})=> {

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }
    
  return(
   
    <div className="cartProductCard position-relative">
      <div 
        className="cartProductCardPic" 
        style={{backgroundImage: `url(${image})`}}
        onClick={handleClick}
      >
      </div>
      <div className="details-container">
        <div className="cartProductCardDetails">   
          <div className="cart-product-title">{title}</div>  
          {attributes?.length > 0 && attributes.map((attribute, index) => (
            
            <div key={index} className="attributeDetails">
              <div>{feathersStore._translateTitle(attribute)}&nbsp;</div>
              <div>{feathersStore._translateNameAttr(attribute)}&nbsp;</div>
              <div>{attribute[packagePricing() ? 'packagePrice' : 'price']}€</div>
              <div className="favorite-red-star">*</div>
            </div>
            
          ))}      
        </div>  
        <div className="delete-button-container">
          <DeleteButton onClick={onClickDelete}/>
        </div>  
        <div className="cartProductCardDetails-P-Q-container">         
          <div className="cartProductCardDetails-Price">
            {`${parseFloat(price).toFixed(2)}€`}
          </div>
          <div className="cartProductCardDetails-Quantity">
            <ButtonMinus onClick={onClickMinus} green={true}/>
              <span style={{margin:"10px"}}>{counter}</span>
            <ButtonPlus onClick={onClickPlus} green={true}/>
          </div>          
        </div>       
      </div> 
      { !QR_READ_ONLY &&   
        <div className="favoriteCard-CartButtonContainer">
          <AddToCartButton
            disabled={counter === 0 || (TABLE_QR_ORDERING_ONLY && (!feathersStore.tableId?.length > 0)) || !feathersStore.restaurantOpen || soldOut || weighted}
            onClick={onClickAddtoCart}
          />
        </div>
      }
    </div>
  )
}

export default inject('feathersStore')(observer(FavoriteComponent));