import React, {useState} from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditIcon from '@mui/icons-material/Edit';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import AddOrEditaddressModal from '../../modals/addOrEditAddressModal/AddOrEditAddressModal';
import AddressDeleteModal from '../../modals/addressDeleteModal';

const AddressComponent = ({
  onPress,
  item, 
  itemIndex,
  title,
  street,
  city,
  apartment,
  postcode,
  streetNumber,
  origin,
  active
}) => {

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  const [visibleAddNewAddress, setAddNewAddress] = useState(false);
  const [visibleDeleteAddress, setDeleteAddress] = useState(false);

  const  editAddress = () => {     
    setAddNewAddress(true); 
  };

  const closeNewAddress = () =>{
    setAddNewAddress(false); 
  }

  const  openDeleteAddressModal = () => {     
    setDeleteAddress(true);  
  };

  const closeDeleteAddressModal = () =>{
    setDeleteAddress(false); 
  }
  
  return (
    <>
    <div className={active ? "wishlist-object address-object-active" : "wishlist-object"}> 
      <div className="address-object-radio">      
        <FormControlLabel 
          value={active} 
          control={<Radio sx={{
            color: "grey",
            '&.Mui-checked': {
              color: "whitesmoke",
            },
          }}/>} 
          classes={active ?  { label: 'active-address-label' } : { label: 'addresss-label' }} 
          label={title} 
          checked={active}
          onChange={ onPress}/>     
      </div>
      <div className="address-object-info-container">
        {`${street} ${streetNumber}, ${city}, `}&nbsp;
        <a style={active ? {color: 'whitesmoke'} : {color: 'grey'}}>{`Όροφος: ${apartment}, ${postcode}`}</a>
      </div>
      <div className="address-object-end">
        <div className={active ? "address-object-button button-active": "address-object-button"}  onMouseOver={changeCursor} onClick={editAddress} aria-label='Change address'>
          <EditIcon style={active ? {color: 'whitesmoke'} : {color: 'grey'}}/>
        </div>
        <div className={active ? "address-object-button button-active": "address-object-button"} onMouseOver={changeCursor} onClick={openDeleteAddressModal} aria-label='Delete address'>
          <CloseOutlinedIcon style={active ? {color: 'whitesmoke'} : {color: 'grey'}}/>
        </div>
      </div>
     
    </div> 
      <AddOrEditaddressModal 
        visible={visibleAddNewAddress} 
        close={closeNewAddress} 
        itemToEdit={item}
        itemIndex={itemIndex}
      />
      <AddressDeleteModal 
        visible={visibleDeleteAddress} 
        itemIndex={itemIndex} 
        onClickNo={closeDeleteAddressModal} 
        itemToDelete={item}
      />
    </>
  );
}

export default AddressComponent;
