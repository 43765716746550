const PUBLIC_API_URL = window.PUBLIC_API_URL;
const HIDE_QR_CODES = window.HIDE_QR_CODES;
const HIDE_APPD = window.HIDE_APPDATE;
const TITLE = window.DOCUMENT_TITLE;
const DESCR = window.META_DESCRIPTION;
const ROBOTS = window.META_ROBOTS;
const APP_THEME = window.THEME;
const APP_SHOW_REVIEWS = window.SHOW_REVIEWS;
const RESTRICT_ORDERING = window.TABLE_QR_ORDERING_ONLY;
const SIMPLE_QR_VAR = window.QR_READ_ONLY;


export const API_URL = PUBLIC_API_URL;
export const HIDE_QR = HIDE_QR_CODES;
export const HIDE_APPDATE = HIDE_APPD;
export const DOCUMENT_TITLE = TITLE;
export const META_DESCRIPTION = DESCR;
export const META_ROBOTS = ROBOTS;
export const THEME = APP_THEME;
export const SHOW_REVIEWS = APP_SHOW_REVIEWS;
export const TABLE_QR_ORDERING_ONLY = RESTRICT_ORDERING;
export const QR_READ_ONLY = SIMPLE_QR_VAR;

//export const SANDBOX_CLIENT_ID = "Ab9xBziZRnJjCwgOIgQb-uTWsr6cWG1B5nCVup5m2EcrZib1dD7V70LViTk6rL3ZvPH0Sg3IAFQDBLFZ"
//export const PRODUCTION_CLIENT_ID = "Aan6sxIsCGqkQgK57MaPULPsAmfwmuIxAQOEiM7qN1zD32LHzayz4PtlC4Z2AD0AMsdZBYS38rGdq6-x"
//export const GOOGLE_CLIENT_ID = "597039682294-a9dbtm4j2u43o5io54kb3rnleg57vk87.apps.googleusercontent.com"
//export const FACEBOOK_APP_ID = "443563063396175"
//export const NBG_PUBLIC_KEY = "lvpb_NzM1OWUxNjAtZjM3Ni00OTg0LWIwMjEtYTA3ZGUwZWEzODkw"
//export const TABLE_QRCODE_PSW = "Hv63sbUg"

export const ADMIN_ID = '5e1748e37073e110a0daa4c1';
export const DEFAULT_EMAIL = "defaultUser@gmail.com";
export const DEFAULT_PSW = ")j~nKj/,N}N6,8&cVVV#G!=F*y";
export const PHONE_REGEX =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/ ;