import React,{ useState, useEffect } from 'react';
import {API_URL, TABLE_QR_ORDERING_ONLY, QR_READ_ONLY} from "../assets/globalConstants";
import FavoriteComponent from '../cards/favoriteComponent'
import SmallAlert from '../modals/smallAlert';
import { inject, observer } from "mobx-react";
import { CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import "./cart/Cart.css"

// Translations
import _useTranslate from '../hooks/_useTranslate';

const Favorites = ({ feathersStore })=> {

  let navigate = useNavigate();
  let common = _useTranslate(feathersStore.language, "common");

  const [cartIncreased, setCartIncreased] = useState(false);
  const [favoritesUpdated, setFavoritesUpdated] = useState(false);
  const [favoriteProducts, setFavoriteProducts] = useState([]);   
  const [loading, setLoading] = useState(true);
  const [warning, setWarning] = useState(false);

  useEffect(()=>{
    feathersStore.isAuthenticated && onLoad();
    favoriteProducts && setLoading(false);
  }, [feathersStore.isAuthenticated, feathersStore?.favoritesArray, feathersStore?.settings
        , feathersStore.productCreatedEvent, feathersStore.productUpdatedEvent])

  const onLoad = async() => {    
    const feathersProducts = await feathersStore.wishlistProducts();
    feathersProducts.map(product => {
      product.product_quantity = 0;    
      product.attributeItems = [];
      product.extraSum = 0;
      product.product_totalPrice = +product[packagePricing() ? 'packagePrice' : 'retailPrice'];       
      product.sizes.filter(s => s.default)
        .map(fs => product.product_size = fs);
      product?.savedAttributesTables && product.savedAttributesTables.forEach( table => {
        if(table?.mandatory && table.eshop){
          Object.assign(table.savedAttributesArray[0], {picked: true, title: table.title, titleTranslated: feathersStore._translateTitle(table)}); 
          product.attributeItems.push(table.savedAttributesArray[0]);  
          product.extraSum += parseFloat(table.savedAttributesArray[0][packagePricing() ? 'packagePrice' : 'price']);  
          product.product_totalPrice += parseFloat(table.savedAttributesArray[0][packagePricing() ? 'packagePrice' : 'price']);
        }
      });       
    });       
    feathersProducts && setFavoriteProducts([...feathersProducts]);         
  }

  const onPressAdd = item => () => {
    let cart = [];   
    if(localStorage.getItem('fdwa_cart')){cart = JSON.parse(localStorage.getItem('fdwa_cart'));} 
    const clonedItem = JSON.parse(JSON.stringify(item)) 
    let clonedFavoriteProducts = [...favoriteProducts];  
    const index = favoriteProducts.indexOf(item);  
    if(clonedItem.product_quantity == null){
      clonedItem.product_quantity = 1;          
    }else{
      clonedItem.product_quantity +=1;
    }   
    if (feathersStore.checkOutOfStock(item._id, item.handleStock, item.stock, clonedItem.product_quantity, cart)){
      setWarning(true); return;
    } 
    clonedItem.product_totalPrice = (+clonedItem[packagePricing() ? 'packagePrice' : 'retailPrice'] + +clonedItem.extraSum) * clonedItem.product_quantity; 
    clonedFavoriteProducts.splice(index, 1, clonedItem);  
    setFavoriteProducts([...clonedFavoriteProducts]);   
  };

  const onPressRemove = item => () => {  
    const clonedItem = JSON.parse(JSON.stringify(item)) 
    let clonedFavoriteProducts = [...favoriteProducts]; 
    const index = favoriteProducts.indexOf(item);
    if(clonedItem.product_quantity > 0){
      clonedItem.product_quantity -=1;
      clonedItem.product_totalPrice = clonedItem.product_quantity > 0 ?
      (+clonedItem[packagePricing() ? 'packagePrice' : 'retailPrice'] + clonedItem.extraSum) * clonedItem.product_quantity :
      (+clonedItem[packagePricing() ? 'packagePrice' : 'retailPrice']+ clonedItem.extraSum);
      clonedFavoriteProducts.splice(index, 1, clonedItem);
      setFavoriteProducts([...clonedFavoriteProducts]);
    }   
  
    }; 

  const onPressDeleteFromFavorites = async(product, index)=> {
    try{
      let clonedFavoriteProducts = [...favoriteProducts];    
      clonedFavoriteProducts.splice(index, 1); 
      setFavoriteProducts([...clonedFavoriteProducts]);  
      const ind = feathersStore.favoritesArray.indexOf(product._id);  
      feathersStore.favoritesArray.splice(ind, 1);      
      await feathersStore.updateWishlist();
      setFavoritesUpdated(true);
    }catch(error){
      console.log(error);
    }
  }

  const addtoCart = item => () => {
    let cart = [];
    let cartBiggestId = 0;
    if(localStorage.getItem('fdwa_cart')){
      cart = JSON.parse(localStorage.getItem('fdwa_cart'));    
      if(cart.length > 0)
        cartBiggestId = +cart.map(e => e.id).sort().reverse()[0] + 1;         
    }        
    const cartItem = {
      id: cartBiggestId,
      product_id: item._id,
      product_name: item.name,
      product_nameTranslated: feathersStore._translateName(item),
      product_size: item.product_size,
      product_image: `${API_URL}/images/${item.images[0]}`,
      product_price: item[packagePricing() ? 'packagePrice' : 'retailPrice'],
      product_totalPrice: parseFloat(item.product_totalPrice),
      product_netPrice: calculateNetPrice(item),
      product_quantity: item.product_quantity,
      product_extraSum: item.extraSum,
      features: [],
      ingredients: [],
      attributes: item.attributeItems,   
      product_printers: item.printers,
      product_kdsSections: item.kdsSections,
      product_vat: item[packagePricing() && item?.packageVat ? 'packageVat' : 'vat'],
      product_stock: item.stock,
      product_handleStock: item.handleStock,
      product_comments: item.comments,
      product_weightedPrice: 0,
      product_weightedQuantity: 0,
      nameTranslations: item?.nameTranslations,
      shortDescriptionTranslations: item?.shortDescriptionTranslations
    }  
    cart.push(cartItem);
    feathersStore.setCartLength(feathersStore.cartLength + 1); 
    localStorage.setItem("fdwa_cart", JSON.stringify(cart));
    resetItem(item);
  }

  const packagePricing = () => {
    return  feathersStore.settings?.deliveryPricing && !feathersStore?.tableId && !(TABLE_QR_ORDERING_ONLY && QR_READ_ONLY);
  }

  const calculateNetPrice = item => {
    const label = feathersStore.settings.vats
      ?.find(v => +v.id === +item[packagePricing() && item?.packageVat ? 'packageVat' : 'vat'])?.label
    return +((item.product_totalPrice / item.product_quantity) / (1 + label / 100 )).toFixed(2);
  }

  const resetItem = item => {
    let clonedFavoriteProducts = [...favoriteProducts];
    const index = favoriteProducts.indexOf(item);
    item.product_quantity = 0;
    item.product_totalPrice = +item[packagePricing() ? 'packagePrice' : 'retailPrice'] + +item.extraSum;
    clonedFavoriteProducts.splice(index, 1, item);
    setFavoriteProducts([...clonedFavoriteProducts]);
    setCartIncreased(true); 
  }

  const goToProduct =  product =>  {    
    navigate(`/product/${feathersStore._translateName(product)}`,{
      state: {id: `${product._id}`,  payload: product}     
    });
  }

  const renderItems = favoriteProducts?.map((item, index) => (      
    <React.Fragment key={index}>
      <FavoriteComponent       
        image={item?.images[0] ? (`${API_URL}/images/` + item?.images[0]) : require("../img/200_spinner.gif")}
        title={feathersStore._translateName(item)}
        price={item.product_totalPrice}
        onClickDelete={() => onPressDeleteFromFavorites(item, index)}
        counter={item.product_quantity ? item.product_quantity : 0}
        onClickPlus={onPressAdd(item)}
        onClickMinus={onPressRemove(item)}
        onClickAddtoCart={addtoCart(item)}
        handleClick={() => goToProduct(item)}
        attributes={item.attributeItems}
        soldOut={item?.soldOut}
        weighted={item?.weighted}
      />
    </React.Fragment>
  ))
  
  return(
    <>
   
   <div className="body-container">
       <div className="grey-header">{common.favorites}</div>     
      <div className="cart-body">      
        {loading ?        
        <CircularProgress 
          color="secondary" 
          style={{ margin: 6 }} 
          size={'4rem'}            
        />
        :
        <div className="table-container">
          <div className="table-body">          
            {favoriteProducts?.length > 0 ?
              renderItems :
              <div className='column-flex-center-100' style={{color: 'grey', fontSize: 24, fontWeight: 'bold', marginTop:"1vw"}}>
                {common.noFavoritesYet}</div>}       
          </div>
        </div> 
        }
      </div>
      <SmallAlert 
        show={cartIncreased} 
        mode="cart"
        heading={common.cartUpdatedAlert} 
        onClose={() => setCartIncreased(false)} 
        message={common.cartUpdated} />
      <SmallAlert 
        show={favoritesUpdated} 
        mode="favorite"
        heading={common.wishlistUpdatedAlert} 
        onClose={() => setFavoritesUpdated(false)} 
        message={common.wishlistUpdated} />
      <SmallAlert 
        show={warning} 
        mode="stock"
        heading={common.stockWarningHeader} 
        onClose={() => setWarning(false)}  
        message={common.stockWarning}
      />    
    </div>
    </>
  )
}

export default inject('feathersStore')(observer(Favorites));
