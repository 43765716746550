export const commonDE = {
    firstName: "Vorname",
    lastName: "Nachname",
    street: "Straße",
    streetNumber: "Nummer",
    city: "Stadt",
    origin: "Region",
    postcode: "PLZ",
    phone: "Telefonnummer",
    apartment: "Apartment",
    email: "E-Mail",
    emailMessage: "Wir werden Ihre E-Mail-Adresse niemals an Dritte weitergeben",
    checkout: "Kasse",
    streetAndNumber: "Straße & Nummer",
    privacyStatement: "Datenschutzerklärung",
    termsOfService: "Nutzungsbedingungen",
    termsOfServiceNoun: "Nutzungsbedingungen",
    aboutUs: "Über uns",
    product: "Produkt",
    products: "Produkte",
    subTotal: "Zwischensumme",
    transportation: "Versand",
    total: "Gesamt",
    price: "Preis",
    quantity: "Menge",
    items: "Artikel",
    firstNameError: "Bitte geben Sie einen Vornamen ein",
    lastNameError: "Bitte geben Sie einen Nachnamen ein",
    phoneError: "Bitte geben Sie eine gültige Telefonnummer ein",
    emailError: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    save: "SPEICHERN",
    updateSuccess: "Das Update war erfolgreich",
    updateError: "Fehler beim Aktualisieren",
    updated: "Aktualisiert!",
    cartUpdated: "Sie haben Ihren Warenkorb erfolgreich aktualisiert!",
    wishlistUpdated: "Sie haben Ihre Wunschliste erfolgreich aktualisiert!",
    streetError: "Bitte geben Sie eine Straße ein",
    streetNumberError: "Bitte geben Sie eine Hausnummer ein",
    cityError: "Bitte geben Sie eine Stadt ein",
    order: "Bestellung",
    logout: "Abmelden",
    warning: "WARNUNG",
    logoutWarning: "Sind Sie sicher, dass Sie sich abmelden möchten?",
    yes: "JA",
    no: "NEIN",
    loginRegistration: "Anmeldung/Registrierung",
    login: "ANMELDUNG",
    passwordHelperText: "mindestens 8 Zeichen, darunter Zahlen, Groß- und Kleinbuchstaben",
    duplicateEmail: "",
    forgotPassword: "Passwort vergessen?",
    or: "oder",
    facebookLogin: "MIT FACEBOOK ANMELDEN",
    googleLogin: "MIT GOOGLE ANMELDEN",
    register: "Registrierung",
    registerButton: "NEUER KUNDE",
    exit: "BEENDEN",
    resetPassword: "Passwort zurücksetzen",
    resetPasswordInfoA: "Bitte geben Sie Ihre E-Mail-Adresse ein",
    resetPasswordInfoB: "und Sie erhalten ein neues Passwort.",
    registerA: "REGISTRIEREN",
    registerInfoA: "Mit meiner Registrierung akzeptiere ich die",
    registerInfoB: "Allgemeinen Nutzungsbedingungen",
    registerInfoC: "die",
    registerInfoD: "Datenschutzerklärung",
    registerInfoE: "und die",
    registerInfoF: "Nutzungsbedingungen der Angebote",
    verificationCode: "Bestätigungscode",
    verificationInfo: "Geben Sie bitte den 4-stelligen Code ein, der an gesendet wurde:",
    enterCode: "Code eingeben",
    errorCode: "Falscher Bestätigungscode. Bitte versuchen Sie es erneut",
    repeat: "ERNEUT SENDEN",
    youSee: "Sie sehen",
    from: "von",
    stockWarning: "Leider ist das von Ihnen gewählte Produkt nicht auf Lager!",
    leaveAComment: "Einen Kommentar hinterlassen...",
    saveReview: "Bewertung speichern",
    reviews: "Bewertungen",
    qrCodeBuy: "Kaufen Sie schnell und einfach mit einem Klick von Ihrem Mobilgerät!",
    qrCodeScanAndroid: "Scannen Sie, um unsere Android-App herunterzuladen",
    qrCodeDownload: "Laden Sie unsere App für Android herunter",
    qrCodeBuyMobile: "Für Android und iOS: Laden Sie unsere Restaurant-App herunter und bestellen Sie jederzeit von Ihrem Mobilgerät!",
    qrCodeScanIos: "Scannen Sie, um unsere App für iOS herunterzuladen",
    qrCodeForAndroid: "Für Android",
    qrCodeForIos: "Für iOS",
    photoUpload: "Foto hochladen",
    edit: "BEARBEITEN",
    delete: "LÖSCHEN",
    addCart: "In den Warenkorb legen",
    noEvaluation: "Bisher keine Bewertung.",
    onlyLoggedInCustomers: "Nur angemeldete Kunden, die das Produkt gekauft haben, können eine Bewertung abgeben.",
    beTheFirstReview: "Seien Sie der Erste, der eine Bewertung für dieses Produkt schreibt.",
    seeMoreProducts: "Mehr Produkte anzeigen",
    favorite: "FAVORITEN",
    favorites: "Favoriten",
    noFavoritesYet: "Sie haben noch keine Lieblingsprodukte",
    deleteProduct: "Möchten Sie dieses Produkt wirklich entfernen?",
    deleteProduct1: "Möchten Sie dieses Produkt wirklich entfernen",
    fromCart: "aus dem Warenkorb?",
    deleteAddress: "Möchten Sie die Adresse wirklich löschen?",
    danger: "ACHTUNG",
    connectionIssue: "Achtung! Der Versand der Bestellung war nicht erfolgreich. Bitte überprüfen Sie Ihre Verbindung.",
    sendOrder: "Bestellung absenden",
    pleaseWait: "Bitte warten...",
    noMoreProductsInStock: "Sie können nicht mehr Produkte in Ihren Warenkorb legen als auf Lager sind",
    productAddedCart: "Produkt wurde Ihrem Warenkorb hinzugefügt!",
    home: "Startseite",
    myAccount: "Mein Konto",
    ckeckout: "KASSE",
    all: "Alle",
    signIn: "Anmelden",
    search: "Suche",
    productSKU: "Artikelnummer",
    productSKUFull: "Artikelnummer:",
    language: "Sprache",
    size: "GRÖSSE",
    extra: "Extra",
    removeIngredients: "Zutaten entfernen",
    status: "Status",
    searchResults: "Suchergebnisse",
    noResults: "KEINE ERGEBNISSE",
    dailyProducts: "Tagesprodukte",
    offersText: "Tagesangebote",
    popularText: "Beliebteste",
    productComments: "Geben Sie einen Kommentar oder einen besonderen Wunsch ein (optional)",
    weighted: "Gewichteter Artikel",
    kgr: "Preis pro kg",
    without: "OHNE",
    noSuchUser: "Kein Benutzer mit dieser E-Mail-Adresse existiert!",
    reset: "Zurücksetzen.",
    lostPassword: "Passwort vergessen?",
    loginOr: "oder anmelden mit",
    here: "hier",
    orRegister: "Sie haben noch keinen Account? Registrieren Sie sich ",
    stockWarningHeader: "Lagerwarnung",
    cartUpdatedAlert: "Warenkorb aktualisiert",
    wishlistUpdatedAlert: "Wunschliste aktualisiert",
    watchThis: "Schauen Sie sich diese Produkte an!",
    pickSize: "Wählen Sie eine Größe",
    addExtra: "Zusätzliche Zutaten hinzufügen",
    logoutAccount: "Abmelden",
    deleteAccount: "Konto löschen",
    deleteAccountMessage: "Möchten Sie Ihr Konto wirklich löschen?",
    yesSmall: "Ja",
    noSmall: "Nein",
    show: "Warenkorb anzeigen",
    removeItem: "Artikel entfernen",
    youSure: "Sind Sie sicher?",
    cancel: "Abbrechen",
    scannerError: "Fehler beim Scannen. Bitte versuchen Sie es erneut.",
    scannerSuccess: "Willkommen",
    scannerMessage: "Fortfahren als Gast an Ihrem Tisch",
    companyOrigin: "Hauptregion",
    addNewAddress: "Neue Adresse hinzufügen",
    holidayText: "Die Räumlichkeiten bleiben heute geschlossen",
    minimumOrderHeader: "Mindestbestellwert",
    minimumOrder: "Der Mindestbestellwert beträgt ",
    duplicateEmail:"Diese E-Mail-Adresse wird von einem anderen Konto verwendet. Bitte versuchen Sie stattdessen, sich anzumelden.",
    appleId: "Diese E-Mail-Adresse wurde für die Anmeldung mit Apple verwendet",
    googleId: "Diese E-Mail-Adresse wurde für die Anmeldung mit Google verwendet",
    facebookId: "Diese E-Mail-Adresse wurde für die Facebook-Anmeldung verwendet",
    emailPassword: "Diese E-Mail-Adresse wurde für die Anmeldung mit E-Mail-Adresse und Passwort verwendet",
    loginError: "Login-Fehler: ",
    discount: "Rabatt",
    to: "Offen bis",
    openingTime: "Öffnungszeit",    
    more: "Mehr",
    lastAddress: "Die letzte Adresse kann nicht gelöscht werden",
    updateFailure: "Aktualisierung fehlgeschlagen",
    succesDeleteAddress: "Die Adresse wurde erfolgreich gelöscht.",
    noCategoryItemsYet: "Es gibt keine aktiven Produkte in dieser Kategorie.",
    reserveTable: "Tisch Reservieren",
    reserveTableUC: "Tisch Reservieren",
    yourReservation: "Ihre Reservierung",
    chooseHour: "Wählen Sie die Uhrzeit",
    bookingComment: "Sonderwunsch hinzufügen (optional)",
    party: "Personen",
    pickDate: "Wählen Sie ein Datum",
    partyOf: "Personen ",
    date: "Date",
    hour: "Time",
    customerDiscount: "Customer discount",
    couponDiscount: "Discount coupon",
    voucherTitle: "You have won a coupon of value ",
    applyVoucher: "Apply this coupon in your shopping cart.",
    copy: "Copy",
    minimumVoucherOrder: "Mindestauftragsgröße",
    minimumVoucherCaption: "Der Mindestbestellwert zur Gewährung Ihres Rabatts beträgt",
    loyaltyTitle: "Treueprämien",
    loyaltyCaption: "Sie haben Treueprämien im Gesamtwert gewonnen",
    loyaltySubCaption: "Der Rabatt wird beim Bezahlvorgang automatisch gewährt.",
    loyaltyMoto:  "Bestellen Sie weiter, um Rabatte zu gewinnen",
    endsIn: "Endet in",
    days: "Tage",
    table: "TISCH",
    comments: "KOMMENTARE",
    dateCut: "Datum",
    newOrder: "NEUE BESTELLUNG",
    num: "S/N",    
    extras: "EXTRAS",
    tableOrder : "TISCH",
    phoneDelivery : "TELEFON DELIVERY",
    phoneTakeAway : "TELEFON TAKE-AWAY",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR TISCH",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR TISCH",
}