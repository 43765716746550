import React from 'react'

const PaymentOptionsTextGerman = ()=> {
  return(
    <div className="terms-text">
    <h2>Zahlung der Bestellung</h2>
    <p>
    In unserem Unternehmen setzen wir alles daran, unseren Kunden den bestmöglichen Service zu bieten. Daher haben wir so viele Zahlungsmethoden wie möglich integriert.
    </p>
    <h2>
    Kreditkarte - Debitkarte
    </h2>
    <p>
    In unserem Geschäft akzeptieren wir Kredit-, Debit- oder Prepaid-Karten von jeder Bank. Bei Bezahlung in unserem physischen Geschäft erfolgt die Zahlung mit einem POS-Terminal. Wenn Sie im Online-Shop einkaufen und mit einer Kreditkarte bezahlen, werden Sie in die sichere Umgebung der Partnerbank (PayPal) weitergeleitet. Diese Website ist sicher und durch ein SSL-Zertifikat geschützt. Die Details der verwendeten Kredit-, Debit- oder Prepaid-Karte werden uns nicht mitgeteilt, und wir speichern sie nicht in unserem Unternehmen.
    </p>
    <h2>
    Abholung im Laden
    </h2>
    <p>
    Wenn Sie sich dafür entscheiden, Ihre Produkte im Laden abzuholen, können Sie den Betrag Ihrer Bestellung vor Ort bezahlen.
    </p>
    </div>
  )
}
    
export default PaymentOptionsTextGerman;