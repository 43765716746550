import React from 'react';
import { inject, observer } from "mobx-react";

const TermsTextSpanish = ({ feathersStore })=> {
    return(<div className="terms-text">
    <p>Para completar sus pedidos, se le pedirá que confirme cada vez por separado que acepta los siguientes términos de uso:</p>
    <ul>
      <li>
        <p>Los derechos de autor del Contenido (por ejemplo, programas, información, datos) y los Servicios del sitio web - sitio web que se han introducido en la Red están protegidos por las leyes de derechos de autor griegas, comunitarias e internacionales.</p>
      </li>
      <li>
        <p>Está prohibida cualquier copia, distribución, transferencia, modificación, reventa, creación de obras derivadas o desinformación sobre el proveedor real del Contenido del Sitio Web. Cualquier reproducción, reedición, carga, anuncio, difusión o transmisión o cualquier otro uso del Contenido de cualquier manera o medio con fines comerciales u otros solo está permitido con el permiso previo por escrito del Sitio Web o cualquier otro titular de derechos de autor.</p>
      </li>
      <li>
        <p>La tienda en línea - sitio web proporciona el Contenido (por ejemplo, información, nombres, fotos, ilustraciones), productos y servicios disponibles a través del sitio web "tal como están". En ningún caso, la tienda en línea - sitio web será responsable de reclamaciones legales o civiles y/o penales ni de ningún daño (positivo, especial o consecuente, que incluye, de manera indicativa y no restrictiva, la pérdida de beneficios, datos, lucro cesante, satisfacción monetaria, etc.) por parte de los visitantes del sitio web o terceros por cualquier motivo relacionado con el funcionamiento o no funcionamiento o el uso del sitio web o la incapacidad de proporcionar servicios o información disponible en él o de cualquier intervención no autorizada de terceros en productos o servicios o información disponible a través de él.</p>
      </li>
      <li>
        <p>Las fotos de los productos son indicativas y no nos hacemos responsables de errores tipográficos.</p>
      </li>
      <li>
        <p>Los precios están sujetos a cambios sin previo aviso. Un compromiso de precio solo se puede hacer para un pedido en línea y se completa con el envío de su confirmación. La tienda con el nombre comercial - sitio web no está obligada a proporcionar garantía de disponibilidad de sus productos, pero se compromete a informar de inmediato a sus clientes sobre cualquier escasez o retraso en los envíos.</p>
      </li>
      <li>
        <p>El cliente, una vez que el pedido ha sido procesado por el departamento de pedidos y ha confirmado su pedido, garantiza que recibirá el producto al precio que "aceptó" en la transacción, incluso si el precio cambia en el camino. En caso de que los productos o servicios se muestren en el sitio web con el precio incorrecto debido a un error tipográfico, los pedidos se cancelarán automáticamente. El sitio web no es responsable de los daños que pueda sufrir el cliente/usuario de la tienda en línea debido a la ejecución o no ejecución de los pedidos o debido a retrasos por cualquier motivo.</p>
      </li>
      <li>
        <p>La tienda en línea - sitio web se reserva el derecho de modificar, agregar, cambiar el contenido o los servicios del sitio web, así como los términos de uso, siempre que lo considere necesario y sin previo aviso, anunciándolos solo a través de su sitio web.</p>
      </li>
    </ul>
    <p>Dependiendo del método de pago elegido por el cliente durante su pedido, el reembolso se realizará de la siguiente manera:</p>
    <ul>
      <li>
        <p>Para una tarjeta de crédito en línea, el reembolso se realizará en la tarjeta.</p>
      </li>
      <li>
        <p>Para el pago en efectivo a la entrega o mediante transferencia bancaria, deberá proporcionarnos el IBAN de una cuenta bancaria, el nombre del beneficiario y el banco correspondiente.</p>
      </li>
    </ul>
  </div>
  
    )
}

export default inject('feathersStore')(observer(TermsTextSpanish));