import React,{ useState, useEffect, useRef } from 'react';
import { inject, observer } from "mobx-react";

import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import MicIcon from '@mui/icons-material/Mic';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const style = {
  root: {   
    maxHeight: 350,
    flexGrow: 1,
    maxWidth: 450, 
    paddingRight: 1   
  },
  positioning: {
    position: 'absolute',
    top: 30,
  }
};

const SearchBar = ({feathersStore})=> {
  
  let navigate = useNavigate(); 
  let common = _useTranslate(feathersStore.language, "common"); 
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const [searchText, setSearchText] = useState("");  
  const [products, setProducts] = useState([]); 
  const [openSlider, setOpenSlider] = useState(false);
  const [mouseOverCollapse, setMouseOverCollapse] = useState(false);

  const inputElement = useRef(null);
  
  useEffect(() => {   
    setSearchText(feathersStore.searchCategory.searchText || "");   
  }, [feathersStore?.searchCategory, feathersStore.language]);

  useEffect(() => {
    feathersStore.categories && searchText && fetchProducts();
  }, [searchText, feathersStore.categories]);

  useEffect(() => {
    setSearchText(transcript);
  }, [transcript]);

  const fetchProducts = async() => {       
    let feathersProducts;
  
      feathersProducts = await feathersStore.products(searchText);          
 
    setProducts([...feathersProducts]);   
  }

  const languageCode = () => {
    switch (feathersStore.language){
      case "el" : {
          return 'el-GR';
      }
      case "en" : {
          return 'en-US';
      }
      case "de" : {
          return 'de-DE';
      }
      case "es" : {
          return 'es-ES';
      }
      case "fr" : {
          return 'fr-FR';
      }
      case "it" : {
          return 'it-IT';
      }
      case "sq" : {
        return 'sq-AL';
      }
      case "ar" : {
        return 'ar-AR';
    }
    }
  }

  const searchChange = event => {
    setSearchText(event.target.value);    
  }

  const searchClicked =  () => {       
    navigate(`/Home/?search=${searchText}`);    
  }

  const micClicked = async() => {
    if (!browserSupportsSpeechRecognition) {
      console.log("Browser doesn't support speech recognition.")
      return;
    }
    if (!listening){
      inputElement.current.focus();
      await SpeechRecognition.startListening({ 
        language: languageCode()  
      });
      console.log("Listening")     
    }else {
      await SpeechRecognition.stopListening();
      resetTranscript();
      console.log("Stopped Listening")
    }
  }

  const goToProduct = product => () => {    
    setSearchText("");
    navigate(`/product/${feathersStore._translateName(product)}`,{
      state: {id: `${product._id}`,  payload: product}      
    }); 
    setOpenSlider(false);   
  }

  const renderProducts = products.map( (product, index) => (
    <div className="collapse-list-item" onClick={goToProduct(product)}  key={product._id}>      
      {feathersStore._translateName(product)}     
    </div>
  )
);

  return(
    <>    
      <div className="search-container" >     
        <div className="search-wrapper">          
          <input 
            ref={inputElement}
            onFocus={() => setOpenSlider(true)} 
            onBlur={() => !mouseOverCollapse && setOpenSlider(false)} 
            aria-labelledby='search Text'
            className="search-input"           
            type="text"       
            placeholder={common.search}
            aria-label="Search"
            value={searchText}
            onChange={searchChange}
          /> 
          {products.length > 0 && searchText && openSlider &&
            <div className="search-collapse" 
              onMouseOver={() => setMouseOverCollapse(true)} 
              onMouseOut={() => setMouseOverCollapse(false)}
           >             
              {renderProducts}           
            </div>
          } 
        </div>        
        <button 
          aria-label='Search'
          className="search-menu-button nav-button-SignIn  search-button"         
          onClick={searchClicked}>
            <span>{<SearchIcon />}</span>
        </button> 
        <button 
          aria-label='Search'
          className="search-menu-button nav-button-SignIn mic-button"         
          onClick={micClicked}>
            <span>{<MicIcon />}</span>
        </button>         
      </div>
       
    </>
  )
}

export default inject('feathersStore')(observer(SearchBar));