export const contactAR = {
  "mustHaveChar": "يجب أن يحتوي على حرفين أو أكثر",
  "less255": "يجب أن يكون أقصر من 255 حرفًا",
  "required": "مطلوب",
  "invalidEmail": "بريد إلكتروني غير صالح",
  "invalidPhone": "رقم هاتف غير صالح",
  "textareaMax": "يجب أن يكون أقصر من 1000 حرف",
  "contactForm": "نموذج الاتصال",
  "firstName": "الاسم الأول",
  "lastName": "اسم العائلة",
  "phone": "الهاتف",
  "email": "البريد الإلكتروني",
  "text": "الرسالة",
  "buttonForm": "إرسال",
  "emailSent": "تم إرسال البريد الإلكتروني!",
  "emailSentMessage": "لقد قمت بإرسال بريدك الإلكتروني بنجاح!",
  "emailError": "خطأ في البريد الإلكتروني!",
  "emailErrorMessage": "لم يتم إرسال بريدك الإلكتروني!"
}