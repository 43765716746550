export const commonEL = {
    firstName : "Όνομα",
    lastName : "Επώνυμο",
    street : "Οδός",
    streetNumber : "Αριθμός",
    city : "Πόλη",
    origin : "Περιφέρεια",
    postcode : "ΤΚ",
    apartment : "Όροφος",
    phone : "Τηλέφωνο",
    email : "E-mail",
    emailMessage : "Δεν θα κοινοποιήσουμε ποτέ το email σας σε τρίτους",
    checkout: "Tαμείο",
    streetAndNumber : "Οδός & αριθμός",
    privacyStatement : "Πολιτική απορρήτου",
    termsOfService : "Όρους και Προϋποθέσεις",
    termsOfServiceNoun : "Όροι και Προϋποθέσεις",
    aboutUs : "Σχετικά με εμάς",
    product : "Προϊόν",
    products : "Προϊόντα",
    subTotal : "Υποσύνολο",
    transportation : "Μεταφορικά",
    total: "Σύνολο",
    price : "Τιμή",
    quantity : "Ποσότητα",
    items : "Τεμάχια",
    firstNameError : "Παρακαλώ συμπληρώστε ένα όνομα",
    lastNameError : "Παρακαλώ συμπληρώστε ένα επώνυμο",
    phoneError : "Παρακαλώ συμπληρώστε ένα κανονικό αριθμό",
    emailError : "Παρακαλώ συμπληρώστε ένα κανονικό email",
    save : "ΑΠΟΘΗΚΕΥΣΗ",
    updateSuccess : "Επιτυχής Αλλαγή Στοιχείων",
    updateError : "Αποτυχία Ενημέρωσης",
    updated : "Ενημερώθηκε!",
    cartUpdated : "Έχετε ενημερώσει επιτυχώς το καλάθι σας.",
    wishlistUpdated : "Έχετε ενημερώσει επιτυχώς τη λίστα αγαπημένων σας.",
    streetError : "Εισάγετε μία οδό",
    streetNumberError : "Εισάγετε ένα αριθμό",
    cityError : "Εισάγετε μία πόλη",
    order : "Παραγγελία",
    logout : "Αποσύνδεση",
    warning : "ΠΡΟΣΟΧΗ",
    logoutWarning : "Είστε σίγουροι ότι θέλετε να αποσυνδεθείτε;",
    yes : "NAI",
    no: "OXI",
    loginRegistration : "Είσοδος / Εγγραφή",
    login : "ΕΙΣΟΔΟΣ",
    passwordHelperText : "τουλάχιστον 8 χαρακτήρες και να περιλαμβάνει αριθμούς, κεφαλαία και μικρά",
    forgotPassword : "Ξεχασες το password?",
    or : "ή",
    facebookLogin : "ΕΙΣΟΔΟΣ ΜΕ FACEBOOK",
    googleLogin : "ΕΙΣΟΔΟΣ ΜΕ GOOGLE",
    register: "Εγγραφή νέου χρήστη",
    registerButton : "ΝΕΟΣ ΠΕΛΑΤΗΣ",
    exit : "ΕΞΟΔΟΣ",
    resetPassword : "Αλλαγή password",
    resetPasswordInfoA : "Πλητρολόγησε το e-mail σου παρακάτω",
    resetPasswordInfoB : "και θα λάβεις νέο password.",
    registerA : "ΕΓΓΡΑΦΗ",
    registerInfoA : "Με τη σύνδεση σας αποδέχεστε τους",
    registerInfoB : "όρους χρήσης",
    registerInfoC : "και την",
    registerInfoD : "πολιτική απορρήτου",
    registerInfoE : "της ιστοσελίδας",
    verificationCode : "Επιβεβαίωση Κωδικού",
    verificationInfo : "Παρακαλώ εισάγετε τον 4ψήφιο κωδικό που στάλθηκε στο:",
    enterCode : "Εισαγωγη κωδικου",
    errorCode : "Λανθασμένος Κωδικός Επιβεβαίωσης, Παρακαλώ προσπαθείστε ξανά",
    repeat : "ΕΠΑΝΑΛΗΨΗ",
    youSee : "Βλέπετε",
    from : "από",
    stockWarning: "Δυστυχώς το προϊόν που επιλέξατε έχει εξαντληθεί!",
    leaveAComment: "Γράψετε ένα σχόλιο...",
    saveReview: "Προσθηκη αξιολογησης",
    reviews: "Αξιολογήσεις",
    qrCodeBuy: "Αγοράστε εύκολα και γρήγορα με ένα κλικ και από κινητό!",
    qrCodeScanAndroid: "Σκανάρετε για να κατεβάσετε την εφαρμογή μας σε Android",
    qrCodeDownload: "Κατεβάστε την εφαρμογή μας σε Android",
    qrCodeBuyMobile: "Είτε Android είτε iOS, κατέβασε την εφαρμογή του εστιατορίου μας και παρήγγειλε οποιαδήποτε ώρα από τη συσκευή σου!",    
    qrCodeScanIos: "Σκανάρετε για να κατεβάσετε την εφαρμογή μας σε IOs",    
    qrCodeForAndroid: "Για Android",
    qrCodeForIos: "Για iOS",
    photoUpload: "Ανέβασμα φωτογραφίας",
    edit: "ΕΠΕΞΕΡΓΑΣΙΑ",
    delete: "ΔΙΑΓΡΑΦΗ",
    addCart: "Προσθηκη στο καλαθι",
    noEvaluation: "Δεν υπάρχει ακόμη καμία αξιολόγηση.",
    onlyLoggedInCustomers: "Μόνο οι συνδεδεμένοι πελάτες που έχουν αγοράσει το προϊόν μπορούν να αφήσουν μια αξιολόγηση.",
    beTheFirstReview: "Γίνετε ο πρώτος που θα αξιολογήσει αυτό το προϊόν.",
    seeMoreProducts: "Δείτε περισσότερα",
    favorite: "ΑΓΑΠΗΜΕΝΑ",
    favorites: "Αγαπημένα",
    noFavoritesYet: "Δεν έχετε προσθέσει κάποιο προϊόν στα αγαπημένα",
    noCategoryItemsYet: "Δεν υπάρχουν ενεργά προϊόντα σε αυτή τη κατηγορία.",
    deleteProduct: "Είστε σίγουροι θέλετε να αφαιρέσετε αυτό το προϊόν;",
    deleteProduct1: "Είστε σίγουροι θέλετε να αφαιρέσετε αυτό το προϊόν",
    fromCart: "από το καλάθι;",
    deleteAddress: "Είστε σίγουροι θέλετε να διαγράψετε αυτή τη διεύθυνση;",
    danger: "ΠΡΟΣΟΧΗ",
    connectionIssue: "Προσοχή! Η αποστολή της παραγγελίας δεν ήταν επιτυχής. Παρακαλώ ελέγξτε την σύνδεσή σας.",
    sendOrder: "Αποστολή παραγγελίας",
    pleaseWait: "Παρακακλώ περιμένετε...",
    noMoreProductsInStock: "Δεν μπορείτε να προσθέσετε περισσότερα τεμάχια στο καλάθι σας από το απόθεμα!",
    productAddedCart: "Το προϊόν προσtέθηκε στο καλάθι σας!",
    home: "Αρχική",
    myAccount: "Λογαριασμός",
    ckeckout: "TAMEIO",
    all:"Όλα",
    signIn:"Είσοδος",
    search: "Αναζήτηση",
    productSKU: "Κωδικός",
    productSKUFull: "Κωδικός προϊόντος:",
    language: "Γλώσσα",
    size: "ΜΕΓΕΘΟΣ",
    extra: "ΕΞΤΡΑ",
    removeIngredients: "Αφαιρέστε υλικά",
    status: "Κατάσταση",
    searchResults: "Αποτελέσματα Αναζήτησης",
    noResults: "ΔΕΝ ΒΡΕΘΗΚΑΝ ΑΠΟΤΕΛΕΣΜΑΤΑ",
    dailyProducts: "Προτάσεις ημέρας",
    offersText: "Προσφορές ημέρας",
    popularText: "Τα πιο δημοφιλή",
    productComments: "Εισάγετε ένα σχόλιο ή μια προτίμηση (Προαιρετικά)",
    weighted: "Ζυγιζόμενο προϊόν",
    kgr: "Τιμή κιλού",
    without: "ΧΩΡΙΣ",
    noSuchUser: "Δεν υπάρχει χρήστης με αυτό το email!",
    reset: "Επαναφορά.",
    lostPassword: "Έχασες τον κωδικό σου; ",
    loginOr: "ή κάνε είσοδο με",
    here: "εδώ",
    orRegister: "Δεν έχεις λογαριασμό; Kάνε εγγραφή ",
    stockWarningHeader: "Ειδοποίηση αποθέματος",
    duplicateEmail: "Το email αυτό χρησιμοποιείται ήδη από έναν άλλο λογαριασμό. Παρακαλώ προσπαθείστε καλύτερα να κάνετε login.",
    cartUpdatedAlert: "Ενημέρωση καλαθιού",
    wishlistUpdatedAlert: "Ενημέρωση λίστας",
    watchThis: "Δες και αυτά!",
    pickSize: "Επιλέξτε μέγεθος",    
    addExtra: "Προσθέστε extra υλικά",
    logoutAccount: "Αποσύνδεση λογαριασμού",
    deleteAccount: "Διαγραφή λογαριασμού",
    deleteAccountMessage: "Θέλετε πραγματικά να διαγράψετε το λογαριασμό σας;",
    yesSmall: "Ναι",
    noSmall: "Όχι",
    show: "Προβολή καλαθιού",
    removeItem: "Αφαίρεση προiόντος",
    youSure: "Είστε σίγουροι;",
    scannerSuccess: "Καλώς ήρθατε",
    welcome: "Είσοδος",
    scannerErrorHeader: "Πρόβλημα στη σάρωση",
    scannerError: "Πρόβλημα στη σάρωση. Παρακαλώ προσπαθείστε ξανά",
    cancel: "Ακυρο",
    scannerMessage: "Συνεχίστε ως επισκέπτης στο τραπέζι σας",
    companyOrigin: "Περιφέρεια Έδρας",
    addNewAddress : "Προσθέστε νέα διεύθυνση",
    holidayText: "Σήμερα το κατάστημα θα παραμείνει κλειστό",
    minimumOrderHeader: "Ελάχιστη παραγγελία",
    minimumOrder: "Η ελάχιστη παραγγελία είναι ",
    appleId: "Αυτό το email έχει χρησιμοποιηθεί για login μέσω Apple",
    facebookId: "Αυτό το email έχει χρησιμοποιηθεί για login μέσω Facebook",
    googleId: "Αυτό το email έχει χρησιμοποιηθεί για login μέσω Google",
    emailPassword: "Αυτό το email έχει χρησιμοποιηθεί για login μέσω email και password",
    loginError: "Λανθασμένο login: ",
    discount: "'Εκπτωση",
    to: "Ανοιχτά έως",
    openingTime: "Ώρες λειτουργίας" ,
    more: "Περισσότερα",
    lastAddress: "Δεν μπορεί να διαγραφεί η μοναδική διεύθυνση",
    updateFailure: "Αποτυχία Ενημέρωσης",
    succesDeleteAddress: "Επιτυχής Διαγραφή Διεύθυνσης",
    reserveTable: "Κράτηση τραπεζιού",
    reserveTableUC: "ΚΡΑΤΗΣΗ ΤΡΑΠΕΖΙΟΥ",
    yourReservation: "Η κράτησή σας",
    chooseHour: "Επίλεξε ώρα",
    bookingComment: "Πρόσθεσε ειδικό αίτημα (προαιρετικό)",
    party: "Άτομα",
    pickDate: "Επίλεξε ημερομηνία",
    partyOf: "Άτομα ",
    date: "Ημερομηνία",
    hour: "Ώρα",
    customerDiscount: "'Εκπτωση πελάτη",
    couponDiscount: "Έκπτωτικό κουπόνι",
    voucherTitle: "Έχετε κερδίσει ένα κουπόνι αξίας ",
    applyVoucher: "Εισάγετέ το στο καλάθι αγορών σας.",
    copy: "Αντιγραφή",
    minimumVoucherOrder: "Ελάχιστη παραγγελία",
    minimumVoucherCaption: "Η ελάχιστη παραγγελία για να εφαρμοστεί η έκπτωσή σας είναι",
    loyaltyTitle: "Κουπόνια επιβράβευσης",
    loyaltyCaption: "Έχετε κερδίσει κουπόνια επιβράβευσης συνολικής αξίας",
    loyaltySubCaption: "Θα εξαργυρωθούν αυτόματα στην ολοκλήρωση της παραγγελίας σας.",
    loyaltyMoto:  "Συνέχισε να παραγγέλνεις και κέρδισε εκπτώσεις",
    endsIn: "Λήγει σε",
    days: "ημέρες",
    table: "ΤΡΑΠΕΖΙ",
    comments: "ΣΧΟΛΙΑ",
    dateCut: "Ημ/νία",
    newOrder: "ΝΕΑ ΠΑΡΑΓΓΕΛΙΑ",
    num: "A/A",
    extras: "EXTRAS",
    tableOrder : "ΤΡΑΠΕΖΙ",
    phoneDelivery : "ΤΗΛΕΦΩΝΟ DELIVERY",
    phoneTakeAway : "ΤΗΛΕΦΩΝΟ TAKE-AWAY",
    webDelivery : "WEB DELIVERY",
    webTakeAway : "WEB TAKE-AWAY",
    webTable : "WEB-QR ΤΡΑΠΕΖΙ",
    appDelivery : "APP DELIVERY",
    appTakeAway : "APP TAKE-AWAY",
    appTable : "APP-QR ΤΡΑΠΕΖΙ",
}