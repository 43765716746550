import React from 'react';
import { inject, observer } from "mobx-react";

const DeliveryOptionsText = ({feathersStore})=> {
  return(
    <div className="terms-text">
      <h2>Παράδοση & αποστολή</h2>
      <p>
        Στο κατάστημα μας καταβάλλουμε κάθε δυνατή προσπάθεια για να εξυπηρετήσουμε το αγοραστικό μας κοινό. Μπορείτε να παραγγείλετε απομακρυσμένα είτε τηλεφωνικά είτε μέσω της ιστοσελίδας μας. Παρακάτω αναγράφονται οι δύο συχνότεροι τρόποι αποστολής - παραλαβής των προϊόντων μας.
      </p>    
      <h2>
        Παραλαβή από το κατάστημα μας
      </h2>
      <p>
        Μπορείτε να παραλαμβάνετε τις αγορές σας από το κατάστημά μας στην οδό {feathersStore.settings?.address}.
      </p>
      <h2>
        Αποστολή στο χώρο σας
      </h2>
      <p>
        Αναλαμβάνουμε να φέρουμε την παραγγελία σας στο χώρο που μας έχετε υποδείξει.
      </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(DeliveryOptionsText));