import React, {useState, useEffect, useRef} from 'react';
import { inject, observer } from "mobx-react";
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnSharpIcon from '@mui/icons-material/LocationOnSharp';
// Translations
import _useTranslate from '../hooks/_useTranslate';
import {pickLanguageWord} from '../utils/pickLanguageWord.js'

import { DateTime, Info } from "luxon";

const Header = ({feathersStore})=> {

  let footer = _useTranslate(feathersStore.language, "footer");

  const [settingsPhone, setSettingsPhone] = useState("");
  const [openingHours, setOpeningHours] = useState("");
  const [closingHours, setClosingHours] = useState("");
  const [holiday, setHoliday] = useState(false);
  
  useEffect(() => {
    
    const now = DateTime.now().setZone("Europe/Athens");

    const luxonToday = (now.weekday + 6) % 7;
    const luxonYesterday = (now.minus({ days: 1 }).startOf('day').weekday + 6) % 7;    
    let dayData = feathersStore?.settings[`${Info.weekdays('long', {locale: 'en-US'})[luxonToday].toLowerCase()}`];
     
    if(dayData){
      setOpeningHours(`${pickLanguageWord(feathersStore.language, "openingTime")}: ` + dayData.openingHours + " - ");
      //---> Check for overlapping 
      const openingDt = now.set({ hour: +dayData.openingHours.split(':')[0], minute: +dayData.openingHours.split(':')[1], seconds: 0, milliseconds: 0 })
      if(feathersStore.restaurantOpen && now < openingDt){
        dayData = feathersStore?.settings[`${Info.weekdays('long', {locale: 'en-US'})[luxonYesterday].toLowerCase()}`];  
      //  setOpeningHours(`${footer.to} `);
      setOpeningHours(`${pickLanguageWord(feathersStore.language, "to")} `);
      }
      // -------------<
     
      setClosingHours(dayData.closingHours); 
      setHoliday(dayData.hoursEnabled); 
    }
    const phoneNumber = feathersStore.settings?.phoneNumbers &&
    feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber;
    setSettingsPhone(phoneNumber);
  },[feathersStore?.settings?.phoneNumbers, 
    feathersStore?.settings?.monday?.openingHours, 
    feathersStore?.settings?.tuesday?.openingHours,
    feathersStore?.settings?.wednesday?.openingHours,
    feathersStore?.settings?.thursday?.openingHours,
    feathersStore?.settings?.friday?.openingHours,
    feathersStore?.settings?.saturday?.openingHours,
    feathersStore?.settings?.sunday?.openingHours, 
    feathersStore.restaurantOpen, feathersStore.language])

 
  return (
    <div className="body-container">
      <header className="header top-bar">
        <div className='top-bar-el-upper'>
          <span className="top-bar-el-first top-bar-el">
            <AddIcCallIcon />
            <a href={`tel:${settingsPhone}`} className="header-text-item" >
              <span  >{settingsPhone}</span>
            </a>          
          </span>
        </div>
        <div className='top-bar-el-upper'>
          <span className="top-bar-el-cent top-bar-el">
            <LocationOnSharpIcon />                    
            <a           
              className="header-text-item"
              href={`https://www.google.com/maps/search/?api=1&query=${feathersStore.settings?.latitude}%2C${feathersStore.settings?.longitude}`} 
              target="_blank" 
              rel="noopener noreferrer"
            >{feathersStore._translateAddress()} 
            </a>                   
          </span>
        </div>
        <div className='top-bar-el-upper'>
          <span className="top-bar-el-last top-bar-el">
            <AccessTimeIcon />
            {holiday ?
            <span className="open-hours header-text-item">
              {feathersStore.settings?.closedShopText ? 
                feathersStore._translateClosedShopText()
              : footer.holidayText}</span>
            :
              <span className="open-hours header-text-item">
                {!(openingHours.slice(-8) === "00:00 - " && closingHours === "23:59") ? 
                `${openingHours}${closingHours}` : footer.openingTime + ": " + footer.allDay}
              </span>
            }
          </span>         
        </div>       
      </header>
    </div>
  );
  
}

export default inject('feathersStore')(observer(Header));
