import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../hooks/_useTranslate';

const AddressDeleteModal = ({visible, setAddresses, onClickNo, itemToDelete, companyId, itemIndex, feathersStore})=> {

  let common = _useTranslate(feathersStore.language, "common");
  
  const [loading, setLoading] = useState(false); 

  const deleteAddress = async () => {
    setLoading(true);  
    let clonedAddresses;
    if(companyId){
      const company = await feathersStore.getCompany(companyId);   
      clonedAddresses=[...company?.addresses];  
    }else{
      clonedAddresses=[...feathersStore.user?.addresses]; 
    } 
    if(clonedAddresses?.length > 1){
      if(itemToDelete){
        clonedAddresses.splice(itemIndex, 1);
        const ind = clonedAddresses.findIndex(i => i.active);
        if(ind < 0)clonedAddresses[0].active = true;
      } 
      try{
        if(companyId){
          await feathersStore.patchCompanyAddresses( companyId, [...clonedAddresses]);
          setAddresses([...clonedAddresses])
        }else{
          const user = await feathersStore.patchAddresses( feathersStore.user._id, [...clonedAddresses]);
          feathersStore.setUser(user);
        }    
        setLoading(false);   
        alert(`${common.succesDeleteAddress}`);
        onClickNo();
      }catch (error){
        setLoading(false);   
        alert(`${common.updateFailure}:  ${error.message}`)
      }
    }else{
      setLoading(false);   
      alert(`${common.lastAddress}`);
      onClickNo();
    }  
   
  };    
    
  return (     
    <Dialog     
      open={visible}
      onClose={onClickNo}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"     
    >     
      <DialogTitle id="alert-dialog-title">{common.danger}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        {common.deleteAddress}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <GreenSubmitButton
          title={common.no}
          loading={false}
          disabled={false}
          onClick={onClickNo}
          autoFocus
          aria-labelledby='No'
        />
        <h3 style={{color: 'grey'}}>|</h3>
        <GreenSubmitButton
          title={common.yes}
          loading={loading}
          disabled={false}
          onClick={deleteAddress}
          aria-labelledby='No'
        />
      </DialogActions>     
    </Dialog>
  );
}

export default inject('feathersStore')(observer(AddressDeleteModal));