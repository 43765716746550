import React from 'react'

const CheckoutComponent = ({sku, title, data, quantity, total})=> {

    return(
        <div className="checkout-order-table-component">
            <div className="component-header">
                <span >{sku} - {title} x {quantity}</span>
                <span>{total.toFixed(2)}€</span>
            </div>
            <div className="component-data">
                {data}
            </div>
           
        </div>
    )
}

export default CheckoutComponent;