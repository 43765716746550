import React from 'react';
import { inject, observer } from "mobx-react";

const TermsTextItalian = ({ feathersStore })=> {
    return(<div className="terms-text">
    <p>Per completare i tuoi ordini, ti verrà chiesto ogni volta di confermare che accetti i seguenti termini di utilizzo:</p>
    <ul>
      <li>
        <p>I diritti d'autore dei contenuti (ad esempio, programmi, informazioni, dati) e dei servizi del sito web - sito web introdotti in rete sono protetti dalle leggi sul diritto d'autore greche, comunitarie e internazionali.</p>
      </li>
      <li>
        <p>È vietata qualsiasi copia, distribuzione, trasferimento, modifica, rivendita, creazione di opere derivate o disinformazione sul vero fornitore dei contenuti del sito web. Qualsiasi riproduzione, ripubblicazione, caricamento, annuncio, diffusione o trasmissione o qualsiasi altro utilizzo dei contenuti in qualsiasi modo o forma a scopi commerciali o altri è consentito solo previa autorizzazione scritta del sito web o di qualsiasi altro titolare del copyright.</p>
      </li>
      <li>
        <p>Il negozio online - sito web fornisce i contenuti (ad esempio, informazioni, nomi, foto, illustrazioni), i prodotti e i servizi disponibili attraverso il sito web "esattamente come sono". In nessun caso il negozio online - sito web è responsabile di reclami legali, civili e/o penali o di danni (positivi, speciali o consequenziali, che indicativamente e non restrittivamente, in via disgiuntiva o cumulativa, consistono nella perdita di profitti, dati, perdita di guadagni, soddisfazione monetaria, ecc.) da parte dei visitatori del sito web o di terze parti per un motivo legato al funzionamento o al mancato funzionamento o all'uso del sito web o all'incapacità di fornire servizi o informazioni disponibili al suo interno o da qualsiasi intervento non autorizzato di terzi nei prodotti o servizi o informazioni disponibili tramite di esso.</p>
      </li>
      <li>
        <p>Le foto dei prodotti sono indicative e non ci assumiamo responsabilità per errori di ortografia.</p>
      </li>
      <li>
        <p>I prezzi sono soggetti a modifiche senza preavviso. Un impegno di prezzo può essere preso solo per un ordine online ed è completato con l'invio della conferma. Il negozio con la denominazione commerciale - sito web non è tenuto a fornire alcuna garanzia sulla disponibilità dei suoi prodotti, ma si impegna a informare tempestivamente i clienti su eventuali carenze o ritardi nelle consegne.</p>
      </li>
      <li>
        <p>Il cliente, una volta che l'ordine è stato elaborato dal reparto ordini e ha confermato il suo ordine, garantisce di ricevere il prodotto al prezzo che ha "accettato" nella transazione, anche se il prezzo cambia lungo il percorso. In caso di visualizzazione errata di prodotti o servizi sul sito web a causa di un errore tipografico, gli ordini vengono annullati automaticamente. Il sito web non è responsabile dei danni subiti dal cliente/utente del negozio online a causa dell'esecuzione o della non esecuzione degli ordini o dei ritardi nell'esecuzione per qualsiasi motivo.</p>
      </li>
      <li>
        <p>Il negozio online - sito web si riserva il diritto di modificare, aggiungere, cambiare il contenuto o i servizi del sito web, nonché i termini di utilizzo, ogni volta che lo ritiene necessario e senza preavviso, annunciandoli solo sul proprio sito web.</p>
      </li>
    </ul>
    <p>A seconda del metodo di pagamento scelto dal cliente durante il suo ordine, il rimborso verrà effettuato come segue:</p>
    <ul>
      <li>
        <p>Per una carta di credito online, il rimborso verrà effettuato sulla carta.</p>
      </li>
      <li>
        <p>Per il pagamento in contanti alla consegna o mediante bonifico bancario, sarà necessario fornirci l'IBAN di un conto bancario, il nome del beneficiario e la banca interessata.</p>
      </li>
    </ul>
  </div>
  
    )
}

export default inject('feathersStore')(observer(TermsTextItalian));