export const cartFR = {
    cart: "Panier",
    couponCode: "Code de réduction",
    couponButton: "Utiliser le coupon",
    cartTotals: "Totals",
    completeOrder: "VALIDER LA COMMANDE",
    continueShopping: "CONTINUER LES ACHATS",
    without: "Sans",
    select: "SÉLECTIONNER",
    selectSmall: "Sélectionner",
    addToCart: "Ajouté au panier",
    cartItems: "Articles dans le panier",
    couponDiscount: "Réduction du coupon",
    subTotal: "Sous-total",
    couponCaption: "Avez-vous un code de réduction ?",
    new: "NOUVEAU",
    sale: "EN SOLDE",
    soldOut: "ÉPUISÉ",
    couverCaption: "Nombre de personnes",
    couverPlaceholder: "Saisir le nombre",
    couverError: "Veuillez entrer le nombre de personnes et appuyer sur vérifier"
}
