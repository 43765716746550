import React from 'react';



const ButtonMinus = ({onClick, green, disabled})=> {

  function changeCursor(e) {
      e.target.style.cursor = 'pointer';
    } 

  return(      
     <button 
      onClick={onClick} 
      onMouseOver={changeCursor}  
      disabled={disabled} 
      className={green ? "roundedButtonGreen" : "roundedButtonRed"}>-</button>
         
  )
}


export default ButtonMinus;