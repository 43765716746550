export const accountDE = {
    myAccount : "meinKonto",
    profile : "PROFIL",
    addresses : "ADRESSEN",
    history : "GESCHICHTE",
    comments : "KOMMENTARE",
    settings : "EINSTELLUNGEN",
    psw : "Kennwort ändern",
    updateAlert : "Ihre Daten wurden erfolgreich aktualisiert",
    updateError : "Fehler beim Aktualisieren",
    addAddress : "füge eine Adresse hinzu",
    addNewAddress : "Füge eine neue Adresse hinzu",
    myLocation : "Mein Standort",    
    modalHeaderUpdate : "Adresse ändern",
    otherLocation : "Anderer Ort",
    home : "Heim",
    work : "Arbeiten",
    helperText : "Bitte geben Sie Ihre Adressdaten ein",
    addressComments : "Zusätzliche Adresskommentare",
    historyPlaceholder : "Sie haben noch keine Einkäufe getätigt",
    commentPlaceholder : "Sie haben noch keinen Kommentar abgegeben",
    emptyHistory: "Sie haben noch keine Bestellung aufgegeben.",
    uploadAvatar: "Klicken Sie auf das Bild, um ein anderes Bild auszuwählen.",
    submit: "EINREICHEN",
    uploadSuccess: "Datei-Upload erfolgreich",    
    uploadError: "Fehler beim Hochladen der Datei",
    bigFile: "Das Bild ist zu groß. Es sollte bis zu 1 MB groß sein. Bitte wählen Sie ein kleineres Bild aus",
    favorites: "meineFavoriten",
    hello: "Hallo",
    profileHeader: "Profil",
    settingsSmall: "Einstellungen"
}
