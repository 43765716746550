import React from 'react';
import { inject, observer } from "mobx-react";

const PrivacyTextItalian = ({ feathersStore })=> {
    return(
        <div className="terms-text">
  <p>Per l'azienda/negozio {feathersStore.settings?.companyName} la protezione dei dati personali è di primaria importanza ed è trattata con la massima serietà. Il rispetto dei dati personali che gestiamo e la garanzia della loro corretta elaborazione sono una delle priorità dell'azienda.</p>
  <p>Per questo motivo, adottiamo tutte le misure tecniche e organizzative adeguate per proteggere i dati personali che trattiamo e garantire che il loro trattamento avvenga sempre in conformità con i requisiti stabiliti dal quadro giuridico attuale e in particolare dal Regolamento generale sulla protezione dei dati (UE) 2016/679.</p>
  <a><strong>1. Persona di contatto - Dettagli</strong></a>
  <p>{feathersStore.settings?.companyName} agisce come Titolare del trattamento per tutti i dati personali che raccoglie, elabora e conserva.</p>
  {feathersStore.settings?.companyName}<br/>
  Indirizzo: {feathersStore.settings?.address}<br/>
  Telefono: {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber}<br/>
  E-mail: {feathersStore.settings?.email}<br/>
  Contatto: {feathersStore.settings?.companyName}<br/><br/>
  <a><strong>2. Quali dati raccogliamo e trattiamo</strong></a><br/>
  <p>Per servirvi e offrirvi i nostri servizi e prodotti, raccogliamo e trattiamo tutti i dati che fornite al rappresentante del call center quando effettuate un ordine o i dati che inserite nei campi corrispondenti quando aprite un account utente sul nostro sito web, come il nome, l'indirizzo postale, il numero di telefono di contatto (fisso o mobile), l'indirizzo e-mail, le informazioni fiscali (nel caso desideriate emettere una fattura).<br/><br/> Si prega di notare che il nostro sito web aziendale potrebbe contenere collegamenti (link) ad altri siti web. La nostra azienda non è responsabile delle pratiche di privacy e protezione dei dati e/o dei contenuti di questi siti web. Se siete al di sotto dei 15 anni, ottenete l'autorizzazione dei vostri genitori o tutori prima di condividere qualsiasi informazione personale con noi.</p>
  <a><strong>3. Finalità e basi giuridiche del trattamento</strong></a><br/>
  <p>Tutti i dati personali sopra menzionati vengono raccolti e sottoposti a trattamento principalmente allo scopo di eseguire il contratto tra noi per la vendita dei nostri prodotti e, in particolare, per la preparazione e la consegna del vostro ordine e la nostra comunicazione con voi in merito. Alcuni dei vostri dati potrebbero essere utilizzati anche per le seguenti finalità, sempre con il vostro consenso pertinente:<br/><br/>
  - Comunicazione con voi in relazione a concorsi, giochi a premi e altri eventi.<br/>
  - Aggiornamento e invio di offerte e materiale informativo sui nostri prodotti e servizi.<br/>
  - Finalità statistiche e di ricerca.<br/>
  - Promozione e promozione dei nostri prodotti e servizi.</p>
  <p>Le basi giuridiche per il trattamento dei dati possono essere caso per caso:<br/>
  i. L'esecuzione e il completamento del contratto tra noi per la vendita dei nostri prodotti a voi (preparazione e consegna del vostro ordine). Senza la raccolta e l'uso delle suddette informazioni non saremo in grado di servirvi.<br/>
  ii. Il consenso che potreste fornirci al fine di ricevere aggiornamenti riguardo offerte o materiale promozionale per i nostri prodotti e servizi, azioni commerciali e attività che sviluppiamo, ecc. Potete ritirare il vostro consenso in qualsiasi momento, contattandoci tramite i dettagli di contatto menzionati nel paragrafo 1 o attraverso il nostro sito web.</p>
  <a><strong>4. Dove condividiamo i vostri dati personali</strong></a><br/>
  <p>I vostri dati personali potrebbero essere condivisi con i seguenti destinatari:<br/>
  - Con i distributori al fine di preparare e consegnare l'ordine.<br/>
  - Con un'azienda di invio newsletter (a condizione che abbiate fornito il vostro consenso per ricevere una newsletter da parte nostra).<br/>
  - Con un'azienda di invio SMS (se avete dato il vostro consenso a ricevere aggiornamenti).<br/>
  - Con aziende partner, al fine di ricevere il vostro regalo se siete vincitori di un concorso a cui avete partecipato (ogni volta che organizziamo un concorso, menzioniamo nei termini del concorso il fatto di condividere i vostri dati con l'azienda partner).<br/>
  - Con un partner esterno che ci supporta nelle questioni software.<br/>
  - Con il reparto legale collaboratore dell'azienda, in caso di controversie giudiziarie o stragiudiziali.<br/>
  - Con la polizia o altre autorità governative se ci viene ufficialmente richiesto o su ordinanza di un pubblico ministero.<br/></p>
  <a><strong>5. Periodo di conservazione dei dati</strong></a><br/>
  <p>I vostri dati vengono conservati nel database di {feathersStore.settings?.companyName} per 5 anni dopo la vostra ultima transazione con l'azienda o per il tempo richiesto dalla legge (ad esempio, nel caso di emissione di una fattura, per tutto il tempo definito dalla normativa fiscale).<br/><br/>
  Se il trattamento si basa sul vostro consenso, i dati personali vengono conservati fino a quando revocate questo consenso. Si precisa che il ritiro del consenso non pregiudica la liceità del trattamento basato sul consenso prima del suo ritiro.<br/>
  In ogni caso, {feathersStore.settings?.companyName} adotta tutte le misure tecniche e organizzative adeguate per garantire la protezione dei dati personali che tratta e si impegna costantemente a prevenire l'accesso non autorizzato a tali dati.<br/></p>
  <a><strong>6. Diritti in relazione ai vostri dati personali</strong></a><br/>
  <p>Per quanto riguarda il trattamento dei vostri dati personali, potete esercitare i seguenti diritti:<br/><br/>
  Diritto di accesso<br/>
  Avete il diritto di sapere quali dati abbiamo su di voi, il motivo e altre informazioni aggiuntive a riguardo, nonché di chiederci una copia di essi.<br/><br/>
  Diritto di rettifica<br/>
  Avete il diritto di richiedere la correzione, la modifica e il completamento dei vostri dati personali.<br/><br/>
  Diritto alla cancellazione ("diritto all'oblio")<br/>
  Avete il diritto di richiedere la cancellazione dei vostri dati personali quando vengono trattati previa vostra autorizzazione. Nei casi in cui il trattamento si basa su un'altra base giuridica (come ad esempio l'esecuzione di un contratto, un obbligo legale o la protezione degli interessi legali dell'azienda, ecc.), il vostro diritto potrebbe essere soggetto a limitazioni o potrebbe non esistere.<br/><br/>
  Diritto alla limitazione del trattamento<br/>
  Avete il diritto di richiedere la limitazione del trattamento dei vostri dati personali<br/>
  - quando la loro accuratezza è contestata e fino a quando non sia stata effettuata la verifica corrispondente.<br/>
  - alternativamente, anziché la cancellazione.<br/>
  - quando non sono più necessari per noi per le finalità di trattamento per cui sono stati raccolti, ma sono necessari per l'instaurazione, l'esercizio o la difesa di azioni legali da parte vostra.<br/>
  - quando vi opponete al loro trattamento e fino a quando non sia stata verificata l'esistenza di motivi legittimi per tale trattamento da parte dell'azienda.<br/><br/>
  Diritto di opposizione al trattamento.<br/>
  Avete il diritto di opporvi al trattamento dei vostri dati personali quando esso si basi su un interesse legittimo, nonché per scopi di marketing diretto e profilazione.<br/><br/>
  Diritto alla portabilità<br/>
  Avete il diritto di richiedere e ricevere i vostri dati personali in una forma che vi permetta di accedervi, usarli e trattarli con metodi di trattamento comuni. Inoltre, per i dati che trattiamo con mezzi automatizzati e sulla base del vostro consenso o per l'esecuzione di un contratto, potete chiederci di trasferirli direttamente a un altro titolare del trattamento, se ciò è tecnicamente possibile.<br/><br/>
  Diritto a ritirare il consenso<br/>
  Se il trattamento dei vostri dati si basa sul vostro consenso, avete il diritto di ritirarlo in qualsiasi momento. Il ritiro del vostro consenso non pregiudica la liceità del trattamento basato sul consenso prima del suo ritiro.<br/><br/>
  Diritto di eliminare l'account<br/>
  Hai il controllo esclusivo e assoluto sulle tue informazioni e puoi modificarle o eliminarle dal tuo account in qualsiasi momento senza il consenso o il consenso del negozio.<br/>
  Lo stesso vale per l'intero tuo account poiché entrando nelle impostazioni potrai procedere con l'opzione corrispondente.<br/><br/>
  Per esercitare i diritti sopra indicati e per qualsiasi domanda, reclamo o ulteriori informazioni riguardo al trattamento dei vostri dati personali, potete contattarci all'indirizzo e-mail {feathersStore.settings?.email} o ai dettagli di contatto menzionati al paragrafo 1.<br/></p>
  <a><strong>7. Diritto di reclamo all'Autorità di controllo della protezione dei dati (DPA)</strong></a><br/>
  <p>Avete il diritto di presentare reclamo presso l'Autorità di controllo della protezione dei dati personali (www.dpa.gr) per questioni relative al trattamento dei vostri dati personali.<br/></p>
  <a><strong>8. Modifiche alla protezione dei dati</strong></a><br/>
  <p>Eventuali future modifiche alla nostra Politica sulla privacy dell'azienda saranno pubblicate sul sito {feathersStore.settings?.siteUrl} e, se necessario, vi verranno comunicate.<br/></p>
  <a><strong>9. Politica sui cookie</strong></a><br/>
  <p>Dato che il negozio online utilizza tecnologie moderne, non vengono utilizzati cookie con i vostri dati, anche se vi siete registrati nel nostro negozio online.<br/><br/> Gli unici cookie utilizzati riguardano il servizio Google Analytics, purché sia attivato. In questo caso, vengono conservate statistiche generali sulle visite alle pagine del negozio online.</p>
</div>

    )
}

export default inject('feathersStore')(observer(PrivacyTextItalian));