export const commonAR = {
  "firstName": "الاسم الأول",
  "lastName": "الاسم الأخير",
  "street": "الشارع",
  "streetNumber": "الرقم",
  "city": "المدينة",
  "origin": "المنطقة",
  "postcode": "الرمز البريدي",
  "phone": "رقم الهاتف",
  "apartment": "الشقة",
  "email": "البريد الإلكتروني",
  "emailMessage": "لن نشارك بريدك الإلكتروني مع أي شخص آخر",
  "checkout": "الدفع",
  "streetAndNumber": "الشارع والرقم",
  "privacyStatement": "بيان الخصوصية",
  "termsOfService": "شروط الخدمة",
  "termsOfServiceNoun": "شروط الخدمة",
  "aboutUs": "من نحن",
  "product": "المنتج",
  "products": "المنتجات",
  "subTotal": "المجموع الفرعي",
  "transportation": "تكلفة الشحن",
  "total": "الإجمالي",
  "price": "السعر",
  "quantity": "الكمية",
  "items": "العناصر",
  "firstNameError": "الرجاء إدخال الاسم الأول",
  "lastNameError": "الرجاء إدخال الاسم الأخير",
  "phoneError": "الرجاء إدخال رقم هاتف صالح",
  "emailError": "الرجاء إدخال بريد إلكتروني صالح",
  "save": "حفظ",
  "updateSuccess": "تم التحديث بنجاح",
  "updateError": "حدث خطأ أثناء التحديث",
  "updated": "تم التحديث!",
  "cartUpdated": "لقد قمت بتحديث عربة التسوق الخاصة بك بنجاح!",
  "wishlistUpdated": "لقد قمت بتحديث قائمة الرغبات الخاصة بك بنجاح!",
  "streetError": "الرجاء إدخال اسم الشارع",
  "streetNumberError": "الرجاء إدخال رقم الشارع",
  "cityError": "الرجاء إدخال اسم المدينة",
  "order": "الطلب",
  "logout": "تسجيل الخروج",
  "warning": "تحذير",
  "logoutWarning": "هل أنت متأكد أنك تريد تسجيل الخروج؟",
  "yes": "نعم",
  "no": "لا",
  "loginRegistration": "تسجيل الدخول / التسجيل",
  "login": "تسجيل الدخول",
  "passwordHelperText": "على الأقل 8 أحرف بما في ذلك الأرقام والحروف الكبيرة والصغيرة",
  "forgotPassword": "هل نسيت كلمة المرور؟",
  "or": "أو",
  "facebookLogin": "تسجيل الدخول باستخدام فيسبوك",
  "googleLogin": "تسجيل الدخول باستخدام جوجل",
  "register": "التسجيل",
  "registerButton": "عميل جديد",
  "exit": "خروج",
  "resetPassword": "إعادة تعيين كلمة المرور",
  "resetPasswordInfoA": "الرجاء إدخال عنوان البريد الإلكتروني الخاص بك",
  "resetPasswordInfoB": "وسوف تحصل على كلمة مرور جديدة.",
  "registerA": "تسجيل",
  "registerInfoA": "مع تسجيلي ، أوافق على",
  "registerInfoB": "الشروط العامة للخدمة",
  "registerInfoC": "و",
  "registerInfoD": "بيان الخصوصية",
  "registerInfoE": "و",
  "registerInfoF": "شروط الخدمة للعروض",
  "verificationCode": "تأكيد الرمز",
  "verificationInfo": "الرجاء إدخال الرمز المكون من 4 أرقام الذي تم إرساله إلى:",
  "enterCode": "إدخال الرمز",
  "errorCode": "رمز التحقق غير صحيح، يرجى المحاولة مرة أخرى",
  "repeat": "إعادة الإرسال",
  "youSee": "أنت ترى",
  "from": "من",
  "stockWarning": "للأسف، المنتج الذي اخترته غير متوفر!",
  "leaveAComment": "اترك تعليقًا...",
  "saveReview": "حفظ التقييم",
  "reviews": "التقييمات",
  "qrCodeBuy": "اشتري بسرعة وسهولة بنقرة واحدة من هاتفك المحمول!",
  "qrCodeScanAndroid": "مسح لتنزيل تطبيقنا لنظام Android",
  "qrCodeDownload": "تحميل تطبيقنا في Android",
  "qrCodeBuyMobile": "لكل من Android و iOS، قم بتنزيل تطبيقنا للمطعم واطلب في أي وقت من هاتفك المحمول!",
  "qrCodeScanIos": "مسح لتنزيل تطبيقنا لنظام IOs",
  "qrCodeForAndroid": "لنظام Android",
  "qrCodeForIos": "لنظام iOS",
  "photoUpload": "تحميل الصورة",
  "edit": "تعديل",
  "delete": "حذف",
  "addCart": "إضافة إلى العربة",
  "noEvaluation": "لا يوجد تقييم بعد.",
  "onlyLoggedInCustomers": "يمكن للعملاء المسجلين فقط الذين قاموا بشراء المنتج كتابة تقييم.",
  "beTheFirstReview": "كن أول من يكتب تقييمًا لهذا المنتج.",
  "seeMoreProducts": "شاهد المزيد من المنتجات",
  "favorite": "المفضلة",
  "favorites": "المفضلة",
  "noFavoritesYet": "ليس لديك منتجات مفضلة بعد",
  "deleteProduct": "هل أنت متأكد أنك تريد إزالة هذا المنتج؟",
  "deleteProduct1": "هل أنت متأكد أنك تريد إزالة هذا المنتج",
  "fromCart": "من العربة؟",
  "deleteAddress": "هل أنت متأكد أنك تريد حذف العنوان؟",
  "danger": "تحذير",
  "connectionIssue": "تنبيه! فشل إرسال الطلب. يرجى التحقق من اتصالك.",
  "sendOrder": "إرسال الطلب",
  "pleaseWait": "الرجاء الانتظار...",
  "noMoreProductsInStock": "لا يمكنك إضافة المزيد من المنتجات إلى عربتك من خلال المخزون",
  "productAddedCart": "تمت إضافة المنتج إلى سلة التسوق الخاصة بك!",
  "home": "الصفحة الرئيسية",
  "myAccount": "حسابي",
  "ckeckout": "الدفع",
  "all": "الكل",
  "signIn": "تسجيل الدخول",
  "search": "البحث",
  "productSKU": "SKU",
  "productSKUFull": "رمز المنتج:",
  "language": "اللغة",
  "size": "الحجم",
  "extra": "إضافي",
  "removeIngredients": "إزالة المكونات",
  "status": "الحالة",
  "searchResults": "نتائج البحث",
  "noResults": "لا توجد نتائج",
  "dailyProducts": "المنتجات اليومية",
  "offersText": "عروض يومية",
  "popularText": "الأكثر شيوعا",
  "productComments": "أدخل تعليقًا أو طلبًا خاصًا (اختياري)",
  "weighted": "منتج مزود بوزن",
  "kgr": "السعر للكيلوغرام الواحد",
  "without": "بدون",
  "noSuchUser": "لا يوجد مستخدم بهذا البريد الإلكتروني!",
  "reset": "إعادة تعيين.",
  "lostPassword": "نسيت كلمة المرور؟",
  "loginOr": "أو تسجيل الدخول باستخدام",
  "here": "هنا",
  "orRegister": "ليس لديك حساب؟ سجل ",
  "stockWarningHeader": "تحذير المخزون",
  "duplicateEmail": "تم استخدام هذا البريد الإلكتروني بواسطة حساب آخر. يرجى محاولة تسجيل الدخول بدلاً من ذلك.",
  "cartUpdatedAlert": "تم تحديث العربة",
  "wishlistUpdatedAlert": "تم تحديث قائمة الرغبات",
  "watchThis": "تحقق من هذه!",
  "pickSize": "اختر حجمًا",
  "addExtra": "إضافة مكونات إضافية",
  "logoutAccount": "تسجيل الخروج",
  "deleteAccount": "حذف الحساب",
  "deleteAccountMessage": "هل تريد حقًا حذف حسابك؟",
  "yesSmall": "نعم",
  "noSmall": "لا",
  "show": "عرض العربة",
  "removeItem": "إزالة العنصر",
  "youSure": "هل أنت متأكد؟",
  "cancel": "إلغاء",
  "scannerError": "خطأ أثناء المسح. يرجى المحاولة مرة أخرى.",
  "scannerSuccess": "مرحبًا",
  "scannerMessage": "تابع كضيف في طاولتك",
  "companyOrigin": "المنطقة الرئيسية",
  "addNewAddress": "إضافة عنوان جديد",
  "holidayText": "سوف يظل المكان مغلقًا اليوم",
  "minimumOrderHeader": "الحد الأدنى للطلب",
  "minimumOrder": "الحد الأدنى للطلب هو",
  "appleId": "تم استخدام هذا البريد الإلكتروني لتسجيل الدخول باستخدام Apple",
  "facebookId": "تم استخدام هذا البريد الإلكتروني لتسجيل الدخول بواسطة Facebook",
  "googleId": "تم استخدام هذا البريد الإلكتروني لتسجيل الدخول بواسطة Google",
  "emailPassword": "تم استخدام هذا البريد الإلكتروني لتسجيل الدخول بالبريد الإلكتروني وكلمة المرور",
  "loginError": "خطأ في تسجيل الدخول: ",
  "discount": "الخصم",
  "to": "مفتوح حتى",
  "openingTime": "وقت الفتح",
  "more": "المزيد",
  "lastAddress": "لا يمكن حذف العنوان الأخير",
  "updateFailure": "فشل التحديث",
  "succesDeleteAddress": "تم حذف العنوان بنجاح.",
  "noCategoryItemsYet": "لا توجد منتجات نشطة في هذه الفئة.",
  "reserveTable": "حجز طاولة",
  "reserveTableUC": "حجز طاولة",
  "yourReservation": "حجزك",
  "chooseHour": "اختر الوقت",
  "bookingComment": "إضافة طلب خاص (اختياري)",
  "party": "حفلة",
  "pickDate": "اختر التاريخ",
  "partyOf": "حفلة من",
  date: "تاريخ",
  hour: "وقت",
  customerDiscount: "خصم العملاء",
  couponDiscount: "كوبون الخصم",
  voucherTitle: "لقد ربحت قسيمة ذات قيمة ",
  applyVoucher: "قم بتطبيق هذه القسيمة في سلة التسوق الخاصة بك",
  copy: "ينسخ",
  minimumVoucherOrder: "الحد الأدنى للطلب",
  minimumVoucherCaption: "الحد الأدنى للطلب المطلوب لتطبيق الخصم الخاص بك هو",
  loyaltyTitle: "مكافآت الولاء",
  loyaltyCaption: "لقد فزت بمكافآت الولاء ذات القيمة الإجمالية",
  loyaltySubCaption: "سيتم خصمها تلقائيًا أثناء الخروج",
  loyaltyMoto:  "استمر في الطلب للفوز بالخصومات",
  endsIn: "ينتهي في",
  days: "أيام",
  table: "طاولة",
  comments: "التعليقات",
  dateCut: "تاريخ",
  newOrder: "طلب جديد",
  num: "الرقم التسلسلي",  
  tableOrder : "طاولة",
  phoneDelivery : "تسليم الهاتف",
  phoneTakeAway : "أخذ الهاتف معك",
  webDelivery : "التسليم عبر الإنترنت",
  webTakeAway : "الويب تيك أواي",
  webTable : "جدول الويب-QR",
  appDelivery : "تسليم التطبيق",
  appTakeAway : "التطبيق جاهز",
  appTable : "جدول APP-QR",
}