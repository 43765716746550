export const checkoutAR = {
  "less255": "الحد الأقصى 255 حرفًا",
  "less50": "الحد الأقصى 50 حرفًا",
  "less20": "الحد الأقصى 20 حرفًا",
  "less10": "الحد الأقصى 10 أحرف",
  "required": "مطلوب",
  "invalidEmail": "بريد إلكتروني غير صالح",
  "invalidPhone": "رقم غير صالح",
  "textareaMax": "الحد الأقصى 1000 حرف",
  "invalidPayment": "طريقة دفع غير صالحة",
  "terms": "يجب أن تقبل الشروط والأحكام",
  "createAccount": "إنشاء حساب",
  "differentDeliveryAddress": "توصيل إلى عنوان مختلف",
  "orderComments": "تعليقات الطلب",
  "payment": "الدفع",
  "cash": "الدفع عند التسليم",
  "cashText": "الدفع نقدًا عند تسليم السلع",
  "clickAway": "الاستلام من المتجر",
  "clickAwayText": "استلام السلع من المتجر",
  "paypalRadio": "بطاقة الائتمان أو PayPal",
  "paypalError": "يرجى ملء النموذج بشكل صحيح!",
  "personalDataInfo": "ستستخدم بياناتك الشخصية لاحتياجات هذا الموقع ولمعالجة حسابك.",
  "readThe": "اقرأ",
  "iHaveRead": "لقد قرأت ووافقت على",
  "ofThisSite": "لهذا الموقع",
  "yourOrder": "طلبك",
  "sendOrder": "إرسال الطلب",
  "alreadyCustomer": "أنا عميل بالفعل",
  "orderCompleteTitle": "تم الطلب بنجاح!",
  "orderCompleteLine1": "تم استلام طلبك بنجاح.",
  "orderCompleteLine2": "شكرًا لك على طلبك.",
  "isCompany": "إصدار فاتورة",
  "companyAfm": "الرقم الضريبي",
  "companyDoy": "مكتب الضرائب",
  "companyName": "اسم الشركة",
  "companyProfession": "المهنة",
  "bankTransfer": "تحويل مصرفي مباشر",
  "bankTransferText": "تحويل إلى أحد حساباتنا المصرفية.",
  "bankLargeText": "قم بدفع الأموال مباشرة إلى حسابنا المصرفي. يرجى تضمين رقم طلبك في تحويل الأموال الخاص بك. سيتم شحن طلبك بعد التحقق من تحويل أموالك.",
  "bank": "البنك",
  "nbgRadio": "بطاقة الائتمان من خلال بنك NBG",
  "without": "بدون",
  "checkoutData": "تفاصيل الدفع / التسليم",
  "product": "المنتج",
  "subTotal": "المجموع الفرعي",
  "total": "الإجمالي",
  "doYouHave": "هل لديك حساب بالفعل؟ انقر",
  "here": "هنا",
  "toConnect": "للاتصال.",
  "legalName": "الاسم القانوني",
  "legalDescription": "نوع الشركة",
  "postalAddress": "العنوان الرئيسي",
  "postalAddressNo": "الرقم",
  "postalAreaDescription": "البلدة الرئيسية",
  "postalZipCode": "الرمز البريدي",
  "firmActDescription": "المهنة الرئيسية",
  "companyPhone": "الهاتف",
  "companyEmail": "البريد الإلكتروني",
  "doyDescription": "اسم مكتب الضرائب",
  "returnToShop": "العودة إلى المتجر",
  "alergies": "هل لديك حساسية أو تحمل لأي طعام؟ أي تفاصيل غذائية خاصة؟ اتصل بالمتجر قبل تقديم طلبك."

}