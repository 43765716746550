import React from 'react'

const PaymentOptionsTextFrench = ()=> {
  return(
    <div className="terms-text">
      <h2>Paiement de la commande</h2>
    <p>
    Dans notre entreprise, nous faisons tout notre possible pour offrir le meilleur service à nos clients. Par conséquent, nous avons inclus le plus grand nombre possible de modes de paiement.
    </p>
    <h2>
    Carte de crédit - Carte de débit
    </h2>
    <p>
    Dans notre magasin, nous acceptons les cartes de crédit, de débit ou prépayées de toutes les banques. En cas de paiement dans notre magasin physique, le paiement est effectué à l'aide d'un terminal de paiement (POS). Si vous achetez dans notre boutique en ligne (e-shop) et que vous payez par carte de crédit, vous serez redirigé vers l'environnement sécurisé de l'organisme bancaire partenaire (PayPal). Ce site Web est sécurisé et couvert par un certificat SSL. Les détails de la carte de crédit, de débit ou prépayée qui sera utilisée ne nous sont pas divulgués, et nous ne les conservons pas dans notre entreprise.
    </p>
    <h2>
    Retrait en magasin
    </h2>
    <p>
    Si vous choisissez de retirer vos produits dans un magasin, vous pouvez également payer le montant de votre commande sur place.
    </p>
    </div>
  )
}
    
export default PaymentOptionsTextFrench;