export const accountAR = {
    "myAccount": "حسابي",
    "profile": "الملف الشخصي",
    "addresses": "العناوين",
    "history": "السجل",
    "comments": "التقييمات",
    "settings": "الإعدادات",
    "psw": "تغيير كلمة المرور",
    "updateAlert": "تم تحديث بياناتك بنجاح",
    "updateError": "حدث خطأ أثناء التحديث",
    "addAddress": "إضافة عنوان",
    "addNewAddress": "إضافة عنوان جديد",
    "myLocation": "موقعي",
    "modalHeaderUpdate": "تغيير العنوان",
    "otherLocation": "موقع آخر",
    "home": "المنزل",
    "work": "العمل",
    "helperText": "يرجى إدخال تفاصيل العنوان الخاص بك",
    "addressComments": "تعليقات إضافية عن العنوان",
    "historyPlaceholder": "لم تقم بأي عمليات شراء بعد",
    "commentPlaceholder": "لم تقم بأي تعليق بعد",
    "emptyHistory": "لم تقم بطلب أي طلب حتى الآن.",
    "uploadAvatar": "انقر على الصورة لاختيار صورة مختلفة.",
    "submit": "إرسال",
    "uploadSuccess": "تم تحميل الملف بنجاح",
    "uploadError": "خطأ في تحميل الملف",
    "bigFile": "الصورة كبيرة جدًا. يجب أن تكون بحجم يصل إلى 1 ميغابايت. يرجى اختيار صورة أصغر",
    "favorites": "المفضلة",
    "hello": "مرحبًا",
    "profileHeader": "الملف الشخصي",
    "settingsSmall": "الإعدادات"
}
