import React, {useEffect, useState} from 'react';
import { inject, observer } from "mobx-react";
import './Loyalty.css';
import _useTranslate from '../../hooks/_useTranslate';
import * as uniq from 'lodash/uniq';
import LoyaltyBar from './LoyaltyBar';
import { DateTime } from "luxon";

const Loyalty = ({feathersStore}) => {

let common = _useTranslate(feathersStore.language, "common");  

const [campaigns, setCampaigns] = useState(null);

useEffect(()=>{ 
  const asyncFn = async() => {
    const now = DateTime.now().setZone("Europe/Athens");
    const _coupons = await feathersStore.coupons();
    const _campaigns = _coupons?.filter(coupon =>  coupon?.active && (coupon?.completion > 0))
    let campaignsArray = [];
    let appliedCampaigns = (feathersStore.user?.loyaltyRewards || [])?.filter(loyalty => loyalty?.applied)?.map(c => c?.code);   
    for(let campaign of  _campaigns){
      if(appliedCampaigns?.length < 1 || !appliedCampaigns.includes(campaign.code)){   
        let valid = false;   
        if(campaign?.endDate){
          if(DateTime.fromISO(campaign.endDate).endOf('day') > now)valid = true;
        }else{
          if(DateTime.fromISO(campaign.startDate).plus({days : campaign.duration}).endOf('day') > now)valid = true;
        }
        if(valid){
          const userOrders = await feathersStore.ordersPerUser();
          const turnover = userOrders
          ?.filter(order => DateTime.fromMillis(order.createdAt) > DateTime.fromISO(campaign.startDate))
            .map(order => order?.total || 0)
            .reduce((a, b) => +a + +b, 0);
          Object.assign(campaign, {turnover});          
          campaignsArray.push(campaign);
        }
      }
    }
    const groupedCampaigns = groupCampaigns(campaignsArray);
    setCampaigns(groupedCampaigns);
  }  
  feathersStore?.isAuthenticated && asyncFn();
},[feathersStore?.isAuthenticated]);

const groupCampaigns = campaignsArray => {
  let returnArray = [];
  let uniqueDates = uniq(campaignsArray.map(cam => cam.startDate));
  for(let date of uniqueDates){
    let arr = campaignsArray?.filter(c => c.startDate === date);   
    arr.sort((a,b) => a.completion - b.completion)
    const item = {
      startDate: date,
      wayPoints: arr
    }
    returnArray.push(item) 
  }
  return returnArray.sort((a, b) => 
    a?.endDate ? DateTime.fromISO(a.endDate) : DateTime.fromISO(a.startDate).plus({days : a.duration}).endOf('day') 
      <  b?.endDate ? DateTime.fromISO(b?.endDate) : DateTime.fromISO(b.startDate).plus({days : b.duration}).endOf('day'));
}

return(  
  <>
    {campaigns?.length > 0 &&<div className="loyalty-container">
      <div className="loyalty-title">{common.loyaltyMoto}</div>
      {  campaigns?.map((campaign, index) => (
        <LoyaltyBar 
          key={index}
          endsIn={common.endsIn}
          days={common.days}
          campaign = {campaign}
        />
      ))
    }  
    </div>}
  </>
)

}

export default inject('feathersStore')(observer(Loyalty));