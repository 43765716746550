import React from 'react';
import { inject, observer } from "mobx-react";

const DeliveryOptionsTextFrench = ({feathersStore})=> {
  return(
    <div className="terms-text">
      <h2>Livraison et expédition</h2>
      <p>
        Dans notre magasin, nous mettons tout en œuvre pour servir nos clients. Vous pouvez commander à distance, que ce soit par téléphone ou via notre site web. Voici les deux moyens les plus fréquents d'envoyer et de recevoir nos produits.
        </p>
        <h2>Retrait dans notre magasin</h2>
        <p>
          Vous pouvez récupérer vos achats dans notre magasin situé à la rue {feathersStore.settings?.address}.
          </p>
          <h2>Livraison à votre emplacement</h2>
          <p>
            Nous nous engageons à livrer votre commande à l'endroit que vous avez indiqué.
            </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(DeliveryOptionsTextFrench));