export const cartAL = {
    cart: "Shporta",
    couponCode: "Kodi i Kuponit",
    couponButton: "Përdor Kuponin",
    cartTotals: "Totali",
    completeOrder: "POROSIA",    
    continueShopping: "Vazhdo me Porosinë",   
    without: "Pa",
    select: "Zgjidh",
    selectSmall: "Zgjidh",
    addToCart: "Shtuar në shportë",
    cartItems: "Artikujt në shportë",
    couponDiscount: "Zbritje kuponi",
    subTotal: "Nëntotali",
    couponCaption: "Keni një kupon?",
    new: "E RE",
    sale: "NË SHITJE",
    soldOut: "E SHITUR",
    couverCaption: "Numri i Personave",
    couverPlaceholder: "Shkruani Numrin",
    couverError: "Ju lutemi shkruani numrin e personave dhe shtypni kontrollo"
}
