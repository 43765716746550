import React from 'react';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";

const FaqTextGerman = ({ feathersStore })=> {
  return(
    <div className="terms-text">
      <h2>Welche Zahlungsmethoden akzeptieren Sie?</h2>
      <p>
      Sie können die akzeptierten Zahlungsmethoden <Link className="privacy-text" to="/paymentoptions">hier</Link> einsehen.
      </p>
      <h2>
      Mit welchen Versandmethoden kann ich meine Bestellung erhalten?
      </h2>
      <p>
     Sie können die Versandmethoden <Link className="privacy-text" to="/deliveryoptions">hier</Link> einsehen.
    </p>
    <h2>
    Kann ich Änderungen an meiner Bestellung vornehmen?
    </h2>
    <p>
    Sie können Änderungen vornehmen, solange Ihre Bestellung noch nicht versandt wurde. Bitte rufen Sie unter {feathersStore.settings?.phonenumbers} an oder kontaktieren Sie uns über das Website-Formular <Link className="privacy-text" to="/contact">hier</Link> oder per E-Mail an {feathersStore.settings?.email} und geben Sie Ihre Details sowie die gewünschten Änderungen an.
    </p>
    <h2>
    Gibt es ein Ladengeschäft, das ich besuchen kann?
    </h2>
    <p>
    Sie können uns an unserer Adresse {feathersStore.settings?.address} besuchen.
    </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(FaqTextGerman));