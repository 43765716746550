import React from 'react';
import {Link} from 'react-router-dom';
import { inject, observer } from "mobx-react";

const FaqTextFrench = ({ feathersStore })=> {
  return(
    <div className="terms-text">
    <h2>Quels modes de paiement acceptez-vous ?</h2>
    <p>
    Vous pouvez consulter les modes de paiement acceptés <Link className="privacy-text" to="/paymentoptions">ici</Link>.
    </p>
    <h2>
    Avec quels modes de livraison puis-je recevoir ma commande ?
    </h2>
    <p>
      Vous pouvez consulter les modes de livraison <Link className="privacy-text" to="/deliveryoptions">ici</Link>.
      </p>
      <h2>
      Puis-je apporter des modifications à ma commande ?
      </h2>
      <p>
      Vous pouvez apporter des modifications tant que votre commande n'a pas été expédiée. Veuillez appeler le {feathersStore.settings?.phonenumbers} ou nous contacter via le formulaire sur le site web <Link className="privacy-text" to="/contact">ici</Link> ou par e-mail à {feathersStore.settings?.email}, en indiquant vos coordonnées et les modifications que vous souhaitez apporter.
      </p>
      <h2>
      Y a-t-il un magasin que je peux visiter ?
      </h2>
      <p>
      Vous pouvez nous rendre visite à notre adresse {feathersStore.settings?.address}.
      </p>
    </div>
  )
}
    
export default inject('feathersStore')(observer(FaqTextFrench));