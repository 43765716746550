import React, {useEffect} from 'react';
import Colors from '../../assets/colors'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PrivacyText from './privacyText';
import PrivacyTextEnglish from './privacyTextEnglish';
import PrivacyTextFrench from './privacyTextFrench';
import PrivacyTextGerman from './privacyTextGerman';
import PrivacyTextItalian from './privacyTextItalian';
import PrivacyTextSpanish from './privacyTextSpanish';
import { inject, observer } from "mobx-react";
import "../account/Account.css";

// Translations
import _useTranslate from '../../hooks/_useTranslate';


const PrivacyPolicy = ({ feathersStore })=> {

  let footer = _useTranslate(feathersStore.language, "footer");

  const pickText = () => {
    switch (feathersStore.language){
        case "el" : {
            return <PrivacyText/>;
        }
        case "en" : {
            return <PrivacyTextEnglish/>;
        }
        case "de" : {
            return <PrivacyTextGerman/>;
        }
        case "es" : {
            return <PrivacyTextSpanish/>;
        }
        case "fr" : {
            return <PrivacyTextFrench/>;
        }
        case "it" : {
            return <PrivacyTextItalian/>;
        }
        case "ar" : {
          return <PrivacyTextEnglish/>;
      }
      }
    }
 
    useEffect(()=> {     
      return(() => {     
        if(!feathersStore.showCookies){
          const showCookies = localStorage.getItem('show_cookies');
          showCookies !== 'false' && feathersStore.setShowCookies(true);      
        }         
      })   
    },[]);
    
    return(
        <div className="settings-sub-container">          
            <div className="pageIcon">
                <FormatListNumberedIcon style={{fontSize: 150, color: Colors.settingsText}}/>
            </div>
            <p className="terms-title">{`-${footer.privacyPolicy}-`}</p>
            <div className="terms-container">
              {pickText()}
            </div> 
        </div>
    )
}

export default inject('feathersStore')(observer(PrivacyPolicy));