import React from 'react';
import { inject, observer } from "mobx-react";

const PrivacyTextEnglish = ({ feathersStore })=> {
    return(
        <div className="terms-text"><p>For the company / store {feathersStore.settings?.companyName} the protection of personal data is of primary importance and is treated with the utmost seriousness. Respecting the personal data we manage and ensuring their correct processing is one of the Company's priorities.</p> 
        <p>For this reason, we take all appropriate technical and organizational measures to protect the personal data we process and to ensure that their processing is always carried out in accordance with the requirements set by the current legal framework and especially by the general Data Protection Regulation (EU ) 2016/679.</p>
        <a><strong>1. Contact person - Details</strong></a>
         <p>{feathersStore.settings?.companyName} acts as the Controller for all personal data it collects, processes and stores.</p>
         {feathersStore.settings?.companyName}<br/>
         Address: {feathersStore.settings?.address}<br/>
         Phone: {feathersStore.settings?.phoneNumbers?.find(p => p.numberEnabled)?.phoneNumber}<br/>
         Email: {feathersStore.settings?.email}<br/>
         Contact: {feathersStore.settings?.companyName}<br/><br/>
        <a><strong>2. What data we collect and process</strong></a><br/>
        <p>In order to serve you and offer you our services and products, we collect and process all the data you provide to the call center representative when you place your order or the data you fill in the corresponding fields when you open a user account on our website and more specifically, name, postal address, contact telephone number (landline or mobile), e-mail, tax information (in case you wish to issue an invoice).<br/><br/> Please note that our Company's website may contain links ( links) to other websites. Our Company is not responsible for the privacy and data protection practices and/or the content of these websites. If you are under the age of 15, please obtain permission from your parent or guardian before sharing any of your personal information with us.</p>
        <a><strong>3. Processing purposes and legal bases</strong></a><br/>
        <p>All the above personal data are collected and subject to processing for the purpose, in principle, of the execution of the contract between us for the sale of our products and more specifically for the preparation and delivery of your order and our communication with you regarding it. Some of your data may also be used for the following purposes always with your relevant consent:<br/><br/>
        - Communicating with you in relation to contests, sweepstakes and other events.<br/>
         - Updating and sending offers and informative material about our products and services.<br/>
         - Statistical purposes and research.<br/>
         - Promotion and promotion of our products and services.</p>
         <p>Legal bases for data processing can be case by case:<br/>
         i. The execution and completion of the contract between us for the sale of our products to you (preparation and delivery of your order). Without the collection and use of the above information it will not be possible to serve you.<br/>
         ii. The consent that you may provide to us in order to receive updates regarding offers or promotional material for our products and services, commercial actions and activities that we develop, etc. You can withdraw your consent whenever you wish, by contacting us at contact details mentioned in paragraph 1 or through our website.</p>
        <a><strong>4. Where we share your personal data</strong></a><br/>
         <p>Your personal data may be shared with the following recipients:<br/>
         - To the distributors in order to prepare and deliver the order.<br/>
         - To a newsletter sending company (provided you have given your consent for us to send you a newsletter).<br/>
         - To an SMS sending company (if you have given your consent to receive updates).<br/>
         - To partner companies, in order to be able to receive your gift, if you are a winner in a competition in which you took part (every time we organize a competition, we mention in the terms of the competition the fact of sharing your details with the partner company).< br/>
         - To an external partner who supports us in software matters.<br/>
         - To the cooperating legal department of the company, in case of a judicial or extrajudicial dispute.<br/>
         - To police or other government authorities if we are officially requested to do so or after a prosecutor's order.<br/></p>
        <a><strong>5. Time interval of data storage</strong></a><br/>
        <p>Your data is kept in the {feathersStore.settings?.companyName} database for 5 years after your last transaction with the company or for as long as the law may require (e.g. in the case of issuing an invoice, as long as time defined by the tax legislative framework).<br/><br/>
         If the processing is based on your consent, the personal data is kept until you withdraw this consent. It is clarified that the withdrawal of consent does not affect the legality of the processing that was based on the consent before its withdrawal.<br/>
         In any case, {feathersStore.settings?.companyName} implements all appropriate technical and organizational measures to ensure the protection of the personal data it processes and constantly takes care to prevent any unauthorized access to this data.<br/><br/>
        <a><strong>6. Rights in relation to your personal data</strong></a><br/></p>
        <p>Regarding the processing of your personal data you can exercise the following rights:<br/><br/>
         Right of access<br/>
         You have the right to know what data we hold and process about you, why and other additional information about it, as well as to ask us for a copy of it.<br/><br/>
         Right of rectification<br/>
         You have the right to request the correction, modification and completion of your personal data.<br/><br/>
         Right to erasure ("right to be forgotten")<br/>
         You have the right to request the deletion of your personal data when it is processed after your consent. In cases where the processing is based on another legal basis (such as, for example, the performance of a contract, a legal obligation or the protection of the Company's legal interests, etc.) your right may be subject to limitations or may not exist.<br/><br/>
         Right to restrict processing<br/>
         You have the right to request the restriction of the processing of your personal data<br/>
         - when their accuracy is disputed and until the relevant verification is done.<br/>
         - alternatively, instead of deleting them.<br/>
         - when they are no longer necessary for us for the processing purposes for which we collected them, but are necessary for the establishment, exercise or support of legal claims by you.<br/>
         - when you object to their processing and until it is verified that there are legitimate reasons for this processing by the Company.<br/><br/>
         Right to object to processing.<br/>
         You have the right to object to the processing of your personal data when it is based on a legitimate interest, as well as for the purposes of direct marketing and profiling.<br/><br/>
         Right to portability<br/>
         You have the right to request and receive your personal data in a form that allows you to access it, use and process it with commonly used processing methods. In addition, for your data that we process by automated means and on the basis of your consent or for the performance of a contract, you can ask us to transfer it directly to another controller, if this is technically possible.<br/><br/>
         Right to withdraw consent<br/>
         If the processing of your data is based on your consent, you have the right to withdraw it at any time. The withdrawal of your consent does not affect the lawfulness of the processing that was based on the consent before its withdrawal.<br/><br/>
         Right to delete account<br/>
         You have sole and absolute control over your information and can modify or delete it from your account at any time without the consent or consent of the store.<br/>
         The same applies to your entire account since by entering the settings you can proceed with the corresponding option.<br/><br/>
         To exercise your above rights and for any question, complaint or other information regarding the processing of your personal data, you can contact us at the email {feathersStore.settings?.email} or the contact details mentioned in paragraph 1.< br/></p>
        <a><strong>7. Right of complaint to the Data Protection Authority (DPA)</strong></a><br/>
        <p>You have the right to file a complaint with the Personal Data Protection Authority (www.dpa.gr) regarding matters concerning the processing of your personal data.<br/></p>
        <a><strong>8. Changes to data protection</strong></a><br/>
        <p>Any future changes to our Company's Privacy Policy will be posted on the {feathersStore.settings?.siteUrl} website and, where necessary, communicated to you.<br/></p>
        <a><strong>9. Cookies Policy</strong></a><br/>
        <p>Because the online store uses modern technologies, no cookies are used with your data even if you have registered in our online store.<br/><br/> The only cookies used concern the Google Analytics service, as long as it is activated and in this case, general statistics are kept on the visits to the online store pages.</p>
    </div>
    )
}

export default inject('feathersStore')(observer(PrivacyTextEnglish));