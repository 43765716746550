import React from 'react';
import { CircularProgress } from '@mui/material';

const LogoutComponent = ({heading, message, yes, no, onClickNo, deleteItem, loading})=> {
  

  const logoutFromServerAndGoogle = () => {   
    deleteItem();
  }

  return (
    <div className="small-alert delete-alert logout-container ">
      <div className="alert-title-text delete-text">           
        {heading}
      </div> 
      <div className="alert-body-text">{message}</div>   
      <div className="delete-buttons-container">       
        <button className="cookie-button delete-button delete-yes-button"  onClick={logoutFromServerAndGoogle}>
          {yes}  
          { loading && 
              <CircularProgress                
                sx={{ marginLeft: 2, color: "hsl(358, 78%, 50%)"}} 
                size={'1rem'}            
              />                  
            }
        </button> 
        <button onClick={onClickNo} className="cookie-button delete-button">              
          {no}
        
        </button>              
      </div>  
  </div>
  );
}

export default LogoutComponent;