import React, {useState, useEffect, useRef} from 'react';
import { 
  FormControl,
  FormHelperText,
  Input, 
  CircularProgress, 
  Modal,
  InputAdornment,
  IconButton  
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import GoogleIcon from '@mui/icons-material/Google';
import { inject, observer } from "mobx-react";
import Utils from '../Utils';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';

import { DEFAULT_EMAIL, DEFAULT_PSW } from "../assets/globalConstants";
import Box from '@mui/material/Box';
import ResetPassword from './resetPassword';
import Colors from "../assets/colors";


// Translations
import _useTranslate from '../hooks/_useTranslate';

let emailElement;
let passwordElement;

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 500,  
  },
  height: 650,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,  
};
const inputControl = {
  fontSize: 20,
}
const inputIcon = {
  fontSize: 35
}


const Prompt = ({visible, feathersStore,
      onClickRegister, close, fromCart, goToAddress})=> {

  let common = _useTranslate(feathersStore.language, "common");  
  
  const [email, setEmail] = useState(""); 
  const [password, setPassword] = useState("");    
  const [loading, setLoading] = useState(false);
  const [fbLoading, setFbLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false); 
  const [emailError, setEmailError] = useState(false); 
  const [passwordError, setPasswordError] = useState(false);  
  const [secondTime, setSecondTime] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false) 
  
  const isMountedRef = useRef(null);  
    
  useEffect(() => {
    setLoading(false);
    isMountedRef.current = true;   
    if(feathersStore.isAuthenticated 
      && feathersStore.user?.firstname !== "default" 
      && fromCart && feathersStore.goToAddressHelper) goToAddress();
    return () => isMountedRef.current = false;
  }, [feathersStore.isAuthenticated, emailElement])
       
  const emailChange = event => {    
    setEmail(event.target.value);
    secondTime && emailValidation(event.target.value);   
  };

  const passwordChange = event => {    
    setPassword(event.target.value);
    passwordValidation(event.target.value);   
  };

  const emailValidation = val => {
     if (!Utils.validateEmail(val) ) {
      setEmailError(true);
    }else{
      setEmailError(false);
    }
  } 

  const passwordValidation = val => {
    if (!Utils.validatePassword(val) ) {
     setPasswordError(true);
   }else{
     setPasswordError(false);
   }
 } 

  const passwordFocus = () => { 
    setSecondTime(true);
    emailValidation(email);
  };

  const enterPressed = (ev) => {   
    if (ev.key === 'Enter') {
        passwordElement.focus();
    }
  }

  const submitEditing = async(ev) => {
    if (ev.key === 'Enter') {
      email && password && !emailError && !passwordError && await logIn();
    }
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleExit = () => {
    setSecondTime(false);
    setEmailError(false);
    setPasswordError(false);
    setEmail('');
    setPassword('');
    close();
  }

  const logIn = async() => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setLoading(true);
    try{
      const {user} = await feathersStore.login(email, password)
       feathersStore.setUser(user);
       handleExit();     
    }catch(error){
      await loginAsDefault();
      if  (error?.data?.code === 11000) {
        const userArray = await feathersStore.getUserByEmail(error.data.keyValue.email);
        if(!userArray?.data?.length > 0){
          let errorMessage = "";
          if(userArray[0]?.appleId?.length > 0)errorMessage = common.appleId;
          else if(userArray[0]?.googleId?.length > 0)errorMessage = common.googleId;
          else if(userArray[0]?.facebookId?.length > 0)errorMessage = common.facebookId;
          else errorMessage = common.emailPassword;
          window.alert(`${common.loginError} ${errorMessage}`);
        }else{
          window.alert(`${common.loginError} ${error.message}`);
          handleExit(); 
        }              
      }else{        
        window.alert(`${common.loginError} ${error.message}`);  
        handleExit();
     }};      
  };

  const loginAsDefault = async()=> {
    isMountedRef.current && setLoading(true);
    try{
      feathersStore.isAuthenticated && await feathersStore.logout();     
        feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
          .then(async(auth) => { 
           // history.push('/') ; 
            await feathersStore.setObservables();                                 
        })
        isMountedRef.current && setLoading(false);
    }catch{
        console.log('ERROR LOGGING OUT');
        isMountedRef.current && setLoading(false);
    }   
  }   
  
  const onLogoutSuccess = res => {
    console.log("Logged out from Google")
  }

  const onFailure = () => {
    console.log("Failed to logout from Google")
  }


  const responseFacebook = async(response) => {
    feathersStore.setGoToAddressHelper(true); 
    isMountedRef.current && setFbLoading(true);
    feathersStore?.isAuthenticated && await feathersStore.logout();
    feathersStore.facebookAuth(response.accessToken?.toString())
      .then(async auth => {
        await feathersStore.setObservables();  
        handleExit();               
      })
      .catch(async(error) => {       
        await loginAsDefault();
        if  (error.data.code === 11000) {
          const userArray = await feathersStore.getUserByEmail(error.data.keyValue.email);
          if(!userArray?.data?.length > 0){
            let errorMessage = "";
            if(userArray[0]?.appleId?.length > 0)errorMessage = common.appleId;
            else if(userArray[0]?.googleId?.length > 0)errorMessage = common.googleId;
            else errorMessage = common.emailPassword;
            window.alert(`${common.loginError} ${errorMessage}`);
          }else{
            window.alert(`${common.loginError} ${error.message}`);
            handleExit(); 
          }              
        }else{        
          window.alert(`${common.loginError} ${error.message}`);  
          handleExit();
       } 
      });  
  }

  const responseFacebookFailed = (error) => {
    console.log(error);
  }

  const responseGoogle = async(response) => {  
    feathersStore.setGoToAddressHelper(true); 
    feathersStore.isAuthenticated && await feathersStore.logout();
    feathersStore.googleAuth(response.access_token?.toString())
      .then(async auth => {
        await feathersStore.setObservables(); 
        googleLogout(); //--> SignOut from Google because the server handles auth and not Google with its cookies
        handleExit();               
      })
      .catch(async(error) => {
        googleLogout();
        await loginAsDefault();            
        if  (error.data.code === 11000) {
          const userArray = await feathersStore.getUserByEmail(error.data.keyValue.email);
          if(!userArray?.data?.length > 0){
            let errorMessage = "";
            if(userArray[0]?.appleId?.length > 0)errorMessage = common.appleId;
            else if(userArray[0]?.facebookId?.length > 0)errorMessage = common.facebookId;
            else errorMessage = common.emailPassword;
            window.alert(`${common.loginError} ${errorMessage}`);
          }else{
            window.alert(`${common.loginError} ${error.message}`);
            handleExit(); 
          }              
        }else{
          window.alert(`${common.loginError} ${error.message}`);
          handleExit(); 
        }                    
      });       
  }

  const responseGoogleFailed = (response) => {
    console.log(response);
    console.log('VOOOO')
  }

 
 const customGoogleLogin = useGoogleLogin({
    onSuccess: responseGoogle,  
    onError: responseGoogleFailed,    
    scope: "openid https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile" 
   // flow: 'auth-code',
   //flow:'implicit'
  //  ux_mode: 'popup',
 //   redirect_uri: 'https://bookali.hellochat.gr/oauth/google/callback'
    //ux_mode: "redirect"
   
  })
  

  const submitReset = ()=> {
    setResetPasswordModal(!resetPasswordModal);
  } 

  return (   
    <section>
      <Modal 
        open={visible} 
        onClose={close}
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
      <Box sx={style}>
        <div className="modalContainer">
          <div className="modalTitleContainer">
            {common.loginRegistration}
          </div>
          <CloseIcon className="close-icon"  onClick={handleExit}/>
          <div className="modalFormContainer">
            <FormControl fullWidth={true} >
              <Input
                sx={inputControl}
                autoFocus
                aria-label='email'
                value={email}     
                error={emailError}
                inputRef={r => {
                  emailElement = r;
                }}
                onChange={emailChange}              
                onKeyPress={enterPressed}
                placeholder="E-mail"
                startAdornment={
                  <InputAdornment position="start">
                    <PersonOutlineIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {emailError && <FormHelperText id="component-error-text" >{common.emailError}</FormHelperText>}
            </FormControl>
            <div className="mt-2"></div>
            <FormControl fullWidth={true}>
              <Input
                sx={inputControl}
                aria-label='password'
                value={password}
                error={passwordError}              
                inputRef={r => {
                  passwordElement = r;
                }}             
                type={showPassword ? 'text' : 'password'}
                onChange={passwordChange}
                onFocus={passwordFocus}          
                onKeyPress={submitEditing}              
                placeholder="Password" 
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <Visibility sx={inputIcon}/> : <VisibilityOff sx={inputIcon}/>}
                    </IconButton>
                  </InputAdornment>
                }
                startAdornment={
                  <InputAdornment position="start">
                    <LockOpenIcon sx={inputIcon}/>
                  </InputAdornment>
                }
              />
              {passwordError && 
                <FormHelperText id="component-error-text" sx={{color: Colors.red}}>{common.passwordHelperText}</FormHelperText>}
            </FormControl>  
            <div className="mt-1"></div>                      
            <div className="login-forgot-psw"          
              aria-labelledby='forgot password'
            ><span>{common.lostPassword}</span>
              <a  onClick={submitReset} className="reset-psw">{common.reset}</a>
            </div>
            <div className="mt-1"></div>
            <button
              onClick={logIn} 
              disabled = {!email || !password || emailError || passwordError}              
              aria-labelledby='Login'
              className="my-facebook-button-class my-email-button-class"
            >
              {common.login}
              {loading && 
              <CircularProgress 
                color="common" 
                style={{ marginLeft: 6 }} 
                size={'1.5rem'}            
              />                  
            }
            </button> 
          </div>
          <div className="login-or">
            <div className="line-or"></div>
            <div>{common.loginOr}</div>
            <div className="line-or"></div>
          </div>
          <div className="modalFacebookContainer">
            <FacebookLogin
              appId={feathersStore.settings?.FACEBOOK_APP_ID}              
              fields="name,email,picture"          
              onSuccess={responseFacebook}     
              onFail={responseFacebookFailed}
              style={{
                background:"transparent",
                border:"none",
                padding: "0px",
                margin: "0px"
              }}
              children={<div className="my-facebook-button-class">
                <FacebookOutlinedIcon/><span className="google-text">FACEBOOK</span>
              </div>}      
              aria-labelledby='Facebook login'
            />    
            {feathersStore.settings?.GOOGLE_CLIENT_ID  && 
            <>
              <button
                className="my-facebook-button-class my-google-button-class"
                onClick={() => customGoogleLogin()}             
                aria-labelledby='google login'             
              >
                <GoogleIcon/><span className="google-text">GOOGLE</span>
              </button>
          
            </>      
            
                   
            }          
          </div> 
          <div className="mt-2"></div>  
            <div className="register-info">
              <span>{common.orRegister}</span>
              <a  onClick={onClickRegister} className="reset-psw">{common.here}</a>
            </div>
            <div className="terms-login-text">{common.registerInfoA} <Link to="/termsnconditions" onClick={handleExit}>{common.registerInfoB}</Link>, {common.registerInfoC} <Link to="/privacypolicy" onClick={handleExit}>{common.registerInfoD}</Link> {common.registerInfoE}.</div>
        </div>        
        <ResetPassword
          visible={resetPasswordModal}
          onClickSubmit={submitReset}
          close={submitReset}
        />  
      </Box>      
      </Modal>
    </section>
  );
}

export default inject('feathersStore')(observer(Prompt));
