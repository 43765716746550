import React from 'react';
import { Button, CircularProgress } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const GreenSubmitButton = ({ type="button", title, size="medium", loading, variant="contained", 
  marginLeft=1, marginRight=1, marginTop=1, marginBottom=1, fontSize, height, textTransform,
  disabled, borderRadius = 10, onClick, width, color="rgb(0, 128, 0)", ...other })=> {

    function changeCursor(e) {
        e.target.style.cursor = 'pointer';
      }      
      
    const theme = createTheme({
      palette: {
        primary: {
          main: `${color}` 
        },
        secondary: {
          main: "#fff"
        },
      },
    });

    const contained = {
      borderRadius, color: "#fff", backgroundColor: "hsl(151, 100%, 28%)", width, marginLeft, marginRight, 
      marginTop, marginBottom, fontSize, height, textTransform
    }

    const outlined ={
     borderRadius, color: "black", width, height, marginLeft, marginRight, marginTop, marginBottom, 
     fontSize, textTransform
    }
       
    return(
        <ThemeProvider theme={theme}>
          <Button       
            sx={variant === "contained" ? contained : outlined}
            aria-label='Submit'
            type={type}
            size={size}
            onClick={onClick}
            onMouseOver={changeCursor}
            variant={variant}                 
            disabled={disabled}
            {...other}
          >
            {title}
            {loading && 
              <CircularProgress 
                color="secondary" 
                style={{ marginLeft: 6 }} 
                size={'1.5rem'}            
              />                  
            }
          </Button>
        </ThemeProvider> 
    )
}

export default GreenSubmitButton;