export const accountFR = {
    myAccount: "Mon Compte",
    profile: "PROFIL",
    addresses: "ADRESSES",
    history: "HISTORIQUE",
    comments: "COMMENTAIRES",
    settings: "PARAMÈTRES",
    psw: "Changer de mot de passe",
    updateAlert: "Vos données ont été mises à jour avec succès",
    updateError: "Erreur lors de la mise à jour",
    addAddress: "Ajouter une adresse",
    addNewAddress: "Ajouter une nouvelle adresse",
    myLocation: "Ma localisation",
    modalHeaderUpdate: "Modifier l'adresse",
    otherLocation: "Autre lieu",
    home: "Domicile",
    work: "Travail",
    helperText: "Veuillez saisir les détails de votre adresse",
    addressComments: "Commentaires supplémentaires sur l'adresse",
    historyPlaceholder: "Vous n'avez encore effectué aucun achat",
    commentPlaceholder: "Vous n'avez encore laissé aucun commentaire",
    emptyHistory: "Vous n'avez encore passé aucune commande.",
    uploadAvatar: "Cliquez sur l'image pour choisir une image différente",
    submit: "SOUMETTRE",
    uploadSuccess: "Téléchargement du fichier réussi",
    uploadError: "Erreur de téléchargement du fichier",
    bigFile: "L'image est trop volumineuse. Elle doit faire jusqu'à 1 Mo. Veuillez sélectionner une image plus petite",
    favorites: "Mes favoris",
    hello: "Bonjour",
    profileHeader: "Profil",
    settingsSmall: "Paramètres"
}
