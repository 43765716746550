import React from 'react';

const SubCategoryComponent = ({ name, image,  onClick })=> {

  function changeCursor(e) {
    e.target.style.cursor = 'pointer';
  }

  return(
    <div 
      className="sub-cat-container"  
      onClick={onClick}   
      onMouseOver={changeCursor} 
    >
      <div className="sub-cat-section">
        {name}
      </div>
      <img className="sub-cat-image" src={image}></img>
    </div>    
  )
}

export default SubCategoryComponent;