import React, { useEffect, useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Rating from '@mui/material/Rating';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { inject, observer } from "mobx-react";
import Tooltip from '@mui/material/Tooltip';
import _useTranslate from '../../hooks/_useTranslate';
import { styled } from '@mui/material/styles';
import * as cloneDeep from 'lodash/cloneDeep';

const GoldRating = styled(Rating)(({ theme }) => ({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
}));


const CommentsCard = ({feathersStore, username, date, stars, 
    reviewText, productId, productImage, redirectFunction})=> {  

  const [edit, setEdit] = useState(false);
  const [newReviewText, setNewReviewText] = useState(reviewText);
  const [newStars, setNewStars] = useState(stars);
  const focused = useRef();

  let common = _useTranslate(feathersStore.language, "common");   
  
  const editComment = () => {
    setEdit(!edit);
    setNewStars(stars);
    setNewReviewText(reviewText);
  }
  
  const getReviewText = (e) => {
    const newComment = e.target.value;
    setNewReviewText(newComment);
  }

  const getNewStars = (event, newValue) => {    
    setNewStars(newValue);
  }

  useEffect(() => {
    if(focused.current) focused.current.focus();
  }, [edit]);
  
  const saveComment = async() => { 
    let product = await feathersStore.getProduct(productId);
    const now = new Date();
    let commentsList = [...product.commentsList];
    const index = commentsList.findIndex(el => new Date(el.date).getTime() === (new Date(date)).getTime())      
    const review = {
      date: now,      
      userId : feathersStore.user?._id,
      avatar: feathersStore.user?.avatar,
      username: feathersStore.user?.firstname + " " + feathersStore.user?.lastname,
      stars: newStars,
      reviewText: newReviewText,
      productImage: commentsList[index].productImage,
      blocked: false,
      productId: commentsList[index].productId,
      productName: commentsList[index].productName,
      isNew: true
    } 
    commentsList.splice(index, 1, review);
    const newStarAverage = calculateStarAverage(commentsList); 
    await feathersStore.patchProduct(product._id, {commentsList: commentsList, starAverage: newStarAverage})  ;
    
    //now update the user too!! ----------------->

    let clonedUser = cloneDeep(feathersStore.user);
    const userListIndex = clonedUser.commentsList.findIndex(el => new Date(el.date).getTime() === (new Date(date)).getTime());
    clonedUser.commentsList.splice(userListIndex, 1, review);
    await feathersStore._patchUser(clonedUser._id, {commentsList: clonedUser.commentsList});
    feathersStore.setUser(clonedUser);
  }

  const deleteComment = async() => {
    let product = await feathersStore.getProduct(productId);
    let commentsList = [...product.commentsList];
    const index = commentsList.findIndex(el =>  new Date(el.date).getTime() === (new Date(date)).getTime());
    commentsList.splice(index, 1);
    const newStarAverage = calculateStarAverage(commentsList); 
    await feathersStore.patchProduct(product._id, {commentsList: commentsList, starAverage: newStarAverage})

     //now update the user too!! ----------------->

    let clonedUser = cloneDeep(feathersStore.user);
    const userListIndex = clonedUser.commentsList.findIndex(el => new Date(el.date).getTime() === (new Date(date)).getTime());
    clonedUser.commentsList.splice(userListIndex, 1);
    await feathersStore._patchUser(clonedUser._id, {commentsList: clonedUser.commentsList});
    feathersStore.setUser(clonedUser);
  }

  const calculateStarAverage = (commentsArray) => {
    let cleanArray = commentsArray
        .filter(comm => !comm.blocked);
    let starsTotal = cleanArray.map(c => c.stars)
        .reduce((a,b) => a+b, 0);
    return starsTotal / cleanArray.length;
  }

  return(
    <div className="rated-card" style={{width: '98%', margin: '0 auto 15px'}}>
      <div className="rated-card-title">
        <Avatar variant="rounded" src={productImage && `${productImage}`} onClick={redirectFunction}/>
        <p style={{marginLeft: 10, marginTop: 5, cursor: 'pointer'}} 
          onClick={redirectFunction}
        >{username}</p>
        <p style={{marginLeft: 5, marginTop: 5, color: 'grey'}}>- {new Date(date).toLocaleDateString()}</p>
        {edit ?
          <GoldRating 
            style={{marginLeft: 20, marginTop: 5}}
            name="editable"
            value={newStars}
            onChange={getNewStars}
          /> :
          <GoldRating 
            style={{marginLeft: 20, marginTop: 5}} 
            name="notEditable"
            readOnly
            value={newStars}        
          />
        }
      </div>
      <div className="style-comment-section">
        {reviewText && 
          <div className="rated-card-commentText comment-card-commentText">
            <textarea 
              ref={focused}
              disabled={!edit}
              value={newReviewText}
              onChange={getReviewText}
            /> 
          </div>
        }
        <div className="handle-comments-button">
          <Tooltip title={common?.edit} aria-label="edit review">
            <div
              className='comment-edit'
              onClick={editComment}
            >
              <EditIcon />
            </div>
          </Tooltip>
          <Tooltip title={common?.save} aria-label="save review">
            <div 
              className='comment-save'
                onClick={saveComment}
            >
              <DoneIcon />
            </div>
          </Tooltip>
          <Tooltip title={common?.delete} aria-label="delete review">
            <div 
              className='comment-delete' 
                onClick={deleteComment}
            >
              <DeleteIcon />
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default inject('feathersStore')(observer(CommentsCard));