import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { CircularProgress } from '@mui/material';

const DeleteAccount = ({ show, heading, onClose, onDelete, message, no, yes, loading, fromCart}) =>{ 

  const redColored = {color: "hsl(358, 78%, 50%)", fontSize: 35, marginRight: 1}
  const greenColored = {color: "hsl(151, 100%, 28%)", fontSize: 35, marginRight: 1}
    
  return(
    <>
    <div className="cookie-updated-alert delete-position">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <div className={fromCart ? "small-alert delete-product-alert" : "small-alert delete-alert"}>
          <div className="alert-title-text delete-text">           
            {heading}
          </div> 
          <div className="alert-body-text">{message}</div>   
          <div className="delete-buttons-container">       
            <button className="cookie-button delete-button delete-yes-button" onClick={onDelete} >
              {yes}
              {loading && 
                <CircularProgress 
                  color="secondary" 
                  style={{ marginLeft: 6 }} 
                  size={'1rem'}            
                />                  
              }
            </button> 
            <button onClick={onClose} className="cookie-button delete-button">              
              {no}
            </button>              
          </div>     
        </div>
      </CSSTransition>
    </div>
  </>
  )
}

export default DeleteAccount;