import React, { useEffect, useState } from 'react'
import HistoryComponent from './historyComponent';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../../hooks/_useTranslate';

const History = ({ feathersStore })=> {

  let account = _useTranslate(feathersStore.language, "account");  
  
  let navigate = useNavigate();

  const [orders, setOrders] = useState(null);
  
  useEffect (() => {
    initOrders();
  }, []);

  const initOrders = async() => {
    let orders = [] = await feathersStore.ordersPerUser();
    let tablesPerUser = [] = await feathersStore.tableOrdersPerUser(); 
    const  tableOrders =  tablesPerUser.map(table => table.order)     
    setOrders([...tableOrders, ...orders]);
  }      

  const goToProduct = product_id => {   

    feathersStore.getProduct(product_id).then(prod => {
      prod && navigate(`/product/${feathersStore. _translateName(prod)}`,{
        state: {id: `${prod._id}`,  payload: prod}     
    });  
    });
  }

  const renderItem = orders?.map(item =>      
    <HistoryComponent 
      key={item._id}
      orderNumber={item?.tableId ? item.tableName : '#' + item._id.slice(-6)}
      date={new Date(item.createdAt).toLocaleDateString()}
      list={item.items}
      total={item.total}
      transportation={item?.transportations || 0}
      discounts={item?.discounts || 0}
      onClick={goToProduct}
      status={item.status}
    />
  )

  return(    
    <div className="history-object-container">
      {orders?.length === 0 ? <div className="empty-component-text">{account.emptyHistory}</div> : renderItem}
    </div>   
  )
}

export default inject('feathersStore')(observer(History));