import React,{ useState, useEffect } from 'react';
import { inject, observer } from "mobx-react";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '../components/topBar/TopBar.css';
import greek from '../img/languages/greek.png';
import english from '../img/languages/us.png';
import germany from '../img/languages/germany.png';
import france from '../img/languages/france.png';
import spain from '../img/languages/spain.png';
import italy from '../img/languages/italy.png';
import arabic from '../img/languages/arabic.png';
import albania from '../img/languages/albania.png';


// Translations
import _useTranslate from '../hooks/_useTranslate';

const style = { 
  positioning: {
    position: 'absolute',
    top: 30,
  }
};

const LanguageButton = ({feathersStore, showCaption})=> {

  let common = _useTranslate(feathersStore.language, "common");
  
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleLanguage = lang => {
    feathersStore.setLanguage(lang);
    handleClose();
  }

  const findLanguage = () => {
    return feathersStore.settings?.targetLanguages?.find(lang => lang.code === feathersStore.language)
  }

  const findImage = (code)  => { 
    switch (code){
      case "el" :      
        return greek;
      case "en" :  
        return english;
      case "de" :
        return germany;
      case "fr" :
        return france;
      case "es" :
        return spain;
      case "it" :
        return italy;
      case "ar" :
        return arabic;
      case "sq" : 
        return albania;      
    }
  }

  const findMasterLanguge = (code)  => { 
    switch (code){
      case "el" :      
        return "Ελληνικά";
      case "en" :  
        return "English";
      case "de" :
        return "Deutsch";
      case "fr" :
        return "Français";
      case "es" :
        return "Español";
      case "it" :
        return "Italiano";
      case "ar" :
        return "العربية";
      case "sq" :
        return "Shqip";
    }
  }

  return(
  <>
    <Menu      
      id="simple-menu"
      anchorEl={anchorEl}      
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} 
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
  //    sx={style.positioning} 
    >
      <MenuItem  onClick={() => toggleLanguage(feathersStore.settings.masterLanguage)}>  
        <span>
          <img aria-label='masterLanguage' src={findImage(feathersStore.settings.masterLanguage)}  alt="..."/>
        </span>
        {findMasterLanguge(feathersStore.settings.masterLanguage)}
      </MenuItem>
      {feathersStore.settings?.targetLanguages?.map(lang =>
        <MenuItem key={lang?.code} onClick={() => toggleLanguage(lang?.code)}>
          <span>
          <img aria-label={`${lang?.name}`} src={findImage(lang?.code)} style={{width: "28px"}}  alt={`${lang?.name}`}/>         
          </span>
          {lang?.name}        
        </MenuItem>  
      )}
    </Menu>       
    <button
      aria-labelledby='Dropdown'     
      className="nav-button-SignIn undecorated-link lang-pointer"              
      onClick={handleClick}
    >
      <span className='row-flex-center'>
        {feathersStore.language === feathersStore.settings.masterLanguage ? 
          <img aria-label='masterLanguage' src={findImage(feathersStore.settings.masterLanguage)} style={{width: "28px"}} alt="masterLanguage"/> :
          <img aria-label={`${findLanguage()?.name}`} src={findImage(findLanguage()?.code)} style={{width: "28px"}}  alt={`${findLanguage()?.name}`}/>         
        } 
        {showCaption && <span style={{marginLeft: "1vw"}}>{common.language}</span>}         
      </span> 
      <span>{<ArrowDropDownIcon />}</span> 
    </button>  
  </> 
  )

}

export default inject('feathersStore')(observer(LanguageButton));