export default class Utils {
  
    static validateEmail(email) {
      let EMAIL_REGEXP = /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;
      //TODO: Add more robust validation
      if (EMAIL_REGEXP.test(email)){
        return true;
      }
      return false;
    }
  
    static validatePassword(password) {
      
      let PASSWORD_REGEXP =/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
      if (PASSWORD_REGEXP.test(password)) {
        return true;
      }
      return false;
      
    // return true;
    }

    static validatePhone(tel) {
      //TODO: Add more robust validation
      if (tel.length === 10 && !isNaN(tel)) {
        return true;
      }
      return false;
    }

    static validateName(name) {
      //TODO: Add more robust validation
      if (name.length > 1 && isNaN(name)) {
        return true;
      }
      return false;
    }

    static validateNonEmpty(str) {
      //TODO: Add more robust validation
      if (str.length > 0) {
        return true;
      }
      return false;
    }
/*
    static async requestLocationPermission() {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION,            
          {
            'title': 'Location Permission',
            'message': 'Η Λειτουργία Οδήγησης θέλει πρόσβαση στο GPS ' +
                       'της συσκευής'
          }            
        )
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          console.log("ΟΚ Location Permission granted")
        } else {
          console.log("Location permission denied")
        }
      } catch (err) {
        console.warn(err)
      }
    }

    static async requestReadPhoneStatePermission() {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_PHONE_STATE,
          {
            'title': 'Read Phone State Permission',
            'message': 'Η Λειτουργία Οδήγησης θέλει πρόσβαση στις ρυθμίσεις ' +
                       'της συσκευής'
          }
        )
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          console.log("ΟΚ Phone State Permission granted")
        } else {
          console.log("Phone State permission denied")
        }
      } catch (err) {
        console.warn(err)
      }
    }

    static async requestSendSmsPermission() {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.SEND_SMS,
          {
            'title': 'Send SMS Permission',
            'message': 'Η Λειτουργία Οδήγησης θέλει άδεια για να ' +
                       'στέλνει SMS'
          }
        )
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          console.log("ΟΚ Send SMS Permission granted")
        } else {
          console.log("Send SMS permission denied")
        }
      } catch (err) {
        console.warn(err)
      }
    }

    static async requestReadSmsPermission() {
      try {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.READ_SMS,
          {
            'title': 'Read SMS Permission',
            'message': 'Η Λειτουργία Οδήγησης θέλει άδεια για να ' +
                       'διαβάζει SMS'
          }
        )
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          console.log("ΟΚ Read SMS Permission granted")
        } else {
          console.log("Read SMS permission denied")
        }
      } catch (err) {
        console.warn(err)
      }
    }

    <Text style={styles.text}>         
          {'\t'}Η εφαρμογή, Ασφαλής Οδήγηση, έχει σκοπό να ελαχιστοποιήσει την απόσπαση της προσοχής του οδηγού για τη μείωση των οδικών ατυχημάτων, εξαιτίας της χρήσης του κινητού κατά την οδήγηση{'\n'}{'\n'}            
          {'\t'}Πώς λειτουργεί:{'\n'}
          {'\t'}Ανοίγετε την εφαρμογή πριν την οδήγηση.{'\n'}  
          {'\t'}Παρουσιάζεται ένας κύκλος με την αναγραφή 0 km /h  και το σήμα του διακόπτη σε πράσινο χρώμα , που δηλώνει ότι η εφαρμογή είναι ON .{'\n'} 
          {'\t'}Όταν το μεταφορικό μέσον που οδηγούμε, αυτοκίνητο, μηχανή ,ποδήλατο, κτλ αναπτύξει ταχύτητα πάνω από 20 km/h για ένα λεπτό, ενεργοποιείται αυτόματα η εφαρμογή.{'\n'}  
          {'\t'}Σαν ένδειξη σβήνει ο κύκλος και αναγράφει, Εφαρμογή Ενεργοποιημένη.{'\n'}  
          {'\t'}Με ενεργοποιημένη την εφαρμογή το τηλέφωνό σας μπαίνει σε σίγαση, ( ελαχιστοποιεί τον ήχο και τη δόνηση )  ώστε να μην παρενοχλήστε. Η εφαρμογή τίθεται αυτόματα εκτός,  εάν παραμείνετε στάσιμοι πάνω από 2 λεπτά, εάν η ταχύτητα είναι για δύο λεπτά κάτω των 20 χιλ.  η με πάτημα πάνω στον πράσινο σήμα του διακόπτη, κάτι που δεν σαν συνιστούμε κατά την οδήγηση.{'\n'} 
          {'\t'}Με ενεργοποιημένη την εφαρμογή, όταν κάποιος σας καλεί η σας στέλνει SMS θα λαμβάνει αυτόματα ένα μήνυμα που θα έχετε επιλέξει. Εφόσον σας το επιτρέπει ο πάροχος κινητής τηλεφωνίας μπορείτε να ηχογραφήσετε και κάποιο μήνυμα.{'\n'}
          {'\t'}Βασική προϋπόθεση για την λειτουργία της εφαρμογής είναι να έχετε ενεργοποιήσει την τοποθεσία στο κινητό σας και να έχει επικοινωνία με δορυφόρο.
          </Text>

*/
  }