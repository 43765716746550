import React, {useEffect, useState} from 'react';
import { CSSTransition } from 'react-transition-group';
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { inject, observer } from "mobx-react";
import _useTranslate from '../..//hooks/_useTranslate';
import CheckIcon from '@mui/icons-material/Check';
import "./Cart.css"

const CouverModal = ({ show, couverQuantity, couverQuantityChange, addCouverToCart, feathersStore}) =>{ 

  const redColored = {color: "hsl(358, 78%, 50%)", fontSize: 35, marginRight: 1}
  const greenColored = {color: "hsl(151, 100%, 28%)", fontSize: 35, marginRight: 1}

  let common = _useTranslate(feathersStore.language, "common");  
  let cart = _useTranslate(feathersStore.language, "cart");  
    
  return(
    <>
    <div className="cookie-updated-alert">
      <CSSTransition
        in={show}
        timeout={300}
        classNames="my-alert"
        unmountOnExit
      >
        <div className="couver-alert">
          <div className="couver-title-text">
            <NotificationsActiveIcon sx={redColored}/>            
            {cart.couverCaption}
          </div> 
          <div className="couver-caption">{cart.couverError}</div>
          <div className="couver-footer">           
            <div className="coupon-input-container"> 
              <input type="number" min="0" max="100" step="1" placeholder={cart.couverPlaceholder} className="couver-input"
                value={couverQuantity}
                onChange={couverQuantityChange}
              />
              <span className="couver-button"  onClick={addCouverToCart}>
                <CheckIcon/>
              </span>   
            </div>             
          </div> 
         
        </div>
      </CSSTransition>
    </div>
  </>
  )
}

export default inject('feathersStore')(observer(CouverModal));