export const footerAR = {
  "contactHours": "ساعات الاتصال",
  "week": "الإثنين – الجمعة",
  "contactUs": "اتصل بنا",
  "address": "شارع ميسوغيون 131، ماروسي 15126",
  "findUs": "ابحث عنا على الخريطة",
  "contact": "اتصل بنا",
  "privacyPolicy": "سياسة الخصوصية",
  "termsOfService": "شروط الخدمة",
  "stayConnected": "ابقى على اتصال",
  "cookiesTitle": "استخدام الكوكيز",
  "aboutUs": "من نحن",
  "cookiesText": "نحن نستخدم فقط الكوكيز اللازمة الحد الأدنى. لا تُخزن بياناتك الشخصية في أي منها.",
  "cookiesAccept": "قبول",
  "accessibilityTitle": "إمكانية الوصول",
  "fontIncrease": "زيادة حجم الخط",
  "fontDecrease": "تقليل حجم الخط",
  "greyscale": "درجات الرمادي",
  "highContrast": "تباين عالي",
  "negativeContrast": "تباين سلبي",
  "lightBackground": "خلفية فاتحة",
  "linksUnderline": "تسطير الروابط",
  "readableFont": "خط قراءة",
  "reset": "إعادة تعيين",
  "paymentOptions": "خيارات الدفع",
  "deliveryOptions": "خيارات التوصيل",
  "openingTime": "وقت الفتح",
  "loginRegister": "تسجيل الدخول / التسجيل",
  "messagingTitle": "الدعم",
  "messagingSubTitle": "أرسل رسالة بأسئلتك!",
  "message": "الرسالة",
  "installBringFood": "ابدأ مع برينج فود!",
  "usefull": "مفيد",
  "company": "الشركة",
  "faq": "الأسئلة الشائعة",
  "chatPlaceholder": "أدخل النص...",
  "restaurantClosed": "المحل مغلق في هذه اللحظة ولا يمكننا تحضير طلبك",
  "holidayText": "سيبقى المحل مغلقًا اليوم",
  "allDay": "طوال اليوم",
  "to": "حتى"
}