export const cartEL = {  
    cart : "Καλάθι",
    couponCode : "Κωδικός κουπονιού",
    couponButton : "Εφαρμογή Κουπονιού",
    cartTotals : "Σύνολα",
    completeOrder : "ΤΑΜΕΙΟ",
    continueShopping : "ΣΥΝΕΧΕΙΑ ΑΓΟΡΩΝ",
    without: "Χωρίς",
    select: "ΕΠΙΛΟΓΗ",
    selectSmall: "Επιλογή",
    addToCart: "Στο καλάθι",
    cartItems: "Είδη καλαθιού",
    couponDiscount: "Έκπτωση κουπονιού",
    subTotal: "Μερικό σύνολο",
    couponCaption: "Έχετε κουπόνι;",
    new: "NEO",
    sale: "ΠΡΟΣΦΟΡΑ",
    soldout: "ΕΞΑΝΤΛΗΜΕΝΟ",
    couverCaption: "Αριθμός ατόμων",
    couverPlaceholder: "Εισάγετε αριθμό",
    couverError: "Παρακαλώ εισάγετε αριθμό ατόμων και πατήστε το check."
}