export const checkoutAL = {
   less255: "Maksimumi 255 karaktere",
  less50: "Maksimumi 50 karaktere",
  less20: "Maksimumi 20 karaktere",
  less10: "Maksimumi 10 karaktere",
  required: "E nevojshme",
  invalidEmail: "Email i pavlefshëm",
  invalidPhone: "Numër i pavlefshëm",
  textareaMax: "Maksimumi 1000 karaktere",
  invalidPayment: "Metodë pagese e pavlefshme",
  terms: "Duhet të pranoni kushtet dhe termat",
  createAccount : "Krijo Llogari",
  differentDeliveryAddress : "Dërgo në një adresë tjetër",
  orderComments : "Komente për porosinë",
  payment : "PAGESA",
  cash : "Paguaj gjatë dorëzimit",
  cashText : "Paguaj me para në dorë kur produktet dorëzohen",
  clickAway : "Kliko dhe Merr",
  clickAwayText : "Merr produktet nga dyqani",
  paypalRadio : "Kartë krediti ose PayPal",
  paypalError : "Ju lutemi plotësoni formularin saktë!",
  personalDataInfo : "Të dhënat tuaja personale do të përdoren për nevojat e këtij sajti dhe për menaxhimin e llogarisë tuaj.",
  readThe : "Lexoni",
  iHaveRead : "Kam lexuar dhe pajtohem me",
  ofThisSite : "e këtij sajti",
  yourOrder : "Porosia Juaj",
  sendOrder : "Dërgo Porosinë",
  alreadyCustomer : "Jam tashmë klient",
  orderCompleteTitle: "POROSIA E PËRFUNDUAR!",
  orderCompleteLine1: "Porosia juaj është pranuar me sukses.",
  orderCompleteLine2: "Faleminderit për porosinë tuaj.",
  isCompany: "Lëshoni Faturë",
  companyAfm: "Numri i TVSH-së",
  companyDoy: "Zyra e Tatimeve",
  companyName: "Emri i Kompanisë",
  companyProfession: "Profesioni",
  bankTransfer: "Transfer Bankar i Drejtpërdrejtë",
  bankTransferText: "Transferoni në një nga llogaritë tona bankare.",
  bankLargeText: "Bëni pagesën tuaj direkt në llogarinë tonë bankare. Ju lutemi përfshini ID-në e porosisë tuaj në transferimin bankar. Porosia juaj do të dërgohet pasi të verifikohet transferimi juaj i parave.",
  bank: "Banka",
  nbgRadio: "Kartë krediti përmes Bankës NBG",
  without: "Pa",
  checkoutData: "Detajet e Pagesës / Dorëzimit",
  product: "PRODUKTI",
  subTotal: "NËNTOTALI",
  without: "PA",
  total: "TOTALI",
  doYouHave: "Keni tashmë një llogari? Kliko",
  here: "këtu",
  toConnect: "për t'u lidhur.",
  legalName: "Emri Ligjor",
  legalDescription: "Lloji i Kompanisë",
  postalAddress: "Rruga Kryesore",
  postalAddressNo: "Numri",
  postalAreaDescription: "Qyteti Kryesor",
  postalZipCode: "Kodi Postal",
  firmActDescription: "Aktiviteti Kryesor",
  companyPhone: "Telefoni",
  companyEmail: "Email",
  doyDescription: "Emri i Zyrës së Tatimeve",
  returnToShop: "KTHEHU NË DYQAN",
  alergies: "Keni ndonjë alergji ose intolerancë ndaj ndonjë ushqimi? Çfarëdo specifikë ushqimore? Kontaktoni dyqanin para se të vendosni porosinë tuaj."
}