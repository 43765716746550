import React, { useEffect, useState } from 'react'
import Carousel from './carousel/Carousel'
import ListProducts from './listProducts';
import { inject, observer } from "mobx-react";
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import SmallAlert from '../modals/smallAlert';
import VoucherModal from '../modals/voucherModal';
import LoyaltyModal from '../modals/loyaltyModal';
import { DateTime } from "luxon";
import {pickLanguageWord} from '../utils/pickLanguageWord.js';
import GreenSubmitButton from "../buttons/greenSubmitButton";
import Colors from '../assets/colors';
import {TABLE_QR_ORDERING_ONLY, QR_READ_ONLY, DEFAULT_EMAIL} from "../assets/globalConstants";

// Translations
import _useTranslate from '../hooks/_useTranslate';
import {commonEL} from "../locales/el/common";

const HomeBody = ({ feathersStore })=> {

  let common = _useTranslate(feathersStore.language, "common");

  let navigate = useNavigate();

  const {name} = useParams(); 
  let [searchParams, setSearchParams] = useSearchParams();

  const [successModal, setSuccessModal] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [voucherModal, setVoucherModal] = useState(false);
  const [voucher, setVoucher] = useState(null);
  const [loyaltyModal, setLoyaltyModal] = useState(false);
  const [loyaltyReward, setLoyaltyReward] = useState(0);
    
  let catId;
  let title;
  let titleTranslated; 

  //Browser BackButton and Refresh usage
  useEffect(()=>{    
    let payload =  {searchText: "", title: commonEL.all, titleTranslated:  pickLanguageWord(feathersStore.language, "all")};
    if ((name || searchParams) && feathersStore.categories) {    
      searchTree(feathersStore.categories, name);
     name && Object.assign(payload, {categoryId: catId, title: title, titleTranslated: titleTranslated});
     searchParams && Object.assign(payload, {searchText: searchParams.get('search') || ""});            
    }
    feathersStore.setSearchCategory(payload); 
 },[name, searchParams,  feathersStore.categories, feathersStore.language]);

  useEffect(()=>{
    const table = searchParams.get('__table');
    if(table && feathersStore.settings?.TABLE_QRCODE_PSW){
      if(table.slice(0, 8) === feathersStore.settings?.TABLE_QRCODE_PSW){
        feathersStore.setTableId(table?.slice(9))
        setWithExpiry('tableId', table?.slice(9), 240 * 60 * 1000)
        setSuccessModal(true)     
      }else if(getWithExpiry('tableId')){
        feathersStore.setTableId(getWithExpiry('tableId'))
      }else {
        setErrorModal(true)
      }
    }
  },[feathersStore.isAuthenticated && feathersStore.settings]);

  useEffect(()=>{ //voucher
    const asyncFn = async() => {
      const now = DateTime.now().setZone("Europe/Athens");
      const _vouchers = await feathersStore.coupons();
      const vouchers = _vouchers?.filter(coupon => coupon?.general)
      for(let voucher of vouchers){
        if(voucher.display){   
          let valid = false;
          if(voucher?.endDate){
            if(DateTime.fromISO(voucher.endDate) > now)valid = true;
          }else{
            if(DateTime.fromMillis(voucher.createdAt).plus({days : voucher.duration}) > now)valid = true;
          }
          if(valid){
            setVoucherModal(true);
            setVoucher(voucher);
          }
        }
      }
    }
    feathersStore?.isAuthenticated && asyncFn();
  },[feathersStore?.isAuthenticated]);

  useEffect(()=>{ //loyalty
    if(feathersStore?.user?.email !== DEFAULT_EMAIL){
      if(feathersStore.user?.loyaltyRewards?.length > 0){
        const totalReward = feathersStore.user?.loyaltyRewards
          ?.filter(r => !r?.applied)//Old rewards not yet applied
          ?.map(reward => reward?.value || 0)
          ?.reduce((a, b) => +a + +b, 0);
        if(totalReward > 0){
          setLoyaltyReward(totalReward);
          setLoyaltyModal(true);
        }
      }
    }
  },[feathersStore?.user]);

  const setWithExpiry = (key, value, ttl) => {
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + ttl, //ttl in milliseconds
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  const emptyCart = () => {
    const emptyItem = {
      'customerId': "",
      'bookingDate': "",
      'status': '',
      'items': [],
      'paymentMethod':"",
      'total': 0,
      'processed': false,
      'latitude': 0,
      'longitude': 0,
      'city': 1,
      'street': "",
      'streetNumber': "",
      'apartment': "",
      'postcode': "",
      'addressComments':""
    }
    localStorage.setItem("fdwa_cart", JSON.stringify([]));
    feathersStore.setOrderItem(emptyItem);
  }

  const getWithExpiry = (key) => {
    const itemStr = localStorage.getItem(key)   
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    if (now.getTime() > item.expiry) { 
      localStorage.removeItem(key);
      emptyCart();
      return null
    }
    return item.value
  }

 const searchTree = (tree, name) => {
   tree.forEach(element => {
    if(element.name?.trim() === name || feathersStore._translateCategoryName(element)?.trim === name){    
      catId = element.id;
      title =  element.name?.trim();
      titleTranslated = feathersStore._translateCategoryName(element);
    }else{
      element.childrens && searchTree(element.childrens, name)
    }
   }) 
  }  

  const closeErrorModal = () => {
    setErrorModal(false);  
    let errorWindow = window.open("https://appdate.gr", "_self");  
  }

  const closeVoucherModal = () => {
    setVoucherModal(false);  
  }

  const closeLoyaltyModal = () => {
    setLoyaltyModal(false);  
  }

  return(
    <>    
      <div className="body-container">
        {feathersStore?.isAuthenticated && (!name) && feathersStore?.settings ? (
          <>
            <div className="column-flex-center-100">
              <Carousel/>
            </div> 
          </>)
          : null
         }
        {feathersStore?.settings?.appBooking && !TABLE_QR_ORDERING_ONLY && !QR_READ_ONLY &&
          <div className="reservation-button-container">
            <GreenSubmitButton
              onClick={() => navigate("/reservation")}
              width="calc(100px + 14vw)"
              height="calc(8px + 2vw)"
              title={common.reserveTable}
              color={Colors.secondary}
              marginBottom={0}
              marginLeft={0}
              marginTop={"calc(8px + 2vw)"}
              fontSize={"calc(9px + 0.7vw)"}
              textTransform={"none"}
            ></GreenSubmitButton>
          </div>
        }
        <ListProducts />
            
     </div>    
     <SmallAlert 
        show={successModal} 
        mode="qrcode"
        heading={common.scannerSuccess} 
        onClose={() => setSuccessModal(false)} 
        message={common.scannerMessage} 
      />   
      <SmallAlert 
        show={errorModal}    
        heading={common.scannerErrorHeader} 
        mode="error"
        onClose={() => closeErrorModal()} 
        message={common.scannerError} 
      /> 
      <LoyaltyModal 
        show={loyaltyModal}      
        reward={loyaltyReward}
        onClose={() => closeLoyaltyModal()} 
      />
      {voucher &&  <VoucherModal 
        show={voucherModal}    
        code={voucher.code}     
        type={voucher.type} 
        reward={voucher.reward}
        minimumOrder={voucher?.minimumOrder || 0}
        promotionText={voucher.promotionText}
        onClose={() => closeVoucherModal()} 
      /> }
    </>
  )
}

export default inject('feathersStore')(observer(HomeBody));