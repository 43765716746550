import React,{ useState } from 'react';
import CategoryPic from './categoryPic';
import parse from 'html-react-parser';
import { inject, observer } from "mobx-react";
import { API_URL } from "../assets/globalConstants";
import "./account/Account.css";


const About = ({ feathersStore })=> {
    
  return(
    <div className="settings-sub-container-about">
      { feathersStore.settings?.images &&
        <CategoryPic image={feathersStore.settings?.images[0] ? `${API_URL}/images/${feathersStore.settings?.images[0]}` : require("../img/200_spinner.gif") }/>           
      }
      {feathersStore.settings?.shortDescription &&
        <div className="aboutView html-text ">
          {parse(`<div>${feathersStore._translateShortDescription()}</div>`)} 
        </div>
      }
    </div>
  )
}

export default inject('feathersStore')(observer(About));