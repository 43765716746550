import React, {useEffect, useState} from 'react';
import './Loyalty.css';
import RedeemOutlinedIcon from '@mui/icons-material/RedeemOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { DateTime } from "luxon";

const LoyaltyBar = ({campaign, endsIn, days}) => {

  const [pointer, setPointer] = useState(1);
  const [wayPoints, setWayPoints] = useState([]);
  const [biggestWayPoint, setBiggestWayPoint] = useState(0)

 
  useEffect(()=>{  
    setWayPoints(campaign.wayPoints)
    const BIGGEST_WAYPOINT = findBiggestWayPoint(campaign);
    setBiggestWayPoint(BIGGEST_WAYPOINT);
    let ANIMATION_END = ((campaign?.turnover || campaign?.wayPoints[0]?.turnover) / BIGGEST_WAYPOINT) * 100;
    if(ANIMATION_END > 100)ANIMATION_END = 100;
    let _pointer = 1;    
    const pointerInterval = setInterval(() => {
      _pointer++;
      if(_pointer <= ANIMATION_END)setPointer(_pointer)
    }, 100)
  return(() => pointerInterval && clearInterval(pointerInterval))
  }, [campaign]);

  const findBiggestWayPoint = (campaign) => { 
      return campaign?.wayPoints[campaign?.wayPoints?.length - 1].completion  
  }

  const findWayPointPercent = completion => {
    return (completion / biggestWayPoint) * 100; 
  }

  const daysToCampaignEnd = (camp) => { 
    const end = camp?.wayPoints[0]?.endDate ?  DateTime.fromISO(camp?.wayPoints[0]?.endDate) 
      : DateTime.fromISO(camp?.wayPoints[0]?.startDate).plus({days : camp?.wayPoints[0].duration});  
    const now = DateTime.now().setZone("Europe/Athens").startOf('day');
    return Math.ceil(end.diff(now, 'days').toObject().days);
  }

  const Pointer = ({style}) => ( 
    <div style={style} className='pointer-container'>
      {`${(campaign?.wayPoints[0]?.turnover || 0) * pointer / 100}€`}
    </div>
  )

  const Line = ({style}) => ( 
    <div style={style} className='animated-line'>
    </div>
  )

  const WayPoint = ({style, percent, completion, reward, promotionText, type}) => (
    <div style={style} className='loyalty-way-point-container'>
      <div className='promotion-text-container'> 
        <div className='promotion-text'>{`${promotionText}`}</div>
      </div>
      <div  className='loyalty-way-icon-plus-caption'>  
        <div style={ {backgroundColor: pointer >= percent ? "rgb(0, 145, 75)" : ""}}  className='loyalty-way-point-icon'>
          {pointer >= percent ?
            <CheckOutlinedIcon style={{fontSize: 20, color: "#fff"}}/>
            : 
            <RedeemOutlinedIcon style={{fontSize: 20, color: "#fff"}}/>
          }              
        </div>
        <div className='loyalty-way-point-caption'> 
          {`${completion}€`}
        </div>
      </div>    
      <div className='loyalty-way-point-bonus'> 
        {`${reward}${type === 'Percent' ? '%' : '€'}`}
      </div>
    </div>

  )

  return(    
     
      <div className="loyalty-bar-container">     
        <Pointer style={{ left: `${pointer}%`}}></Pointer>
        <Line style={{ width: `${pointer}%`}}></Line>
        { wayPoints?.map((wayPoint, index) => (
            <WayPoint 
              key={index}
              style={{ left: `calc(${findWayPointPercent(wayPoint.completion)}% - 95px`}}
              percent = {findWayPointPercent(wayPoint.completion)}
              completion = {wayPoint.completion}
              reward = {wayPoint.reward}
              promotionText ={wayPoint?.display ? (wayPoint?.promotionText || "") : ""}
              type = {wayPoint.type}
            >
            </WayPoint>
          ))
        }      
        <div className="days-till-end">{`${endsIn} ${daysToCampaignEnd(campaign)} ${days}`}</div>
      </div>
   
  )

}

export default LoyaltyBar;