import React from 'react';
import qrCodeAndroid from '../img/qr-code-bringfood-android.png';
import qrCodeIos from '../img/qr-code-bringfood-ios.png'
import MobileStoreButton from 'react-mobile-store-button';
import { inject, observer } from "mobx-react";

// Translations
import _useTranslate from '../hooks/_useTranslate';

const QrCode = ({ feathersStore }) => {

  let common = _useTranslate(feathersStore.language, "common");
 
  const androidUrl = feathersStore.settings?.ANDROID_URL || "";
  const urlIos = feathersStore.settings?.IOS_URL || "";
  const imageIos = 'https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1625702400&h=756295c0d4e95f66799873c9eee485ea'

  return (
    <div className="qr-code-container"> 
      <div className="arrow-down"></div>      
      <div className='qr-codes-wrap'>
        <div className="section column-flex-start">
          <div className='text-1'>{common.qrCodeBuy}</div>
          <div className="line-20"></div>
          <div className='text-2'>{common.qrCodeBuyMobile}</div>           
        </div>           
        <div className="section row-flex-center"> 
          <div className='row-flex-end-wrap section-2'>
            <div className="column-flex-center qr-component"> 
              <div className="hanging-wrapper">
                <div className="qr-row">
                  <div className= "dot"></div>
                  <div className="hanging-line left-rotate "></div>
                  <div className="hanging-line right-rotate"></div>
                </div>              
                <img className="qr-hanging-image" src={qrCodeAndroid} alt="QR Code" />
              </div>            
              <div className="scan-desk">Android</div>
            </div>
            <div className="column-flex-center ml-3 qr-component">  
              <div className="hanging-wrapper">
                <div className="qr-row">
                  <div className= "dot"></div>
                  <div className="hanging-line left-rotate "></div>
                  <div className="hanging-line right-rotate"></div>
                </div> 
                <img className="qr-hanging-image" src={qrCodeIos} alt="QR Code"/> 
              </div>
              <div className="scan-desk">iOS</div>
            </div>
          </div>          
        </div>
      </div>
        <div className="store-icons">
            <div className='text-1 header-mobile'>{common.qrCodeBuy}</div>
            <div className='android-icons'>
                <h5>{common.qrCodeForAndroid}</h5>
                <MobileStoreButton
                    store="android"
                    url={androidUrl}
                    linkProps={{ title: 'Android Store Button' }}
                    style={{ height: '60px', width: '140px' }}
                />               
            </div>
            <div className='ios-icons'>
                <h5>{common.qrCodeForIos}</h5>
                 <a href={urlIos}>
                  <img src={imageIos} alt="Download on the App Store" />
                </a>
            </div>
        </div>     
    </div>
    )
}

export default inject('feathersStore')(observer(QrCode));