import React from 'react';

const AppColors = {

    primary: '#1fc281',
    secondary: 'hsl(151, 100%, 28%)',
    productTitles: '#777777',
    menuTitles: '#fff',
    backgroundLight: '#1fc281',
    red: 'hsl(358, 78%, 50%)',
    pageTitle: '#f18401',
    settingsText: '#393e46',
    reviewBtn: '#094d92',
    footer: 'rgb(235, 236, 236)'
}

export default AppColors;