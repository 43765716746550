import React,{useState, useEffect, useRef} from 'react'
import { useNavigate } from 'react-router-dom';
import { inject, observer } from "mobx-react";
import _useTranslate from '../../hooks/_useTranslate';
import GreenSubmitButton from "../../buttons/greenSubmitButton";
import Colors from '../../assets/colors';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DigitalClock  } from '@mui/x-date-pickers/DigitalClock';
import { FormControl, MenuItem , FormHelperText, TextField  } from '@mui/material';
import { DateTime } from "luxon";
import Utils from '../../Utils';
import * as cloneDeep from 'lodash/cloneDeep';

import "./Reservation.css";

const Reservation = ({feathersStore})=> {
  
  let navigate = useNavigate();

  const firstNameElement = useRef();
  const lastNameElement = useRef();
  const phoneElement = useRef();
  const emailElement = useRef();

  let common = _useTranslate(feathersStore.language, "common");  
  let cart = _useTranslate(feathersStore.language, "cart");  

  const personsArray = [2, 3, 4, 5, 6, 7, 8, 9, 10];
  const today = DateTime.now().startOf("day");

  const roundHour = () => {
    let dt = DateTime.now().setZone("Europe/Athens")
    let roundedDate = dt;
    if(dt.minute < 30)roundedDate = dt.set({minute: 30}).startOf("minute");
    else roundedDate = dt.plus({ hours: 1}).startOf("hour");
    return roundedDate.plus({ hours: 1});  //add at least 1 hour before booking;
  }

  const [email, setEmail] = useState(feathersStore.user?.email  || ""); 
  const [firstName, setFirstName] = useState(feathersStore.user?.firstname || ""); 
  const [lastName, setLastName] = useState(feathersStore.user?.lastname || ""); 
  const [phone, setPhone] = useState(feathersStore.user?.phone || ""); 
  const [bookingComment, setBookingComment] = useState("");
  const [firstNameError, setFirstNameError] = useState(false); 
  const [lastNameError, setLastNameError] = useState(false); 
  const [phoneError, setPhoneError] = useState(false); 
  const [bookingPersons, setBookingPersons] = useState(2);
  const [bookingDate, setBookingDate] = useState(today.setLocale('el-GR'));
  const [bookingHour, setBookingHour] = useState(roundHour()); 
  const [tables, setTables] = useState([]);
  const weekdaysArray =  ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const extendedWeekdaysArray =  ["extendedMonday", "extendedTuesday", "extendedWednesday", "extendedThursday", "extendedFriday", "extendedSaturday", "extendedSunday"]
 
  useEffect(() => {
    setBookingPersons(2);
    setBookingDate(DateTime.now().startOf("day").setLocale('el-GR'));
    setBookingHour(roundHour());
  }, [feathersStore?.isAuthenticated]);

  useEffect(() => {
    const asyncFn = async() => {
      const _tables = await feathersStore.bookingTables();
      setTables(_tables);
      }
      feathersStore?.isAuthenticated && asyncFn();
  }, [feathersStore?.isAuthenticated, feathersStore.tablePatchedEvent]);

  const shouldDisableTime = (value, view, bookingDate, bookingPersons) => {
    let _bookingDate = cloneDeep(bookingDate);
    const weekday = (bookingDate.weekday + 6) % 7;    
    const luxonDay = feathersStore?.settings?.extendedDay ? extendedWeekdaysArray[weekday] : weekdaysArray[weekday];
    const dayData = feathersStore?.settings[luxonDay];   
    if(dayData){ 

      const openingDt = _bookingDate.set({ hour: +dayData.openingHours.split(':')[0], minute: +dayData.openingHours.split(':')[1], seconds: 0, milliseconds: 0 })
      let closingDt = _bookingDate.set({ hour: +dayData.closingHours.split(':')[0], minute: +dayData.closingHours.split(':')[1], seconds: 0, milliseconds: 0 })
      if(closingDt < openingDt)closingDt = _bookingDate.endOf("day");
      else closingDt = closingDt.minus({hours: 2}); //Two hours booking duration
      const valueDt = _bookingDate.set({ hour: +value.get("hour"), minute: +value.get("minute"), seconds: 0, milliseconds: 0 });    
      
    
      if((bookingDate.toLocaleString(DateTime.DATE_SHORT) === DateTime.now().setLocale('el-GR').toLocaleString(DateTime.DATE_SHORT)) 
          && (valueDt < roundHour())){        
        return true;
      }else  if((valueDt < openingDt || valueDt > closingDt) ){
        return true;
      }else if(checkUnAvailableTable(valueDt, bookingPersons)){
       return true;
      }
     
    }  
    return false;
  };

  const checkUnAvailableTable = (valueDt, bookingPersons) => {
    const availableTables = findAvailableTables(valueDt, bookingPersons);
    if(availableTables?.length < 1)return true;
    else return false;
  }

  const findAvailableTables = (valueDt, bookingPersons) => {
    const availableTables = tables
    .filter(table => (table.capacity >= +bookingPersons) && (table.capacity <  (+bookingPersons + 3))) // Do not book enormously big table
    .filter(t => {
      if(!t?.bookings || t.bookings.length < 1)return true;
      else{ 
        const availableHours = t.bookings
             .filter(booking => DateTime.fromISO(booking.bookingDate).set({ hour: +booking.bookingHour.split(":")[0], 
              minute: +booking.bookingHour.split(":")[1], seconds: 0, milliseconds: 0 }).plus({hours : 2}) <= valueDt);
        if(availableHours.length > 0) return true;   
        else return false; 
      }        
    })
    return availableTables;
  }

  const shouldDisableDate = (value) => {
    const weekday = (value.weekday+ 6) % 7;   
    const luxonDay = weekdaysArray[weekday];
    if (feathersStore?.settings[luxonDay]?.hoursEnabled) {
      return true;
    }  
    return false;
  };

  const createBooking = async() => {
    let _bookingDate = cloneDeep(bookingDate);
    const selectedDT = _bookingDate.set({ hour: +bookingHour.get("hour"), 
      minute: +bookingHour.get("minute"), seconds: 0, milliseconds: 0 })
    const newBooking =  {
      bookingName: firstName + " " + lastName,
      bookingPersons: bookingPersons, 
      bookingPhone: phone,           
      bookingDate: bookingDate.toISO(),            
      bookingHour: bookingHour.toLocaleString(DateTime.TIME_24_SIMPLE),          
      bookingComment: bookingComment || ''  ,
      webBooking: true          
    };
    const availableTables = findAvailableTables(selectedDT, bookingPersons)?.sort((a,b) => a.capacity - b.capacity);
    let tableToBePatched = availableTables[0];
    let newBookings = [...tableToBePatched?.bookings] || [];
    newBookings.push(newBooking);    
    const sortedBookings = newBookings.sort((a,b) => DateTime.fromISO(a.bookingDate).set({ hour: +a.bookingHour.split(":")[0], 
      minute: +a.bookingHour.split(":")[1], seconds: 0, milliseconds: 0 }) > 
      DateTime.fromISO(b.bookingDate).set({ hour: +b.bookingHour.split(":")[0], 
      minute: +b.bookingHour.split(":")[1], seconds: 0, milliseconds: 0 }) ? 1 : -1)
    let payload = {bookings: sortedBookings, newWebBooking: true};    
    
    try{
      await feathersStore.patchTable(tableToBePatched._id, payload);
      navigate('/');
    }catch(error){
      console.log(error)
    }
   
   
  } 

  useEffect(() => {
    setEmail(feathersStore.user?.email || "");
    setFirstName(feathersStore.user?.firstname || "");
    setLastName(feathersStore.user?.lastname || "");
    setPhone(feathersStore.user?.phone || "")
  }, [feathersStore.user])
    
  const firstNameChange = event => {    
    setFirstName(event.target.value);
    firstNameValidation(event.target.value);   
  };

  const lastNameChange = event => {    
    setLastName(event.target.value);
    lastNameValidation(event.target.value);   
  };

  const phoneChange = event => {    
    setPhone(event.target.value);
    phoneValidation(event.target.value);   
  };

  const emailChange = event => {    
    setEmail(event.target.value);
  };

  const bookingCommentChange = event => {    
    setBookingComment(event.target.value);
  };

  const firstNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setFirstNameError(true);
    }else{
     setFirstNameError(false);
   }
  }

  const lastNameValidation = val => {
    if (!Utils.validateName(val) ) {
      setLastNameError(true);
    }else{
     setLastNameError(false);
   }
  }

  const phoneValidation = val => {
    if (!Utils.validatePhone(val) ) {
      setPhoneError(true);
    }else{
     setPhoneError(false);
   }
  } 

  const enterFirstNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
        lastNameElement.focus();
    }
  }

  const enterLastNamePressed = (ev) => {   
    if (ev.key === 'Enter') {
      phoneElement.focus();
    }
  }  

  const enterPhonePressed = (ev) => {   
    if (ev.key === 'Enter') {
      firstNameElement.focus();
    }
  }

  const enterEmailPressed = (ev) => {   
    if (ev.key === 'Enter') {
      firstNameElement.focus();
    }
  }  
  
  const goToHome = () => {
    navigate('/');
  } 

  return(
  <> 
    <div className="body-container">
       <div className="grey-header">{common.reserveTable}</div>
      <div className="reservation-container">     
        <div className="reservation-body">
          <div className="reservation-section-header">{common.chooseHour}<div className="reservation-section-line"></div></div>
          <div className="reservation-select-row">
            <TextField
              select
              label={common.party}
              variant="outlined" 
              type="number" 
              defaultValue={2}
              InputProps={{ inputProps: { min: "2", max: "10", step: "1" } }}
              id="my-input" 
              value={+bookingPersons}
              onChange={(event) => setBookingPersons(+event.target.value)}
            >   
              {personsArray.map((person) => (
                <MenuItem key={person} value={+person}>
                  {person}
                </MenuItem>
              ))}
            </TextField>        
            <div className="reservation-second-input">             
              <DatePicker 
                label={common.pickDate}
                minDate={DateTime.now()}
                maxDate={DateTime.now().plus({ days: 15 })}
                value={bookingDate}
                onChange={(newValue) => setBookingDate(newValue)}
                shouldDisableDate={shouldDisableDate}
              />              
            </div>     
            <div className="reservation-second-input">             
              <DigitalClock 
                label={common.chooseHour}
                ampm={false}
                minTime={bookingDate}
                value={bookingHour}
                onChange={(newValue) => setBookingHour(newValue)}
                shouldDisableTime={(value, view) => shouldDisableTime(value, view, bookingDate, bookingPersons)}
              />              
            </div>         
          </div>      
          <div className="reservation-table-footer"> 
            <div className="reservation-select-row">
              <FormControl > 
                <TextField
                  label={common.firstName + "*"}
                  variant="outlined" 
                  value={firstName}
                  error={firstNameError}
                  ref={firstNameElement}
                  onChange={firstNameChange}
                  onKeyPress={enterFirstNamePressed}
                />   
                {firstNameError && <FormHelperText id="component-error-text" >{common.firstNameError}</FormHelperText>}
              </FormControl>
              <div className="reservation-second-input">             
                <FormControl > 
                  <TextField
                    label={common.lastName + "*"}
                    variant="outlined" 
                    value={lastName}
                    error={lastNameError}
                    ref={lastNameElement}
                    onChange={lastNameChange}
                    onKeyPress={enterLastNamePressed}
                  />   
                  {lastNameError && <FormHelperText id="component-error-text" >{common.lastNameError}</FormHelperText>}
                </FormControl>            
              </div>   
            </div>
            <div className="reservation-select-row">
              <FormControl >
                <TextField
                  label={common.phone + "*"} 
                  variant="outlined" 
                  value={phone}     
                  error={phoneError}
                  ref={phoneElement}
                  onChange={phoneChange}              
                  onKeyPress={enterPhonePressed}  
                /> 
                {phoneError && <FormHelperText id="component-error-text" >{common.phoneError}</FormHelperText>}
              </FormControl>  
              <div className="reservation-second-input">             
                <TextField               
                  label={common.email} 
                  variant="outlined" 
                  value={email} 
                  onKeyPress={enterEmailPressed}
                  onChange={emailChange}
                />                
              </div> 
            </div>      
            <div className="reservation-select-row">
              <TextField 
                sx={{width: "100%"}}
                label={common.bookingComment}
                variant="outlined"
                multiline
                rows={4} 
                value={bookingComment}  
                onKeyPress={enterEmailPressed}
                onChange={bookingCommentChange}
              />   
            </div>     
          </div> 
        </div> 
        <div className="reservation-total-table">
          <div className="section-header">{common.yourReservation}<div className="section-line"></div></div>        
          <div className="reservation-total-body">
            <div className="reservation-totals-line">{common.party}: {bookingPersons}</div>
            <div className="reservation-totals-line">{common.date}: {bookingDate.toLocaleString()}</div>
            <div className="reservation-totals-line">{common.hour}: {bookingHour.toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
          </div>
          <div className="reservation-total-footer">        
            <GreenSubmitButton aria-labelledby='Make reservation' 
              disabled={!feathersStore.restaurantOpen || !firstName || !lastName || !phone || !bookingDate || !bookingHour}
              color={Colors.secondary}
              title={common.reserveTableUC} 
              onClick={createBooking} 
              width="100%"
            ></GreenSubmitButton>         
            <GreenSubmitButton aria-labelledby='Complete order' 
              color={Colors.secondary}
              title={cart.continueShopping} 
              onClick={goToHome} 
              width="100%"
              variant="outlined"
            ></GreenSubmitButton>       
          </div>
        </div>   
      </div>   
    </div>   
  </>
  )
}

export default inject('feathersStore')(observer(Reservation));