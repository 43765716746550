import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import GreenSubmitButton from '../buttons/greenSubmitButton';
import AppColors from '../assets/colors';
import { inject, observer } from "mobx-react";
import VerificationErrorModal from './verificationErrorModal';
import { DEFAULT_EMAIL, DEFAULT_PSW } from "../assets/globalConstants";
import SmallAlert from "./smallAlert";
import Box from '@mui/material/Box';

// Translations
import _useTranslate from '../hooks/_useTranslate';

let box0;
let box1;
let box2;
let box3;

const style = {
  position: 'absolute',
  width: {
    xs: '100%',
    sm: '80%', 
    md: 600,  
  },
  height: 600,
  top: {xs: 60, sm: '50%'},
  left: {xs: 0, sm: '50%'},
  transform: {sm: 'translate(-50%, -50%)'}, 
  bgcolor: 'background.paper',
  border: '2px solid #000',
  borderRadius: 4,
  boxShadow: 24,
  p: 4,
};

const VerificationCode = ({visible, onClickCloseCode, payload,  feathersStore})=> {

  let common = _useTranslate(feathersStore.language, "common");  

  const [value0, setValue0] = useState('');
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorOnCreate, setErrorOnCreate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");  

  const handleTextChange0 = (e)=> {
    e.preventDefault();
    setValue0('');
    const value = e.target.value;
    if(value && !isNaN(value)){
      setValue0(value);  
      box1.focus(); 
    }     
  }

  const handleTextChange1 = (e)=> {
    setValue1('');
    const value = e.target.value;
    if(value && !isNaN(value)){      
      setValue1(value);  
      box2.focus(); 
    }     
   }

  const handleTextChange2 = (e)=> {
    setValue2('');
    const value = e.target.value;
    if(value && !isNaN(value)){
      setValue2(value);  
      box3.focus(); 
    }    
  }

  const handleTextChange3 = (e)=> {
    setValue3('');
    const value = e.target.value;
    if(value && !isNaN(value)){
      setValue3(value); 
    }
  }

  const exitError = () => {
    setError(false);
    onClickCloseCode();
  }

  const resend = async() => {
    const { applicationId, firstName, lastName, email } = payload
    await feathersStore.sendAgain(applicationId, firstName, lastName, email)
    setError(false);
  }

  const submit = () => { 
    const pin = value0 + value1 + value2 + value3;
    const { applicationId, firstName, lastName, email, password, phone } = payload  
    setLoading(true);
    feathersStore.newestEmail(applicationId)
        .then(async newestEmail =>{         
              const { random4digit } = newestEmail[0];                  
              if(+pin === random4digit){
                try{                
                  const user = await feathersStore.createUser(firstName, lastName, email, password, phone);
                  user && feathersStore.login(email, password)
                  .then(async(auth) => {         
                    await feathersStore.setObservables();
                    await feathersStore.updateApplicationToProcessed(applicationId)
                    setLoading(false); 
                    onClickCloseCode();
                  })
                  .catch(error => console.log('error while authenticating: ', error));                
                }catch(error){
                  console.log('error while creating user: ', error) 
                  feathersStore.login(DEFAULT_EMAIL, DEFAULT_PSW)
                  .then(async(auth) => {         
                      await feathersStore.setObservables();
                      setErrorMessage(error.message);                         
                      setErrorOnCreate(true);
                      onClickCloseCode();
                  })
                  setLoading(false); 
                }
              }else{
                setLoading(false); 
                setValue0('');
                setValue1('');
                setValue2('');
                setValue3('');
                setError(true);
                return;
              }
        });
  }

 
  return (
    <section>
      <Modal 
        open={visible} 
        onClose={onClickCloseCode}
        aria-labelledby="login-modal"
        aria-describedby="login-or-register"
      >
      <Box sx={style}>
        <div className="modalContainer">
          <div className="modalTitleContainer">           
            <h1>{common.verificationCode}</h1>                               
          </div>
          <p style={{color: 'grey'}}>{common.verificationInfo}<br/> {payload?.email}</p>
          <div className="modalVerificationCodeContainer">
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              onChange={handleTextChange0}                     
              ref= {(element) => box0 = element}              
              required
              aria-label='verification code'
              style={{backgroundColor: value0.length > 0 && AppColors.amazonColor}}  
            />
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1"
              aria-label='verification code'
              onChange={handleTextChange1}                      
              ref= {(element) => box1 = element}             
              required
              style={{backgroundColor: value1.length > 0 && AppColors.amazonColor}}   
            />
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              aria-label='verification code'
              onChange={handleTextChange2}                       
              ref= {(element) => box2 = element}              
              required
              style={{backgroundColor: value2.length > 0 && AppColors.amazonColor}}   
            />
            <input className="modalVerificationCodeBox" 
              type="text" 
              maxLength="1" 
              aria-label='verification code'
              onChange={handleTextChange3}                        
              ref= {(element) => box3 = element}              
              required
              style={{backgroundColor: value3.length > 0 && AppColors.amazonColor}}   
            />
          </div>
          <div className="modalFacebookContainer">
            <GreenSubmitButton
              aria-labelledby='Submit'
              title={common.enterCode}
              loading={loading} 
              onClick={submit}
              disabled={value0.length < 1 || value1.length < 1 || value2.length < 1 || value3.length < 1 }
            >              
            </GreenSubmitButton>
          </div>         
        </div>
        <VerificationErrorModal
          visible={error}
          exit={exitError}
          resend={resend}
        > 
        </VerificationErrorModal>
        </Box>
      </Modal>   
      <SmallAlert
        show={errorOnCreate} 
        mode="stock"
        heading="error while creating user" 
        onClose={()=>setErrorOnCreate(false)} 
        message={errorMessage}
     />
    </section>
   
  );
}

export default inject('feathersStore')(observer(VerificationCode));
